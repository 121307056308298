import React from "react";
import dayjs from "dayjs";
import BetaTag from "../../../tk/bits/BetaTag";
import ControlBoard from "./ControlBoard";

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);


const serviceClassDefs = {
    'Host institute': {
        name: 'Host institute',
        label: 'Host institute',
        color: '#9ff9ff',
    },
    'Partner institute': {
        name: 'Partner institute',
        label: 'Partner institute',
        color: '#b4ff9f',
    },
    'Project': {
        name: 'Project',
        label: 'Project',
        color: '#ffd39f',
    },
    'World': {
        name: 'World',
        label: 'World',
        color: '#fcff9f',
    }
}

const columnDefs = {
    'backlog': {
        columnName: 'backlog',
        name: 'Submitted',
        subColumns: {
            waitingForExternal: {
                label: 'Waiting for author'
            }
        }
    },
    '1st Review Passed': {
        columnName: '1st Review Passed',
        name: '1st review passed',
    },
    'todo': {
        columnName: 'todo',
        name: 'Todo',
    },
    'inProgress': {
        columnName: 'inProgress',
        name: 'In curation',
        subColumns: {
            waitingForExternal: {
                label: 'Waiting for author'
            }
        }
    },
    'Author approval': {
        columnName: 'Author approval',
        name: 'Author approval',
        subColumns: {
            waitingForExternal: {
                label: 'Waiting for author'
            }
        }
    },
    'done': {
        columnName: 'done',
        name: 'Done this week',
    }
}

const BoardOpts = Object.freeze({
    DEFAULT: {
        values: {
            board: 'PDI',
            showServiceClassPercentages: true,
            jiraProjectId: 10010,
            components: [],
        }
    }
})
const PDIBoardPage = () => {
    return (
        <div style={{padding: "4px"}}>
            <ControlBoard
                title={<h2>Editorial Process Control <BetaTag/></h2>}
                serviceClassDefs={serviceClassDefs}
                columnDefs={columnDefs}
                options={BoardOpts}
            />
        </div>
    )
}

export default PDIBoardPage;