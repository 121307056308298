import {QuestionCircleFilled} from "@ant-design/icons";
import PropTypes from "prop-types";

export const WikiBaseUrl = "https://wiki.pangaea.de/wiki/Intern:Editorial";

const HelpLink = ({category, title, href}) => {
    let myHref = WikiBaseUrl;
    if (href) {
        myHref = href;
    } else {
        if (category) {
            myHref += "/" + category;
        }
        if (title) {
            myHref += "/" + title;
        }
    }
    return (
        <a href={myHref} target="_blank" rel="noreferrer">
            <QuestionCircleFilled/>
        </a>
    )
}

HelpLink.propTypes = {
    category: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string
}

export default HelpLink;
