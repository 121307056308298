import EntityCreatorModal from "./entities/EntityCreatorModal";
import ModalPop from "../../tk/bits/ModalPop";
import ListFileImporter from "./listimport/ListFileImporter";
import JiraDirectImportModal from "./dataset/JiraDirectImportModal";
import {
    addEntityDetailTab,
    boardScreen,
    mixerScreen,
    openList,
    splashScreen,
    tabListImport
} from "../../lib/flexlayoutTabTools";
import entityDefs from "./entities/entityDefs";
import DatafileDirectImportModal from "./dataset/DatafileDirectImportModal";
import PangaeaLogo from "../../tk/bits/PangaeaLogo";
import {Button, Dropdown, Layout, Space, Switch} from "antd";
import {ImportOutlined, OrderedListOutlined, PlusOutlined, UserOutlined} from "@ant-design/icons";
import DetailBarButton from "../../tk/forms/DetailBarButton";
import RecentChangesButton from "./RecentChangesButton";
import {WikiBaseUrl} from "../../tk/bits/HelpLink";
import {logoutUrl} from "../../hooks/useWS2Axios";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {nextInstanceId} from "./EditorialApp";
import MyUserContext from "../../contexts/MyUserContext";
import {useNavigate} from "react-router-dom";
import BetaTag from "../../tk/bits/BetaTag";
import GlobalSearch from "./GlobalSearch";

const {Header} = Layout;


const logoImgStyle = {
    float: "left",
}

const EditorialHeaderMenu = ({refLayout, setHandleClone}) => {
    const {myUser} = useContext(MyUserContext);
    const navigate = useNavigate();
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [createEntityDef, setCreateEntityDef] = useState(undefined);
    const [createDefaults, setCreateDefaults] = useState({});
    const [instanceId, setInstanceId] = useState(nextInstanceId());
    const [isFileImportModalOpen, setIsFileImportModalOpen] = useState(false);
    const [fileImportEntityDef, setFileImportEntityDef] = useState();
    const [importState, setImportState] = useState();
    const [jiraDirectImportOpen, setJiraDirectImportOpen] = useState(false);
    const [datafileDirectImportOpen, setDatafileDirectImportOpen] = useState(false);
    const [settingsWideTags, setSettingsWideTags] = useState(false);

    const handleFileImportPop = useCallback(() => {
            tabListImport(refLayout, importState);
            setIsFileImportModalOpen(false);
        },
        [importState, refLayout]
    );

    const listMenuItems = useMemo(() => {
            return Object.entries(entityDefs)
                .filter(([entityType, entityDef]) => !entityDef.noMenu)
                .filter(([entityType, entityDef]) => entityType !== "dataset")
                .map(([entityType, entityDef], i) => ({
                        label: entityDef.labelPl,
                        key: 'L' + entityDef.entityType
                    })
                )
                .sort((a, b) => a.label > b.label);
        },
        []
    );

    const createMenuItems = useMemo(() => {
            return Object.entries(entityDefs)
                .filter(([entityType, entityDef]) => entityDef.withCreate && !entityDef.noMenu)
                .map(([entityType, entityDef]) => ({
                        label: entityDef.label,
                        key: 'C' + entityDef.entityType
                    })
                )
                .sort((a, b) => a.label > b.label);
        },
        []
    );

    const importMenuItems = useMemo(() => {
            const listImports = Object.entries(entityDefs)
                .filter(([entityType, entityDef]) => entityDef.withImport && !entityDef.noMenu)
                .map(([entityType, entityDef]) => ({
                        label: entityDef.labelPl,
                        key: 'I' + entityDef.entityType
                    })
                )
                .sort((a, b) => a.label > b.label);
            return [
                {
                    label: "Dataset from JIRA",
                    key: 'Ijiradirectimport'
                },
                {
                    label: "Dataset from data file",
                    key: 'Idatasetfromfile'
                },
                {
                    type: 'divider'
                },
                ...listImports
            ];
        },
        []
    );

    const handleOpenList = useCallback((e, refLayout) => {
            const entityDef = entityDefs[e.key.substring(1)];
            openList(entityDef, refLayout);
        },
        []
    );

    const handleCreate = useCallback((entityDef, defaults) => {
            setCreateEntityDef(entityDef);
            setCreateModalVisible(true);
            setCreateDefaults(defaults);
            setInstanceId(nextInstanceId());
        },
        [setCreateEntityDef, setCreateModalVisible, setCreateDefaults, setInstanceId]
    );

    const handleClone = useCallback((entity, entityDef) => {
            if (!entityDef) {
                console.warn("entityDef not set, but calling handleClone", entity);
                return;
            }
            if (entityDef.clonePreprocessFn) {
                handleCreate(entityDef, entityDef.clonePreprocessFn(entity));
            } else {
                handleCreate(entityDef, entity);
            }
        },
        [handleCreate]
    );

    const handleImport = useCallback(e => {
            const key = e.key.substring(1);
            if (key === 'jiradirectimport') {
                setInstanceId(nextInstanceId());
                setJiraDirectImportOpen(true);
            } else if (key === 'datasetfromfile') {
                setInstanceId(nextInstanceId());
                setDatafileDirectImportOpen(true);
            } else {
                setIsFileImportModalOpen(true);
                setFileImportEntityDef(entityDefs[key]);
            }
        },
        [setInstanceId, setJiraDirectImportOpen, setDatafileDirectImportOpen, setIsFileImportModalOpen, setFileImportEntityDef]
    );

    useEffect(() => {
            setHandleClone(handleClone);
        },
        [handleClone, setHandleClone]
    );

    return (<>
        <EntityCreatorModal
            visible={createModalVisible}
            setVisible={setCreateModalVisible}
            entityDef={createEntityDef}
            newDefaults={createDefaults}
            instanceId={instanceId}
        />
        <ModalPop
            open={isFileImportModalOpen}
            setOpen={setIsFileImportModalOpen}
            width="90%"
            footer={[]}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsFileImportModalOpen(false)}
            onPop={handleFileImportPop}
        >
            <ListFileImporter
                entityDef={fileImportEntityDef}
                onChange={setImportState}
            />
        </ModalPop>
        <JiraDirectImportModal
            instanceId={instanceId}
            open={jiraDirectImportOpen}
            setOpen={setJiraDirectImportOpen}
            onDone={newEntity => {
                setJiraDirectImportOpen(false)
                addEntityDetailTab(refLayout.current, newEntity.me, entityDefs.dataset);
            }}
            refLayout={refLayout}
        />
        <DatafileDirectImportModal
            open={datafileDirectImportOpen}
            setOpen={setDatafileDirectImportOpen}
            instanceId={instanceId}
            onCancel={() => setDatafileDirectImportOpen(false)}
            onDone={newEntity => {
                setDatafileDirectImportOpen(false)
            }}
        />
        <Header className="header" style={{backgroundColor: process.env.REACT_APP_MODE_BAR_COLOR}}>
            <PangaeaLogo
                subtext="editorial"
                onClick={() => {
                    //model.doAction()
                    return navigate("/");
                }}
            />
            <Space align='center'>
                <Dropdown.Button
                    onClick={() => {
                        openList(entityDefs.dataset, refLayout);
                    }}
                    type="primary"
                    style={logoImgStyle}
                    menu={{
                        items: listMenuItems,
                        onClick: (e) => handleOpenList(e, refLayout)
                    }}
                    trigger="click"
                >
                    <OrderedListOutlined/> List Datasets
                </Dropdown.Button>
                <Dropdown
                    menu={{
                        items: createMenuItems,
                        onClick: (e) => handleCreate(entityDefs[e.key.substring(1)])
                    }}
                    trigger="click"
                >
                    <DetailBarButton>
                        <PlusOutlined/> Create
                    </DetailBarButton>
                </Dropdown>
                <Dropdown
                    menu={{
                        items: importMenuItems,
                        onClick: (e) => handleImport(e, refLayout)
                    }}
                    trigger="click"
                >
                    <DetailBarButton>
                        <ImportOutlined/> Import
                    </DetailBarButton>
                </Dropdown>
                <GlobalSearch refLayout={refLayout}/>
            </Space>
            <span
                style={{
                    float: "right",
                    color: "#FFF"
                }}
            >
                <Space>
                    <Switch
                        checked={settingsWideTags}
                        onChange={checked => setSettingsWideTags(checked)}
                        checkedChildren="wide"
                        unCheckedChildren="narrow"
                    />
                    <RecentChangesButton/>
                    <Dropdown
                        menu={{
                            items: [
                                {label: "Splash", key: "splash"},
                                {label: <span>MiXeR</span>, key: "mixer"},
                                {label: <span>Development board</span>, key: "pmwBoard"},
                                {label: <span>Curation board <BetaTag/></span>, key: "pdiBoard"},
                                {label: "Profile", key: "profile"},
                                {type: 'divider'},
                                {label: "Help", key: "help"},
                                {label: "Queue status", key: "queuestatus"},
                                {label: "JIRA PDI issues", key: "pdiissues"},
                                {label: "Wiki public", key: "wikipublic"},
                                {label: "Wiki internal", key: "wikiinternal"},
                                {type: 'divider'},
                                {label: "Log out", key: "logout"}
                            ],
                            onClick: (e) => {
                                switch (e.key) {
                                    case "mixer":
                                        mixerScreen(refLayout);
                                        break;
                                    case "pdiBoard":
                                        boardScreen(refLayout, "PDI");
                                        break;
                                    case "pmwBoard":
                                        boardScreen(refLayout, "PMW");
                                        break;
                                    case "splash":
                                        splashScreen(refLayout, myUser);
                                        break;
                                    case "profile":
                                        window.open('https://www.pangaea.de/user/', '_blank');
                                        break;
                                    case "help":
                                        window.open(WikiBaseUrl, '_blank');
                                        break;
                                    case "logout":
                                        window.location.href = logoutUrl();
                                        break;
                                    case "queuestatus":
                                        window.open('https://secure.pangaea.de/curator/queue.php', '_blank');
                                        break;
                                    case "wikipublic":
                                        window.open('https://wiki.pangaea.de/wiki/Main_Page', '_blank');
                                        break;
                                    case "wikiinternal":
                                        window.open('https://wiki.pangaea.de/wiki/Intern:Main_Page', '_blank');
                                        break;
                                    case "pdiissues":
                                        window.open('https://issues.pangaea.de/projects/PDI/issues/PDI-33224?filter=allopenissues', '_blank');
                                        break;
                                    default:
                                }
                            }
                        }}
                        trigger="click"
                    >
                        <Button type="link" style={{color: "white", top: "-5px"}}>
                            <UserOutlined/> {myUser.name}
                        </Button>
                    </Dropdown>
                </Space>
            </span>
        </Header>

    </>)
}

export default EditorialHeaderMenu;