import useWS2Axios from "../../hooks/useWS2Axios";
import useEntitySearch from "../../hooks/useEntitySearch";
import React, {useMemo, useState} from "react";
import {nextInstanceId} from "../../pages/editorial/EditorialApp";
import {Button, Divider, Select, Space} from "antd";
import EntityCreatorModal from "../../pages/editorial/entities/EntityCreatorModal";
import {PlusOutlined} from "@ant-design/icons";

const {Option} = Select;


const AddEntitySelect = ({entityDef, onChange, onCreated, altEntityType, filterFn}) => {
    const {ws2Axios} = useWS2Axios();
    const entitySearch = useEntitySearch(altEntityType || entityDef.entityType, undefined, 1000, ws2Axios);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [newVisible, setNewVisible] = useState(false);
    const [instanceId, setInstanceId] = useState(nextInstanceId());
    const compDropdownButtons = useMemo(() => ([
            <Button
                onClick={() => {
                    setNewVisible(true);
                    setInstanceId(nextInstanceId());
                }}
                key={"newbutton"}
            >
                + new
            </Button>
        ]),
        []
    );
    return (
        <>
            <EntityCreatorModal
                visible={newVisible}
                setVisible={setNewVisible}
                entityDef={entityDef}
                onCreated={newEntity => {
                    setNewVisible(false);
                    onCreated(newEntity.me);
                }}
                instanceId={instanceId}
            />
            <Select
                showSearch
                style={{width: '100%', minWidth: '200px'}}
                value={{value: null, label: <span><PlusOutlined/> Add {entityDef.label}</span>}}
                onChange={onChange}
                onSearch={s => entitySearch.doSearch(s)}
                filterOption={false}
                notFoundContent={null}
                allowClear={false}
                labelInValue={true}
                placeholder={"Add " + entityDef.label}
                open={dropdownVisible}
                onDropdownVisibleChange={open => {
                    if (!open) {
                        setDropdownVisible(false);
                    }
                }}
                onInputKeyDown={() => {
                    setDropdownVisible(true);
                }}
                onSelect={() => {
                    setDropdownVisible(false);
                }}
                onFocus={() => {
                    entitySearch.doSearch("").then(() => setDropdownVisible(true));
                }}
                onClick={() => {
                    entitySearch.doSearch("").then(() => setDropdownVisible(true));
                }}
                dropdownRender={menu =>
                    <>
                        {menu}
                        <Divider style={{margin: '8px 0',}}/>
                        <Space style={{padding: '0 8px 4px'}}>
                            {compDropdownButtons}
                        </Space>
                    </>
                }
                popupMatchSelectWidth={300}
            >
                {entitySearch.result.filter(filterFn || (() => true)).map(value =>
                    <Option key={value.id} value={value.id}>{value.name}</Option>
                )}
            </Select>
        </>
    )
}

export default AddEntitySelect;
