import React from "react";
import Mixer from "./Mixer";


const MixerPage = () => {

    return (
        <div style={{padding: "4px"}}>
            <h2>MiXeR</h2>
            <Mixer/>
        </div>
    )
}

export default MixerPage;