const humanReadableSize = sizeInBytes => {
    let size = sizeInBytes;
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        ++i;
    }
    let fractionDigits = 1;
    if (i === 0) {
        fractionDigits = 0;
    }
    return [size.toFixed(fractionDigits), units[i]];
}

export const fileSize = sizeInBytes => {
    const [size, unit] = humanReadableSize(sizeInBytes);
    return size + ' ' + unit;
}

const FileSize = ({sizeInBytes}) => {
    const [size, unit] = humanReadableSize(sizeInBytes);
    return <span>{size}&nbsp;{unit}</span>;
}

export default FileSize;