import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const AwardOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Award number" value={entity.awardNumber}/>
            <OverviewLine label="Title" value={entity.title}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="Project" value={entity.project} entityDef={entityDefs.project}/>
        </div>
    )
}

export default AwardOverview;