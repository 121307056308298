import {BugTwoTone} from "@ant-design/icons";
import React from "react";
import PropTypes from "prop-types";


const NetworkError = (props) => {
    const {code, message} = props;
    return (
        <div style={{height: "120px", width: "100%"}}>
            <BugTwoTone twoToneColor="#f24"/>
            <BugTwoTone twoToneColor="#f24"/>
            <BugTwoTone twoToneColor="#f24"/>
            <BugTwoTone twoToneColor="#f24"/>
            <BugTwoTone twoToneColor="#f24"/><br/>
            {code && <><span style={{fontSize: "1.6em"}}>{code}</span><br/></>}
            {Array.isArray(message) &&
            message.map(m => <span>{m}</span>)
            }
            {!Array.isArray(message) &&
            message
            }
            {!message &&
            <span>Oh no, an error!</span>
            }
        </div>
    );
}

NetworkError.propTypes = {
    code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
}

export default NetworkError;