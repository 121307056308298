import {useCallback, useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import LRU from "../lib/LRU";
import useWS2Axios from "./useWS2Axios";
import useReload from "./useReload";

const cache = new LRU(500);


const useMatrixData = (datasetConfig) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const {ws2Axios} = useWS2Axios();
    const {reload, reloadDependency} = useReload();

    const getData = useCallback((idSeries, idConfigField, reloadDependency) => {
            let cacheKey = idSeries + '-' + idConfigField + '-' + reloadDependency;
            const cachedItem = cache.get(cacheKey);
            if (cachedItem !== undefined) {
                return new Promise((resolve, reject) => {
                    resolve(cachedItem);
                })
            }
            if (idSeries !== null) {
                return ws2Axios
                    .get(apiBaseUrl
                        + "data"
                        + "/" + idSeries
                        + "/" + idConfigField
                    )
                    .then(response => {
                        cache.set(cacheKey, response.data);
                        return response.data;
                    });
            } else {
                return new Promise((resolve, reject) => {
                    resolve([{
                        sequence: 1,
                        value: "3031468",
                        dataStatus: {id: 0, name: "valid"}
                    }])
                        .then(response => {
                            cache.set(cacheKey, response.data);
                            return response.data;
                        });
                });
            }
        },
        [ws2Axios]
    );

    useEffect(() => {
            const dataBuilder = [];

            const loadData = (index, onDone, onError) => {
                if (index >= datasetConfig.length) {
                    onDone(dataBuilder);
                    return;
                }

                const dc = datasetConfig[index];
                if (dc.datasetConfigField.id === null) {
                    dataBuilder.push([]);
                    loadData(index + 1, onDone, onError)
                    return;
                }

                if(dc.dataSeries.dummy) {
                    dataBuilder.push([]);
                    loadData(index + 1, onDone, onError)

                } else {
                    getData(dc.dataSeries.id, dc.datasetConfigField.id, reloadDependency)
                        .then(data => {
                            dataBuilder.push(data);
                            loadData(index + 1, onDone, onError)
                        })
                        .catch(error => {
                            onError(error);
                        })
                }
            }

            setIsLoading(true);
            loadData(0,
                data => {
                    let maxSequence = 0;
                    const indexedData = data.map(seriesData => {
                        const result = {};
                        seriesData.forEach(cellData => {
                            result[cellData.sequence] = cellData;
                            if (cellData.sequence > maxSequence) {
                                maxSequence = cellData.sequence;
                            }
                        })
                        return result;
                    })

                    const transformedData = [];
                    for (let i = 0; i < maxSequence + 1; i++) {
                        let item = {
                            sequence: i
                        };
                        indexedData.forEach((seriesData, j) => {
                            if (!seriesData[i]) return;
                            item[j] = seriesData[i];
                        });
                        transformedData.push(item)
                    }

                    setData(transformedData);
                    setIsLoading(false);
                },
                (error) => {
                    setIsLoading(false);
                    setError(error.response);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [datasetConfig, reloadDependency]
    );

    return {data, isLoading, error, reload};
}

export default useMatrixData;