import {apiBaseUrl} from "./config";


export const handleCatchErrorResponse = (errorResponse, onError, setResult, fileName) => {
    const errors = [];
    if (errorResponse.message) {
        errors.push(errorResponse.message);
    }

    if (errorResponse.response) {
        if (errorResponse?.response?.data?.message) {
            errors.push(errorResponse.response.data.message);
        }

        if (errorResponse?.response?.data?.generalErrors) {
            errors.push(...errorResponse.response.data.generalErrors);
        }
    }

    if (setResult !== undefined) {
        setResult({
            filename: fileName,
            errors: errors
        });
    }

    if (onError) {
        onError(errorResponse);
    }
};


export const uploadFile = (myAxios, onSuccess, onError, file, setResult, apiPath) => {
    const uploadTimeoutMilliseconds = process.env.REACT_APP_UPLOAD_TIMEOUT_MS;
    const formData = new FormData();
    formData.append(
        "file-0",
        file,
        file.name
    );
    return myAxios
        .post(
            apiBaseUrl + apiPath,
            formData,
            {timeout: uploadTimeoutMilliseconds}
        )
        .then(response => {
            setResult({...response.data, filename: file.name});
            if (200 <= response.status && response.status <= 299) {
                if (onSuccess) {
                    onSuccess(response);
                }
            } else {
                if (onError) {
                    onError(response);
                }
            }
        })
        .catch(errorResponse => {
            handleCatchErrorResponse(errorResponse, onError, setResult);
        });
}

export const importMatrix = (myAxios, onSuccess, onError, setResult, apiPath, matrixMetadata) => {
    return myAxios.post(
        apiBaseUrl + apiPath,
        matrixMetadata
    )
        .then(response => {
            setResult(response.data);
            if (response.data.success) {
                if (onSuccess !== undefined) {
                    onSuccess(response);
                }
            } else {
                if (onError !== undefined) {
                    onError(response);
                }
            }
        })
        .catch(errorResponse => {
            const errors = [];
            if (errorResponse.response.data.message) {
                errors.push(errorResponse.response.data.message);
            }
            if (errorResponse.response.data.generalErrors) {
                errors.push(...errorResponse.response.data.generalErrors);
            }
            setResult({errors});
            if (onError) {
                onError(errorResponse);
            }
        });
}