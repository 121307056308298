/*
const pendingStates = [
    'upload_registered',
    'upload_scheduled',
    'upload_running',
    'delete_scheduled',
    'delete_running',
];

const uploadCompletedStates = [
    'upload_succeeded',
    'upload_failed'
];

const deleteCompletedStates = [
    'delete_succeeded',
    'delete_failed'
];
*/


// const completedStates = [...uploadCompletedStates, ...deleteCompletedStates];

// const uploadStates = [...pendingStates, ...completedStates];


// const isPending = (state) => pendingStates.includes(state);
// const isUploadComplete = (state) => uploadCompletedStates.includes(state);
// const isDeleteComplete = (state) => deleteCompletedStates.includes(state);
// const isComplete = (state) => completedStates.includes(state);
// const uploadStateIsValid = (state) => uploadStates.includes(state);

/*
const uploadInfoSomePending = (info) => {
    for (const key of info.keys()) {
        const value = info.get(key);

        if (isPending(value.state)) {
            return true;
        }
    }

    return false;
};
 */


export const uploadInfoOnDraggerChange = (uploadInfo, draggerInfo) => {
    const filenamesInUploadInfo = [...uploadInfo.keys()];
    const filenamesInDraggerInfo = [...(draggerInfo.fileList.map(f => f.name))];

    // Process files that are currently in dragger.
    for (const f of draggerInfo.fileList) {
        const filename = f.name;

        // Case 1: file is in dragger info and in upload info.
        // Nothing to change here.
        if (filenamesInUploadInfo.includes(filename)) {
            // Nothing to do.
        }
        // Case 2: file is in dragger info but not in upload info.
        // Register file for upload.
        else {
            console.log('Registering file upload:', filename);
            const attachmentInfo = {
                options: undefined,
                complete: false,
                promise: undefined,
                state: 'upload_registered'
            };

            uploadInfo.set(filename, attachmentInfo);
        }
    }

    // Process files in upload info that are not in dragger any longer.
    for (const filename of filenamesInUploadInfo) {
        // Case 3: file is no longer in dragger.
        // Delete also from state.
        if (!filenamesInDraggerInfo.includes(filename)) {
            uploadInfo.delete(filename);
        }
    }
};


export const uploadInfoOnDraggerCustomRequest = (uploadInfo, draggerOptions) => {
    const file = draggerOptions.file;
    const filename = file.name;

    const attachmentInfo = uploadInfo.get(filename);
    if (attachmentInfo === undefined) {
        throw new Error("Internal error: cannot schedule upload for file '" + filename + "'");
    }

    const newAttachmentInfo = {
        ...attachmentInfo,
        options: draggerOptions,
        state: 'upload_scheduled'
    };

    console.log('Scheduling file upload:', filename);
    uploadInfo.set(filename, newAttachmentInfo);
};

/**
 * Check that there are no files in 'upload_registered' state,
 * i.e. any files that might have been in that state have
 * been moved at least to state 'upload_scheduled'.
 *
 * @param uploadInfo the upload information to be checked
 */
export const uploadInfoReady = (uploadInfo) => {
    for (const key of uploadInfo.keys()) {
        const val = uploadInfo.get(key);
        if (val.state === 'upload_registered') {
            return false;
        }
    }

    return true;
};

export const uploadInfoCompletedUploads = (uploadInfo) => {
    const result = [];

    for (const filename of uploadInfo.keys()) {
        const attachmentInfo = uploadInfo.get(filename);
        if (attachmentInfo.state === 'upload_succeeded') {
            result.push(filename);
        }
    }

    return result;
}