import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const JournalOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Discipline" value={entity.discipline}/>
            <OverviewLine label="ISSN" value={entity.issn}/>
            <OverviewLine label="Periodicity" value={entity.periodicity}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term}/>
        </div>
    )
}

export default JournalOverview;