import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import SelectEntityInput from "./SelectEntityInput";
import FormFieldError from "../forms/FormFieldError";
import {Space} from "antd";


export const transformSize = size => {
    switch (size) {
        case "tiny2":
            return {minWidth: "100px", width: "100px", maxWidth: "100px"};
        case "tiny":
            return {minWidth: "200px", width: "100%"};
        case "small":
        default:
            return {minWidth: "300px", width: "300px"};
        case "full":
            return {minWidth: "100%", width: "100%"};
    }
}

const SelectEntityField = (props) => {
    const {
        paramName,
        entityDef,
        disabled,
        apiPath,
        tagStyle,
        searchFilter,
        searchLimit,
        size,
        newDefaults,
        autoFocus,
        noNewButton,
        dropdownButtons,
        placeholder,
        dropdownVisible,
        setDropdownVisible,
    } = props;
    const {control, getValues} = useContext(FormExtContext);

    // console.log("get values are in SelectEntityField",getValues())
    // console.log("get fieldstate is in SelectEntityField",formState)
    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                // field = {
                //     "name": "method",
                //     "value": {
                //     "id": 12247,
                //         "name": "L-Band Microwave Radiometer"
                // },
                //     "onChange": "onChange(event)",
                //     "onBlur": "onBlur()",
                //     "ref": "ref(elm)"
                // }
                <Space>
                    <SelectEntityInput
                        entityDef={entityDef}
                        {...field}
                        value={getValues(paramName)}
                        onChange={field.onChange}
                        noNewButton={noNewButton}
                        dropdownVisible={dropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                        dropdownButtons={dropdownButtons}
                        disabled={disabled}
                        apiPath={apiPath}
                        tagStyle={tagStyle}
                        searchFilter={searchFilter}
                        searchLimit={searchLimit}
                        size={size}
                        newDefaults={newDefaults}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                    />
                    <FormFieldError paramName={paramName}/>
                </Space>
            }
        />
    )
}

SelectEntityField.propTypes = {
    paramName: PropTypes.string,
    placeholder: PropTypes.string,
    entityDef: PropTypes.object,
    disabled: PropTypes.bool,
    apiPath: PropTypes.string,
    tagStyle: PropTypes.object,
    searchFilter: PropTypes.func,
    searchLimit: PropTypes.number,
    newDefaults: PropTypes.object,
    size: PropTypes.string,
    autoFocus: PropTypes.bool,
    noNewButton: PropTypes.bool,
    dropdownButtons: PropTypes.array,
    dropdownVisible: PropTypes.bool,
    setDropdownVisible: PropTypes.func
}

export default SelectEntityField;