import React, {useMemo} from "react";
import {BrowserRouter} from "react-router-dom";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ConfigProvider} from "antd";
import {pangaeaColor} from "./lib/globalColors";
import Color from "color";
import AppRoutes from "./AppRoutes";
import {Provider} from "react-redux";
import {store} from "./store";
import en_USFix from "./i18n/en_US-fix";

const App = () => {
    const theme = useMemo(() => ({
            token: {
                colorPrimary: pangaeaColor,
                colorInfo: Color(pangaeaColor).lighten(0.1).toString(),
                borderRadius: 2,
                fontFamily: "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif"
            }
        }),
        []
    )

    return (
        <ConfigProvider theme={theme} locale={en_USFix}>
            <Provider store={store}>
                <DndProvider backend={HTML5Backend}>
                    <BrowserRouter>
                        <AppRoutes/>
                    </BrowserRouter>
                </DndProvider>
            </Provider>
        </ConfigProvider>
    );
}

export default App;
