import React, {useContext, useEffect, useState} from "react";
import useEntity from "../../../hooks/useEntity";
import {Modal, Spin} from "antd";
import NetworkError from "../../../tk/error/NetworkError";
import Usages from "../../../tk/bits/Usages";
import {CloseCircleTwoTone} from "@ant-design/icons";
import PropTypes from "prop-types";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {decoratorComponentFactory} from "./componentFactories";


const EntityDetailLoader = (props) => {
    const {entityDef, defaultEntity, entityId, onDelete, onLoad, onEntityCreated, children} = props;
    const {
        entity,
        updateEntity,
        batchEntity,
        deleteEntity,
        setEntity,
        loadEntity,
        createEntity,
        error
    } = useEntity(entityDef, entityId, defaultEntity);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(<span/>);
    const [errorTitle, setErrorTitle] = useState(<span/>);
    const {notifySuccess, notifyError} = useContext(NotificationsContext);

    useEffect(() => {
            if (!entity) return;
            if (onLoad === undefined) return;
            onLoad(entity);
        },
        [entity, onLoad]
    );

    const handleUpdateError = (errorResponse, onError, onFinish) => {
        if (errorResponse.request.status === 409) {
            setErrorTitle(<span><CloseCircleTwoTone
                twoToneColor='red'/>&nbsp;&nbsp;Error updating entity</span>);
            setErrorMessage(<span>Your copy of the entity is outdated.</span>)
            setErrorModalOpen(true);
        } else if (errorResponse.request.status === 400 && errorResponse.response.data.validationErrors === undefined) {
            setErrorTitle(<span><CloseCircleTwoTone
                twoToneColor='red'/>&nbsp;&nbsp;Error updating entity</span>);
            setErrorMessage(<span>{errorResponse.response.data.message}</span>)
            setErrorModalOpen(true);
        } else if (onError !== undefined) { // Display inline validation results
            notifyError(entityDef.label + ' validation errors. Update cancelled.')
            onError(errorResponse.response.data);
        }
        if (onFinish !== undefined) {
            onFinish();
        }
    }

    const handleUpdateSuccess = (onSuccess, response, onFinish) => {
        if (onSuccess !== undefined) {
            onSuccess(response);
        }
        if (onFinish !== undefined) {
            onFinish();
        }
        notifySuccess(entityDef.label + ' update successful')
    }

    const updateEntityWrapper = (data, onSuccess, onFinish, onError) => {
        updateEntity(data)
            .then(response => {
                handleUpdateSuccess(onSuccess, response, onFinish);
            })
            .catch(errorResponse => {
                handleUpdateError(errorResponse, onError, onFinish);
            })
    }

    const batchEntityWrapper = (batch, intercept, onSuccess, onFinish, onError) => {
        return batchEntity(batch, intercept)
            .then(response => {
                handleUpdateSuccess(onSuccess, response, onFinish);
            })
            .catch(errorResponse => {
                handleUpdateError(errorResponse, onError, onFinish);
            })
    };

    const deleteEntityWrapper = entityId => {
        deleteEntity(entityId)
            .then(() => {
                notifySuccess(entityDef.label + " deleted")
                onDelete();
            })
            .catch(({response}) => {
                setErrorTitle(<span><CloseCircleTwoTone twoToneColor='red'/>&nbsp;&nbsp;Error deleting entity</span>);
                if (response.data.usages) {
                    setErrorMessage(<span>There are usages: <Usages usages={response.data.usages}/></span>)
                } else if (response.data.generalErrors) {
                    setErrorMessage(<span>{response.data.generalErrors[0]}</span>)
                }
                setErrorModalOpen(true);
            })
    };

    if (error !== undefined) {
        return (
            <NetworkError code={error.code} message={error.message}/>
        );

    }
    if (entity === undefined) {
        return (
            <Spin>
                <div style={{height: "100px", width: "100%"}}/>
            </Spin>
        );

    }

    let element;
    const decorator = decoratorComponentFactory(entityDef.entityType);
    if (decorator) {
        element = React.cloneElement(
            decorator,
            {
                entity,
                entityDef,
                updateEntity: updateEntityWrapper,
                batchEntity: batchEntityWrapper,
                deleteEntity: deleteEntityWrapper,
                setEntity,
                loadEntity,
                createEntity,
                entityId
            },
            children
        );
    } else {
        element = React.cloneElement(
            children,
            {
                entity,
                entityDef,
                updateEntity: updateEntityWrapper,
                deleteEntity: deleteEntityWrapper,
                setEntity,
                createEntity: (data, onSuccess, onFinish, onError) =>
                    createEntity(
                        data,
                        newEntity => {
                            onSuccess(newEntity);
                            onEntityCreated(newEntity);
                        },
                        onFinish,
                        onError
                    ),
                entityId
            }
        );
    }

    return (
        <>
            {element}
            <Modal
                title={errorTitle}
                open={errorModalOpen}
                okButtonProps={{
                    style: {display: "none"}
                }}
                onCancel={() => setErrorModalOpen(false)}
            >
                {errorMessage}
            </Modal>
        </>
    );
}

EntityDetailLoader.propTypes = {
    entityDef: PropTypes.object,
    entityId: PropTypes.number,
    onDelete: PropTypes.func,
    onLoad: PropTypes.func
}

export default EntityDetailLoader;