import React, {useCallback, useContext, useState} from "react";
import JiraDirectImport from "./JiraDirectImport";
import ModalPop from "../../../tk/bits/ModalPop";
import FlexLayoutContext from "../../../contexts/FlexLayoutContext";

const JiraDirectImportModal = (props) => {
    const {open, setOpen, onCancel, onDone, instanceId} = props;
    const [ticket, setTicket] = useState(undefined);
    const [allValues, setAllValues] = useState();
    const {addJiraDirectImportTab} = useContext(FlexLayoutContext);

    const handlePop = useCallback(() => {
            addJiraDirectImportTab(ticket, allValues);
            setOpen(false);
        },
        [addJiraDirectImportTab, allValues, setOpen, ticket]
    );

    const handleCancel = useCallback(() => {
            setOpen(false);
            if (onCancel) {
                onCancel();
            }
        },
        [onCancel, setOpen]
    );

    return (
        <ModalPop
            title="Submission Import"
            open={open}
            setOpen={open => {
                setOpen(open);
                if (!open && onCancel) {
                    onCancel();
                }
            }}
            onCancel={handleCancel}
            footer={null}
            width="80%"
            destroyOnClose={true}
            keyboard={false}
            onPop={handlePop}
        >
            <JiraDirectImport
                onCancel={handleCancel}
                onDone={onDone}
                instanceId={instanceId}
                ticket={ticket}
                setTicket={setTicket}
                onChange={setAllValues}
            />
        </ModalPop>
    )
}

export default JiraDirectImportModal;