import React, {useContext} from "react";
import EntityHeader from "../entities/EntityHeader";
import AttributeTableField from "../../../tk/inputComplex/AttributeTableField";
import FormBackground from "../../../tk/forms/FormBackground";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {entityFields, renderField} from "../entities/entityFields";


const CampaignDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["name", "nameOptional", "comment", "datetimeBegin", "datetimeEnd", "uri"].map(
                        name => renderField(entityFields.campaign.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["basis", "project", "reference", "staffs"].map(
                        name => renderField(entityFields.campaign.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2} wide>
                    <EntityFormItem label='Attributes' paramName='attributes' border={true}>
                        <AttributeTableField entityType='campaign/attribute'/>
                    </EntityFormItem>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default CampaignDetail;
