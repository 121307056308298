import entityDefs from "../../pages/editorial/entities/entityDefs";
import {
    renderEditableEntityRef,
    renderEditableInstitutionWithAffiliationDefault,
    renderEntityRef
} from "../../pages/test/TestPage2";
import TableField2 from "./TableField2";
import React, {useCallback, useContext, useState} from "react";
import {FormExtContext} from "../forms/FormExt";
import {Button} from "antd";

const DatasetStaffsTableField = ({paramName, batchColumn}) => {
    const [defaultEntities, setDefaultEntities] = useState([])
    const {getValues} = useContext(FormExtContext);
    const {watch} = useContext(FormExtContext);
    const addedIds = watch(paramName).map(entry => entry.staffs.id)
    const updateDefaultStaffsInst = useCallback((paramName, staffs) => {
        const defaultEntitiesCopy = defaultEntities;
        if (staffs && staffs?.institution?.id) {
            defaultEntitiesCopy.push({paramName: paramName, entityRef: staffs.institution})
            setDefaultEntities(defaultEntitiesCopy)
        }
    }, [defaultEntities])

    function renderAuxSelect(mode, defaultEntities, setValue) {
        if (mode === 'setDefault') {
            return (
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span>Set default</span>
                    <Button
                        type="text"
                        onClick={() => {
                            defaultEntities.filter(entity => entity?.entityRef?.id).forEach(entity => {
                                setValue(entity.paramName, entity.entityRef, {shouldDirty: true})
                            })
                        }}
                    >
                        Go!
                    </Button>
                </div>
            );
        }
        return null;
    }

    const auxSelect = {
        selectOptions: [{label: "Set default", value: "setDefault"}],
        selectRender: renderAuxSelect
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'staffs',
            width: 60,
            align: 'right',
            render: entityRef => entityRef?.id
        },
        {
            title: 'Staffs',
            dataIndex: 'staffs',
            width: 210,
            indexColumn: true,
            entityDef: entityDefs.staffs,
            render: renderEntityRef(entityDefs.staffs)
        },
        {
            title: 'Affiliation',
            dataIndex: 'institution',
            width: 300,
            entityDef: entityDefs.institution,
            render: renderEditableInstitutionWithAffiliationDefault(
                paramName, 'institution', 'staffs', getValues, updateDefaultStaffsInst),
            // render: renderEditableEntityRef(entityDefs.institution, 'staffs', 'institution'),
            multiEdit: 'entityRef',
            auxSelect: auxSelect
        },
        {
            title: '2nd Affiliation',
            dataIndex: 'institution2',
            width: 300,
            entityDef: entityDefs.institution,
            render: renderEditableEntityRef(entityDefs.institution, paramName, 'institution2'),
            multiEdit: 'entityRef'
        }
    ]

    if (batchColumn) {
        columns.splice(batchColumn.index, 0, batchColumn.column);
    }

    return (
        <TableField2
            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
            paramName={paramName}
            entityDef={entityDefs.staffs}
            defaultEntities={defaultEntities}
            withOrder={true}
            columns={columns}
            newRowFn={entityRef => ({
                staffs: entityRef,
                institution: {id: null, name: null},
                institution2: {id: null, name: null},
                sequence: 1
            })}
        />
    )
}

export default DatasetStaffsTableField;