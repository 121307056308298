import EntityHeader from "../entities/EntityHeader";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import TextAreaField from "../../../tk/input/TextAreaField";
import InputLimitedField from "../../../tk/input/InputLimitedField";
import UriInputField from "../../../tk/input/UriInputField";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";


const TerminologyDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    <EntityFormItem label='Abbreviation' paramName='abbreviation' required>
                        <InputLimitedField maxLength={20} rules={{required: true}} autoFocus={true}/>
                    </EntityFormItem>
                    <EntityFormItem label='Name' paramName='name' required>
                        <InputLimitedField maxLength={20} rules={{required: true}}/>
                    </EntityFormItem>
                    <EntityFormItem label='Comment' paramName='comment'>
                        <TextAreaField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    <EntityFormItem label='Creator' paramName='creator'>
                        <InputLimitedField maxLength={255}/>
                    </EntityFormItem>
                    <EntityFormItem label='Description' paramName='description'>
                        <TextAreaField/>
                    </EntityFormItem>
                    <EntityFormItem label='URI' paramName='uri'>
                        <UriInputField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2}>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default TerminologyDetail;
