import useJiraImport from "../../../hooks/useJiraImport";
import entityDefs from "../entities/entityDefs";
import MyError from "../../../tk/bits/MyError";
import NetworkError from "../../../tk/error/NetworkError";
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import FormExt from "../../../tk/forms/FormExt";
import {emptyEntityRef} from "../../../lib/entityUtils";
import {Button, Checkbox, Space, Spin} from "antd";
import {insetStyle} from "../getreferencemetadata/ReferenceLookupDialog";
import SelectMultiEntityField from "../../../tk/input/SelectMultiEntityField";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {referenceLookup, staffsLookup} from "../../../lib/networkRequests";
import {
    LayoutWrapper,
    RenderKeywords,
    RenderProjects,
    RenderReferences,
    RenderStaffs
} from "./ImportDialogeRenderComponents";
import OverviewLineImport from "../overviews/OverviewLineImport";


const JiraImportDialog = (props) => {
    const {ticket, onCancel, onImport, datasetId, okText, onChange, initEntity} = props;
    const {entity, isLoading, error} = useJiraImport(ticket, initEntity?.jiraImportEntity);
    const {ws2Axios} = useWS2Axios();
    const [jiraImportEntity, setJiraImportEntity] = useState();
    const [inputValues, setInputValues] = useState();
    const defaultCheckboxFilters = [
        "title", "abstractText", "comment",
        "license", "projects", "keywords",
        "staffs", "references", "moratoriumUntil", "summary", "awards"
    ]
    const [checkboxFilters, setCheckboxFilters] = useState(defaultCheckboxFilters);
    const indeterminate = checkboxFilters.length > 0 && checkboxFilters.length < defaultCheckboxFilters.length
    const checkedAll = checkboxFilters.length === defaultCheckboxFilters.length
    const CheckboxWrapper = useCallback(
        ({checkboxName}) => {
            if (!defaultCheckboxFilters.includes(checkboxName)) {
                return <Checkbox style={{visibility: "hidden"}}/>
            } else {
                return <Checkbox
                    value={checkboxName}
                >
                </Checkbox>
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [checkboxFilters],
    );

    if (entity && entity.moratoriumRequired !== true) {
        entity.moratoriumUntil = null;
    }

    useEffect(() => {
            setJiraImportEntity(entity);
        },
        [entity]
    );

    useEffect(() => {
            if (!onChange) return;
            onChange({jiraImportEntity, inputValues});
        },
        [jiraImportEntity, inputValues, onChange]
    );

    const loadAllMatchesStaffs = useCallback(() => {
            let query = entity.staffs.map(staffs => ({
                name_last: staffs.nameLast,
                name_first: staffs.nameFirst,
                email: staffs.email,
                orcid: staffs.orcid
            }));
            return staffsLookup(ws2Axios, query, datasetId)
                .then(result =>
                    result.map(staffs => ({
                        nameLast: staffs.name_last,
                        nameFirst: staffs.name_first,
                        email: staffs.email,
                        orcid: staffs.orcid,
                        matches: staffs.matches.map(match => ({
                            matchInfo: match.match_info,
                            score: match.score,
                            entityRef: {id: match.id, name: match.name}
                        }))
                    })))
        },
        [datasetId, entity, ws2Axios]
    );

    const loadMatchesReferences = useCallback(index =>
            referenceLookup(ws2Axios, "citation", entity.references[index].name)
                .then(result =>
                    result.matches.map(aMatch => ({
                        matchInfo: aMatch.match_info,
                        score: aMatch.score,
                        entityRef: {
                            id: aMatch.id,
                            name: aMatch.name
                        }
                    }))
                ),
        [entity, ws2Axios]
    );

    const handleImport = useCallback(data => {
            const d2 = {};
            for (const [key, value] of Object.entries(data)) {
                if (key === 'datetimeUpdated') continue;
                if (key === 'fileDescriptions') continue;
                if (key === 'files') continue;
                if (key === 'summary') continue;
                if (key === 'tocAccepted') continue;
                if (key === 'commentInternal') continue;
                if (key === 'datasetStatus') continue;
                if (key === 'moratoriumUntil') {
                    if (value === null) {
                        d2[key] = null;
                        continue;
                    }
                    d2[key] = value + "T00:00:00.000000";
                    continue;
                }
                if (key === 'keywords') {
                    if (!d2.keywords) {
                        d2.keywords = [];
                    }
                    d2.keywords.push(
                        ...value.filter(entityRef => entityRef.id !== null)
                    );
                    continue;
                }
                if (key === 'additionalKeywords') {
                    if (!d2.keywords) {
                        d2.keywords = [];
                    }
                    if (value) {
                        value.forEach(keyword => {
                            d2.keywords.push(keyword);
                        });
                    }
                    continue;
                }
                if (key === 'projects') {
                    d2[key] = value.filter(entityRef => entityRef.id !== null);
                    continue;
                }
                if (key === 'references') {
                    d2[key] = value
                        .filter(entityRef => entityRef.id !== null)
                        .map(entityRef => ({reference: entityRef, relationType: {id: 12}}))
                    continue;
                }
                if (key === 'staffs') {
                    d2[key] = value
                        .filter(entityRef => entityRef.id !== null)
                        .map(entityRef => ({staffs: entityRef, institution: emptyEntityRef, institution2: emptyEntityRef}))
                    continue;
                }
                if (key === 'comment') {
                    if (value === null) {
                        d2.commentInternal = null;
                    } else {
                        d2.commentInternal = value;
                    }
                    continue;
                }
                d2[key] = value;
            }
            onImport(d2);
        },
        [onImport]
    );

    if (isLoading) {
        return (
            <div style={{...insetStyle, marginBottom: "24px"}}>
                <Spin/>
                <div>Loading ...</div>
                <br/>
            </div>
        )
    }

    const noLoad = initEntity !== undefined && initEntity.inputValues !== undefined;
    const filterData = (data) => {
        const dataKeysToBeExcluded = defaultCheckboxFilters.filter(val => !checkboxFilters.includes(val))
        if (dataKeysToBeExcluded.includes('keywords')) {
            dataKeysToBeExcluded.push('additionalKeywords')
        }
        const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => !dataKeysToBeExcluded.includes(key)))
        return filteredData;
    }

    const handleCheckAllBoxClick = () => {
        const newCheckboxFilters = checkedAll ? [] : defaultCheckboxFilters
        setCheckboxFilters(newCheckboxFilters)
    }

    return <>
        {entity &&
            <FormExt
                defaultEntity={{
                    staffs: entity.staffs ? entity.staffs.map(author => author.matches.length > 0 ? author.matches[0].entityRef : emptyEntityRef) : [],
                    keywords: entity.keywords ? entity.keywords.map(keyword => keyword.matches.length > 0 ? keyword.matches[0].entityRef : emptyEntityRef) : [],
                    projects: entity.projects ? entity.projects.map(project => project.matches.length > 0 ? project.matches[0].entityRef : emptyEntityRef) : [],
                    references: entity.references ? entity.references.map(reference => reference.matches.length > 0 ? reference.matches[0].entityRef : emptyEntityRef) : [],
                    datasetStatus: {id: -2, name: 'draft'},
                    commentInternal: '',
                    awards: [],
                    moratoriumRequired: undefined
                }}
                entityInit={initEntity?.inputValues}
                updateEntity={(data) => {
                    return handleImport(filterData({...entity, ...data}))
                }}
                globalErrors={[]}
                setGlobalErrors={(e) => console.log("SET GLOBAL", e)}
                onChange={setInputValues}
            >
                <div style={{...insetStyle, marginBottom: "24px"}}>
                    <Space direction={'vertical'}>
                       <Checkbox
                            indeterminate={indeterminate}
                            onClick={() => handleCheckAllBoxClick()}
                            checked={checkedAll}
                        >
                            {"Select all"}
                        </Checkbox>
                        <Checkbox.Group
                            defaultValue={defaultCheckboxFilters}
                            value={checkboxFilters}
                            style={{
                                width: '100%',
                            }}
                            onChange={(checkedValues) => {
                                setCheckboxFilters(checkedValues);
                            }}
                        >
                            <Space direction={'vertical'}>
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'summary'}/>}
                                    component={<OverviewLineImport label="Summary" value={entity.summary}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'ticket'}/>}
                                    component={<OverviewLineImport label="Ticket" value={ticket} type="ticket"/>}
                                />
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'title'}/>}
                                    component={<OverviewLineImport label="Title" value={entity.title}/>}
                                />
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'abstractText'}/>}
                                    component={
                                        <OverviewLineImport
                                            label="Abstract"
                                            value={entity.abstractText}/>
                                    }
                                />
                                <LayoutWrapper
                                    align='bottom'
                                    checkbox={<CheckboxWrapper checkboxName={'license'}/>}
                                    component={
                                        <OverviewLineImport
                                            label="License" value={entity.license}
                                            entityDef={entityDefs.license}/>
                                    }
                                />
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'moratoriumUntil'}/>}
                                    component={<OverviewLineImport label="Moratorium until"
                                                                   value={entity.moratoriumUntil}
                                                                   type="date"/>}
                                />
                                <LayoutWrapper
                                    checkbox={<CheckboxWrapper checkboxName={'comment'}/>}
                                    component={<OverviewLineImport label="Comment" value={entity.comment}/>}
                                />
                                <LayoutWrapper
                                    renderCondition={entity?.staffs?.length>0}
                                    checkbox={<CheckboxWrapper
                                        checkboxName={'staffs'}
                                    />}
                                    component={<RenderStaffs
                                        staffs={entity.staffs}
                                        loadAllMatchesStaffs={loadAllMatchesStaffs}
                                        noLoad={noLoad}
                                    />}
                                />
                                <LayoutWrapper
                                    renderCondition={entity?.keywords?.length>0}
                                    checkbox={<CheckboxWrapper
                                        checkboxName={'keywords'}
                                    />}
                                    component={
                                        <>
                                            <RenderKeywords
                                                noLoad={noLoad}
                                                entity={entity}
                                            />
                                            <EntityFormItem label="Additional keywords:"
                                                            entityDef={entityDefs.keyword}
                                                            paramName="additionalKeywords">
                                                <SelectMultiEntityField/>
                                            </EntityFormItem>
                                        </>
                                    }
                                />
                                <LayoutWrapper
                                    renderCondition={entity?.projects?.length>0}
                                    checkbox={<CheckboxWrapper checkboxName={'projects'}/>}
                                    component={<RenderProjects
                                        noLoad={noLoad}
                                        entity={entity}
                                    />}
                                />
                                <LayoutWrapper
                                    align='middle'
                                    checkbox={<CheckboxWrapper checkboxName={'awards'}/>}
                                    component={<EntityFormItem label="Awards:" entityDef={entityDefs.award}
                                                               paramName="awards">
                                        <SelectMultiEntityField/>
                                    </EntityFormItem>}
                                />
                                <LayoutWrapper
                                    renderCondition={entity?.references?.length>0}
                                    checkbox={<CheckboxWrapper checkboxName={'references'}/>}
                                    component={<RenderReferences
                                        noLoad={noLoad}
                                        entity={entity}
                                        loadMatchesReferences={loadMatchesReferences}
                                    />}
                                />
                            </Space>
                        </Checkbox.Group>
                    </Space>
                </div>

                <div className="ant-modal-footer">
                    <Space>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit'>{okText || "Update dataset"}</Button>
                    </Space>
                </div>
            </FormExt>
        }

        {error &&
            <div>
                <div style={{...insetStyle}}>
                    <h3><MyError/> Error</h3>
                    <NetworkError code={error.code}
                                  message={error.errorMessages || error.message || error.generalErrors}/>
                </div>
                <div className="ant-modal-footer">
                    <Space>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button type='primary' htmlType='submit' disabled>Update dataset</Button>
                    </Space>
                </div>
            </div>
        }
    </>
}

JiraImportDialog.propTypes = {
    ticket: PropTypes.string,
    onCancel: PropTypes.func,
    onImport: PropTypes.func,
    datasetId: PropTypes.number
}

export default JiraImportDialog;