import React from "react";
import PropTypes from "prop-types";
import {LogoutOutlined} from "@ant-design/icons";
import {getDatasetLandingUrl} from "../../lib/networkRequests";


const PangaeaDatasetLink = (props) => {
    const {idDataset, disabled} = props;

    if (disabled) {
        return (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>
                PANGAEA <LogoutOutlined/>
            </span>
        )
    }

    return (
        <a
            className="button"
            target="_blank"
            href={getDatasetLandingUrl(idDataset)}
            rel="noreferrer"
        >
            PANGAEA <LogoutOutlined/>
        </a>
    )
}

PangaeaDatasetLink.propTypes = {
    idDataset: PropTypes.number
}

export default PangaeaDatasetLink;