import useEntity from "../../hooks/useEntity";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import React, {useContext, useEffect, useState} from "react";
import {FormExtContext} from "../forms/FormExt";
import {Button, Spin} from "antd";
import SelectEntityField from "./SelectEntityField";

const SelectStaffsWithAffiliationDefault = (props) => {
    const {paramName, staffsRef, size, tagStyle, updateDefaultStaffsInst} = props;
    const {entity: staffs, error} = useEntity(entityDefs.staffs, staffsRef.id);
    const {setValue} = useContext(FormExtContext);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        if (updateDefaultStaffsInst) {
            // add default staff institution entry to the list which will be
            // later to set Author(s) affiliation to default
            updateDefaultStaffsInst(paramName, staffs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffs]) //do it each time staffs is changed

    if (!staffs && !error) {
        return <Spin/>
    }

    const defaultButton =
        <Button
            type="link"
            onClick={() => {
                setValue(paramName, staffs.institution, {shouldDirty: true});
                setDropdownVisible(false);
            }}
            key="usedefaultaffiliation"
        >
            Use default
        </Button>

    return (
        <SelectEntityField
            paramName={paramName}
            entityDef={entityDefs.institution}
            dropdownButtons={staffs && staffs.institution.id !== null ? [defaultButton] : undefined}
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
            size={size}
            tagStyle={tagStyle}
        />
    )
}

export default SelectStaffsWithAffiliationDefault;