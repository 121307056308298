import {Button, Col, Empty, Row} from "antd";
import React from "react";
import OverviewLine from "../overviews/OverviewLine";
import PropTypes from "prop-types";

export const insetStyle = {paddingLeft: "24px", paddingRight: "24px", paddingBottom: "0px", paddingTop: "0"};

const RorLookupDialog = (props) => {
    const {result, onImport, onCancel} = props;
    return (
        <>
            <h3 style={insetStyle}>Results</h3>
            <div style={insetStyle}>
                {result.institutions.map(institution => (
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <div key={institution.rorId}>
                                <h4 style={{marginBottom: "8px"}}>{institution.name}</h4>
                                <OverviewLine label="City" value={institution.city || undefined}/>
                                <OverviewLine label="State" value={institution.state || undefined}/>
                                <OverviewLine label="Country" value={institution.country || undefined}/>
                                <OverviewLine label="FundRef" value={institution.fundRef || undefined}/>
                                <OverviewLine label="ROR" value={institution.rorId || undefined} type='uri'/>
                            </div>
                        </Col>
                        <Col>
                            <Button
                                onClick={e => {
                                    onImport(institution);
                                }}
                            >
                                Import
                            </Button>
                        </Col>
                    </Row>
                ))}
                {(!result.institutions || result.institutions.length === 0) &&
                    <Empty/>
                }
            </div>

            <div className="ant-modal-footer">
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </>
    )
}

RorLookupDialog.propTypes = {
    result: PropTypes.object,
    onImport: PropTypes.func
}

export default RorLookupDialog;
