import StarPage from "../editorial/StarPage";
import {Col, Row} from "antd";
import BigEntityTable from "../editorial/bigentitytable/BigEntityTable";
import entityColumns from "../editorial/entities/entityColumns";
import React from "react";
import entityDefs from "../editorial/entities/entityDefs";

const TestPage3 = (props) => {
    console.log("Render TestPage3");
    return (
        <Row>
            <Col span={6}>
                <StarPage/>
            </Col>
            <Col span={18}>
                <BigEntityTable
                    entityDef={entityDefs.parameter}
                    entityColumns={entityColumns[entityDefs.parameter.entityType]}
                />
            </Col>
        </Row>
    )
}

export default TestPage3;