import React from "react";


const AttributesDecorator = (props) => {
    const {entity, entityDef, updateEntity, deleteEntity, createEntity, loadEntity, children} = props;

    return React.cloneElement(
        children,
        {
            entityDef,
            entity,
            createEntity,
            deleteEntity,
            loadEntity,
            updateEntity: (entity, onSuccess, onFinish, onError) => {
                console.log("DECORATE ATTRIBUTES: ", entity)
                entity.attributes = filterEmptyAttributes(entity.attributes)
                updateEntity(entity, onSuccess, onFinish, onError);
            }
        }
    )
}

export const filterEmptyAttributes = attributes => attributes.filter(attr => attr.value && attr.value !== "")

export default AttributesDecorator;
