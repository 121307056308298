import FormExt from "../../../tk/forms/FormExt";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import TicketInputField from "../../../tk/input/TicketInputField";
import {Button, Space} from "antd";
import JiraImportDialog from "../getjirametadata/JiraImportDialog";
import entityDefs from "../entities/entityDefs";
import React, {useCallback, useContext, useEffect, useState} from "react";
import useEntityCreate from "../../../hooks/useEntityCreate";
import ContainerContext from "../../../contexts/ContainerContext";


const JiraDirectImport = (props) => {
    const {onCancel, onDone, instanceId, ticket, setTicket, onChange, initEntity, onDirtyChange, tabId} = props;
    const [ticketUncontrolled, setTicketUncontrolled] = useState(undefined);
    const ticketWrapped = ticket || ticketUncontrolled;
    const setTicketWrapped = setTicket || setTicketUncontrolled;
    const {createEntity} = useEntityCreate(entityDefs.dataset);
    const {closeContainer} = useContext(ContainerContext);

    useEffect(
        () => {
            if (onDirtyChange) onDirtyChange(tabId, true);
        },
        [onDirtyChange, tabId]
    );

    useEffect(() => {
            setTicketWrapped(undefined)
        },
        [instanceId, setTicketWrapped]
    );

    const onImport = useCallback(data => {
            const entityDef = entityDefs.dataset;
            const defaultEntity = entityDef.setDefaultsFn(entityDef.emptyEntity);
            createEntity(
                {
                    ...defaultEntity,
                    ticket: ticketWrapped,
                    ...data
                },
                newEntity => {
                    onDone(newEntity);
                    closeContainer();
                },
                undefined,
                error => console.log("ERROR", error)
            )
        },
        [closeContainer, createEntity, onDone, ticketWrapped]
    );

    const handleCancel = useCallback(() => {
            closeContainer();
            if (onCancel) {
                onCancel();
            }
        },
        [closeContainer, onCancel]
    );

    return (
        <>
            <FormExt
                defaultEntity={{}}
                updateEntity={data => {
                    setTicketWrapped(data.ticket);
                }}
            >
                {!ticketWrapped &&
                    <>
                        <EntityFormItem label="Ticket" paramName='ticket'>
                            <TicketInputField width="170px" autoFocus={true}/>
                        </EntityFormItem>
                        <div className="ant-modal-footer" style={{marginTop: "16px"}}>
                            <Space>
                                <Button onClick={handleCancel}>Cancel</Button>
                                <Button type={ticketWrapped ? undefined : 'primary'} htmlType='submit'>Lookup</Button>
                            </Space>
                        </div>
                    </>
                }
            </FormExt>
            {ticketWrapped &&
                <JiraImportDialog
                    ticket={ticketWrapped}
                    okText="Create dataset"
                    onCancel={handleCancel}
                    onImport={onImport}
                    datasetId={0}
                    onChange={onChange}
                    initEntity={initEntity}
                />
            }
        </>
    )
}

export default JiraDirectImport;