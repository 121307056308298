import {Col, Empty, Row, Space, Tag} from "antd";
import CardOnBoard from "./CardOnBoard";
import React from "react";
import PoliciesButton from "./PoliciesButton";


export const columnEnteredSorter = (a, b) => {
    if (a.datetimeColumnEntered < b.datetimeColumnEntered) {
        return -1;
    } else if (a.datetimeColumnEntered > b.datetimeColumnEntered) {
        return 1;
    } else {
        return 0;
    }
}

const CardColumn = ({columnDef, columnCards, serviceClassDefs, options, isFiltered}) => {
    if (!columnCards) {
        return <Empty></Empty>
    }
    const sortedCards = columnDef.sorter
        ? columnCards.sort(columnDef.sorter)
        : columnCards.sort(columnEnteredSorter);

    const subs = {
        main: []
    };
    const addToSub = (sub, card) => {
        let subName;
        if (sub === null) {
            subName = "main";
        } else {
            subName = sub;
        }
        if (!subs[subName]) {
            subs[subName] = [];
        }
        subs[subName].push(card);
    }
    sortedCards.forEach(card => addToSub(card.subColumn, card));

    let colHeaderStyle = {
        marginTop: '0',
        marginBottom: '0px !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px'
    };
    let colHeaderLimitsStyle = {
        marginRight: '0'
    };
    if (columnDef.limits && !isFiltered && (columnDef.limits.max <= sortedCards.length || columnDef.limits.min > sortedCards.length)) {
        colHeaderStyle = {
            ...colHeaderStyle,
            backgroundColor: '#de350b',
            color: '#fff'
        }
        colHeaderLimitsStyle = {
            ...colHeaderLimitsStyle,
            color: '#fff'
        }
    }

    return (
        <div>
            <div style={colHeaderStyle}>
                <Row align='middle' justify='space-between'>
                    <Col>
                        <h3>{columnDef.name} ({sortedCards.length})</h3>
                    </Col>
                    <Col>
                        <Space>
                            <PoliciesButton content={columnDef.policies}
                                            backgroundColor={colHeaderStyle.backgroundColor || '#fff'}/>
                            {columnDef.limits && columnDef.limits.min && !isFiltered &&
                                <Tag style={colHeaderLimitsStyle}>MIN {columnDef.limits.min}</Tag>
                            }
                            {columnDef.limits && columnDef.limits.max && !isFiltered &&
                                <Tag style={colHeaderLimitsStyle}>MAX {columnDef.limits.max}</Tag>
                            }
                        </Space>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    padding: '8px'
                }}
            >
                {Object.entries(subs).map(entry => {
                    let subColumnName = entry[0];
                    if (columnDef.subColumns && columnDef.subColumns[subColumnName]) {
                        subColumnName = columnDef.subColumns[subColumnName].label;
                    }
                    return (
                        <div key={subColumnName + '-column'}>
                            {subColumnName !== 'main' &&
                                <h3 style={{marginTop: '48px', paddingLeft: '8px'}}>{subColumnName}</h3>
                            }
                            <Space wrap={true}>
                                {entry[1].map(card => (
                                    <CardOnBoard
                                        key={card.ticket}
                                        card={card}
                                        serviceClassDefs={serviceClassDefs}
                                        options={options}
                                    />
                                ))}
                            </Space>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default CardColumn;