import EntityTagList from "../../../tk/bits/EntityTagList";
import entityDefs from "../entities/entityDefs";
import useEntityList from "../../../hooks/useEntityList";
import Hello from "./Hello";
import {Empty} from "antd";
import {useContext} from "react";
import MyUserContext from "../../../contexts/MyUserContext";


const SplashScreen = (props) => {
    const {myUser} = useContext(MyUserContext);
    const {entityList: datasetList} = useEntityList(
        entityDefs.dataset,
        {current: 1, pageSize: 100},
        "-id",
        "userUpdated==" + myUser.id + " or userCreated==" + myUser.id
    );

    return (
        <div
            style={{
                margin: "50px 170px"
            }}
        >
            <h2><Hello name={myUser.name}/></h2>
            <p>Here are some of your recently created and updated datasets:</p>
            <EntityTagList
                list={datasetList?.map(entity => entity.me) || []}
                entityDef={entityDefs.dataset}
                empty={<Empty/>}
            />
        </div>
    );
}

export default SplashScreen;