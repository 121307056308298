import {Col, Row, Space} from "antd";
import CardColumn from "./CardColumn";
import React, {useMemo} from "react";
import {PlusOutlined} from "@ant-design/icons";
import useMyUser from "../../../hooks/useMyUser";
import PoliciesButton from "./PoliciesButton";


const borderStyle = {
    borderRightStyle: 'solid',
    borderColor: '#fff'
}

const KanbanBoard = ({columnDefs, serviceClassDefs, options, cards, isFiltered}) => {

    const serviceClassCounts = useMemo(() => {
            const classCounts = {};
            Object.keys(serviceClassDefs).forEach(key => {
                classCounts[key] = 0;
            });
            cards
                .filter(card => card.boardColumn !== 'done')
                .forEach(card => classCounts[card.serviceClass]++);
            return classCounts;
        },
        [cards, serviceClassDefs]
    );

    const columnCards = useMemo(() => {
            const modCards = {};
            Object.values(columnDefs).forEach(columnDef => {
                modCards[columnDef.columnName] = cards
                    .filter(card => card.boardColumn === columnDef.columnName)
            })
            return modCards;
        },
        [cards, columnDefs]
    );

    const {myUser} = useMyUser();

    const ticketCount = Object.values(serviceClassCounts)
        .reduce((p, c) => p + c, 0);

    return (<>
        <Row>
            <Col>
                <Space size={[16, 0]} align='baseline'>
                    <div style={{fontSize: '18px'}}>Total tickets: {ticketCount}</div>
                    <div>Service classes:</div>
                    {Object.values(serviceClassDefs).map((classDef) => {

                        let count;
                        if (options.showServiceClassPercentages) {
                            count = (serviceClassCounts[classDef.name] / ticketCount * 100).toFixed(0) + '%';
                        } else {
                            count = serviceClassCounts[classDef.name];
                        }

                        return (
                            <div
                                key={classDef.name}
                                style={{
                                    padding: '8px',
                                    marginBottom: '24px',
                                    width: '200px'
                                }}
                            >
                                <div
                                    style={{
                                        padding: '8px',
                                        backgroundColor: classDef.color,
                                        boxShadow: '4px 4px 15px #ccc',
                                    }}
                                >
                                    <Row align='middle' justify='space-between'>
                                        <Col
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {classDef.label || classDef.serviceClass} ({count})
                                        </Col>
                                        <Col>
                                            <Space align='baseline'>
                                                <PoliciesButton content={classDef.policies}
                                                                backgroundColor={classDef.color}/>
                                                {options?.jiraProjectId &&
                                                    <a href={"https://issues.pangaea.de/secure/CreateIssueDetails!init.jspa?"
                                                        + "pid=" + options.jiraProjectId
                                                        + "&issuetype=" + classDef.jiraIssueType
                                                        + options.components.map(i => "&components=" + i).join('')
                                                        + "&priority=3&reporter=" + myUser.username
                                                        + (classDef.jiraLabel ? '&labels=' + classDef.jiraLabel : '')
                                                    }
                                                       target='_blank'
                                                       rel='noreferrer'
                                                    >
                                                        <PlusOutlined/>
                                                    </a>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        );
                    })}

                </Space>
            </Col>
        </Row>
        <div>
            <Row
                gutter={[0, 0]}
                style={{
                    backgroundColor: '#f8f8f8'
                }}
            >
                {Object.values(columnDefs).map((columnDef) => (
                    <Col
                        span={24 / Object.keys(columnDefs).length}
                        style={borderStyle}
                        key={columnDef.name}
                    >
                        <CardColumn
                            columnDef={columnDef}
                            columnCards={columnCards[columnDef.columnName]}
                            serviceClassDefs={serviceClassDefs}
                            options={options}
                            isFiltered={isFiltered}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    </>)
}

export default KanbanBoard;