import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useEntities = (entityDef, idList) => {
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const {ws2Axios} = useWS2Axios();

    const handleError = errorResponse => {
        let response = errorResponse.response;
        if (response) {
            console.log("SET ERROR HERE", response.data)
            setError(response.data) // with fields: data.code and data.message
        } else {
            setError({
                code: "0",
                message: "Oh no, an error!"
            })
        }
    };

    useEffect(() => {
            if (idList === undefined || idList.length === 0) {
                setEntities([]);
                setLoading(false);
                return;
            }
            setLoading(true);
            const query = idList
                .reduce((prevId, currentId) => prevId + "," + currentId);

            ws2Axios
                .post(
                    apiBaseUrl + entityDef.apiGet + "/list",
                    {
                        offset: 0,
                        limit: 10000,
                        sort: "id",
                        q: "id=in=(" + query + ")"
                    }
                )
                .then(response => {
                    setEntities(response.data.records);
                    setLoading(false);
                    setError(undefined);
                })
                .catch(errorResponse => {
                    setLoading(false);
                    handleError(errorResponse);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityDef, JSON.stringify(idList), ws2Axios]
    );

    return {
        entities,
        loading,
        error
    };
};

export default useEntities;
