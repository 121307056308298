import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const StaffsOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Last name" value={entity.nameLast}/>
            <OverviewLine label="First name" value={entity.nameFirst}/>
            <OverviewLine label="Email address" value={entity.email}/>
            <OverviewLine label="Phone" value={entity.phone}/>
            <OverviewLine label="ORCID" value={entity.orcid} type="orcid"/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term}/>
        </div>
    )
}

export default StaffsOverview;