import React from "react";
import ParameterDetail from "../detailforms/ParameterDetail";
import AwardDetail from "../detailforms/AwardDetail";
import BasisDetail from "../detailforms/BasisDetail";
import CampaignDetail from "../detailforms/CampaignDetail";
import DatasetDetail from "../detailforms/DatasetDetail";
import EventDetail from "../detailforms/EventDetail";
import InstitutionDetail from "../detailforms/InstitutionDetail";
import JournalDetail from "../detailforms/JournalDetail";
import MethodDetail from "../detailforms/MethodDetail";
import ProjectDetail from "../detailforms/ProjectDetail";
import ReferenceDetail from "../detailforms/ReferenceDetail";
import StaffsDetail from "../detailforms/StaffsDetail";
import TermDetail from "../detailforms/TermDetail";
import TerminologyDetail from "../detailforms/TerminologyDetail";
import DataSeriesDetail from "../detailforms/DataSeriesDetail";
import PanGroupDetail from "../detailforms/PanGroupDetail";
import PanUserDetail from "../detailforms/PanUserDetail";
import AwardOverview from "../overviews/AwardOverview";
import BasisOverview from "../overviews/BasisOverview";
import CampaignOverview from "../overviews/CampaignOverview";
import DatasetOverview from "../overviews/DatasetOverview";
import EventOverview from "../overviews/EventOverview";
import InstitutionOverview from "../overviews/InstiututionOverview";
import JournalOverview from "../overviews/JournalOverview";
import MethodOverview from "../overviews/MethodOverview";
import ParameterOverview from "../overviews/ParameterOverview";
import ProjectOverview from "../overviews/ProjectOverview";
import ReferenceOverview from "../overviews/ReferenceOverview";
import StaffsOverview from "../overviews/StaffsOverview";
import TermOverview from "../overviews/TermOverview";
import TerminologyOverview from "../overviews/TerminologyOverview";
import DataSeriesOverview from "../overviews/DataSeriesOverview";
import PanGroupOverview from "../overviews/PanGroupOverview";
import PanUserOverview from "../overviews/PanUserOverview";
import DatasetDecorator from "../detailforms/DatasetDecorator";
import AttributesDecorator from "../detailforms/AttributesDecorator";


const detailComponents = {
    award: <AwardDetail/>,
    basis: <BasisDetail/>,
    campaign: <CampaignDetail/>,
    dataset: <DatasetDetail/>,
    event: <EventDetail/>,
    funder: <InstitutionDetail/>,
    institution: <InstitutionDetail/>,
    journal: <JournalDetail/>,
    method: <MethodDetail/>,
    parameter: <ParameterDetail/>,
    project: <ProjectDetail/>,
    reference: <ReferenceDetail/>,
    staffs: <StaffsDetail/>,
    term: <TermDetail/>,
    terminology: <TerminologyDetail/>,
    series: <DataSeriesDetail/>,
    keyword: <TermDetail/>,
    location: <TermDetail/>,
    panGroup: <PanGroupDetail/>,
    panUser: <PanUserDetail/>
}

const decoratorComponents = {
    campaign: <AttributesDecorator/>,
    event: <AttributesDecorator/>,
    dataset: <DatasetDecorator/>
}

const overviewComponents = {
    award: <AwardOverview/>,
    basis: <BasisOverview/>,
    campaign: <CampaignOverview/>,
    dataset: <DatasetOverview/>,
    event: <EventOverview/>,
    funder: <InstitutionOverview/>,
    institution: <InstitutionOverview/>,
    journal: <JournalOverview/>,
    method: <MethodOverview/>,
    parameter: <ParameterOverview/>,
    project: <ProjectOverview/>,
    reference: <ReferenceOverview/>,
    staffs: <StaffsOverview/>,
    term: <TermOverview/>,
    terminology: <TerminologyOverview/>,
    series: <DataSeriesOverview/>,
    keyword: <TermOverview/>,
    location: <TermOverview/>,
    panGroup: <PanGroupOverview/>,
    panUser: <PanUserOverview/>
}

export const overviewComponentFactory = (entityType) => {
    return overviewComponents[entityType];
}

export const detailComponentFactory = (entityType) => {
    return detailComponents[entityType];
}

export const decoratorComponentFactory = (entityType) => {
    return decoratorComponents[entityType];
}
