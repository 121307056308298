import React from "react";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import TableField2 from "../inputComplex/TableField2";
import {renderEditableNumberInput, renderEntityRef} from "../../pages/test/TestPage2";

const SelectSequencedEntityField = ({paramName, entityDef}) => {
    return (
        <TableField2
            paramName={paramName}
            entityDef={entityDef}
            columns={[
                {
                    title: 'ID',
                    dataIndex: 'entityRef',
                    width: 30,
                    align: 'right',
                    render: entityRef => entityRef?.id,
                },
                {
                    title: 'Staffs',
                    dataIndex: 'entityRef',
                    width: 60,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.staffs)
                },
                {
                    title: "Staff N (sequence)",
                    dataIndex: "sequence",
                    width: 60,
                    render: renderEditableNumberInput(paramName, "sequence", 1)
                }
            ]}
            newRowFn={entityRef => ({
                entityRef: entityRef,
                sequence: 1

            })}
        />
    )
}

export default SelectSequencedEntityField;