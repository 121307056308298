import {collectFn, DndHighlight} from "../../lib/dropIndicatorStyle";
import EntityCreatorModal from "../../pages/editorial/entities/EntityCreatorModal";
import {Button, Divider, Select, Space} from "antd";
import {formStyle} from "../forms/formStyle";
import React, {useState} from "react";
import useEntitySearch from "../../hooks/useEntitySearch";
import {useDrop} from "react-dnd";
import {nextInstanceId} from "../../pages/editorial/EditorialApp";
import EntityTag from "../bits/EntityTag";
import {transformSize} from "./SelectEntityField";

const {Option} = Select;

const SelectEntityInput = React.forwardRef(
    (props, ref) => {
        const {
            value,
            onChange,
            entityDef,
            disabled,
            apiPath,
            tagStyle,
            searchFilter,
            searchLimit,
            size,
            fixedWidth,
            newDefaults,
            autoFocus,
            noNewButton,
            dropdownVisible: controlledDropdownVisible,
            setDropdownVisible: setControlledDropdownVisible,
            dropdownButtons,
            placeholder,
            style,
            filterFn
        } = props;
        const [dropdownVisible, setDropdownVisible] = useState(false);
        const entitySearch = useEntitySearch(apiPath === undefined ? entityDef.entityType : apiPath, searchFilter, searchLimit);
        const [{canDrop, isOver}, drop] = useDrop(() => ({
            accept: 'ENTITY_' + entityDef.entityType,
            collect: collectFn,
            drop: item => {
                onChange(item.data);
            }
        }), [onChange]);
        const width = fixedWidth !== undefined ? fixedWidth : transformSize(size);
        const [newVisible, setNewVisible] = useState(false);
        const [instanceId, setInstanceId] = useState(nextInstanceId());

        const wrapSetDropdownVisible = value => setControlledDropdownVisible ?
            setControlledDropdownVisible(value) :
            setDropdownVisible(value);

        const wrapDropdownVisible = controlledDropdownVisible !== undefined ?
            controlledDropdownVisible :
            dropdownVisible;

        const compDropdownButtons = [];
        if (dropdownButtons) {
            compDropdownButtons.push(...dropdownButtons);
        }

        if (!noNewButton) {
            compDropdownButtons.push(
                <Button
                    onClick={() => {
                        setNewVisible(true);
                        setInstanceId(nextInstanceId());
                    }}
                    key={"newbutton"}
                >
                    + new
                </Button>
            )
        }

        const tagRender = (props) => {
            const {label, value} = props;
            return (
                <EntityTag
                    entityRef={{id: value, name: label}}
                    entityDef={entityDef}
                    style={tagStyle}
                    key={value}
                />
            );
        };

        /*
        let highlight = null
        if (canDrop || isOver) {
            highlight = <div style={{
                width: "100%",
                height: "100%",
                //backgroundColor: "#800",
                position: "absolute",
                zIndex: 1000,
                outlineStyle: "solid",
                outlineOffset: "-3px",
                outlineWidth: "2px"
            }}>

            </div>
        }
        */
        return (
            <div
                style={{
                    ...width,
                    ...style
                }}
                ref={drop}
            >
                <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDef.color}
                              color2={entityDef.bgColor}></DndHighlight>
                <EntityCreatorModal
                    visible={newVisible}
                    setVisible={setNewVisible}
                    entityDef={entityDef}
                    newDefaults={newDefaults || {}}
                    onCreated={newEntity => {
                        setNewVisible(false);
                        onChange({...newEntity.me});
                    }}
                    instanceId={instanceId}
                />
                <Select
                    showSearch
                    style={{
                        color: isOver ? 'red' : 'black',
                        ...formStyle,
                        ...width,
                        ...style
                    }}
                    value={value === undefined || value.id === null ? [] : [{value: value.id, label: value.name}]}
                    onChange={newValue => {
                        if (newValue === undefined || newValue.length === 0) {
                            onChange({id: null, name: null})
                        } else {
                            onChange({
                                id: newValue[newValue.length - 1].value,
                                name: newValue[newValue.length - 1].label
                            });
                        }
                    }}
                    loading={entitySearch.isLoading}
                    onSearch={entitySearch.doSearch}
                    filterOption={false}
                    notFoundContent={null}
                    allowClear={!entitySearch.isLoading}
                    labelInValue={true}
                    disabled={disabled}
                    tagRender={tagRender}
                    mode="multiple"
                    open={wrapDropdownVisible}
                    onDropdownVisibleChange={open => {
                        if (!open) {
                            wrapSetDropdownVisible(false);
                            return;
                        }
                        if (!value || value.id === null) {
                            entitySearch.doSearch("").then(() => wrapSetDropdownVisible(true));
                        }
                    }}
                    onInputKeyDown={() => {
                        wrapSetDropdownVisible(true);
                    }}
                    onSelect={() => {
                        wrapSetDropdownVisible(false);
                    }}
                    onDoubleClick={() => wrapSetDropdownVisible(true)}
                    autoFocus={autoFocus}
                    dropdownRender={
                        compDropdownButtons && compDropdownButtons.length > 0 ?
                            menu => <>
                                {menu}
                                <Divider style={{margin: '8px 0',}}/>
                                <Space style={{padding: '0 8px 4px'}}>
                                    {compDropdownButtons}
                                </Space>
                            </> :
                            undefined
                    }
                    placeholder={placeholder}
                    ref={ref}
                    popupMatchSelectWidth={300}
                >
                    {/* this goes up one line. but disabled because it pops up, when you click on an entitytag
                        onFocus={() => {
                            entitySearch.doSearch("").then(() => setDropdownVisible(true));
                        }}
                        */}
                    {entitySearch.result.filter(filterFn || (() => true))
                        .map(value => (
                            <Option key={value.id} value={value.id}>{value.name}</Option>
                        ))}
                </Select>
            </div>

        )
    }
);

export default SelectEntityInput;