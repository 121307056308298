import React from "react";
import PropTypes from "prop-types";
import FileImportForm from "./FileImportForm";
import Modal from "antd/es/modal/Modal";
import ContainerContext from "../../../contexts/ContainerContext";
import HelpLink from "../../../tk/bits/HelpLink";


const FileImportModal = (props) => {
    const {
        modalTitle,
        open,
        setOpen,
        customError,
        width,
        uploadHint,
        customResult,
        extraOptions,
        extraDeps,
        onReset,
        onTest,
        onImport,
        entityDef // extra, should not be here
    } = props;

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            width={width}
            onCancel={() => setOpen(false)}
            footer={[]}
            destroyOnClose={true}
            maskClosable={false}
        >
            <ContainerContext.Provider value={{
                closeContainer: () => setOpen(false)
            }}>
                <FileImportForm
                    title={<span>{modalTitle} <HelpLink category="Import_Datasets"/></span>}
                    uploadHint={uploadHint}
                    onImport={onImport}
                    onTest={onTest}
                    customError={customError}
                    customResult={customResult}
                    onReset={onReset}
                    extraOptions={extraOptions}
                    extraDeps={extraDeps}
                    onCancel={() => setOpen(false)}
                    entityDef={entityDef}
                />
            </ContainerContext.Provider>
        </Modal>
    )
}

FileImportModal.propTypes = {
    modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onImportSuccess: PropTypes.func,
    customError: PropTypes.func,
    width: PropTypes.string,
    uploadHint: PropTypes.string,
    customResult: PropTypes.func,
    extraOptions: PropTypes.object,
    onOpenReceipt: PropTypes.func,
    onReset: PropTypes.func
}

export default FileImportModal;