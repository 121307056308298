import {Checkbox, Divider, Popover} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import React from "react";
import DndSortableContainer from "./DndSortableContainer"
import DndSortableItem from "./DndSortableItem";

export const ColumnSelector = props => {
    const {selectedColumns, setSelectedColumns, defaultColumns} = props;
    return (
        <DropdownWithCheckboxes
            items={selectedColumns}
            setItems={setSelectedColumns}
            defaultItems={defaultColumns}
        />
    )
}

const DropdownWithCheckboxes = (props) => {
    const {items, setItems, defaultItems} = props;

    const styleCheckbox = {
        padding: 5,
        //flex to make the whole area on the checkbox line "clickable"
        display: "flex",
    }

    const getItemById = (items, id) => {
        for (const ind in items) {
            if (items[ind].id === id) {
                return items[ind];
            }
        }
    }

    const isDefaultColumnOrder = (items) => {
        for (const index in items) {
            const defaultItem = defaultItems[index];
            const currentItem = items[index];
            if (defaultItem.id !== currentItem.id) {
                return false;
            }
        }
        return true
    }
    const isEveryColumnSelected = (items) => {
        for (const index in items) {
            if (!items[index].checked) {
                return false;
            }
        }
        return true;
    }
    const handleBatchChecked = (checkedAll) => {
        const newItems = [...items];
        for (const index in newItems) {
            newItems[index].checked = !checkedAll;
        }
        setItems(newItems);
    }
    const handleDefaultItems = () => {
        const newItems = [...defaultItems];
        for (const index in newItems) {
            const newItemID = newItems[index].id;
            const currentItem = getItemById(items, newItemID);
            if (currentItem) {
                //conserve selection status, change ONLY order
                newItems[index].checked = currentItem.checked;
            }
        }
        setItems(newItems);
    }
    const handleCheckboxClick = (index) => {
        const newItems = [...items];
        newItems[index].checked = !newItems[index].checked;
        setItems(newItems);
    }
    const checkedAll = isEveryColumnSelected(items);

    const DndSortableContent = ({idItems, itemStyle, undraggableIds}) => {
        const idItemsFiltered = idItems.map(
            (idItem) => undraggableIds.includes(idItem) ? null : idItem
        );
        return (
            idItems.map((idItem, index) => (
                <DndSortableItem key={idItem} id={idItemsFiltered[index]}>
                    <Checkbox
                        checked={items[index].checked}
                        style={itemStyle}
                        onClick={() => {
                            handleCheckboxClick(index);
                        }}
                    >
                        {items[index].name}
                    </Checkbox>
                </DndSortableItem>
            ))
        )
    }
    const prepareDropdownItems = (items) => {
        const idItems = items.map((item) => item.id);
        return [
            <DndSortableContainer
                items={items}
                setItems={setItems}
                idItems={idItems}
            >
                <DndSortableContent
                    idItems={idItems}
                    itemStyle={styleCheckbox}
                    undraggableIds={["id"]}
                />
            </DndSortableContainer>,
            <Divider
                style={{margin: 0}}
            />,
            <Checkbox
                style={styleCheckbox}
                onClick={() => handleBatchChecked(checkedAll)}
                checked={isEveryColumnSelected(items)}
            >
                {checkedAll ? "Deselect all" : "Select all"}
            </Checkbox>,
            <Checkbox
                style={styleCheckbox}
                onClick={() => handleDefaultItems()}
                checked={isDefaultColumnOrder(items)}
            >
                Default Order
            </Checkbox>
        ]
    }

    const menuItems = prepareDropdownItems(items);

    return (
        <Popover
            trigger="click"
            placement="right"
            content={
                <div style={{backgroundColor: "white"}}>
                    {menuItems.map((item, index) => (
                        <div key={index}>
                            {item}
                        </div>
                    ))}
                </div>
            }
        >
            <SettingOutlined/>
        </Popover>
    )
}

