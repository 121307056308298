import React from "react";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, rectSortingStrategy, SortableContext} from "@dnd-kit/sortable";


const DndSortableContainer = (props) => {
    const {items, setItems, idItems} = props;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // Require the mouse to move by 10 pixels before activating
                // distance represents the distance, in pixels, by which the pointer needs to be moved before a drag start event is emitted.
                // To allow onClick trigger in Checkboxes
                distance: 8,
            },
        })
    )

    const handleDragEnd = ({active, over}) => {
        if (!over) {
            return;
        }

        const activeIndex = active.data.current.sortable.index;
        const overIndex = over.data.current?.sortable.index || 0;
        setItems(arrayMove(items, activeIndex, overIndex));
    };

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                ///e.g. id ={"group1"}, items={["4", "5", "6"]} Unique IDs
                items={idItems}
                strategy={rectSortingStrategy}
            >
                {props.children}
            </SortableContext>
        </DndContext>
    );
}

export default DndSortableContainer;