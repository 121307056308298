import React, {useContext} from "react";
import {Col, Space} from "antd";
import PropTypes from "prop-types";
import {FormExtContext} from "./FormExt";
import FormLabel from "./FormLabel";
import HelpLink from "../bits/HelpLink";


export const labelStyle = {
    marginTop: '0px',
    marginBottom: '2px',
    paddingLeft: '0',
    paddingBottom: '0',
    fontSize: '12px',
    color: '#999'
};


const FormRowContainer = (props) => {
    const {label, paramName, required, entityDef, helpHref, children} = props;

    const {formState} = useContext(FormExtContext);
    const isDirty = formState.dirtyFields[paramName] === true
    const myLabelStyle = {
        ...labelStyle
    }
    if (isDirty) {
        myLabelStyle.fontWeight = '900';
        myLabelStyle.color = '#1890ff'
    }

    const childrenProps = {
        fullWidth: true,
        paramName,
        entityDef
    };

    return (
        <>
            <Col
                style={myLabelStyle}
                span='24'
            >
                <Space size={0}>
                    <FormLabel label={label} paramName={paramName} required={required} entityDef={entityDef}/>&nbsp;
                    {helpHref &&
                    <HelpLink href={helpHref}/>
                    }
                </Space>
            </Col>
            <Col
                span='24'
            >
                {React.cloneElement(children, childrenProps)}
            </Col>
        </>
    )
}

FormRowContainer.propTypes = {
    label: PropTypes.string.isRequired,
    paramName: PropTypes.string,
    required: PropTypes.bool,
    entityDef: PropTypes.object,
    helpHref: PropTypes.string
}

export default FormRowContainer;