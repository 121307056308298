import OverviewLine from "./OverviewLine";

const TerminologyOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Abbreviation" value={entity.abbreviation}/>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Description" value={entity.description}/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
        </div>
    )
}

export default TerminologyOverview;