import {Button} from "antd";
import PropTypes from "prop-types";

const DetailBarButton = (props) => {
    const {children, type, ...restProps} = props;
    if (type === "primary") {
        return (
            <Button type="primary" {...restProps}>
                {children}
            </Button>
        )
    }
    return (
        <Button ghost type="primary" style={{backgroundColor: '#0000'}} {...restProps}>
            {children}
        </Button>
    )
}

DetailBarButton.propTypes = {
    type: PropTypes.string
}

export default DetailBarButton;
