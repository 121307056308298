import React, {useState} from "react";
import DetailBarButton from "../../../tk/forms/DetailBarButton";
import FileImportModal from "./FileImportModal";


const FileImportButton = (props) => {
    const {
        disabled,
        uploadHint,
        modalTitle,
        onTest,
        onImport,
        onClose,
        children,
        customResult,
        customError
    } = props;
    const [open, setOpen] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);

    return (
        <>
            <DetailBarButton
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                {children}
            </DetailBarButton>
            <FileImportModal
                modalTitle={modalTitle}
                uploadHint={uploadHint}
                open={open}
                setOpen={newOpen => {
                    if (!newOpen && onClose) {
                        onClose(hasSuccess);
                    }
                    setOpen(newOpen);
                }}
                customResult={customResult}
                customError={customError}
                onTest={onTest}
                onImport={options => onImport({
                    ...options,
                    onSuccess: response => {
                        setHasSuccess(true);
                        if (options.onSuccess) {
                            options.onSuccess(options);
                        }
                    }
                })}
            />
        </>
    )
}

export default FileImportButton;
