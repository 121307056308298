import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const PanGroupOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
        </div>
    )
}

export default PanGroupOverview;