import {
    collectionTypeColumn,
    createUpdateColumns,
    curationLevelColumn,
    datasetStatusColumn,
    dataTypeColumn,
    dateColumn,
    dateTimeColumn,
    ellipsisColumn,
    entityRefColumn,
    entityRefListColumn,
    floatColumn,
    institutionTypeColumn,
    integerColumn,
    labelColumn,
    loginOptionColumn,
    meColumn,
    orcidColumn,
    processingLevelColumn,
    projectTypeColumn,
    referenceStatusColumn,
    referenceTypeColumn,
    termCategoryColumn,
    termStatusColumn,
    ticketColumn,
    titleColumn,
    topotypeColumn,
    uriColumn
} from "./entityDefTools";
import entityDefs from "./entityDefs";
import TextAreaLimitedField from "../../../tk/input/TextAreaLimitedField";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import React from "react";
import InputLimitedField from "../../../tk/input/InputLimitedField";
import NumberInputField from "../../../tk/input/NumberInputField";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import InputField from "../../../tk/input/InputField";
import UriInputField from "../../../tk/input/UriInputField";
import {SelectInputField} from "../../../tk/input/SelectInputField";


const entityColumns = {

    award: () => ({
        columns: [
            meColumn("me", entityDefs.award),
            titleColumn("title", "Title"),
            labelColumn("awardNumber", "Award number"),
            entityRefColumn("institution", "Funder", entityDefs.institution),
            entityRefColumn("project", "Project", entityDefs.project),
            uriColumn("uri", "URI"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    basis: () => ({
        columns: [
            meColumn("me", entityDefs.basis),
            labelColumn("name", "Name"),
            labelColumn("callsign", "Callsign"),
            labelColumn("imoNumber", "IMO number"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            ...createUpdateColumns()
        ]
    }),
    campaign: () => ({
        columns: [
            meColumn("me", entityDefs.campaign),
            labelColumn("name", "Name"),
            labelColumn("nameOptional", "Name optional"),
            dateColumn("datetimeBegin", "Date begin"),
            dateColumn("datetimeEnd", "Date end"),
            uriColumn("uri", "URI"),
            entityRefListColumn("staffs", "Staffs", entityDefs.staffs),
            entityRefColumn("basis", "Basis", entityDefs.basis),
            entityRefColumn("project", "Project", entityDefs.project),
            entityRefColumn("reference", "Reference", entityDefs.reference),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    dataset: () => ({
        columns: [
            meColumn("me", entityDefs.dataset),
            titleColumn("title", "Title"),
            entityRefListColumn("staffs", "Author(s)", entityDefs.staffs, entry => entry.staffs),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            datasetStatusColumn("datasetStatus", "Status"),
            dateColumn("doiRegistered", "DOI registered"),
            loginOptionColumn("loginOption", "Protection"),
            entityRefColumn("parentDataset", "Parent", entityDefs.dataset),
            entityRefColumn("license", "License", entityDefs.license, 150),
            dateTimeColumn("moratoriumUntil", "Morat. until"),
            dateTimeColumn("datetimePublication", "Pub. date"),
            curationLevelColumn("curationLevel", "Curation level"),
            processingLevelColumn("processingLevel", "Process. level"),
            ticketColumn("ticket", "Ticket"),
            ellipsisColumn("abstractText", "Abstract"),
            topotypeColumn("topotype", "Topotype"),
            collectionTypeColumn("collectionType", "Collection type"),
            ellipsisColumn("filename", "Filename"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ],
        defaultFilters: {'datasetStatus': ['draft', 'in review', 'published', 'questionable', 'validated']},
    }),
    event: () => ({
        columns: [
            meColumn("me", entityDefs.event),
            labelColumn("label", "Label"),
            labelColumn("labelOptional", "Label optional"),
            dateTimeColumn("datetime", "Time begin"),
            dateTimeColumn("datetime2", "Time end"),
            floatColumn("longitude", "Lon."),
            floatColumn("longitude2", "Lon. 2"),
            floatColumn("latitude", "Lat."),
            floatColumn("latitude2", "Lat. 2"),
            floatColumn("elevation", "Ele."),
            floatColumn("elevation2", "Ele. 2"),
            entityRefColumn("campaign", "Campaign", entityDefs.campaign),
            entityRefColumn("project", "Project", entityDefs.project),
            entityRefColumn("termLocation", "Location", entityDefs.location),
            entityRefColumn("method", "Method", entityDefs.method),
            uriColumn("uri", "URI"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    funder: () => ({
        columns: [
            meColumn("me", entityDefs.funder),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            uriColumn("ror", "ROR"),
            uriColumn("uri", "URI"),
            uriColumn("crossrefFunderId", "Crossref Funder ID"),
            ellipsisColumn("contact", "Contact"),
            ellipsisColumn("country", "Country"),
            ellipsisColumn("state", "State"),
            ellipsisColumn("city", "City"),
            ellipsisColumn("street", "Street"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("fax", "Fax"),
            ...createUpdateColumns()
        ]
    }),
    institution: () => ({
        columns: [
            meColumn("me", entityDefs.institution),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            institutionTypeColumn("institutionType", "Institution type"),
            uriColumn("ror", "ROR"),
            uriColumn("uri", "URI"),
            uriColumn("crossrefFunderId", "Crossref Funder ID"),
            ellipsisColumn("contact", "Contact"),
            ellipsisColumn("country", "Country"),
            ellipsisColumn("state", "State"),
            ellipsisColumn("city", "City"),
            ellipsisColumn("street", "Street"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("fax", "Fax"),
            ...createUpdateColumns()
        ]
    }),
    journal: () => ({
        columns: [
            meColumn("me", entityDefs.journal),
            titleColumn("name", "Name"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            labelColumn("issn", "ISSN"),
            ellipsisColumn("discipline", "Discipline"),
            ellipsisColumn("periodicity", "Periodicity"),
            ...createUpdateColumns()
        ]
    }),
    keyword: () => ({
        columns: [
            meColumn("me", entityDefs.keyword),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    license: () => ({
        columns: [
            meColumn("me", entityDefs.license),
            labelColumn("label", "Label"),
            titleColumn("name", "Name"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
        ]
    }),
    location: () => ({
        columns: [
            meColumn("me", entityDefs.location),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    method: () => ({
        columns: [
            meColumn("me", entityDefs.method),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            ellipsisColumn("description", "Description"),
            uriColumn("uri", "URI"),
            entityRefColumn("reference", "Reference", entityDefs.reference),
            ...createUpdateColumns()
        ]
    }),
    panGroup: () => ({
        columns: [
            meColumn("me", entityDefs.panGroup),
            titleColumn("name", "Name"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    panUser: () => ({
        columns: [
            meColumn("me", entityDefs.panUser),
            labelColumn("username", "Username"),
            labelColumn("fullName", "Full name"),
            entityRefColumn("staffs", "Staff", entityDefs.staffs),
            ellipsisColumn("email", "Email"),
            orcidColumn("orcid", "ORCID"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("institution", "Institution"),
            entityRefListColumn("groups", "Groups", entityDefs.panGroup),
            integerColumn("logins", "#Logins"),
            dateTimeColumn("lastLogin", "Last login"),
            dateTimeColumn("created", "Created at"),
        ]
    }),
    parameter: () => ({
        columns: [
            meColumn("me", entityDefs.parameter),
            titleColumn("name", "Name", "stringValue", () =>
                <EntityFormItem label='Name' paramName='name' mode="textArea">
                    <TextAreaLimitedField maxLength={255} rules={{required: false}}/>
                </EntityFormItem>),
            labelColumn("abbreviation", "Abbreviation", "stringValue", () =>
                <EntityFormItem label='Abbreviation' paramName='abbreviation'>
                    <InputLimitedField maxLength={40} />
                </EntityFormItem>),
            ellipsisColumn("unit", "Unit", "stringValue", () =>
                <EntityFormItem label='Unit' paramName='unit'>
                    <InputLimitedField maxLength={80}/>
                </EntityFormItem>),
            ellipsisColumn("ucum", "UCUM", "stringValue", () =>
                <EntityFormItem label='UCUM' paramName='ucum'>
                    <InputLimitedField maxLength={80}/>
                </EntityFormItem>),
            dataTypeColumn("dataType", "Data type", "entityRef", () =>
                <EntityFormItem label='Data type' paramName='dataType' entityDef={entityDefs.dataType}>
                    <SelectInputField enumType='dataType'/>
                </EntityFormItem>
            ),
            //TODO: add dataTypes to be able to batch edit dataTypeColumn
            ellipsisColumn("format", "Format", "stringValue", () =>
                <EntityFormItem label='Default format' paramName='format'>
                    <InputLimitedField maxLength={40}/>
                </EntityFormItem>),
            floatColumn("rangeMin", "Rng. min.", "doubleValue", () =>
                <EntityFormItem label='Range min' paramName='rangeMin'>
                    <NumberInputField/>
                </EntityFormItem>),
            floatColumn("rangeMax", "Rng. max.", "doubleValue", () =>
                <EntityFormItem label='Range max' paramName='rangeMax'>
                    <NumberInputField/>
                </EntityFormItem>),
            entityRefColumn("method", "Method", entityDefs.method, undefined, "entityRef", () =>
                <EntityFormItem label='Default method' paramName='method' entityDef={entityDefs.method}>
                    <SelectEntityField size="tiny"/>
                </EntityFormItem>),
            entityRefColumn("reference", "Reference", entityDefs.reference, undefined, "entityRef", () =>
                <EntityFormItem label='Reference' paramName='reference' entityDef={entityDefs.reference}>
                    <SelectEntityField size="tiny"/>
                </EntityFormItem>),
            ellipsisColumn("description", "Description", "stringValue", () =>
                <EntityFormItem label='Description' paramName='description'>
                    <InputField/>
                </EntityFormItem>),
            uriColumn("uri", "URI", "stringValue", () =>
                <EntityFormItem label='URI' paramName='uri'>
                    <UriInputField/>
                </EntityFormItem>),
            ...createUpdateColumns()
        ]
    }),
    project: () => ({
        columns: [
            meColumn("me", entityDefs.project),
            titleColumn("name", "Name"),
            labelColumn("label", "Label"),
            projectTypeColumn("projectType", "Project type"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            entityRefColumn("staffs", "Coordinator", entityDefs.staffs),
            uriColumn("uri", "URI"),
            uriColumn("uriData", "URI data"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    reference: () => ({
        columns: [
            meColumn("me", entityDefs.reference),
            titleColumn("title", "Title"),
            titleColumn("source", "Source"),
            entityRefListColumn("staffs", "Author(s)", entityDefs.staffs),
            integerColumn("year", "Year"),
            entityRefColumn("journal", "Journal", entityDefs.journal),
            ellipsisColumn("volume", "Volume"),
            ellipsisColumn("pages", "Pages"),
            uriColumn("uri", "URI"),
            referenceStatusColumn("referenceStatus", "Status"),
            referenceTypeColumn("referenceType", "Type"),
            ...createUpdateColumns()
        ]
    }),
    series: () => ({
        columns: [
            meColumn("me", entityDefs.series),
            entityRefColumn("dataset", "Dataset", entityDefs.dataset),
            entityRefColumn("parameter", "Parameter", entityDefs.parameter),
            entityRefColumn("staffs", "PI", entityDefs.staffs),
            entityRefColumn("method", "Method", entityDefs.method),
            dataTypeColumn("dataType", "Data type"),
            ellipsisColumn("format", "Format"),
            integerColumn("nDataPoints", "#Datapoints"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    staffs: () => ({
        columns: [
            meColumn("me", entityDefs.staffs),
            labelColumn("nameLast", "Last name"),
            labelColumn("nameFirst", "First name"),
            ellipsisColumn("email", "Email"),
            orcidColumn("orcid", "ORCID"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            ellipsisColumn("phone", "Phone"),
            entityRefListColumn("users", "Users", entityDefs.panUser),
            ...createUpdateColumns()
        ]
    }),
    term: () => ({
        columns: [
            meColumn("me", entityDefs.term),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    terminology: () => ({
        columns: [
            meColumn("me", entityDefs.terminology),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            ellipsisColumn("comment", "Comment"),
            ellipsisColumn("creator", "Creator"),
            ellipsisColumn("description", "Description"),
            uriColumn("uri", "URI"),
            ...createUpdateColumns()
        ]
    })
}

export default entityColumns;
