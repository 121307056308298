import React from "react";
import ModalPop from "./ModalPop";
import ListOfMatches from "../../pages/editorial/getjirametadata/ListOfMatches";
import {useRenderCounter} from "./useRenderCounter";

const GenericCreateFooter = (props) => {
    const {entityDef, renderItem,entityRefList,isModalOpen,handleOkDuplicate, handleCancelDuplicate} = props;
    const matches = entityRefList? entityRefList: []
    const countMSG = useRenderCounter("GenericCreateFooter N renders=");

    const render = () =>
        <>
            <ModalPop title="Duplication warning" open={isModalOpen} onOk={handleOkDuplicate} onCancel={handleCancelDuplicate}>
                <div>
                    <span>Same/similar {entityDef?.label}</span>
                    <span> already exist, proceed anyway?</span>
                </div>
                <ListOfMatches
                    matches={matches}
                    entityDef={entityDef}
                />
            </ModalPop>
            {renderItem()}
            {countMSG}
        </>
    //TODO: make useCallback dependent on actual change of enteredValues object value
    return (render())
}

export default GenericCreateFooter;