import {Col, Collapse, Row} from "antd";
import UsagesLoader from "../../../tk/bits/UsagesLoader";
import React from "react";
import PropTypes from "prop-types";


const UsagesDisplay = (props) => {
    const {entityId, entityDef} = props;
    if (!entityId) {
        return <span/>
    }

    return (
        <Row style={{width: '100%'}}>
            <Col span={24}>
                <Collapse bordered={false} items={[{
                    key: "usekey",
                    label: "Usages",
                    children: <UsagesLoader entityId={entityId} entityDef={entityDef}/>
                }]}/>
            </Col>
        </Row>
    )
}

UsagesDisplay.propTypes = {
    entityId: PropTypes.number,
    entityDef: PropTypes.object.isRequired
}

export default UsagesDisplay;
