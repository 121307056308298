import {Button, Space, Spin, Tooltip, Typography} from "antd";
import EntityTag from "../../../tk/bits/EntityTag";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SyncOutlined} from "@ant-design/icons";

const {Text} = Typography;

const ListOfMatches = (props) => {
    const {matches, entityDef, loadMatches, index} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isTempError, setIsTempError] = useState(false);
    const [isPermError, setIsPermError] = useState(false);
    const [loadedMatches, setLoadedMatches] = useState([]);
    const [retryCounter, setRetryCounter] = useState(0);

    useEffect(() => {
            if (!loadMatches) return;
            setIsLoading(true);
            setIsTempError(false);
            setIsPermError(false);
            loadMatches(index)
                .then(result => {
                    setIsLoading(false);
                    setLoadedMatches(result || []);
                })
                .catch(error => {
                    setIsLoading(false);
                    if (error.toJSON().status === 404) {
                        setIsPermError(true);
                    } else {
                        setIsTempError(true);
                    }
                })
        }, [loadMatches, retryCounter, index]
    );

    if (isLoading) {
        return <Spin/>
    }

    if (isTempError) {
        return (
            <Space>
                <Text type='warning'>Request error</Text>
                <Button onClick={() => setRetryCounter(retryCounter + 1)}>
                    Retry <SyncOutlined/>
                </Button>
            </Space>
        );
    }

    if (isPermError) {
        return <Text type='warning'>Not found</Text>
    }

    const allMatches = [...matches, ...loadedMatches];
    return allMatches.map((match, index) =>
        <Tooltip key={match.entityRef.id} title={match.matchInfo + ", score: " + match.score.toFixed(1)}>
            <span><EntityTag entityRef={match.entityRef} entityDef={entityDef}/></span>
        </Tooltip>
    );
}

ListOfMatches.propTypes = {
    matches: PropTypes.array,
    entityDef: PropTypes.object,
    loadMatches: PropTypes.func
}

export default ListOfMatches;
