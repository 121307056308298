import React, {useCallback, useContext, useEffect, useState} from "react";
import {FormExtContext} from "../../../tk/forms/FormExt";
import {useFieldArray} from "react-hook-form";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import {Button, Table} from "antd";
import PropTypes from "prop-types";
import ListOfMatches from "./ListOfMatches";
import {SwapLeftOutlined} from "@ant-design/icons";
import {useImmer} from "use-immer";


export const getBestMatch = matches => {
    if (matches.length === 0) return undefined;
    matches.sort((a, b) => {
        if (a.score > b.score) return -1;
        else if (a.score < b.score) return 1;
        else return 0;
    });
    if (matches[0].score < 1.0) return undefined;
    return matches[0];
}

const EntityRefMatchField = (props) => {
    const {paramName, extEntities, renderExternal, getDefault, entityDef, loadMatches, loadAllMatches, noLoad} = props;
    const {control, setValue, getValues} = useContext(FormExtContext);
    const {fields} = useFieldArray({
        control,
        name: paramName
    });
    const [loadAllPromise, setLoadAllPromise] = useState(undefined);
    const [loadedMatches, setLoadedMatches] = useImmer({});

    useEffect(() => {
            extEntities.forEach((extEntity, i) => {
                const bestMatch = getBestMatch(extEntity.matches);
                if (bestMatch) {
                    setValue(paramName + '.' + i, bestMatch.entityRef);
                }
            })
        },
        [extEntities, paramName, setValue]
    );

    useEffect(() => {
            if (!loadAllMatches) return;
            const loadAllPromise = loadAllMatches()
                .then(result => {
                    if (!noLoad) {
                        result.forEach((staffs, i) => {
                            let default1 = getBestMatch(staffs.matches);
                            if (default1) {
                                setValue(paramName + '.' + i, default1.entityRef);
                            }
                        });
                    }
                    return result;
                })
            setLoadAllPromise(loadAllPromise);
        },
        [getValues, loadAllMatches, noLoad, paramName, setValue]
    );

    const loadMatchesWrapper = useCallback(index => {
            if (loadAllPromise) {
                return loadAllPromise
                    .then(matches => {
                        setLoadedMatches(draft => {
                            draft[index] = matches[index].matches;
                        });
                        return matches[index].matches;
                    })
            }
            if (loadMatches) {
                return loadMatches(index)
                    .then(matches => {
                        if (!noLoad) {
                            const bestMatch = getBestMatch(matches);
                            if (bestMatch) {
                                setValue(paramName + '.' + index, bestMatch.entityRef);
                            }
                        }
                        setLoadedMatches(draft => {
                            draft[index] = matches;
                        });
                        return matches;
                    });
            }
            return Promise.resolve([]);
        },
        [loadAllPromise, loadMatches, noLoad, paramName, setLoadedMatches, setValue]
    );

    const columns = [
        {
            title: 'External',
            dataIndex: 'original',
            width: '35%',
            render: (a, _, index) => renderExternal(extEntities[index])
        },
        {
            title: 'PANGAEA',
            dataIndex: 'ignored',
            width: '30%',
            render: (entityRef, _, index) =>
                <SelectEntityField
                    paramName={paramName + '.' + index}
                    entityDef={entityDef}
                    size="tiny"
                    newDefaults={getDefault(extEntities[index])}
                />
        },
        {
            title: 'Potential Matches',
            dataIndex: 'matches',
            width: '35%',
            render: (matches, _, index) =>
                <ListOfMatches
                    matches={extEntities[index].matches}
                    entityDef={entityDef}
                    index={index}
                    loadMatches={loadMatchesWrapper}
                />
        }
    ];

    return (
        <div>
            <Table
                className='mini-table'
                size='small'
                columns={columns}
                dataSource={fields}
                rowKey='id'
                pagination={false}
            />
            <div className="ant-modal-footer">
                <Button
                    type="link"
                    onClick={() => {
                        for (var i = 0; i < fields.length; i++) {
                            const param = paramName + '.' + i;
                            const loaded = loadedMatches[i] ? loadedMatches[i] : [];
                            const allMatches = [...extEntities[i].matches, ...loaded]
                            if ((!getValues(param) || getValues(param).id === null) && allMatches.length === 1) {
                                setValue(param, allMatches[0].entityRef);
                            }
                        }
                    }}
                >
                    <SwapLeftOutlined/> Add all
                </Button>
            </div>
        </div>
    )
}

EntityRefMatchField.propTypes = {
    paramName: PropTypes.string,
    extEntities: PropTypes.array,
    renderExternal: PropTypes.func,
    getDefault: PropTypes.func,
    entityDef: PropTypes.object,
    loadMatches: PropTypes.func,
    loadAllMatches: PropTypes.func
}

export default EntityRefMatchField;