import PropTypes from "prop-types";
import {useMemo} from "react";


const hello = {
    English: "Hello",
    Hindi: "नमस्ते",
    Hungarian: "Helló",
    Filipino: "Kamusta",
    Korean: "안녕하세요",
    Basque: "Kaixo",
    Navajo: "Yá’át’ééh",
    Chinese: "你好",
    Persian: "سلام",
    Dutch: "Hallo",
    Finnish: "Hei",
    Slovenian: "zdravo",
    Afrikaans: "Hallo",
    Czech: "Ahoj",
    Turkish: "Merhaba",
    Urdu: "ہیلو",
    Danish: "Hej",
    Hebrew: "שלום",
    Sanskrit: "नमो नमः",
    Croatian: "zdravo",
    Swedish: "Hej",
    Spanish: "Hola",
    Italian: "Ciao",
    Irish: "Dia dhuit",
    French: "Bonjour",
    Japanese: "こんにちは",
    Icelandic: "Halló",
    Albanian: "Përshëndetje",
    Portuguese: "Olá",
    Punjabi: "ਸਤ ਸ੍ਰੀ ਅਕਾਲ",
    German: "Hallo",
    Romanian: "Buna ziua",
    Marathi: "नमस्कार",
    Thai: "สวัสดี",
    Vietnamese: "xin chào",
    Indonesian: "Halo",
    Telugu: "హలో",
    Malayalam: "ഹലോ ",
    Arabic: "مرحبا",
    Norwegian: "Hallo",
    Esperanto: "Saluton",
    Kannada: "ನಮಸ್ಕಾರ",
    Polish: "Witaj",
    Swahili: "Habari",
    Scottish: "Halò",
    Welsh: "Helo",
    Bengala: "হ্যালো"
}

const Hello = (props) => {
    const {name} = props;
    const values = Object.values(hello);
    const rand = useMemo(() => {
        return Math.floor(Math.random() * values.length);
    }, [values.length]);
    return (
        <span>{values[rand]} {name}</span>
    )
}

Hello.propTypes = {
    name: PropTypes.string
}

export default Hello;