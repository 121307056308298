import CitationButton from "../dataset/CitationButton";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {getDatasetLandingUrl, listIdsRequest, requestCitationList} from "../../../lib/networkRequests";
import {LinkOutlined} from "@ant-design/icons";

const cStyle = {
    marginTop: '6px',
    marginBottom: '6px',
}

function loadCitation(ws2Axios, entityDef, rsql) {
    return listIdsRequest(ws2Axios, entityDef, rsql)
        .then(result => requestCitationList(ws2Axios, result.data))
        .then(result => {
            const citations = result.data.split('\n\n');
            const summary = <div>
                {citations.slice(0, 3).map((c, i) => <div key={i} style={cStyle}>{c}</div>)}
                {citations.length > 3 && <div>... {citations.length} total</div>}
            </div>

            return ({
                html: summary,
                text: result.data
            });
        });
}

const overviewRsqlButtons = {
    dataset: (rsql, entityDef, ws2Axios) => (
        <>
            <CitationButton onLoad={() => loadCitation(ws2Axios, entityDef, rsql)}>
                <Button type="link" style={{padding: '0'}}>
                    <FontAwesomeIcon icon={faQuoteLeft}/>
                </Button>
            </CitationButton>
            <CitationButton
                onLoad={() => {
                    return listIdsRequest(ws2Axios, entityDef, rsql)
                        .then(result => {
                            const urls = result.data.map(idDataset => getDatasetLandingUrl(idDataset));
                            const copy = urls.reduce((acc, curr) => acc ? acc + '\n' + curr : curr, undefined);
                            return ({
                                html: <div>
                                    {urls.slice(0, 3).map((c, i) => <div key={i} style={cStyle}>{c}</div>)}
                                    {urls.length > 3 && <div>... {urls.length} total</div>}
                                </div>,
                                text: copy
                            });
                        });
                }}
            >
                <Button type="link" style={{padding: '0'}}>
                    <LinkOutlined/>
                </Button>
            </CitationButton>
        </>
    )
}

export default overviewRsqlButtons;