export const handleNetworkError = errorResponse => {
    const err = JSON.parse(JSON.stringify(errorResponse));
    if (errorResponse.message) {
        return {
            code: err.status || err.name || "???",
            message: err.message
        };
    }

    let response = err.response;
    if (response) {
        return response.data; // with fields: data.code and data.message
    }

    return {
        code: "0",
        message: "Oh no, an error!"
    }
}

export const handleApplicationError = (error, setError, setGlobalErrors) => {
    if (!error || !error.validationErrors) return;
    const validationErrors = error.validationErrors;
    const ge = [];
    for (const entityRefError of validationErrors) {
        for (const key of Object.keys(entityRefError.errors)) {
            let message = entityRefError.errors[key].join(". ");
            if (key === "") {
                ge.push(message);
            } else {
                setError(key, {type: "server", message});
            }
        }
    }
    setGlobalErrors(ge);
}
