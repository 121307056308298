import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const EventOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Label" value={entity.label}/>
            <OverviewLine label="Label optional" value={entity.labelOptional}/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="Datetime" value={entity.datetime} type="datetime"/>
            <OverviewLine label="Datetime2" value={entity.datetime2} type="datetime"/>
            <OverviewLine label="Elevation" value={entity.elevation}/>
            <OverviewLine label="Elevation2" value={entity.elevation2}/>
            <OverviewLine label="Latitude" value={entity.latitude}/>
            <OverviewLine label="Latitude2" value={entity.latitude2}/>
            <OverviewLine label="Longitude" value={entity.longitude}/>
            <OverviewLine label="Longitude2" value={entity.longitude2}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Method" value={entity.method} entityDef={entityDefs.method}/>
            <OverviewLine label="Campaign" value={entity.campaign} entityDef={entityDefs.campaign}/>
            <OverviewLine label="Project" value={entity.project} entityDef={entityDefs.project}/>
            <OverviewLine label="Term location" value={entity.termLocation} entityDef={entityDefs.term}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term}/>
        </div>
    )
}

export default EventOverview;