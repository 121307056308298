import {Button, Space,} from "antd";
import React, {useContext, useEffect, useState} from "react";
import FormExt, {FormExtContext} from "../../tk/forms/FormExt";
import EntityFormItem from "../../tk/forms/EntityFormItem";
import TextAreaField from "../../tk/input/TextAreaField";
import {randomInt} from "../../lib/random";
import {useFieldArray} from "react-hook-form";

const TestPage4 = (props) => {
    console.log("Render TestPage4");
    const defaultEntity = {
        title: "hallo",
        extras: []
    }
    return (
        <FormExt defaultEntity={defaultEntity} updateEntity={e => console.log("Nothing to do", e)}>
            <TestForm/>
        </FormExt>
    )
}

const TestForm = (props) => {
    const {watch, setValue, control} = useContext(FormExtContext)
    const {fields, append, remove} = useFieldArray({
        control,
        name: "extras"
    })

    const [structure, setStructure] = useState([]);

    const values = watch();
    console.log("VALS: ", JSON.stringify(values))
    console.log("FIELDS: ", JSON.stringify(fields))
    //console.log("EXTRAS: ", JSON.stringify(extras))

    const table = watch("extras");
    console.log("TABLE", JSON.stringify(table))

    useEffect(() => {
            const s = table.map(row => row.title);
            setStructure(s);
            console.log("REDO STRUCTURE", s)
        },
        [table]
    );

    return (
        <>
            <EntityFormItem label={"Title"} paramName={"title"}>
                <TextAreaField/>
            </EntityFormItem>

            {structure
                .map(title => {
                    const index = fields.findIndex(field => field.title === title);
                    const item = fields[index];
                    return {item, index};
                })
                .filter(({item, index}) => item !== undefined)
                .map(({item, index}) => {
                    console.log("STRUCTURE MAP", index, item);
                    return (
                        <EntityFormItem
                            key={item.id}
                            label={item.title}
                            paramName={`extras.${index}.value`}
                            onDelete={() => remove(index)}
                        >
                            <TextAreaField/>
                        </EntityFormItem>
                    )
                })}

            {/*fields.map((item, index, array) => {
                return (
                    <div>
                        <EntityFormItem
                            key={item.id}
                            label={item.title}
                            paramName={`extras.${index}.value`}
                            onDelete={() => remove(index)}
                        >
                            <TextAreaField/>
                        </EntityFormItem>
                    </div>
                )
            })*/}

            {/*extras.map(extra => (
                <EntityFormItem key={extra} label={extra} paramName={extra}>
                    <TextAreaField/>
                </EntityFormItem>
            ))*/}
            <Space>
                <Button
                    onClick={() => {
                        append({
                            title: "extra-" + randomInt(10000),
                            value: "NEU"
                        })
                        /*
                        setExtras(extras => {
                            extras.push("extra-"+randomInt(10000))
                        })
                         */
                    }}
                >
                    Extra
                </Button>
                <Button
                    onClick={() => {
                        setValue("title", "")
                        remove()
                        //setExtras(extras => [])

                    }}
                >
                    Clear
                </Button>
            </Space>
        </>
    )
}

export default TestPage4;