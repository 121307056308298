import {Button, Modal} from "antd";
import {SelectOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import ContainerContext from "../../contexts/ContainerContext";

const ModalPop = (props) => {
    const {onPop, children, open, setOpen, ...restProps} = props;
    const [openInt, setOpenInt] = useState(true);
    const openWrapper = open !== undefined ? open : openInt;
    const setOpenWrapper = setOpen !== undefined ? setOpen : setOpenInt;
    return (
        <Modal
            {...restProps}
            open={openWrapper}
            setOpen={setOpenWrapper}
            modalRender={node => {
                const mapped = node.props.children.map((n, index) => {
                    if ((n ? n.props.className : "") === "ant-modal-close") {
                        return (
                            <span key="some key">
                                <Button
                                    className="ant-modal-close"
                                    style={{marginRight: "24px"}}
                                    onClick={onPop}
                                >
                                    <SelectOutlined/>
                                </Button>
                                {n}
                            </span>
                        );
                    }
                    return n;
                })
                return React.cloneElement(node, {children: mapped});
            }}
        >
            <ContainerContext.Provider value={{
                closeContainer: () => setOpenWrapper(false)
            }}>
                {children}
            </ContainerContext.Provider>
        </Modal>
    )
}

export default ModalPop;