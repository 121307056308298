import {Button} from "antd";
import {useState} from "react";
import PropTypes from "prop-types";


const defaultMax = 400;
const buttonStyle = {
    padding: "0",
    height: "24px"
};

const EllipsisText = (props) => {
    const {max, text} = props;
    const [more, setMore] = useState(false);

    if (text.length < (max || defaultMax)) {
        return <span>{text}</span>
    }

    if (more) {
        return (
            <span>
                {text}
                <Button
                    type="link"
                    onClick={() => setMore(false)}
                    style={buttonStyle}
                >
                    &nbsp;less
                </Button>
            </span>
        )
    }

    return (
        <span>
            {text.substring(0, max || defaultMax)} ...&nbsp;
            <Button
                type="link"
                onClick={() => setMore(true)}
                style={buttonStyle}
            >
                more
            </Button>
        </span>
    )
}

EllipsisText.propTypes = {
    max: PropTypes.number,
    text: PropTypes.string
}

export default EllipsisText;