import {labelStyle} from "../overviews/OverviewLine";
import EntityRefMatchField from "./EntityRefMatchField";
import entityDefs, {getEmptyWithDefaults} from "../entities/entityDefs";
import React from "react";
import OrcidLink from "../../../tk/bits/OrcidLink";
import ResolveUri from "../../../tk/bits/ResolveUri";
import {Col, Row} from "antd";
import JournalMatchField from "../getreferencemetadata/JournalMatchField";

export const LayoutWrapper = ({checkbox, component, align, renderCondition = true}) => {
    if (renderCondition) {
        return <Row align={align ? align : 'top'} gutter={8}>
            <Col>
                {checkbox}
            </Col>
            <Col span={20}>
                {component}
            </Col>
        </Row>
    }
}
export const RenderReferences = (props) => {
    const {entity, noLoad, loadMatchesReferences} = props;
    const entityDef = entityDefs.reference;
    return <>
        <span style={labelStyle}>References:</span>
        <div style={{paddingLeft: "24px"}}>
            <EntityRefMatchField
                extEntities={entity.references}
                paramName="references"
                entityDef={entityDef}
                getDefault={extEntity => ({
                    ...getEmptyWithDefaults(entityDef),
                    title: extEntity.name
                })}
                renderExternal={extEntity => <span>{extEntity.name}</span>}
                loadMatches={loadMatchesReferences}
                noLoad={noLoad}
            />
        </div>
    </>
}
export const RenderJournal = ({result}) => {
    if (result.journal !== null) {
        return <>
            <span style={labelStyle}>Journal:</span>
            <div style={{paddingLeft: "24px"}}>
                <JournalMatchField journal={result.journal} paramName="journal"/>
            </div>
        </>
    }
}
export const RenderStaffs = (props) => {
    const {loadAllMatchesStaffs, noLoad, staffs} = props
    const entityDef = entityDefs.staffs;
    return <>
        <span style={labelStyle}>Authors:</span>
        <div style={{paddingLeft: "24px"}}>
            <EntityRefMatchField
                extEntities={staffs}
                paramName="staffs"
                entityDef={entityDef}
                getDefault={staffs => ({
                    ...getEmptyWithDefaults(entityDef),
                    nameFirst: staffs.nameFirst,
                    nameLast: staffs.nameLast,
                    orcid: staffs.orcid,
                    email: staffs.email
                })}
                renderExternal={extEntity => (
                    <span>
                                    {extEntity.nameLast}
                        {extEntity.nameFirst && extEntity.nameFirst.length > 0 &&
                            <span>, {extEntity.nameFirst}</span>
                        }
                        {extEntity.email && extEntity.email.length > 0 &&
                            <span> &lt;{extEntity.email}&gt;</span>
                        }
                        {extEntity.orcid && extEntity.orcid.length > 0 &&
                            <span> <OrcidLink orcid={extEntity.orcid}/></span>
                        }
                                </span>
                )}
                loadAllMatches={loadAllMatchesStaffs}
                noLoad={noLoad}
            />
        </div>
    </>
}
export const RenderProjects = (props) => {
    const {entity, noLoad} = props
    const entityDef = entityDefs.project;
    return <>
        <span style={labelStyle}>Projects:</span>
        <div style={{paddingLeft: "24px"}}>
            <EntityRefMatchField
                extEntities={entity.projects}
                paramName="projects"
                entityDef={entityDef}
                getDefault={extEntity => ({
                    ...getEmptyWithDefaults(entityDef),
                    name: extEntity.projectName,
                    uri: extEntity.projectWebsite
                })}
                renderExternal={extEntity => {
                    let websiteName = <span/>
                    if (extEntity.projectWebsite) {
                        websiteName = <span>, <ResolveUri uri={extEntity.projectWebsite}/></span>
                    }
                    let awardName = <span/>
                    if (extEntity.awardName) {
                        awardName = <span>, Award: {extEntity.awardName}</span>
                    }
                    return <span>{extEntity.projectName}{websiteName}{awardName}</span>
                }}
                noLoad={noLoad}
            />
        </div>
    </>
}
export const RenderKeywords = (props) => {
    const {entity, noLoad} = props
    const entityDef = entityDefs.keyword;
    if (entity.keywords.length > 0) {
        return <>
            <span style={labelStyle}>Keywords:</span>
            <div style={{paddingLeft: "24px"}}>
                <EntityRefMatchField
                    extEntities={entity.keywords}
                    paramName="keywords"
                    entityDef={entityDef}
                    getDefault={keyword => ({
                        ...getEmptyWithDefaults(entityDef),
                        name: keyword.name
                    })}
                    renderExternal={extEntity => extEntity.name}
                    noLoad={noLoad}
                />
            </div>
        </>
    }
}
