import {Controller} from "react-hook-form";
import {Input} from "antd";
import React, {useContext} from "react";
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import useAutofocus from "../../hooks/useAutofocus";

const {TextArea} = Input;


const TextAreaField = (props) => {
    const {paramName, rows, disabled, rules, autoFocus, placeholder} = props;
    const {setValue, control, instanceId} = useContext(FormExtContext);
    const inputRef = useAutofocus(autoFocus, instanceId);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));

    return (
        <Controller
            name={paramName}
            control={control}
            rules={rules}
            render={({field}) => {
                return (
                    <div ref={drop}>
                        <DndHighlight isOver={isOver} canDrop={canDrop}/>
                        <TextArea
                            style={{
                                minWidth: '200px',
                                ...formStyle
                            }}
                            rows={rows || 3}
                            disabled={disabled}
                            autoSize={rows ? false : {minRows: 1, maxRows: 12}}
                            placeholder={placeholder}
                            {...field}
                            ref={inputRef || field.ref}
                        />
                        <FormFieldError paramName={paramName}/>
                    </div>
                )
            }}
        />
    )
}

TextAreaField.propTypes = {
    paramName: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    disabled: PropTypes.bool,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool
}

export default TextAreaField;
