import EntityHeader from "../entities/EntityHeader";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import entityDefs from "../entities/entityDefs";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import TableField2 from "../../../tk/inputComplex/TableField2";
import {
    renderEditableCheckbox,
    renderEditableNumberInput,
    renderEditableTextArea,
    renderEntityRef
} from "../../test/TestPage2";
import dayjs from "dayjs";
import MyUserContext from "../../../contexts/MyUserContext";
import {entityFields, renderField} from "../entities/entityFields";


const MethodDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);
    const {myUser} = useContext(MyUserContext);
    const {watch} = useContext(FormExtContext);
    const addedIds = watch('terms')?.map(entry => entry.term.id)

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["name", "abbreviation", "uri"].map(
                        name => renderField(entityFields.method.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["reference", "description"].map(
                        name => renderField(entityFields.method.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2} wide>
                    <EntityFormItem label='Terms' paramName='terms' border={true} entityDef={entityDefs.term}>
                        <TableField2
                            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
                            columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'term',
                                    width: 60,
                                    align: 'right',
                                    render: entityRef => entityRef?.id
                                },
                                {
                                    title: 'Term',
                                    dataIndex: 'term',
                                    width: 210,
                                    indexColumn: true,
                                    entityDef: entityDefs.term,
                                    render: renderEntityRef(entityDefs.term)
                                },
                                {
                                    title: 'Comment',
                                    dataIndex: 'comment',
                                    width: 300,
                                    render: renderEditableTextArea('terms', 'comment'),
                                    multiEdit: 'text'
                                },
                                {
                                    title: 'Start',
                                    dataIndex: 'startOffset',
                                    width: 130,
                                    render: renderEditableNumberInput('terms', 'startOffset')
                                },
                                {
                                    title: 'End',
                                    dataIndex: 'endOffset',
                                    width: 130,
                                    render: renderEditableNumberInput('terms', 'endOffset')
                                },
                                {
                                    title: 'Sequence',
                                    dataIndex: 'sequence',
                                    width: 130,
                                    render: renderEditableNumberInput('terms', 'sequence')
                                },
                                {
                                    title: 'Confirmed',
                                    dataIndex: 'confirmed',
                                    width: 80,
                                    render: renderEditableCheckbox('terms', 'confirmed')
                                }
                            ]}
                            newRowFn={entityRef => ({
                                term: entityRef,
                                comment: null,
                                startOffset: null,
                                endOffset: null,
                                sequence: null,
                                confirmed: true,
                                datetimeCreated: dayjs.utc(),
                                userCreated: {
                                    id: myUser.id,
                                    name: myUser.fullname
                                },
                                datetimeUpdate: null,
                                userUpdated: {id: null, name: null}
                            })}
                        />
                    </EntityFormItem>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )

}

export default MethodDetail;