import ModBoolean from "./ModBoolean";
import ModServerTransform from "./ModServerTransform";


const ModByType = ({onDelete, collapsed, model, setModel, isRoot}) => {
    switch (model.spec.modName) {
        case  'ModOr':
            return <ModBoolean isRoot={isRoot} mode='OR' onDelete={onDelete} collapsed={collapsed}
                               model={model} setModel={setModel}/>
        case  'ModAnd':
            return <ModBoolean isRoot={isRoot} mode='AND' onDelete={onDelete}
                               collapsed={collapsed} model={model} setModel={setModel}/>
        case  'ModNot':
            return <ModBoolean isRoot={isRoot} mode='NOT' onDelete={onDelete}
                               collapsed={collapsed} model={model} setModel={setModel}/>
        case 'ModServerTransform':
            return <ModServerTransform isRoot={isRoot} onDelete={onDelete} collapsed={collapsed} model={model}
                                       setModel={setModel}/>
        default:
            return <span/>
    }
}

export default ModByType;