import EntityTag from "./EntityTag";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import FilterTag from "./FilterTag";


const EntityTagList = (props) => {
    const {list, entityDef, max, empty, noListIcon} = props;
    const [exMax, setExMax] = useState(max || 20);

    useEffect(() => {
        setExMax(max || 20);
    }, [max, list]);

    return (
        <span data-testid='EntityTagList'>
            {list && list.length > 1 && !noListIcon &&
                <FilterTag
                    entityDef={entityDef}
                    extraStyle={{borderStyle: 'none'}}
                    list={list}
                >
                    <UnorderedListOutlined/>
                </FilterTag>
            }
            {list &&
                list
                    .filter((value, i) => i < exMax)
                    .map(entityRef => {
                        return (
                            <EntityTag key={entityRef.id} entityRef={entityRef} entityDef={entityDef}/>
                        );
                    })
            }
            {list && list.length > exMax &&
                <Button
                    onClick={() => setExMax(exMax + 100)}
                    type="link"
                >
                    ... {list.length - exMax} more
                </Button>
            }
            {empty && (!list || list.length === 0) &&
                React.cloneElement(empty)
            }
        </span>
    );
}

EntityTagList.propTypes = {
    list: PropTypes.array,
    entityDef: PropTypes.object,
    max: PropTypes.number,
    empty: PropTypes.object,
}

export default EntityTagList;
