import React from "react";
import FormExt from "../../../tk/forms/FormExt";
import PropTypes from "prop-types";
import BatchEditContent from "./BatchEditContent";


const BatchEditForm = ({onOk, onCancel, entityRefs, entityDef, previewColumns}) => {

    return (
        <FormExt
            defaultEntity={{dirtyCounter: 0}}
            updateEntity={e => console.log("Nothing to do", e)}
            noDirtyReport={true}
        >
            <BatchEditContent
                onCancel={onCancel}
                onOk={onOk}
                entityRefs={entityRefs}
                entityDef={entityDef}
                previewColumns={previewColumns}
            />
        </FormExt>
    )
}

BatchEditForm.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func
}

export default BatchEditForm;
