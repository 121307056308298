import React, {useContext} from "react";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import TableField2 from "./TableField2";
import {renderEntityRef} from "../../pages/test/TestPage2";
import SelectField from "../input/SelectField";
import useEnumType from "../../hooks/useEnumType";
import useMyUser from "../../hooks/useMyUser";
import {FormExtContext} from "../forms/FormExt";


const RelatedTermsTableField = ({paramName, relationTypes}) => {
    const termRelationTypes = useEnumType(relationTypes);
    const {myUser} = useMyUser();
    const {watch} = useContext(FormExtContext);
    const addedIds = watch(paramName)?.map(entry => entry.termRelated.id)

    return (
        <TableField2
            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
            paramName={paramName}
            entityDef={entityDefs.term}
            columns={[
                {
                    title: 'Relation type',
                    dataIndex: 'relationType',
                    width: 130,
                    render: (entityRef, _, index) =>
                        <SelectField
                            paramName={paramName + "." + index + '.relationType'}
                            values={termRelationTypes}
                            style={{width: '155px'}}
                        />,
                    multiEdit: 'type',
                    multiEditOptions: termRelationTypes
                },
                {
                    title: 'ID',
                    dataIndex: 'termRelated',
                    width: 80,
                    align: 'right',
                    render: entityRef => entityRef?.id
                },
                {
                    title: 'Term name',
                    dataIndex: 'termRelated',
                    width: 270,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.term)
                },
                {
                    title: 'ID',
                    dataIndex: 'terminology',
                    width: 60,
                    align: 'right',
                    render: entityRef => entityRef?.id
                },
                {
                    title: 'Terminology name',
                    dataIndex: 'terminology',
                    width: 270,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.terminology)
                },
            ]}
            newRowFn={entityRef => ({
                termRelated: entityRef,
                relationType: {
                    id: 1,
                    name: 'has broader term'
                },
                userCreated: myUser
            })}
        />
    )
}

export default RelatedTermsTableField;
