import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import SelectTextInput from "./SelectTextInput";
import {formStyle} from "../forms/formStyle";


const SelectTextField = ({paramName, values, disabled, allowClear, rules, onChange, style}) => {
    const {control, getValues} = useContext(FormExtContext);
    return (
        <Controller
            name={paramName}
            control={control}
            rules={rules}
            render={({field}) => (
                <>
                    <SelectTextInput
                        {...field}
                        style={{
                            minWidth: '200px',
                            width: '100%',
                            ...formStyle
                        }}
                        value={getValues(paramName)} // Fix for revert on TableField2
                        onChange={v => {
                            let newValue = "";
                            const oldValue = getValues(paramName);
                            if (!v) {
                                newValue = "";
                            } else if (oldValue.length === 0) {
                                newValue = v;
                            } else {
                                const v2 = v + "; ";
                                const i = oldValue.indexOf(v2);
                                if (i > -1) {
                                    newValue = oldValue
                                        .replaceAll(new RegExp(v2, "gi"), "")
                                        .trim()
                                    if (newValue.endsWith(';')) {
                                        newValue = newValue.slice(0, -1);
                                    }
                                } else if (oldValue.endsWith(v)) {
                                    newValue = oldValue
                                        .slice(0, -v.length)
                                        .trim();
                                    if (newValue.endsWith(';')) {
                                        newValue = newValue.slice(0, -1);
                                    }
                                } else {
                                    newValue = oldValue + "; " + v;
                                }
                            }
                            field.onChange(newValue);
                            if (onChange) onChange(newValue);
                        }}
                        allowClear={allowClear}
                        disabled={disabled}
                        options={values}
                    />
                    <FormFieldError paramName={paramName}/>
                </>
            )}
        />
    )
}

SelectTextField.propTypes = {
    paramName: PropTypes.string,
    values: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func,
    rules: PropTypes.object,
    tagStyle: PropTypes.shape({
        width: PropTypes.string
    }),
    style: PropTypes.object

}

export default SelectTextField;