import useMatrixData from "../../../hooks/useMatrixData";
import {Button, Space, Table} from "antd";
import FormattedNumber from "../../../tk/bits/FormattedNumber";
import FormattedDatetime from "../../../tk/bits/FormattedDatetime";
import LinkResolve from "../../../tk/bits/LinkResolve";
import LinkDatasetFile from "../../../tk/bits/LinkDatasetFile";
import EntityTag from "../../../tk/bits/EntityTag";
import entityDefs from "../entities/entityDefs";
import React, {useContext, useState} from "react";
import {useFieldArray} from "react-hook-form";
import {FormExtContext} from "../../../tk/forms/FormExt";
import {ReloadOutlined} from "@ant-design/icons";
import {useDrop} from "react-dnd";
import {collectFn, DndHighlight} from "../../../lib/dropIndicatorStyle";
import useEntity from "../../../hooks/useEntity";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import AutocompleteField from "../../../tk/input/AutocompleteField";
import NetworkError from "../../../tk/error/NetworkError";
import PropTypes from "prop-types";
import TextAreaField from "../../../tk/input/TextAreaField";
import useEntities from "../../../hooks/useEntities";
import {getDataMatrixContext2} from "../detailforms/DatasetDecorator";
import {randomId} from "../../../lib/random";


export const formatOptions = [
    [], // index 0: not used
    ["##0", "##0.000", "##0.0000", "###.##", "##0.0E0", "##0.000E0", "0.00E0", "<humanReadableSize>"], // numeric
    [], // string
    ["yyyy-MM-dd'T'HH:mm", "yyyy-MM-dd'T'HH:mm:ss", "yyyy-MM-dd'T'HH:mm:ss.SSS", "yyyy-MM", "yyyy-MM-dd", "yyyy-'W'ww-dd", "yyyy-'W'ww", "yyyy-'Q'q", "yyyy-DDD", "HH:mm:ss", "HH:mm:ss.SSS", "yyyy", "q", "MM", "ww", "dd", "DDD", "E", "HH", "mm", "ss", "SSS"], // datetime
    [], // binary
    [], // term
    [], // uri
    [], // event
    [], // filename
];

const dataCellStyle = {
    paddingLeft: "16px",
    paddingRight: "16px"
}

const myTagStyle = {maxWidth: "140px"}

export const getAvailableConfigs = (inputDataType, datasetConfig) => (
    Object.values(datasetConfig.fields)
        .filter(record => record.inputDataType.includes(inputDataType))
);

export const findIndexWithInstance = (entityRef, watchFields) => {
    for (let i = 0; i < watchFields.length; i++) {
        if (watchFields[i].dataSeries &&
            watchFields[i].dataSeries.id === entityRef.id &&
            watchFields[i].dataSeries.instanceId === entityRef.instanceId
        ) {
            return i;
        }
    }
    return -1;
}

export const newSeriesRow = (datasetConfig, entityRef) => {
    const series = datasetConfig.series[entityRef.id];
    const parameter = datasetConfig.parameter[series.parameter.id];
    const availableConfigs = getAvailableConfigs(parameter.dataType.id, datasetConfig);
    return {
        dataSeries: {
            ...entityRef,
            instanceId: randomId()
        },
        parameter: series.parameter,
        method: series.method,
        staffs: series.staffs,
        datasetConfigField: availableConfigs[0].me,
        format: series.format || parameter.format,
        comment: series.comment
    };
}

export const newEventRow = datasetConfig => {
    const availableConfigs = getAvailableConfigs(7, datasetConfig);
    return {
        dataSeries: {
            id: randomId(),
            instanceId: randomId(),
            name: null,
            dummy: true
        },
        parameter: {id: 500000, name: "Event"},
        method: {id: null, name: null},
        staffs: {id: null, name: null},
        datasetConfigField: availableConfigs[0].me,
        format: "",
        comment: ""
    };
}


const DataMatrixHorizontal = (props) => {
    const {idDataset, paramName, columnWidth, firstEvent, datasetConfig} = props;
    const {control} = useContext(FormExtContext);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const {fields, append, move, remove, swap, insert} = useFieldArray({
        control,
        name: paramName,
    });
    const watchFields = fields;

    const parameterIds = watchFields
        .filter(config => config.parameter.id)
        .map(config => config.parameter.id);
    const {entities: parameters} = useEntities(entityDefs.parameter, parameterIds);
    const parametersTrans = {};
    for (const parameter of parameters) {
        parametersTrans[parameter.idParameter] = parameter;
    }
    const {data, isLoading, error, reload} = useMatrixData(watchFields);
    const {entity: firstEventEntity, error: eventError} = useEntity(entityDefs.event, firstEvent?.id);
    const {entity: firstCampaignEntity} = useEntity(entityDefs.campaign, firstEventEntity?.campaign?.id);

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [
            'ENTITY_' + entityDefs.series.entityType,
            'ENTITY_' + entityDefs.event.entityType
        ],
        collect: collectFn,
        drop: (item, monitor) => {
            if (monitor.didDrop()) return;
            const index = findIndexWithInstance(item.data, watchFields);
            if (index !== -1) {
                move(index, fields.length - 1);
            } else if (item.entityDef === entityDefs.series) {
                append(newSeriesRow(datasetConfig, item.data));
            } else if (item.entityDef === entityDefs.event) {
                append(newEventRow(datasetConfig));
            }
        }
    }), [watchFields]);

    const handleTagInsert = (entityRefNew, insertIndex, watchFields) => {
        const removeIndex = findIndexWithInstance(entityRefNew, watchFields);
        if (removeIndex >= 0) {
            if (removeIndex < insertIndex) {
                insertIndex--;
            }
            move(removeIndex, insertIndex);
            return;
        }

        insert(insertIndex, newSeriesRow(datasetConfig, entityRefNew));
    };
    const handleTagInsertRelative = (entityRefNew, entityRefRelativeTo, entityDefNew) => {
        let insertIndex = findIndexWithInstance(entityRefRelativeTo, watchFields);
        if (entityDefNew === entityDefs.series) {
            handleTagInsert(entityRefNew, insertIndex, watchFields);
        } else if (entityDefNew === entityDefs.event) {
            insert(insertIndex, newEventRow(datasetConfig));
        }
    };
    const handleRemoveById = entityRef => {
        const findIndexWithInstance1 = findIndexWithInstance(entityRef, watchFields);
        remove(findIndexWithInstance1);
    };
    const handleTagDuplicate = (entityRef, index) => {
        insert(
            index + 1,
            {
                ...watchFields[index],
                dataSeries: {
                    ...watchFields[index].dataSeries,
                    instanceId: randomId()
                }
            }
        );
    }

    const columns = watchFields
        .map((config, index) => {
            const {
                series,
                dataTypeId,
                dataTypeIdForConfigOptions,
                format,
                parameter,
                configField,
                title,
                unit,
                options,
                availableConfigs
            } = getDataMatrixContext2(datasetConfig, config, parametersTrans);
            let render;
            let align;

            if (series !== undefined) {
                switch (dataTypeId) {
                    case 1:
                        render = (cellNumeric) =>
                            (cellNumeric &&
                                <FormattedNumber value={cellNumeric.value} format={format} style={dataCellStyle}/>
                            );
                        align = "right";
                        break;
                    case 2:
                        render = (cellString) =>
                            (cellString &&
                                <div style={dataCellStyle}>{cellString.value}</div>
                            );
                        break;
                    case 3:
                        render = (cellDateTime) =>
                            (cellDateTime &&
                                <FormattedDatetime value={cellDateTime.value} format={format} style={dataCellStyle}/>
                            );
                        break;
                    case 4:
                        render = (cellBinary) =>
                            (cellBinary &&
                                <div style={dataCellStyle}>{cellBinary.value.substring(2)}</div> // removes \\x prefix
                            );
                        break;
                    case 6:
                        render = (cellUri) =>
                            (cellUri &&
                                <LinkResolve uri={cellUri.value} style={dataCellStyle}>{cellUri.value}</LinkResolve>
                            );
                        break;
                    case 8:
                        render = (cellFilename) =>
                            (cellFilename &&
                                <LinkDatasetFile idDataset={idDataset} filename={cellFilename.value}
                                                 style={dataCellStyle}>
                                    {cellFilename.value}
                                </LinkDatasetFile>
                            );
                        break;
                    default:
                        render = (value) => <span style={dataCellStyle}>?</span>
                        align = "left";
                }

            } else {

                if (firstEventEntity && configField) {
                    let value;
                    switch (configField.relatedTable + ":" + configField.relatedField) {
                        case "event:label_optional":
                            value = firstEventEntity.labelOptional;
                            break;
                        case "campaign:name":
                            value = firstEventEntity.campaign.name;
                            break;
                        case "method:name":
                            value = firstEventEntity.method.name;
                            break;
                        case "basis:name":
                            value = firstCampaignEntity?.basis.name;
                            break;
                        case "term_location:name":
                            value = firstEventEntity.termLocation.name;
                            break;
                        default:
                            value = firstEventEntity[configField.relatedField];
                            break;
                    }

                    align = "left";
                    switch (dataTypeId) {
                        case 1:
                            render = () => <FormattedNumber value={value} format={format} style={dataCellStyle}/>
                            align = "right";
                            break;
                        case 2:
                            render = () => <div style={dataCellStyle}>{value}</div>
                            break;
                        case 3:
                            render = () => <FormattedDatetime value={value} format={format} style={dataCellStyle}/>
                            break;
                        case 6:
                            render = () => <LinkResolve uri={value} style={dataCellStyle}>{value}</LinkResolve>
                            break;
                        default:
                            render = () => <div style={dataCellStyle}>{value}</div>
                    }
                } else {
                    render = () => <span/>
                }
            }

            return {
                title: (
                    <div style={{textAlign: "left"}}>
                        {series && <>
                            <div>
                                <EntityTag
                                    entityRef={config.dataSeries}
                                    entityDef={entityDefs.series}
                                    style={{maxWidth: "150px"}}
                                    onRemove={handleRemoveById}
                                    onInsert={handleTagInsertRelative}
                                    onDuplicate={entityRef => handleTagDuplicate(entityRef, index)}
                                    dndDirection="horizontal"
                                    dropAcceptOverride={[
                                        entityDefs.series,
                                        entityDefs.event
                                    ]}
                                />
                            </div>
                            <div>
                                <SelectEntityField
                                    paramName={paramName + '.' + index + '.parameter'}
                                    placeholder="Parameter"
                                    entityDef={entityDefs.parameter}
                                    tagStyle={myTagStyle} size="tiny"
                                />
                            </div>
                            <div>
                                <SelectEntityField
                                    paramName={paramName + '.' + index + '.method'}
                                    placeholder="Method"
                                    entityDef={entityDefs.method}
                                    tagStyle={myTagStyle} size="tiny"
                                />
                            </div>
                            <div>
                                <SelectEntityField
                                    paramName={paramName + '.' + index + '.staffs'}
                                    placeholder="PI"
                                    entityDef={entityDefs.staffs}
                                    tagStyle={myTagStyle} size="tiny"
                                />
                            </div>
                            <div style={{height: "75px"}}>
                                <TextAreaField
                                    paramName={paramName + '.' + index + '.comment'}
                                    placeholder="Comment"
                                    rows={3}
                                />
                            </div>
                        </>}
                        {!series && <>
                            <div>
                                <EntityTag
                                    entityRef={config.dataSeries}
                                    entityDef={entityDefs.series}
                                    style={{maxWidth: "150px"}}
                                    onRemove={handleRemoveById}
                                    onInsert={handleTagInsertRelative}
                                    onDuplicate={entityRef => handleTagDuplicate(entityRef, index)}
                                    dndDirection="horizontal"
                                    dropAcceptOverride={[
                                        entityDefs.series,
                                        entityDefs.event
                                    ]}
                                />
                            </div>

                            <div style={{height: "180px"}}/>
                        </>}
                        <SelectEntityField
                            paramName={paramName + '.' + index + '.datasetConfigField'}
                            entityDef={entityDefs.datasetConfigField}
                            size="full"
                            tagStyle={{maxWidth: "140px"}}
                            searchFilter={
                                records => records.filter(
                                    record => datasetConfig.fields[record.id].inputDataType.includes(dataTypeIdForConfigOptions)
                                )
                            }
                            searchLimit={100}
                            onChange={() => swap(0, 0)} // force redraw
                            disabled={!availableConfigs || availableConfigs.length <= 1}
                            noNewButton={true}
                        />
                        <AutocompleteField
                            style={{paddingTop: "4px"}}
                            paramName={paramName + '.' + index + '.format'}
                            placeholder={parameter?.format || "Format"}
                            options={options || []}
                            onChange={() => swap(0, 0)} // Force redraw
                        />
                        <div style={{width: '100%', paddingTop: '8px'}}>
                            <nobr>
                                <b style={{textAlign: "center"}}>{title}</b>{unit && <span>&nbsp;[{unit}]</span>}
                            </nobr>
                        </div>
                    </div>
                ),
                dataIndex: index,
                key: config.id,
                align,
                render,
                width: columnWidth
            }
        });

    const ReloadButton = (props) =>
        (
            <Space>
                <Button
                    size='small'
                    onClick={reload}
                >
                    <ReloadOutlined/> Reload
                </Button>
            </Space>
        );

    if (error !== undefined) {
        return <NetworkError code={error.data.code} message={error.data.message}/>
    }
    if (eventError !== undefined) {
        return <NetworkError code={eventError.data.code} message={eventError.data.message}/>
    }

    return (
        <div ref={drop}>
            <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDefs.series.color}
                          color2={entityDefs.series.bgColor}/>
            <Table
                size='small'
                columns={columns}
                dataSource={data}
                rowKey={record => record.sequence}
                loading={isLoading}
                pagination={{
                    pageSizeOptions: [100, 1000],
                    showSizeChanger: true,
                    current: pageCurrent,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                        setPageCurrent(page);
                        setPageSize(pageSize);
                    },
                    showTotal: () => <ReloadButton/>
                }}
                scroll={{
                    x: columns.length * columnWidth
                }}
                sticky
            />
        </div>
    )
}

DataMatrixHorizontal.propTypes = {
    idDataset: PropTypes.number,
    datasetConfig: PropTypes.object,
    columnWidth: PropTypes.number
}

export default DataMatrixHorizontal;