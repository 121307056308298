import {useDrop} from "react-dnd";
import {collectFn, DndHighlight} from "../../../lib/dropIndicatorStyle";
import {useCallback, useEffect} from "react";
import EntityTag from "../../../tk/bits/EntityTag";
import FilterTag, {listToRsql} from "../../../tk/bits/FilterTag";
import {Button, Col, Row, Space} from "antd";
import {CloseOutlined, DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import {borderStyle} from "../../../tk/forms/EntityFormItem";
import AddModButton from "./AddModButton";
import ModByType from "./ModByType";
import {FollowsArrow} from "./MixerSymbols";
import mixerMods from "./mixerMods";
import entityDefs from "../entities/entityDefs";


const globalAcceptArray = mixerMods.global.entityTypes
    .map(entityType => ([
            'ENTITY_' + entityType,
            'LIST_' + entityType,
            'FILTER_' + entityType
        ])
    )
    .reduce((prev, current) => (
        [...prev, ...current]
    ));

function combineIds(list) {
    return list
        .filter(item => item.id)
        .map(item => item.id)
        .reduce((prev, current) => {
            if (prev === '') return current.toString();
            return prev + "," + current
        }, '');
}

function makeIdSetQuery(combinedIdsPre, operator) {
    return combinedIdsPre && combinedIdsPre.length > 0 ? 'id' + operator + '(' + combinedIdsPre + ')' : undefined;
}

function combineFilters(list, separator, pre, post) {
    if (!pre) pre = '';
    if (!post) post = '';
    return list
        .filter(item => item.rsql)
        .map(item => item.rsql)
        .reduce((prev, current) => {
            if (prev === '') return pre + current + post;
            return prev + separator + pre + current + post;
        }, '');
}

const inputToRsql = input => {
    if (Array.isArray(input)) {
        return "id=in=(" + input.reduce((acc, entityRef) => acc + (acc === '' ? '' : ',') + entityRef.id, '') + ')';
    } else {
        return input;
    }
}

function makeOrRsql(list, input) {
    const combinedIds = makeIdSetQuery(combineIds(list), '=in=');
    const combinedFilters = combineFilters(list, ",");

    return [input, combinedIds, combinedFilters]
        .filter(x => x && x.length > 0)
        .join(',');
}

function makeAndRsql(list, input) {
    const combinedIds = makeIdSetQuery(combineIds(list), '=in=');
    const combinedFilters = combineFilters(list, ";");

    return [input, combinedIds, combinedFilters]
        .filter(x => x && x.length > 0)
        .join(';');
}

function makeNotRsql(list, input) {
    const combinedIds = makeIdSetQuery(combineIds(list), '=out=');

    return [input, combinedIds]
        .filter(x => x && x.length > 0)
        .join(';');
}

/**
 *
 * @param entityDefExt
 * @param onDelete
 * @param mode: 'OR', 'AND', 'NOT'
 * @returns {JSX.Element}
 * @constructor
 */
const ModBoolean = ({isRoot, mode, onDelete, model, setModel}) => {
    const entityDef = model?.entityType ? entityDefs[model.entityType] : undefined;

    const setEntityDef = useCallback(newEntityDef => {
            setModel({
                ...model,
                entityType: newEntityDef?.entityType
            })
        },
        [model, setModel]
    );

    const list = model.list || [];
    const setList = (entityType, newList) => {
        setModel({
            ...model,
            list: newList,
            entityType: entityType
        })
    }

    const clearList = () => {
        setModel({
            ...model,
            list: undefined
        })
    }

    const setChild = useCallback(newChild => {
            setModel({
                ...model,
                child: newChild
            })
        },
        [model, setModel]
    );

    useEffect(() => {
        if (entityDef && list.length === 0 && model.input === undefined && model.child === undefined && isRoot === true) {
            setEntityDef(undefined);
        }
    }, [entityDef, isRoot, list.length, model, model.child, model.input, setEntityDef]);

    const [{canDrop, isOver}, drop] = useDrop(() => {
        let accept;
        if (!entityDef) {
            accept = globalAcceptArray;
        } else {
            accept = [
                'ENTITY_' + entityDef.entityType,
                'LIST_' + entityDef.entityType,
                'FILTER_' + entityDef.entityType
            ]
        }
        return ({
            accept: accept,
            collect: collectFn,
            drop: (item, monitor) => {
                if (monitor.didDrop()) return;
                if (Array.isArray(item.data)) {
                    const rsql = listToRsql(item.data);
                    setList(item.entityDef.entityType, [...list, {rsql: rsql}]);
                } else if (item.data.rsql) {
                    setList(item.entityDef.entityType, [...list, {rsql: item.data.rsql}]);
                } else {
                    setList(item.entityDef.entityType, [...list, item.data]);
                }
            }
        });
    }, [entityDef, setList]);

    let combinedRsql;
    switch (mode) {
        case 'OR':
            combinedRsql = makeOrRsql(list, inputToRsql(model.input));
            break;
        case 'AND':
            combinedRsql = makeAndRsql(list, inputToRsql(model.input));
            break;
        case 'NOT':
            combinedRsql = makeNotRsql(list, inputToRsql(model.input));
            break;
        default:
            console.log("ERR, unknown mode", mode);
            break;
    }

    useEffect(() => {
        if (model.child && model.child.input !== combinedRsql) {
            setChild({
                ...model.child,
                input: combinedRsql
            })
        }
    }, [combinedRsql, model.child, setChild]);

    let dropAreaText = "Drop entities";
    if (entityDef) {
        dropAreaText = "Drop " + entityDef.labelPl;
    }

    let modByTypeMemo = <></>
    if (model.child) {
        modByTypeMemo = (
            <ModByType
                onDelete={() => {
                    setChild(undefined);
                }}
                collapsed={list.length === 0}
                model={model.child}
                setModel={setChild}
            />
        );
    }

    return (<>
            <div style={{marginTop: "8px", width: "100%"}}>
                <div ref={drop}>
                    <Row wrap={false} align='middle' justify='space-between'>
                        <Col>
                            <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDef?.color}
                                          color2={entityDef?.bgColor}/>
                            <Space align={"baseline"}>
                                {onDelete &&
                                    <div>
                                        {mode}
                                    </div>
                                }
                                {(entityDef && (!onDelete) && (list.length > 0)) &&
                                    <h3>
                                        {entityDef.labelPl}
                                    </h3>
                                }
                                <div
                                    style={{
                                        ...borderStyle,
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        paddingBottom: '8px',
                                        marginTop: '-4px',
                                        marginLeft: onDelete ? '8px' : '0',
                                        marginRight: '16px',
                                        marginBottom: '0'
                                    }}
                                >
                                    {list.length === 0 &&
                                        <div>
                                            <DownloadOutlined
                                                style={{fontSize: "32px", paddingRight: "8px"}}
                                            /> {dropAreaText}
                                        </div>
                                    }
                                    {list.map(entry => {
                                        if (entry.rsql) {
                                            return (
                                                <FilterTag
                                                    key={entry.rsql}
                                                    entityDef={entityDef}
                                                    rsql={entry.rsql}
                                                />
                                            )
                                        } else {
                                            return (
                                                <EntityTag
                                                    key={entry.id}
                                                    entityDef={entityDef}
                                                    entityRef={entry}
                                                />
                                            )
                                        }
                                    })}
                                    {list.length > 0 &&
                                        <Button
                                            onClick={() => clearList()}
                                            size="small"
                                            type="text"
                                        >
                                            <CloseOutlined/>
                                        </Button>
                                    }
                                </div>
                                {list.length === 0 &&
                                    modByTypeMemo
                                }
                            </Space>
                        </Col>
                        <Col>
                            {onDelete &&
                                <Button
                                    onClick={() => onDelete()}
                                    size="small"
                                    type="text"
                                >
                                    <DeleteOutlined/>
                                </Button>
                            }
                        </Col>
                    </Row>
                </div>
                {(list.length > 1 || onDelete) &&
                    <Row wrap={false} justify='start'>
                        <Col>
                            <div>
                                <FollowsArrow/>&nbsp;<FilterTag
                                rsql={combinedRsql}
                                entityDef={entityDef}/>
                            </div>
                        </Col>
                    </Row>
                }
                {list.length > 0 &&
                    <Row wrap={false} justify='start'>
                        {!model.child === true &&
                            <AddModButton
                                entityDef={entityDef}
                                setOutMod={spec => setChild({
                                    spec: spec,
                                    input: combinedRsql,
                                    entityType: entityDef.entityType
                                })}
                            />
                        }
                    </Row>
                }
            </div>
            {list.length > 0 && modByTypeMemo}
        </>
    );
}

export default ModBoolean;
