import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const TermOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Abbreviation" value={entity.abbreviation}/>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="Description" value={entity.description}/>
            <OverviewLine label="Semantic URI" value={entity.semanticUri}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Term category" value={entity.termCategory} entityDef={entityDefs.termCategory}/>
            <OverviewLine label="Term status" value={entity.termStatus} entityDef={entityDefs.termStatus}/>
            <OverviewLine label="Terminology" value={entity.terminology} entityDef={entityDefs.terminology}/>
        </div>
    )
}

export default TermOverview;