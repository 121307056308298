import React, {useContext, useState} from "react";
import HelpLink from "../../../tk/bits/HelpLink";
import ModalPop from "../../../tk/bits/ModalPop";
import FlexLayoutContext from "../../../contexts/FlexLayoutContext";
import DatafileDirectImport from "./DatafileDirectImport";


const DatafileDirectImportModal = (props) => {
    const {open, setOpen} = props;
    const [importState, setImportState] = useState();
    const {addDatafileDirectImportTab} = useContext(FlexLayoutContext);

    return (
        <ModalPop
            title={<span>Datafile Import <HelpLink category="Import_Datasets"/></span>}
            open={open}
            setOpen={setOpen}
            onCancel={() => setOpen(false)}
            footer={[]}
            width="80%"
            destroyOnClose={true}
            keyboard={false}
            onPop={() => {
                addDatafileDirectImportTab(importState);
                setOpen(false);
            }}
        >
            <DatafileDirectImport onChange={setImportState}/>
        </ModalPop>
    )
}

export default DatafileDirectImportModal;
