import EntityHeader from "../entities/EntityHeader";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import React, {useContext} from "react";
import SelectMultiEntityField from "../../../tk/input/SelectMultiEntityField";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import entityDefs from "../entities/entityDefs";
import ReadonlyField from "./ReadonlyField";
import {FormExtContext} from "../../../tk/forms/FormExt";


const PanUserDetail = (props) => {
    const {entity} = props;
    const {formState} = useContext(FormExtContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDefs.panUser}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    <EntityFormItem label='Username' paramName='username'>
                        <ReadonlyField/>
                    </EntityFormItem>
                    <EntityFormItem label='Full name' paramName='fullName'>
                        <ReadonlyField/>
                    </EntityFormItem>
                    <EntityFormItem label='Email' paramName='email'>
                        <ReadonlyField/>
                    </EntityFormItem>
                    <EntityFormItem label='ORCID' paramName='orcid'>
                        <ReadonlyField type='orcid'/>
                    </EntityFormItem>
                    <EntityFormItem label='Phone' paramName='phone'>
                        <ReadonlyField/>
                    </EntityFormItem>
                    <EntityFormItem label='Institution' paramName='institution'>
                        <ReadonlyField/>
                    </EntityFormItem>
                    <EntityFormItem label='Description' paramName='description'>
                        <ReadonlyField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    <EntityFormItem label='Staffs' paramName='staffs' entityDef={entityDefs.staffs}>
                        <SelectEntityField/>
                    </EntityFormItem>
                    <EntityFormItem label='Groups' paramName='groups' entityDef={entityDefs.panGroup}>
                        <SelectMultiEntityField/>
                    </EntityFormItem>
                    <EntityFormItem label='Created' paramName='created'>
                        <ReadonlyField type="datetime"/>
                    </EntityFormItem>
                    <EntityFormItem label='Last login' paramName='lastLogin'>
                        <ReadonlyField type="datetime"/>
                    </EntityFormItem>
                    <EntityFormItem label='#Logins' paramName='logins'>
                        <ReadonlyField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default PanUserDetail;