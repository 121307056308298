import {apiBaseUrl, bgServicesBaseUrl} from "./config";
import {entityFields} from "../pages/editorial/entities/entityFields";
import {BatchOps} from "../pages/editorial/bigentitytable/BatchEditContent";
import entityDefs from "../pages/editorial/entities/entityDefs";

export const listRequest = (ws2Axios, entityDef, query, offset, limit, sort, postOptions) => {
    return ws2Axios
        .post(apiBaseUrl + entityDef.entityType + "/list",
            {
                offset,
                limit,
                sort: encodeURIComponent(sort === "undefined" ? "" : sort),
                q: encodeURIComponent(query)
            },
            postOptions
        )
}

export const listIdsRequest = (ws2Axios, entityDef, query) => {
    return ws2Axios
        .get(apiBaseUrl + "query/list/id/" + entityDef.entityType, {
                params: {
                    q: query
                }
            }
        )
}

export const batchRequest = (ws2Axios, data, entityDef, entityRefs, test) => {
    //console.log("SUBMIT", data)

    const patches = [];
    const specials = [];
    Object.keys(data)
        .filter(k => data[k] !== undefined)
        .forEach(key => {
            const field = entityFields[entityDef.entityType].find(field => field.paramName === key);
            const patch = {
                field: field.paramName,  // patch has at least one key
            };
            let dataValues;
            const fieldType = field.batchType ? field.batchType : field.type
            switch (data[key].ops.id) {
                case BatchOps.PATCH.SET.id:
                    patch.ops = "SET";
                    patch[fieldType.dataType + "Value"] = data[key].value;
                    break;
                case BatchOps.PATCH.FIND_REPLACE.id:
                    patch.ops = "FIND_REPLACE";
                    patch.find = data[key].find;
                    patch.replace = data[key].replace;
                    break;
                case BatchOps.PATCH.APPEND.id:
                    patch.ops = "APPEND";
                    patch.append = data[key].append;
                    break;
                case BatchOps.PATCH.REGEXP_REPLACE.id:
                    patch.ops = "REGEXP_REPLACE";
                    patch.find = data[key].find;
                    patch.replace = data[key].replace;
                    break;
                case BatchOps.PATCH.REGEXP_REPLACE_CASEINSENSITIVE.id:
                    patch.ops = "REGEXP_REPLACE_CASEINSENSITIVE";
                    patch.find = data[key].find;
                    patch.replace = data[key].replace;
                    break;
                case BatchOps.SPECIAL.CLEAR.id:
                    const special = {
                        field: field.paramName,
                        operation: "CLEAR",
                    }
                    specials.push(special)
                    break
                case BatchOps.SPECIAL.ADD.id:
                    dataValues = data[key][field.addKey] || data[key].value;
                    if (fieldType.fieldNameMappings) {
                        dataValues = dataValues.map(
                            // dataValue => renameKeys(dataValue, fieldType.fieldNameMappings)
                            dataValue => fieldType.fieldNameMappings(dataValue)
                        )
                    }
                    if (Array.isArray(dataValues) && dataValues.length > 0) {
                        dataValues.forEach(value => {
                            const special = {
                                field: field.paramName,
                                operation: "ADD",
                            }
                            special[fieldType.valueKey] = value;
                            specials.push(special)
                        })
                    }
                    break;
                case BatchOps.SPECIAL.REMOVE.id:
                    dataValues = data[key][field.removeKey] || data[key].value;
                    if (fieldType?.remove?.fieldNameMappings) {
                        dataValues = dataValues.map(
                            // dataValue => renameKeys(dataValue, fieldType.fieldNameMappings)
                            dataValue => fieldType.remove?.fieldNameMappings(dataValue)
                        )
                    }
                    if (Array.isArray(dataValues) && dataValues.length > 0) {
                        dataValues.forEach(value => {
                            const special = {
                                field: field.paramName,
                                operation: "REMOVE",
                            }
                            special[fieldType.remove?.valueKey || fieldType.valueKey] = value;
                            specials.push(special)
                        })
                    }
                    break;
                case BatchOps.SPECIAL.PARAMETER_ANNOTATOR.id: {
                    const special = {
                        field: field.paramName,
                        operation: "PARAMETER_ANNOTATOR"
                    };
                    specials.push(special);
                }
                    break;
                case BatchOps.SPECIAL.DATASET_CONFIG_FORMAT.id: {
                    const configFormat = data?.configFormat?.value;
                    const special = {
                        field: configFormat,
                        operation: "DATASET_CONFIG_FORMAT",
                    };
                    specials.push(special);
                }
                    break;
                case BatchOps.SPECIAL.DELETE.id: {
                    const field = data?.eventSelf !== undefined ? 'event' :
                        (data?.datasetSelf !== undefined ? 'dataset' : '');

                    const special = {
                        field: field,
                        operation: "DELETE"
                    };
                    specials.push(special);
                }
                    break;
                default:
                    break;
            }
            //TODO: replace ugly hack below with some better way to split patch and special
            if (Object.keys(patch).length > 1) {
                patches.push(patch);
            }
            return patch;
        });

    const batchPatchRequest = []
    if (Array.isArray(patches) && patches.length > 0) {
        batchPatchRequest.push(
            {
                action: "PATCH",
                entityType: entityDef.entityType,
                entityRefs,
                patches,
            },
        )
    }
    if (Array.isArray(specials) && specials.length > 0) {
        batchPatchRequest.push(
            {
                action: "SPECIAL",
                entityType: entityDef.entityType,
                entityRefs,
                specials
            }
        )
    }

    return ws2Axios
        .post(
            apiBaseUrl + 'batch' + (test === true ? '?test=true' : ''),
            batchPatchRequest
        )
}

export const referenceLookup = (axios, method, query, excludeReferences) => {
    const params = {
        excludeReferences: excludeReferences
    };
    params[method] = query;
    return axios
        .get(bgServicesBaseUrl + "publicationMetadata/v2", {params: params})
        .then(response => ({
            ...response.data,
            authors: response.data.authors.map(author => ({
                nameFirst: author.name_first,
                nameLast: author.name_last,
                orcid: author.orcid,
                email: author.email,
                matches: author.matches
                    .map(match => ({
                        matchInfo: match.match_info,
                        score: match.score,
                        entityRef: {
                            id: match.id,
                            name: match.name
                        }
                    }))
            })),
            matches: response.data.matches
                .filter(match => match.id !== excludeReferences)
        }));
}

const duplicateChecks = {
    "staffs": (query, axios) => {
        return axios
            .post(
                bgServicesBaseUrl + "staffListMatcher",
                [query]
            )
            .then(response => {
                if (response.error) {
                    console.log("******* ERRORO IN STAFFS LOOKUP *****", response.error)
                } else {
                    return response.data;
                }
            })
    },
    "reference": (query, axios) => {
        return axios
            .post(
                bgServicesBaseUrl + "publicationMetadata/match",
                query
            )
            .then(response => {
                if (response.error) {
                    console.log("******* ERRORO IN REFERENCE LOOKUP *****", response.error)
                } else {
                    return response.data;
                }
            })
    },
    "parameter": (query, axios) => {
        return listRequest(axios, entityDefs.parameter, query, 0, 10, "-id")
            .then(response => {
                return response.data;
            })
    }
}
export const entityDuplicates = (data, axios, findDuplicates) => {
    if (duplicateChecks[findDuplicates?.duplicateCheckMethod]) {
        const query = findDuplicates.fromInputToAPIQuery(data);
        return duplicateChecks[findDuplicates.duplicateCheckMethod](query, axios).then(
            response => {
                return findDuplicates.fromAPIResponseToRenderObject(response)
            }
        )
    } else {
        return Promise.reject(new Error("Couldnt find corresponding duplicateCheck function"))
    }
}
export const staffsLookup = (axios, query, excludeDatasetId) => {
    return axios
        .post(
            bgServicesBaseUrl + "staffListMatcher?excludeDatasets=" + excludeDatasetId.toString(),
            query
        )
        .then(response => {
            if (response.error) {
                console.log("******* ERRORO IN STAFFS LOOKUP *****", response.error)
            } else {
                return response.data;
            }
        })
}

export const uriCheck = (axios, uri) => {
    return axios
        .get(bgServicesBaseUrl + "checkURI?uri=" + encodeURIComponent(uri))
        .then(response => response.data)
}

export const enforceDOIRegistration = (axios, datasetId) => {
    return axios
        .post(process.env.REACT_APP_ENFORCE_DOI_REGISTRATION_URL + datasetId)
}

export const getDatasetLandingUrl = idDataset => process.env.REACT_APP_DATASET_CITATION + idDataset;

export const requestCitation = (axios, idDataset) => {
    return axios
        .get(
            getDatasetLandingUrl(idDataset) + "?preliminary=true",
            {headers: {Accept: "text/x-bibliography"}}
        )
}

export const requestCitationList = (axios, datasetIds) => {
    const ids = datasetIds.reduce(
        (acc, curr) => !acc ? curr : acc + ',' + curr,
        undefined
    );
    return axios.post(
        process.env.REACT_APP_DATASET_CITATION_LIST,
        {
            datasets: ids,
            preliminary: true
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                //'Accept': 'application/json'
            }
        }
    )
}

export const requestTempAccess = (axios, idDataset, lifeTimeDays, action) => {
    if (action === 'createToken') {
        return axios.post(
            `${process.env.REACT_APP_DATASET_ACCESS_TOKENS_URL}${idDataset}?lifetimeDays=${lifeTimeDays}`)
    } else if (action === 'removeAllTokens') {
        return axios.delete(
            `${process.env.REACT_APP_DATASET_ACCESS_TOKENS_URL}${idDataset}/all`)
    }

}

export const rorLookup = (axios, info) => {
    return axios
        .get(process.env.REACT_APP_ROR_LOOKUP_URL + "?affiliation=" + encodeURIComponent(info))
}