import PropTypes from "prop-types";


const CardColorBackground = (props) => {
    const {color, children} = props;
    return (
        <div
            style={{
                marginTop: "0px",
                backgroundColor: "#eee",
                height: "100%"
            }}
        >
            <div
                style={{
                    background: color,
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }}
            >
                {children}
            </div>
        </div>
    )
}

CardColorBackground.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ])
}

export default CardColorBackground;