import React, {useEffect, useState} from "react";
import {getEntity} from "../../../lib/entityRequests";
import entityDefs from "../entities/entityDefs";
import EntityTag from "../../../tk/bits/EntityTag";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {Col, Row, Spin} from "antd";


const TermHierarchy = ({entity}) => {
    const {ws2Axios} = useWS2Axios();
    const [broaderTerms, setBroaderTerms] = useState([]);
    const [hierarchyLoading, setHierarchyLoading] = useState(false);

    const indentStep = 15;

    useEffect(() => {
            setHierarchyLoading(true);

            const findStructure = async (entity) => {
                const broaderTerms = entity.termsRelated
                    .filter(related => related.relationType.id === 1) // broader
                    .map(related => related.termRelated);
                const structure = [];
                for (let broader in broaderTerms) {
                    const broaderEntity = await getEntity(ws2Axios, entityDefs.term, broaderTerms[broader].id)
                    structure.push({
                        entity: broaderEntity.me,
                        broader: await findStructure(broaderEntity),
                        terminology: broaderEntity.terminology
                    })
                }
                return structure;
            }

            findStructure(entity)
                .then(structure => {
                    console.log("Structure done: ", structure);
                    setBroaderTerms([{
                        entity: entity.me,
                        broader: structure,
                        terminology: entity.terminology
                    }]);
                    setHierarchyLoading(false);
                })
        },
        [entity, ws2Axios]
    );

    const renderBroader = ((entries, indent) => {
        if (entries.length === 0) {
            return null;
        }
        return entries.map(entry => (
            <div key={entry.entity.id}>
                <EntityTag
                    entityDef={entityDefs.term}
                    entityRef={entry.entity}
                />
                {entry.broader.length > 0 &&
                    <Row>
                        <Col style={{
                            borderColor: entry.broader.length > 1 ? "lightgray" : "transparent",
                            borderRightStyle: entry.broader.length > 1 ? "dotted" : "none",
                            minWidth: "14px",
                            marginRight: "6px"
                        }}></Col>
                        <Col>
                            {renderBroader(entry.broader, indent + indentStep)}
                        </Col>
                    </Row>
                }
                {entry.broader.length === 0 &&
                    <Row>
                        <Col style={{
                            minWidth: "20px"
                        }}></Col>
                        <Col>
                            <EntityTag
                                entityDef={entityDefs.terminology}
                                entityRef={entry.terminology}
                            />
                        </Col>
                    </Row>
                }
            </div>
        ))
    })

    return (
        <Spin spinning={hierarchyLoading}>
            {renderBroader(broaderTerms, 0)}
        </Spin>
    )
}

export default TermHierarchy;
