import EntityTag from "../../../tk/bits/EntityTag";
import ResolveUri from "../../../tk/bits/ResolveUri";
import React from "react";
import entityDefs from "./entityDefs";
import dayjs from "dayjs";
import OrcidLink from "../../../tk/bits/OrcidLink";
import getColumnSearchPropsNumeric from "../bigentitytable/search/getColumnSearchPropsNumeric";
import getColumnSearchPropsText from "../bigentitytable/search/getColumnSearchPropsText";
import getColumnSearchPropsEntity from "../bigentitytable/search/getColumnSearchPropsEntity";
import getColumnSearchPropsDatetime from "../bigentitytable/search/getColumnSearchPropsDatetime";
import JiraLink from "../../../tk/bits/JiraLink";
import EntityTagList from "../../../tk/bits/EntityTagList";


export const meColumn = (dataIndex, entityDef) => ({
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
    fixed: "left",
    width: 110,
    render: (id, row) => <EntityTag entityRef={row[dataIndex]} entityDef={entityDef} display="id"/>,
    ...getColumnSearchPropsNumeric("id")
})

export const labelColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 200,
    ...getColumnSearchPropsText(dataIndex)
})

export const titleColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 300,
    ...getColumnSearchPropsText(dataIndex)
})

export const floatColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 120,
    ...getColumnSearchPropsNumeric(dataIndex)
})

export const integerColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 120,
    ...getColumnSearchPropsNumeric(dataIndex)
})

export const ellipsisColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: true,
    width: 200,
    ...getColumnSearchPropsText(dataIndex)
})

export const dateColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 150,
    render: date => date === null ? "" : dayjs(date).format('YYYY-MM-DD'),
    ...getColumnSearchPropsDatetime(dataIndex, false)
})

export const dateTimeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    width: 150,
    render: date => date === null ? "" : dayjs(date).format('YYYY-MM-DD HH:mm'),
    ...getColumnSearchPropsDatetime(dataIndex, true)
})

export const projectTypeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 150,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.projectType}/>,
    filters: [
        {
            text: <span>International</span>,
            value: 'International',
        },
        {
            text: <span>National</span>,
            value: 'National',
        },
        {
            text: <span>EU</span>,
            value: 'EU',
        },
        {
            text: <span>National institution</span>,
            value: 'National institution',
        },
        {
            text: <span>National DFG</span>,
            value: 'National DFG',
        },
        {
            text: <span>National BMBF</span>,
            value: 'National BMBF',
        },
        {
            text: <span>Institute</span>,
            value: 'Institute',
        },
    ],
})

export const institutionTypeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.institutionType}/>,
    filters: [
        {
            text: <span>Funder</span>,
            value: 'Funder',
        },
        {
            text: <span>Governmental facility</span>,
            value: 'Governmental facility',
        },
        {
            text: <span>Publisher</span>,
            value: 'Publisher',
        },
        {
            text: <span>Research company</span>,
            value: 'Research company',
        },
        {
            text: <span>Research facility</span>,
            value: 'Research facility',
        },
        {
            text: <span>University</span>,
            value: 'University',
        },
    ],
})

export const datasetStatusColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.datasetStatus}/>,
    filters: [
        {
            text: <span>draft</span>,
            value: 'draft',
        },
        {
            text: <span>in review</span>,
            value: 'in review',
        },
        {
            text: <span>published</span>,
            value: 'published',
        },
        {
            text: <span>questionable</span>,
            value: 'questionable',
        },
        {
            text: <span>validated</span>,
            value: 'validated',
        },
        {
            text: <span>deleted</span>,
            value: 'deleted',
        },
    ],
})

export const loginOptionColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.loginOption}/>,
    filters: [
        {
            text: <span>access right needed</span>,
            value: 'access rights needed',
        },
        {
            text: <span>signup required</span>,
            value: 'signup required',
        },
        {
            text: <span>unrestricted</span>,
            value: 'unrestricted',
        },
    ],
})

export const curationLevelColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.curationLevel}/>,
    filters: [
        {
            text: <span>Basic curation</span>,
            value: 'Basic curation',
        },
        {
            text: <span>Content distributed as deposited</span>,
            value: 'Content distributed as deposited',
        },
        {
            text: <span>Data-level curation</span>,
            value: 'Data-level curation',
        },
        {
            text: <span>Enhanced curation</span>,
            value: 'Enhanced curation',
        },
    ],
})

export const processingLevelColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.processingLevel}/>,
    filters: [
        {
            text: <span>ProcLevel0</span>,
            value: 'ProcLevel0',
        },
        {
            text: <span>ProcLevel1</span>,
            value: 'ProcLevel1',
        },
        {
            text: <span>ProcLevel2</span>,
            value: 'ProcLevel2',
        },
        {
            text: <span>ProcLevel3</span>,
            value: 'ProcLevel3',
        },
        {
            text: <span>ProcLevel4</span>,
            value: 'ProcLevel4',
        },
    ],
})

export const topotypeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.topotype}/>,
    filters: [
        {
            text: <span>gridded surface</span>,
            value: 'gridded surface',
        },
        {
            text: <span>gridded time slice</span>,
            value: 'gridded time slice',
        },
        {
            text: <span>horizontal profile</span>,
            value: 'horizontal profile',
        },
        {
            text: <span>irregular surface</span>,
            value: 'irregular surface',
        },
        {
            text: <span>not specified</span>,
            value: 'not specified',
        },
        {
            text: <span>point</span>,
            value: 'point',
        },
        {
            text: <span>profile series</span>,
            value: 'profile series',
        },
        {
            text: <span>time series</span>,
            value: 'time series',
        },
        {
            text: <span>time slice</span>,
            value: 'time slice',
        },
        {
            text: <span>uncoded table</span>,
            value: 'uncoded table',
        },
        {
            text: <span>vertical profile</span>,
            value: 'vertical profile',
        },
    ],
})

export const collectionTypeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.collectionType}/>,
    filters: [
        {
            text: <span>bibliography</span>,
            value: 'bibliography',
        },
        {
            text: <span>bundled publication</span>,
            value: 'bundled publication',
        },
        {
            text: <span>editorial publication</span>,
            value: 'editorial publication',
        },
        {
            text: <span>publication series</span>,
            value: 'publication series',
        },
    ],
})

export const dataTypeColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 150,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.dataType}/>,
    filters: [
        {
            text: <span>binary</span>,
            value: 'binary',
        },
        {
            text: <span>datetime</span>,
            value: 'datetime',
        },
        {
            text: <span>event</span>,
            value: 'event',
        },
        {
            text: <span>filename</span>,
            value: 'filename',
        },
        {
            text: <span>numeric</span>,
            value: 'numeric',
        },
        {
            text: <span>string</span>,
            value: 'string',
        },
        {
            text: <span>term</span>,
            value: 'term',
        },
        {
            text: <span>uri</span>,
            value: 'uri',
        },
    ],
})

export const referenceStatusColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.referenceStatus}/>,
    filters: [
        {
            text: <span>accepted</span>,
            value: 'accepted',
        },
        {
            text: <span>in prep.</span>,
            value: 'in prep.',
        },
        {
            text: <span>in press</span>,
            value: 'in press',
        },
        {
            text: <span>in review</span>,
            value: 'in review',
        },
        {
            text: <span>preprint</span>,
            value: 'preprint',
        },
        {
            text: <span>published</span>,
            value: 'published',
        },
        {
            text: <span>submitted</span>,
            value: 'submitted',
        },
    ],
})

export const referenceTypeColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.referenceType}/>,
    filters: [
        {
            text: <span>audiovisual</span>,
            value: 'audiovisual',
        },
        {
            text: <span>book</span>,
            value: 'book',
        },
        {
            text: <span>book chapter</span>,
            value: 'book chapter',
        },
        {
            text: <span>dataset</span>,
            value: 'dataset',
        },
        {
            text: <span>dissertation</span>,
            value: 'dissertation',
        },
        {
            text: <span>journal article</span>,
            value: 'journal article',
        },
        {
            text: <span>other</span>,
            value: 'other',
        },
        {
            text: <span>physical storage medium</span>,
            value: 'physical storage medium',
        },
        {
            text: <span>report</span>,
            value: 'report',
        },
        {
            text: <span>software</span>,
            value: 'software',
        },
        {
            text: <span>thesis</span>,
            value: 'thesis',
        },
        {
            text: <span>webpage</span>,
            value: 'webpage',
        },
        {
            text: <span>web service</span>,
            value: 'web service',
        },
    ],
});

export const termCategoryColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.termCategory}/>,
    filters: [
        {
            text: <span>attributes</span>,
            value: 'attributes',
        },
        {
            text: <span>classes</span>,
            value: 'classes',
        },
        {
            text: <span>grammar</span>,
            value: 'grammar',
        },
        {
            text: <span>instances</span>,
            value: 'instances',
        },
        {
            text: <span>keywords</span>,
            value: 'keywords',
        },
        {
            text: <span>quantities</span>,
            value: 'quantities',
        },
        {
            text: <span>systematics</span>,
            value: 'systematics',
        },
    ],
});

export const termStatusColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 160,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDefs.termStatus}/>,
    filters: [
        {
            text: <span>accepted</span>,
            value: 'accepted',
        },
        {
            text: <span>not accepted</span>,
            value: 'not accepted',
        },
        {
            text: <span>PANGAEA accepted</span>,
            value: 'PABGAEA accepted',
        },
    ],
});

export const entityRefColumn = (dataIndex, title, entityDef, width, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: true,
    width: width === undefined ? 260 : width,
    render: entityRef => <EntityTag entityRef={entityRef} entityDef={entityDef}/>,
    ...getColumnSearchPropsEntity(dataIndex, entityDef)
});

export const entityRefListColumn = (dataIndex, title, entityDef, getEntityRefFn) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: false,
    width: 260,
    render: entityRefList => {
        const mappedEntityRefList = getEntityRefFn !== undefined
            ? entityRefList.map(getEntityRefFn)
            : entityRefList;
        return (
            <EntityTagList list={mappedEntityRefList} entityDef={entityDef} max={4}/>
        );
    },
    ...getColumnSearchPropsEntity(dataIndex, entityDef)
});

export const uriColumn = (dataIndex, title, batchKey, batchRender) => ({
    batchKey,
    batchRender,
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: true,
    width: 200,
    render: uri => <ResolveUri uri={uri}/>,
    ...getColumnSearchPropsText(dataIndex)
});

export const orcidColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: true,
    width: 180,
    render: orcid => <OrcidLink orcid={orcid}/>,
    ...getColumnSearchPropsText(dataIndex)
});

export const ticketColumn = (dataIndex, title) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: true,
    ellipsis: true,
    width: 160,
    render: ticket => <JiraLink ticket={ticket}/>,
    ...getColumnSearchPropsText(dataIndex)
});

export const createUpdateColumns = () => ([
    dateTimeColumn("datetimeCreated", "Created at"),
    entityRefColumn("userCreated", "Created by", entityDefs.panUser, 200, null),
    dateTimeColumn("datetimeUpdated", "Updated at"),
    entityRefColumn("userUpdated", "Last update by", entityDefs.panUser, 200, null)
]);
