import DisabledTooltip from "./DisabledTooltip";
import {Button} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const CloneButton = (props) => {
    const {onClone, cloneDisabledMessage} = props;
    if (!onClone) return <span/>

    return (
        <DisabledTooltip
            conditions={[
                {isTrue: cloneDisabledMessage !== undefined, message: cloneDisabledMessage}
            ]}
        >
            <Button
                type='link'
                style={{paddingLeft: '4px', paddingRight: '0px'}}
                onClick={onClone}
            >
                <PlusSquareOutlined/>
            </Button>
        </DisabledTooltip>
    )
}

CloneButton.propTypes = {
    onClone: PropTypes.func,
    cloneDisabledMessage: PropTypes.string
}

export default CloneButton