import {Button, List, Space, Table} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import EntityTag from "../../../tk/bits/EntityTag";
import entityDefs from "../entities/entityDefs";
import "./UploadResponseView.css"
import PropTypes from "prop-types";


const ParameterUploadReportView = (props) => {
    const {data, dimensions, entityDef, preview} = props;

    const pageSizeOptions = [10, 20, 50, 100, 250, 500];

    const pagination = {
        showSizeChanger: true,
        simple: false,
        position: ["bottomRight"],
        pageSizeOptions: pageSizeOptions,
        responsive: true,
    };


    if (data.filename === undefined) {
        return <div></div>;
    }

    const matchTypeToString = (isFuzzy) => {
        if (isFuzzy === true) {
            return 'Unconfirmed'; // 'Fuzzy';
        }
        else if (isFuzzy === false) {
            return 'Confirmed'; // 'Full';
        }
        else {
            return '-';
        }
    };


    const renderEntityRef = (entityRef, entityDef) => {
        if (entityRef === undefined) return <span>undefined</span>;
        if (entityRef.id === undefined) return entityRef;
        if (entityRef.id === null) return <span></span>;
        return <EntityTag entityRef={entityRef} entityDef={entityDef}/>;
    };

    const renderUnsavedRef = (entityRef) => {
        const name = entityRef?.name;
        if (name === undefined) {
            return <span>_</span>;
        }
        else {
            return <span>{name}</span>;
        }
    };

    const renderEntityRefList = (list, entityDef) => {
        if (list === '') return <span>none</span>;
        if (!Array.isArray(list)) return renderEntityRef(list, entityDef); // if it is in the raw entity, there is no list, yet
        return (<>
            {list.map(entry => renderEntityRef(entry, entityDef))}
        </>);
    }

    const renderStatus = status => {
        if (status === undefined) return <></>;
        if (status.renderMeTag) {
            return <div style={{paddingLeft: "40px"}}><EntityTag entityDef={entityDef} entityRef={status.me}/></div>
        }
        if (status.ok) {
            if (status.updateEntityId === undefined || status.updateEntityId === null) {
                return <span><CheckCircleOutlined/>&nbsp;&nbsp;&nbsp; new</span>
            } else {
                return <span><CheckCircleOutlined/>&nbsp;&nbsp;&nbsp; update <EntityTag
                    entityRef={{id: status.updateEntityId}} entityDef={entityDef}/></span>
            }
        }
        return (
            <ul style={{marginBottom: "1px"}}>
                {status.errors.map((error, index) => {
                        const entityDefErr = entityDefs[error.relatedEntitiesType];
                        return <li key={index}>
                            {error.message}
                            <Space wrap size={[0, 0]}>
                                {error.relatedEntities && error.relatedEntities.length > 0 && !status.isParent &&
                                    error.relatedEntities.map(related =>
                                        <EntityTag
                                            entityRef={{id: related}}
                                            entityDef={entityDefErr}
                                        />
                                    )}
                            </Space>
                        </li>;
                    }
                )}
            </ul>
        )
    }

    const prettyPrintLabel = label => {
        return label
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function (str) {
                return str.toUpperCase();
            });
    }

    if (data.errors && data.errors.length > 0) {
        return (
            <>
                <List size="small" bordered>
                    {data.errors && data.errors.map((error, index) =>
                        <List.Item key={index}>{error}</List.Item>
                    )}
                </List>
            </>
        );
    }

    if (data.columnDefinitions === null) {
        data.columnDefinitions = [];
    }

    const widthForLabel = (label) => {
        if (label === 'name') {
            return 250;
        }
        else if (label === 'abbreviation') {
            return 100;
        }
        else if (label === 'unit') {
            return 50;
        }
        else if (label === 'dataType') {
            return 80;
        }
        else {
            return 150;
        }
    };


    // Column order: #, Match Type, Terminology, Term, Name, Unit.
    const makeColumnDef = col => {
        let colDef = {
            title: prettyPrintLabel(col.label),
            dataIndex: col.label,
            key: col.label,
            width: widthForLabel(col.label),
            ellipsis: false,
            sorter: (a, b) => {
                if (a[col.label] > b[col.label]) {
                    return 1;
                } else if (a[col.label] < b[col.label]) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };
        if (col.dataTypeDef.dataType === 'ENTITY' || col.dataTypeDef.dataType === 'ENUM') {
            const entityDef = entityDefs[col.dataTypeDef.entityType];
            if (col.dataTypeDef.isList) {
                colDef.render = list => renderEntityRefList(list, entityDef);
            } else {
                colDef.render = entityRef => renderEntityRef(entityRef, entityDef);
            }
        }

        if (col.label === 'name') {
            if (preview) {
                colDef.render = entityRef => renderUnsavedRef(entityRef);
            }
            else {
                colDef.render = entityRef => renderEntityRef(entityRef, entityDefs.parameter);
            }
        }

        return colDef;
    };

    const parameterReportBasicColumnLabels = ['name', 'abbreviation', 'unit', 'dataType'];

    const parameterReportColumns = data.columnDefinitions
                                       .filter(col => parameterReportBasicColumnLabels.includes(col.label))
                                       .map(makeColumnDef);

    parameterReportColumns.unshift({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 85,
        fixed: "left",
        filters: [
            {
                text: <span>OK</span>,
                value: true,
            },
            {
                text: <span>Errors</span>,
                value: false,
            },
        ],
        onFilter: (value, record) => record.status.ok === value,
        render: renderStatus
    });


    parameterReportColumns.unshift({
        title: '#',
        fixed: "left",
        width: 30,
        render: (text, record, index) => {
            if (record.paramIndex < 1) {
                return <span></span>;
            }

            return <span>{record.paramIndex}</span>;
        }
    });


    const termReportBasicColumnLabels = ['name', 'unit'];

    const termReportColumns = data.columnDefinitions
                                  .filter(col => termReportBasicColumnLabels.includes(col.label))
                                  .map(makeColumnDef);


    termReportColumns.unshift({
        title: 'Term',
        fixed: "left",
        width: 70,
        sorter: (x, y) => {
            const xName = x.term.name;
            const yName = y.term.name;
            if (xName > yName) {
                return 1;
            }
            else if (xName < yName) {
                return -1;
            }
            else {
                return 0;
            }
        },
        render: (text, record, index) => {
            return renderEntityRef(record.term, entityDefs.term);
        }
    });


    termReportColumns.unshift({
        title: 'Terminology',
        fixed: "left",
        width: 50,
        sorter: (x, y) => {
            const xName = x.terminology.name;
            const yName = y.terminology.name;
            if (xName > yName) {
                return 1;
            }
            else if (xName < yName) {
                return -1;
            }
            else {
                return 0;
            }
        },
        render: (text, record, index) => {
            return renderEntityRef(record.terminology, entityDefs.terminology);
        }
    });


    termReportColumns.unshift({
        title: 'Match type',
        fixed: "left",
        width: 70,
        sorter: (x, y) => {
            if (x.matchType > y.matchType) {
                return 1;
            }
            else if (x.matchType < y.matchType) {
                return -1;
            }
            else {
                return 0;
            }
        },

        render: (text, record, index) => {
            const color = colorForMatchType(record.matchType);
            const colorStyle = { color: color };

            return <span style={colorStyle}>{record.matchType}</span>;
        }
    })


    termReportColumns.unshift({
        title: '#',
        fixed: "left",
        width: 30,
        render: (text, record, index) => {
            if (record.paramIndex < 1) {
                return <span></span>;
            }

            return <span>{record.paramIndex}</span>;
        }
    });


    const colorForMatchType = (matchType) => {
        const green = "#389e0d";
        const red = "#f5222d";
        const grey = "#262626";

        if (matchType === 'Confirmed') {
            return green;
        }
        else if (matchType === 'Unconfirmed') {
            return red;
        }
        else {
            return grey;
        }
    };


    function makeRenderFromDbEntity(key, dbEntity) {
        let newVar = {
            key: key,
            ...dbEntity
        };
        if (dbEntity.attributes !== undefined) {
            dbEntity.attributes.forEach(attribute => {
                newVar[attribute.entityRef.name] = attribute.value;
            });
        }
        return newVar;
    }


    const makeTermFields = (dbTerm) => {
        return {
            term: dbTerm.term,
            termName: dbTerm.term.name,
            idTerm: dbTerm.term.id,
            fuzzy: dbTerm.confirmed === false,
            matchType: matchTypeToString(dbTerm.confirmed === false)
        };
    };


    const makeBasicRowFromDbEntity = (index, dbEntity) => {
        return {
            paramIndex: index + 1,
            name: {
                id: dbEntity.me.id,
                name: dbEntity.name
            },
            abbreviation: dbEntity.abbreviation,
            unit: dbEntity.unit,
            dataType: dbEntity.dataType.name
        };
    };


    const makeFullTermMap = (fullTerms) => {
        const m = new Map();
        for (const ft of fullTerms) {
            m.set(ft.idTerm, ft);
        }

        return m;
    };


    const makeReportRowsFromDbEntity = (index, dbEntity, altRowFunc) => {
        const basicRow = makeBasicRowFromDbEntity(index, dbEntity);

        const terms = dbEntity.terms;
        const fullTerms = dbEntity.fullTerms;

        if (!Array.isArray(terms) || terms.length < 1 || !Array.isArray(fullTerms) || fullTerms.length < 1) {
            return [];
        }
        else {
            let index = 0;
            const basicRowNoName = altRowFunc(basicRow);
            const fullTermMap = makeFullTermMap(fullTerms);

            return terms.map(term => {
                const row = index === 0 ? basicRow : basicRowNoName;
                index = index + 1;

                const fullTerm = fullTermMap.get(term.term.id);
                const terminology = fullTerm.terminology;

                return {...row, ...(makeTermFields(term)), terminology: terminology};
            });
        }
    };


    const makeReportRowsFromDbEntityWithFullAlt = (index, dbEntity) => {
        return makeReportRowsFromDbEntity(index, dbEntity, basicRow => basicRow);
    };


    const makeNewVarForParameter = (row, index) => {
        let newVar = {};

        if (row.rawEntity !== null && row.rawEntity.attachment !== undefined) {
            newVar.attachment = row.rawEntity.attachment;
        }

        if (row.errors.length > 0) {
            newVar.status = {
                ok: false,
                errors: row.errors
            }
            if (row.errors[0].code === 6 && row.prevEntity) {
                const child = makeRenderFromDbEntity(index - 100000, row.prevEntity);
                child.status = {};
                child.status.me = {
                    id: child[entityDef.idProp]
                };
                child.status.renderMeTag = true;
                newVar.children = [child];
                newVar.status.isParent = true;
            }
        }
        else {
            newVar.status = {
                ok: true,
                updateEntityId: row.updateEntityId
            }
        }

        return newVar;
    };


    const makeNewVarForTerm = (row, index) => {
        let newVar = {};

        if (row.rawEntity !== null && row.rawEntity.attachment !== undefined) {
            newVar.attachment = row.rawEntity.attachment;
        }

        // If the parameter has errors or is being updated, do not collect any terms.
        if (row.errors.length > 0) {
            return undefined;
        }
        else {
            newVar.status = {
                ok: true,
                updateEntityId: row.updateEntityId
            }
        }

        return newVar;
    };


    const makeErrorRows = (row, index) => {
        const me = {
            id: undefined,
            name: row.rawEntity.name
        };

        return [{
            paramIndex: index + 1,
            key: index,
            ...row.rawEntity,
            name: me,
            status: {
                ok: false,
                errors: row.errors
            },
            term: '-',
            terminology: '-',
            fuzzy: undefined
        }];
    };


    const makeDataSourceFromDataRows = (rows, makeNewRowsFunc, makeNewVarFunc, shouldMakeErrors) => {
        return rows.flatMap((row, index) => {
            if (row.dbEntity != null) {
                const newRows = makeNewRowsFunc(index, row.dbEntity);
                const newVar = makeNewVarFunc(row, index);
                if (newVar === undefined) {
                    return [];
                }

                return newRows.map(newRow => { return {...newVar, ...newRow}; });
            }
            else {
                if (shouldMakeErrors) {
                    return makeErrorRows(row, index);
                }
                else {
                    return [];
                }
            }
        });
    };

    const parameterReportDataSource =
        makeDataSourceFromDataRows(data.rows, (index, dbEntity) => [makeBasicRowFromDbEntity(index, dbEntity)], makeNewVarForParameter, true);
    const termReportDataSource = makeDataSourceFromDataRows(data.rows, makeReportRowsFromDbEntityWithFullAlt, makeNewVarForTerm, false);
    const exportReportDataSource = makeDataSourceFromDataRows(data.rows, makeReportRowsFromDbEntityWithFullAlt, makeNewVarForTerm, false);


    const renumber = (items) => {
        const ar = [];
        let key = 0;

        for (const item of items) {
            ar.push({...item, key: key});
            key = key + 1;
        }

        return ar;
    }


    const renumberedParamDataSource = renumber(parameterReportDataSource);
    const renumberedTermDataSource = renumber(termReportDataSource);
    const renumberedExportDataSource = renumber(exportReportDataSource);


    const statusToString = (status) => {
        if (status?.ok === true) {
            return 'OK';
        }
        else {
            return 'Failed';
        }
    }


    const tsvFromDataSource = (ds) => {
        // Column order: #, Match Type, Terminology, Term, Name, Unit.
        const header = ['#', 'Status', 'Match Type', 'idTerminology', 'Terminology', 'idTerm', 'Term', 'Name', 'Abbreviation', 'Unit', 'Data Type'].join('\t');
        const lines = ds.map(d => {
            const index = '' + d.paramIndex;
            const status = statusToString(d.status);
            const matchType = matchTypeToString(d.fuzzy);
            const idTerminology = d.terminology.id;
            const terminology = d.terminology.name;
            const idTerm = d.idTerm;
            const term = d.termName;
            const name = d.name.name;
            const abbreviation = d.abbreviation !== undefined ? d.abbreviation : '';
            const unit = d.unit;
            const dataType = d.dataType;

            const fields = [index, status, matchType, idTerminology, terminology, idTerm, term, name, abbreviation, unit, dataType];

            return fields.join('\t');
        });

        return [header].concat(lines).join('\n') + '\n';
    };


    const onSaveReportClicked = (event) => {
        const tsvString = tsvFromDataSource(renumberedExportDataSource);
        const url = window.URL.createObjectURL(new Blob([tsvString]));
        const link = document.createElement('a');
        link.href = url;
        link.download = "parameter-report.txt";

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);
    };


    return (
        <>
            <Space
                 size={12}
                 direction={"vertical"}>
            <Table
                columns={parameterReportColumns}
                dataSource={renumberedParamDataSource}
                pagination={pagination}
                size="small"
                scroll={{
                    x: 300,
                    y: dimensions.height - 145
                }}
            />
            {renumberedTermDataSource.length > 0 &&
            <Table
                columns={termReportColumns}
                dataSource={renumberedTermDataSource}
                pagination={pagination}
                size="small"
                scroll={{
                    x: 300,
                    y: dimensions.height - 145
                }}
            />}
            <Button onClick={onSaveReportClicked}>Download report</Button>
            </Space>
        </>
    )
}

ParameterUploadReportView.propTypes = {
    data: PropTypes.object,
    dimensions: PropTypes.object,
    entityDef: PropTypes.object
}

export default ParameterUploadReportView;