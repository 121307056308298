import * as FlexLayout from "flexlayout-react";


export const addEntityDetailTab = (flexLayout, entityRef, entityDef) => {
    flexLayout.addTabToActiveTabSet({
            type: 'tab',
            component: {
                type: "entity",
                entityType: entityDef.entityType,
                entityId: entityRef.id
            },
            name: entityDef.abbr + "-" + entityRef.id
        }
    );
}

export const openList = (entityDef, refLayout, rsql) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {
            type: "list",
            entityType: entityDef.entityType,
            rsql
        },
        name: entityDef.labelPl
    })
}

export const tabCreateEntity = (entityDef, refLayout, defaultEntity, initEntity) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "new", entityType: entityDef.entityType, defaultEntity, initEntity},
        name: 'New ' + entityDef.label
    })
}

export const importReceipt = (refLayout, entityDef, result) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "importReceipt", entityType: entityDef.entityType, result},
        name: 'Import Receipt ' + result.filename
    })
}

export const splashScreen = (refLayout, myUser) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "splash", myUser},
        name: 'Splash'
    })
}

export const mixerScreen = (refLayout) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "mixer"},
        name: 'MiXeR'
    })
}

export const boardScreen = (refLayout, board) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "board", board},
        name: board === 'PDI' ? 'Curation Board' : 'Development Board'
    })
}

export const networkScreen = (refLayout, entityId) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "network", entityId},
        name: 'Network T-' + entityId
    })
}

export const tabJiraDirectImport = (refLayout, ticket, initEntity) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "jiraDirectImport", ticket, initEntity},
        name: "Submission Import"
    })
}

export const tabDatafileDirectImport = (refLayout, importState) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "datafileDirectImport", importState},
        name: "Datafile Mass Import"
    })
}

export const tabListImport = (refLayout, importState) => {
    refLayout.current.addTabToActiveTabSet({
        type: 'tab',
        component: {type: "listImport", importState},
        name: "List Import"
    })
}

export const deleteTab = (model, tabId) => {
    model.doAction(FlexLayout.Actions.deleteTab(tabId));
}

export const deleteTabset = (model, tabsetId) => {
    model.doAction(FlexLayout.Actions.deleteTabset(tabsetId));
}

