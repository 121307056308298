import {Button, Empty} from "antd";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import EntityTagList from "./EntityTagList";
import FilterTag from "./FilterTag";
import {DeleteOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {clearStars} from "../../features/stars/entityStarsSlice";


const Usages = ({usages, withClearStarsButtons}) => {
    const dispatch = useDispatch();

    if (usages.length === 0) {
        return <Empty description="There are no usages"/>
    }

    return (
        <div>
            {usages.map((usage) => {
                const usageEntityDef = entityDefs[usage.type];
                if (usage.entityRefs.length === 0) return <span key={usage.type}/>
                return (
                    <div key={usage.type}>
                        <h4 style={{
                            marginTop: "8px",
                            marginBottom: "2px",
                            color: 'rgb(153, 153, 153)'
                        }}>
                            {usageEntityDef.labelPl} <FilterTag
                            entityDef={usageEntityDef}
                            list={usage.entityRefs}
                            extraStyle={{borderStyle: "none"}}
                        >
                            <UnorderedListOutlined/>
                        </FilterTag>
                            {withClearStarsButtons &&
                                <Button
                                    type='link'
                                    onClick={() => {
                                        dispatch(clearStars({
                                            entityType: usageEntityDef.entityType
                                        }))
                                    }}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            }
                        </h4>
                        <EntityTagList
                            list={usage.entityRefs}
                            entityDef={usageEntityDef}
                            max={20}
                            noListIcon={true}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default Usages;
