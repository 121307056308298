import React from "react";


export const dropTextFn = (item, setValue, paramName) => {
    if (item.text !== undefined) {
        setValue(paramName, item.text);
    } else if (item.html !== undefined) {
        setValue(paramName, item.html.replace(/<[^>]+>/g, ''));
    }
}

export const collectFn = (monitor) => ({
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
})

export const DndHighlight = ({canDrop, isOver, color1, color2}) => {
    if (color1 === undefined) {
        color1 = "green";
    }
    if (color2 === undefined) {
        color2 = "lightgreen";
    }
    if (canDrop || isOver) {
        return <div style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1000,
            outlineStyle: "solid",
            outlineOffset: "-3px",
            outlineWidth: "2px",
            outlineColor: isOver ? color1 : color2,
            pointerEvents: "none"
        }}>
        </div>
    }
    return null;
}