import HelpLink from "../bits/HelpLink";
import {Typography} from "antd";
import PropTypes from "prop-types";
import Starred from "../bits/Starred";
import CloneButton from "../bits/CloneButton";
import dayjs from "dayjs";
import EntityTag from "../bits/EntityTag";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import React from "react";

const {Text} = Typography;

const UserDate = (props) => {
    const {date, user} = props;
    if (date && user) {
        return <span>{dayjs.utc(date).format('YYYY-MM-DD')} / <EntityTag
            entityRef={user} entityDef={entityDefs.panUser}/></span>
    } else if (date) {
        return <span>{dayjs.utc(date).format('YYYY-MM-DD')}</span>
    } else if (user) {
        return <EntityTag entityRef={user} entityDef={entityDefs.panUser}/>
    } else {
        return "---";
    }
}

const CardHeading = (props) => {
    const {
        color,
        text,
        label,
        entity,
        entityDef,
        children,
        onClone,
        cloneDisabledMessage,
        isDirty,
        noCreatedUpdated,
        helpHref
    } = props;
    return (
        <h4 style={{
            marginTop: '0',
            marginBottom: '0'
        }}>
            {children}
            <span
                style={{
                    color: color
                }}
            >
                {text && <span style={{fontWeight: "1000"}}>
                    <Text copyable>
                        {text}
                    </Text>
                </span>} {entityDef && entityDef.cloneable &&
            <CloneButton onClone={onClone} cloneDisabledMessage={cloneDisabledMessage}/>} {entity?.me && entityDef &&
            <Starred entityRef={entity.me} entityDef={entityDef}/>} / <span
                style={{fontWeight: "1"}}>
                        {label} <HelpLink category="Entities" title={label} href={helpHref}/>
                </span>
            </span> &nbsp;
            <span
                style={{
                    lineHeight: "20px",
                    fontSize: "30px"
                }}
            >
                &nbsp;
            </span>
            {isDirty && <span>(unsaved)</span>}
            <span
                style={{
                    lineHeight: "20px",
                    fontSize: "30px"
                }}
            >
                &nbsp;
            </span>
            {!noCreatedUpdated && entity &&
            <span style={{fontWeight: "1", color: entityDef.color}}>
                Created: <UserDate date={entity.datetimeCreated} user={entity.userCreated}/> Updated: <UserDate
                date={entity.datetimeUpdated} user={entity.userUpdated}/>
            </span>
            }
        </h4>
    );
}

CardHeading.propTypes = {
    color: PropTypes.object,
    text: PropTypes.string,
    label: PropTypes.string,
    entityDef: PropTypes.object,
    onClone: PropTypes.func,
    cloneDisabledMessage: PropTypes.string,
    isDirty: PropTypes.bool,
    entity: PropTypes.object,
    noCreatedUpdated: PropTypes.bool,
    helpHref: PropTypes.string
}

export default CardHeading;