import React from "react";
import {Button, Col, Row, Space} from "antd";
import {labelStyle} from "./FormRowContainer";
import PropTypes from "prop-types";
import FormLabel from "./FormLabel";
import HelpLink from "../bits/HelpLink";
import EntityTag from "../bits/EntityTag";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import {DeleteOutlined} from "@ant-design/icons";


export const borderStyle = {
    borderStyle: "solid",
    borderWidth: "3px",
    borderTop: "0",
    borderBottom: "0",
    borderColor: "#EEF",
    borderRadius: "15px",
    marginTop: "12px",
    marginBottom: "12px",
    paddingRight: "12px"
};

const EntityFormItem = (props) => {
    const {
        label,
        paramName,
        required,
        entityDef,
        children,
        border,
        mode,
        noChildrenProps,
        helpHref,
        noRevert,
        termEntityRef,
        onDelete
    } = props;

    const childrenProps = {
        fullWidth: true,
        paramName,
        entityDef
    };

    let myBorderStyle = {};
    if (border) myBorderStyle = borderStyle;

    let myLabelStyle = {...labelStyle};
    if (mode === 'textArea') {
        myLabelStyle.paddingBottom = '20px';
    }
    return (
        <Col span={24} style={myBorderStyle}>
            <Row style={{flexFlow: 'wrap'}} align='middle'>
                <Col
                    style={myLabelStyle}
                >
                    <Space size={0}>
                        <FormLabel label={label} paramName={paramName} entityDef={entityDef} required={required}
                                   noRevert={noRevert}/>
                        {termEntityRef &&
                            <span>&nbsp;<EntityTag entityRef={termEntityRef} entityDef={entityDefs.term}
                                                   display={'letter'}/></span>
                        }
                        {helpHref &&
                            <HelpLink href={helpHref}/>
                        }
                    </Space>
                </Col>
                <Col
                    style={{
                        paddingLeft: '30px',
                        marginTop: '4px'
                    }}
                    flex='auto'
                >
                    {React.cloneElement(children, noChildrenProps ? {} : childrenProps)}
                    {border && <div style={{height: "12px"}}/>}
                </Col>
                {onDelete &&
                    <Button type="link" onClick={onDelete}>
                        <DeleteOutlined/>
                    </Button>
                }
            </Row>
        </Col>
    )
}

EntityFormItem.propTypes = {
    label: PropTypes.string.isRequired,
    paramName: PropTypes.string,
    required: PropTypes.bool,
    entityDef: PropTypes.object,
    border: PropTypes.bool,
    mode: PropTypes.string,
    noChildrenProps: PropTypes.bool,
    helpHref: PropTypes.string
}

export default EntityFormItem;
