import {Space} from "antd";
import mixerMods from "./mixerMods";
import SelectInput from "../../../tk/input/SelectInput";
import {PlusOutlined} from "@ant-design/icons";
import React from "react";

const AddModButton = ({entityDef, setOutMod}) => {

    const globalOptions = mixerMods.global.options;

    function mapOptionsForSelectInput(options) {
        return options.map((o, i) => ({
            name: o.label,
            id: i,
            key: o.label + o.modName
        }))
    }

    const optionsAll = [
        ...globalOptions
    ];

    if (mixerMods[entityDef.entityType]) {
        optionsAll.push(...mixerMods[entityDef.entityType].options);
    }

    const mappedOptions = mapOptionsForSelectInput(optionsAll);
    return (
        <div
            style={{
                marginTop: "6px"
            }}
        >
            <Space>
                <SelectInput
                    options={mappedOptions}
                    overrideValue={{value: null, label: <span><PlusOutlined/> Add mod</span>}}
                    onChange={e => setOutMod(optionsAll[e.id])}
                />
            </Space>
        </div>
    )
}

export default AddModButton;