import {labelStyle, OverviewLabel} from "./OverviewLine";
import EntityTag from "../../../tk/bits/EntityTag";
import ResolveUri from "../../../tk/bits/ResolveUri";
import JiraLink from "../../../tk/bits/JiraLink";
import dayjs from "dayjs";
import EllipsisText from "../../../tk/bits/EllipsisText";
import React from "react";

const OverviewLineImport = ({value, label, entityDef, type}) => {
    if (value === null || value === '') {
        return (
            <>
                <OverviewLabel label={label}/>
                <span style={labelStyle}>blank</span>
                <br/>
            </>
        )

    } else if (entityDef !== undefined) {
        return (
            <>
                    <span>
                        <OverviewLabel label={label}/>
                        <EntityTag entityRef={value} entityDef={entityDef}/>
                    </span>
                <br/>
            </>
        );
    } else if (type === 'uri') {
        return (
            <>
                <span>
                    <OverviewLabel label={label}/>
                    <ResolveUri uri={value}/>
                </span>
                <br/>
            </>
        );
    } else if (type === 'ticket') {
        return (
            <>
                <span>
                    <OverviewLabel label={label}/>
                    <JiraLink ticket={value}/>
                </span>
                <br/>
            </>
        );
    } else if (type === 'date') {
        return (
            <>
                <span>
                    <OverviewLabel label={label}/>
                    {dayjs(value).format("YYYY-MM-DD")}
                </span>
                <br/>
            </>
        );

    }
    return <>
        <span>
            <OverviewLabel label={label}/>
            <EllipsisText text={value.toString()}/>
            <br/>
        </span>
    </>
}
export default OverviewLineImport;