import React, {useMemo} from "react";
import ResizeObserver from "rc-resize-observer";
import useDelayedState from "../../hooks/useDelayedState";


export const ResponsiveContext = React.createContext({
    dimensions: {width: 100, height: 100},
    colSpan: [24, 24, 24],
    wideSpan: [24, 24, 24]
});

const getColSpanForWidth = width => {
    if (width > 1400) {
        return [8, 8, 8];
    } else if (width > 700) {
        return [12, 12, 24];
    } else {
        return [24, 24, 24];
    }
}

const getWideSpanForWidth = width => {
    if (width > 1500) {
        return [12, 12, 24];
    } else {
        return [24, 24, 24];
    }
}

const ResponsiveArea = (props) => {
    const {children} = props;
    const [dimensions, setDimensions] = useDelayedState({width: 100, height: 100}, 300);
    //console.log("RENDER Responsive Area", dimensions)

    const provideContext = useMemo(() => ({
            dimensions,
            colSpan: getColSpanForWidth(dimensions.width),
            wideSpan: getWideSpanForWidth(dimensions.width)
        }),
        [dimensions]
    );

    return (
        <ResizeObserver onResize={dim => {
            // Avoid unnecessary rerenders with these conditionals
            if (dim.width === 0 || dim.height === 0) return;
            if (dim.width === dimensions.width && dim.height === dimensions.height) return;
            //console.log("Resize dim=", dim, dimensions);
            setDimensions({width: dim.width, height: dim.height})
        }}>
            <ResponsiveContext.Provider value={provideContext}>
                {React.cloneElement(children)}
            </ResponsiveContext.Provider>
        </ResizeObserver>
    )
}

ResponsiveArea.propTypes = {}

export default ResponsiveArea;
