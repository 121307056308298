import {Tooltip} from "antd";
import React, {useState} from "react";
import PropTypes from "prop-types";


const DisabledTooltip = (props) => {
    const {conditions, children, ...restProps} = props;
    const [open, setOpen] = useState(false);
    const [timerId, setTimerId] = useState();

    let message = conditions
        .filter(condition => condition.isTrue)
        .map(condition => condition.message)
        .reduce((prev, current) => prev + ", " + current, "");
    if (message.length >= 2) {
        message = message.substring(2);
    }

    return (
        <Tooltip
            title={message}
            open={open}
            onOpenChange={open => {
                setOpen(open);
                if (timerId) {
                    clearTimeout(timerId);
                    setTimerId(undefined);
                }
                if (open) {
                    const newTimerId = setTimeout(() => setOpen(false), 2000);
                    setTimerId(newTimerId);
                }
            }}
        >
            <span>
                {React.cloneElement(
                    children,
                    {
                        ...restProps,
                        disabled: message.length > 0
                    }
                )}
            </span>
        </Tooltip>
    )
}

DisabledTooltip.propTypes = {
    conditions: PropTypes.array
}

export default DisabledTooltip;
