import {Button, Popover, Space, Spin, Tag} from "antd";
import {useDrag, useDrop} from "react-dnd";
import React, {useContext, useEffect, useState} from "react";
import {CloseCircleTwoTone, CopyOutlined, EditOutlined} from '@ant-design/icons';
import FlexLayoutContext from "../../contexts/FlexLayoutContext";
import Color from "color";
import "./EntityTag.css";
import PropTypes from "prop-types";
import {commonStyle, getText} from "./EntityTagNonInteractive";
import {collectFn} from "../../lib/dropIndicatorStyle";
import MySettingsContext from "../../contexts/MySettingsContext";
import Starred from "./Starred";
import CloneButton from "./CloneButton";
import {getEntity} from "../../lib/entityRequests";
import useWS2Axios from "../../hooks/useWS2Axios";
import NotificationsContext from "../../contexts/NotificationsContext";
import {overviewComponentFactory} from "../../pages/editorial/entities/componentFactories";
import overviewButtons from "../../pages/editorial/entities/overviewButtons";
import NetworkError from "../error/NetworkError";


const EntityTag = (props) => {
    const {
        entityRef,
        entityDef,
        display,
        style,
        noLoader,
        onRemove,
        onInsert,
        onDuplicate,
        dndDirection,
        dropAcceptOverride,
        onClick
    } = props;

    const entityRefWithInstance = entityRef;
    const dropAccept = dropAcceptOverride
        ? dropAcceptOverride.map(over => "ENTITY_" + over.entityType)
        : 'ENTITY_' + entityDef.entityType;
    const [visible, setVisible] = useState(false);
    const [hovers, setHovers] = useState(false);
    const [entityData, setEntityData] = useState(undefined);
    const [isTriggerOpen, setIsTriggerOpen] = useState(false);
    const [error, setError] = useState();
    const {addEntityDetailTab} = useContext(FlexLayoutContext);
    const {wideTags, onClone} = useContext(MySettingsContext);
    const {notifySuccess} = useContext(NotificationsContext);
    const {ws2Axios} = useWS2Axios();
    const [loading, setLoading] = useState(false);
    const [suppressPopup, setSuppressPopup] = useState(false);
    const [doOpen, setDoOpen] = useState(false);

    useEffect(() => {
            if (!isTriggerOpen) return;
            setIsTriggerOpen(false);
            setTimeout(() => {
                setDoOpen(true);
            }, 200)
        },
        [entityDef, entityRef, isTriggerOpen, ws2Axios]
    );

    useEffect(() => {
            if (!doOpen) return;
            setDoOpen(false);
            if (suppressPopup) return;
            setError(undefined);
            setLoading(true);
            getEntity(ws2Axios, entityDef, entityRef.id)
                .then(entity => {
                    setEntityData(entity);
                    setVisible(true);
                    setLoading(false);
                })
                .catch((e) => {
                    setVisible(true);
                    setLoading(false);
                    setError(e.response);
                });
        },
        [doOpen, entityDef, entityRef, suppressPopup, ws2Axios]
    );

    const [, drag] = useDrag(() => {
            return ({
                type: 'ENTITY_' + entityDef?.entityType,
                item: {
                    entityDef,
                    data: entityRefWithInstance
                }
            })
        },
        [entityRef, entityDef]
    );

    const [{isOver}, drop] = useDrop(() => ({
        accept: dropAccept,
        collect: collectFn,
        drop: item => {
            if (!onInsert) return;
            onInsert(item.data, entityRefWithInstance, item.entityDef);
        }
    }), [onInsert, entityRef, entityRefWithInstance]);

    if (!entityRef) {
        return <span>none</span>
    }

    if (entityRef.id === null) {
        return <span/>
    }

    const styleFollow = {
        ...commonStyle(entityDef),
        cursor: "pointer",
        ...style,
        maxWidth: wideTags ? "800px" : (style && style.maxWidth) || "250px",
        width: ""
    }
    const styleNoFollow = {
        ...commonStyle(entityDef),
        ...style
    }
    const title = () => {
        if (entityDef === undefined) {
            return <span>???</span>
        }
        const moreButtons = overviewButtons[entityDef.entityType];

        return (
            <div
                style={{
                    color: Color(entityDef.color).darken(0).saturate(1)
                }}
            >
                {entityDef.label} {entityDef.abbr}-{entityRef.id}
                <Space style={{paddingLeft: '20px'}}>
                    <Button type="link" style={{padding: '0'}} onClick={() => {
                        setVisible(false);
                        addEntityDetailTab(entityRef, entityDef);
                    }}>
                        <EditOutlined/>
                    </Button>
                    <Button type="link" style={{padding: '0'}} onClick={() => {
                        navigator.clipboard.writeText(entityRef.id);
                        notifySuccess('ID ' + entityRef.id + ' copied to clipboard');
                    }}>
                        <CopyOutlined/>
                    </Button>
                    {entityDef.cloneable &&
                        <CloneButton
                            onClone={() => {
                                setVisible(false);
                                onClone(entityData, entityDef)
                            }}
                            cloneDisabledMessage={entityData ? undefined : "Loading ..."}
                        />
                    }
                    <Starred entityRef={entityRef} entityDef={entityDef}/>
                    {moreButtons && React.cloneElement(moreButtons(entityRef, ws2Axios))}
                </Space>
            </div>
        );
    }

    const content = () => {
        const overviewComponent = overviewComponentFactory(entityDef.entityType);
        if (error) {
            return <NetworkError code={error.status} message={error.statusText}/>
        }
        if (overviewComponent === undefined || !entityData) {
            return <div>Hello World!</div>
        }
        if (noLoader) {
            return <>{overviewComponent}</>;
        }
        return React.cloneElement(overviewComponent, {entity: entityData})
    }

    const dropStyle = {}
    if (isOver && onInsert) {
        if (dndDirection === 'vertical') {
            dropStyle.borderTopStyle = 'double';
            dropStyle.paddingTop = '4px';
        } else {
            dropStyle.borderLeftStyle = 'double';
            dropStyle.paddingLeft = '8px';
        }
    }

    if (entityDef.noFollow) {
        return (
            <Tag
                checked={false}
                style={styleNoFollow}
                ref={drag}
            >
                {getText(entityRef, entityDef, display)}
            </Tag>
        );
    }

    const draggableTag = (
        <span
            ref={drop}
            onMouseEnter={() => setHovers(true)}
            onMouseLeave={() => setHovers(false)}
        >
                    <span style={dropStyle}>
                        <Tag
                            ref={drag}
                            style={styleFollow}
                            onMouseDown={e => e.stopPropagation()} // Necessary for EntityTags with the antd Select element
                            onDoubleClick={(e) => {
                                e.stopPropagation();
                                setSuppressPopup(true);
                                addEntityDetailTab(entityRef, entityDef);
                            }}
                            onClick={() => {
                                setSuppressPopup(false);
                                if (onClick) onClick()
                            }}
                        >
                            <Spin spinning={loading}>
                                <span>{getText(entityRef, entityDef, display)}</span>
                            </Spin>
                        </Tag>
                        {hovers && onRemove &&
                            <span
                                style={{
                                    position: "absolute",
                                    marginLeft: "-24px",
                                    marginTop: "-20px",
                                    cursor: "pointer",
                                    padding: "10px 10px 0px"
                                }}
                                onClick={(e) => {
                                    onRemove(entityRefWithInstance);
                                    e.stopPropagation();
                                }}
                            >
                            <CloseCircleTwoTone twoToneColor="#eb2f96"/>
                        </span>
                        }
                        {hovers && onDuplicate &&
                            <span
                                style={{
                                    position: "absolute",
                                    marginLeft: "-48px",
                                    marginTop: "-20px",
                                    cursor: "pointer",
                                    padding: "10px 10px 0px"
                                }}
                                onClick={(e) => {
                                    onDuplicate(entityRefWithInstance);
                                    e.stopPropagation();
                                }}
                            >
                            <CopyOutlined/>
                        </span>
                        }
                    </span>
                </span>
    );

    if (entityRef.dummy) {
        return draggableTag;
    }

    return (
        <>
            <Popover
                content={content}
                title={title}
                trigger="click"
                placement="bottomLeft"
                open={visible}
                onOpenChange={open => {
                    if (!open) {
                        setVisible(false);
                        return;
                    }
                    setIsTriggerOpen(true);
                }}
                destroyTooltipOnHide={true}
                zIndex={10000}
                overlayInnerStyle={{paddingTop: "8px", maxWidth: "700px"}}
                autoAdjustOverflow={true}
            >
                {draggableTag}
            </Popover>
        </>
    );
}

EntityTag.propTypes = {
    entityRef: PropTypes.object,
    entityDef: PropTypes.object,
    display: PropTypes.string,
    style: PropTypes.object,
    noLoader: PropTypes.bool,
    onRemove: PropTypes.func,
    onInsert: PropTypes.func,
    onDuplicate: PropTypes.func,
    dndDirection: PropTypes.oneOf(['horizontal', 'vertical']),
    dropAcceptOverride: PropTypes.array
}

export default EntityTag;
