import {VictoryArea, VictoryAxis, VictoryChart, VictoryLine, VictoryStack, VictoryTheme} from "victory";
import useResource from "../../../hooks/useResource";
import {useState} from "react";
import {Checkbox, Slider, Space, Switch} from "antd";
import {useImmer} from "use-immer";

const KPIs = ({columnDefs, options}) => {
    const {result, isLoading} = useResource("board/" + options.board + "/cumulativeflow")
    const [viewStart, setViewStart] = useState(-300)
    const [viewEnd, setViewEnd] = useState(-1)
    const [stacked, setStacked] = useState(true)
    const [columnsEnabled, setColumnsEnabled] = useImmer({})

    if (isLoading) return <div>LOADING</div>

    let data = [];
    if (result) data = result;

    // Extract list of board columns dynamically from the data
    let boardColumns = [...new Set(data.map(entry => entry.currentColumn))];
    boardColumns = [
        "done",
        "Author approval",
        "inProgress",
        "todo",
        "1st Review Passed",
        "backlog",
    ];

    // Define a mapping of board column names to colors
    const colorMap = {
        backlog: "blue",
        inProgress: "green",
        "Author approval": "orange",
        done: "red",
        "1st Review Passed": "cyan",
        todo: "purple"
    };

    // Reorganize data to have cumulative counts for each board column at each date
    let stackedData = data.reduce((acc, {datetime, currentColumn, beforeColumn, count}) => {
        datetime = new Date(datetime); // Convert date string to JavaScript Date object
        let lastEntry = acc[acc.length - 1];
        if (!lastEntry) {
            lastEntry = {datetime: datetime}
            boardColumns.forEach(col => lastEntry[col] = 0)
            acc.push(lastEntry)
        }
        while (lastEntry.datetime.getTime() < datetime.getTime()) {
            const newEntry = {...lastEntry}
            newEntry.datetime = new Date(lastEntry.datetime);
            newEntry.datetime.setDate(lastEntry.datetime.getDate() + 1);
            acc.push(newEntry);
            lastEntry = newEntry;
        }
        if (currentColumn) {
            lastEntry[currentColumn] += count;
        }
        if (beforeColumn) {
            lastEntry[beforeColumn] -= count;
        }
        return acc;
    }, []);


    stackedData = stackedData.slice(viewStart, viewEnd);
    if (stackedData.length === 0) return <div>NO DATA</div>

    const finalCol = 'done';
    const startCount = stackedData[0][finalCol];
    stackedData.forEach(row => row[finalCol] -= startCount)

    // Determine the range of dates for the domain of x-axis
    const minDate = stackedData[0].datetime; //new Date(Math.min(...dates));
    const maxDate = stackedData[stackedData.length - 1].datetime; //new Date(Math.max(...dates));

    const isColumnEnabled = (boardColumn) => {
        return (columnsEnabled[boardColumn] === true) || (columnsEnabled[boardColumn] === undefined)
    }

    return (
        <div>
            <VictoryChart
                width={600}
                height={300}
                domainPadding={{y: 20}}
                padding={{top: 8, bottom: 25, left: 33, right: 8}}
            >
                <VictoryAxis
                    dependentAxis
                    tickFormat={(t) => t.toLocaleString()}
                    tickCount={20}
                    style={{
                        grid: {stroke: "lightgrey"},
                        ticks: {stroke: "grey", size: 5},
                        tickLabels: {fontSize: 6}
                    }}
                    theme={VictoryTheme.material}
                />
                <VictoryAxis
                    tickFormat={(t) => new Date(t).toLocaleDateString()}
                    tickCount={13}
                    style={{
                        grid: {stroke: "lightgrey"},
                        ticks: {stroke: "grey", size: 5},
                        tickLabels: {fontSize: 6}
                    }}
                    scale="time"
                    domain={[minDate, maxDate]}
                    theme={VictoryTheme.material}
                />
                {!stacked &&
                    boardColumns.map((boardColumn, index) => {
                        if (!isColumnEnabled(boardColumn)) {
                            return null;
                        }
                        return (
                            <VictoryLine
                                key={index}
                                data={stackedData}
                                x="datetime"
                                y={boardColumn}
                                interpolation="linear"
                                style={{data: {stroke: colorMap[boardColumn]}}}
                            />
                        );
                    })
                }
                {stacked &&
                    <VictoryStack>
                        {boardColumns.map((boardColumn, index) => {
                            if (!isColumnEnabled(boardColumn)) {
                                return null;
                            }
                            return (
                                <VictoryArea
                                    key={index}
                                    data={stackedData}
                                    x="datetime"
                                    y={boardColumn}
                                    interpolation="linear"
                                    style={{
                                        data: {
                                            fill: colorMap[boardColumn],
                                            stroke: colorMap[boardColumn]
                                        }
                                    }}
                                />
                            );
                        })}
                    </VictoryStack>
                }
            </VictoryChart>
            <div style={{paddingLeft: "64px", paddingRight: "24px"}}>
                <Slider
                    range={{draggableTrack: true}}
                    min={-4000}
                    max={-1}
                    step={50}
                    defaultValue={[-300, -1]}
                    value={[viewStart, viewEnd]}
                    onChange={([start, end]) => {
                        if (start > -50) start = -50;
                        if (end > -1) end = -1;
                        setViewStart(start);
                        setViewEnd(end);
                    }}
                />
                <Space size={24}>
                    <Switch
                        checked={stacked}
                        onChange={e => setStacked(e)}
                        checkedChildren="Stacked"
                        unCheckedChildren="Stacked"
                    />
                    {boardColumns.map((boardColumn, index) => (
                        <Checkbox
                            key={boardColumn}
                            checked={isColumnEnabled(boardColumn)}
                            onChange={e => setColumnsEnabled(c => {
                                c[boardColumn] = e.target.checked
                            })}
                        >
                            <Space>
                                <div style={{
                                    backgroundColor: colorMap[boardColumn],
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "32px"
                                }}></div>
                                <span>{boardColumn}</span>
                            </Space>
                        </Checkbox>
                    ))}
                </Space>
            </div>
        </div>
    );
}

export default KPIs