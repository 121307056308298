import RequiredMarker from "../bits/RequiredMarker";
import React, {useContext, useState} from "react";
import {Button, Popover, Tooltip} from "antd";
import {FormExtContext} from "./FormExt";
import {labelStyle} from "./FormRowContainer";
import PropTypes from "prop-types";
import EntityTag from "../bits/EntityTag";
import {RollbackOutlined} from "@ant-design/icons";
import isFieldDirty from "../../lib/dirtyCheck";


const RevertButton = ({resetField, paramName, children}) => {
    return (
        <>
            {children}
            <Tooltip title="Revert field">
                <Button type="link" onClick={() => resetField(paramName)}>
                    <RollbackOutlined/>
                </Button>
            </Tooltip>
        </>
    );
}

const FormLabel = (props) => {
    const {label, paramName, required, entityDef, noRevert} = props;
    const {formState, resetField, defaultEntity} = useContext(FormExtContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const isDirty = isFieldDirty(formState.dirtyFields[paramName]);

    const myLabelStyle = {
        ...labelStyle
    }

    if (isDirty) {
        myLabelStyle.fontWeight = '900';
        myLabelStyle.color = '#1890ff'
    }

    const resetAndClose = (field) => {
        resetField(field);
        setDropdownOpen(false);
    }

    const menu = () => {
        const defaultValue = defaultEntity[paramName];
        if (defaultValue === undefined || defaultValue === null || (defaultValue.id !== undefined && defaultValue.id === null)) {
            return (
                <div>
                    <RevertButton paramName={paramName} resetField={resetAndClose}>
                        &lt;empty&gt;
                    </RevertButton>
                </div>
            )
        }
        if (defaultValue.id !== undefined) {
            return (
                <div>
                    <RevertButton paramName={paramName} resetField={resetAndClose}>
                        <EntityTag entityRef={defaultValue} entityDef={entityDef}/>
                    </RevertButton>
                </div>
            )
        }
        if (Array.isArray(defaultValue)) {
            if (defaultValue.length === 0) {
                return (
                    <div>
                        <RevertButton paramName={paramName} resetField={resetAndClose}>
                            &lt;empty&gt;
                        </RevertButton>
                    </div>
                )
            }
            if (defaultValue[0].id !== undefined) {
                return (
                    <div>
                        <RevertButton paramName={paramName} resetField={resetAndClose}>
                            {defaultValue.map(value =>
                                <EntityTag key={value.id} entityRef={value} entityDef={entityDef}/>
                            )}
                        </RevertButton>
                    </div>
                )
            }
            return (
                <div>
                    <RevertButton paramName={paramName} resetField={resetAndClose}>
                        {JSON.stringify(defaultValue)}
                    </RevertButton>
                </div>
            )

        }
        if (typeof defaultValue == "object") {
            return (
                <div>
                    <RevertButton paramName={paramName} resetField={resetAndClose}>
                        {JSON.stringify(defaultValue)}
                    </RevertButton>
                </div>
            )
        }
        return (
            <div>
                <RevertButton paramName={paramName} resetField={resetAndClose}>
                    {defaultValue}
                </RevertButton>
            </div>
        )
    }

    if (isDirty && !noRevert) {
        return (
            <Popover
                content={menu()}
                trigger={['click']}
                open={dropdownOpen}
                onOpenChange={(open) => {
                    setDropdownOpen(open);
                }}
            >
                <Button
                    type="link"
                    style={{
                        padding: '0px',
                        border: '0px',
                        fontSize: '12px',
                        marginBottom: '-9px',
                        fontWeight: '900'
                    }}
                    onClick={(e) => e.preventDefault()}>
                    {label}&nbsp;{required && <RequiredMarker/>}
                </Button>
            </Popover>
        )
    }

    return (
        <div style={{paddingTop: '7px', paddingBottom: '0', fontWeight: '900'}}>{label}&nbsp;{required &&
            <RequiredMarker/>}</div>
    )
}

FormLabel.propTypes = {
    label: PropTypes.string.isRequired,
    paramName: PropTypes.string,
    required: PropTypes.bool,
    entityDef: PropTypes.object
}

export default FormLabel;
