import useResource from "../../../hooks/useResource";
import JiraLink from "../../../tk/bits/JiraLink";
import dayjs from "dayjs";
import {Tag} from "antd";
import {calcAge} from "./CardOnBoard";


const DoneList = ({serviceClassDefs, options}) => {
    const {result} = useResource("board/" + options.board + '/column/done');
    let lastDate = '';
    return (
        <div>
            {result.map(card => {
                let dateHeader = <></>
                const d = dayjs(card.datetimeColumnEntered).format('YYYY-MM-DD');
                if (d !== lastDate) {
                    dateHeader = <h4>{d}</h4>;
                    lastDate = d;
                }

                const createdDate = dayjs(card.datetimeCreated).format('YYYY-MM-DD');
                const age = calcAge(createdDate, d)

                return (
                    <div key={card.ticket}>
                        {dateHeader}
                        <div
                            style={{
                                borderColor: serviceClassDefs[card.serviceClass].color,
                                borderTopWidth: '0',
                                borderBottomWidth: '0',
                                borderLeftWidth: '16px',
                                borderRightWidth: '0',
                                borderStyle: 'solid',
                                paddingLeft: '16px'
                            }}
                        >
                            <JiraLink ticket={card.ticket}/>
                            {card.subColumn != null &&
                                <span><Tag style={{marginLeft: "24px"}}>{card.subColumn}</Tag></span>
                            }
                            <span style={{marginLeft: '24px'}}>{card.summary}</span>
                            <span style={{
                                paddingLeft: "16px",
                                fontSize: "12px",
                                color: "#999",
                                fontWeight: "1000"
                            }}>{createdDate} created, {age} days</span>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default DoneList;
