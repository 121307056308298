const isObjectDirty = (value) => {
    if (typeof value == "object") {
        const dirtyArray = Object.keys(value)
            .map(key => {
                const valueElement = value[key];
                if (Array.isArray(valueElement)) {
                    return isArrayDirty(valueElement)
                } else if (typeof valueElement == "object") {
                    return isObjectDirty(valueElement);
                } else {
                    return valueElement === true;
                }
            });
        return isArrayDirty(dirtyArray);
    } else {
        return value === true;
    }
}

const isArrayDirty = (arrayField) => {
    return arrayField.reduce(
        (previousValue, currentValue) => previousValue || isObjectDirty(currentValue),
        false
    );
}

const isFieldDirty = (dirtyField) => {
    if (Array.isArray(dirtyField)) {
        return isArrayDirty(dirtyField);
    } else if (typeof dirtyField == "object") {
        return isObjectDirty(dirtyField);
    }
    return dirtyField === true;
}

export default isFieldDirty;