import React from "react";
import {List} from "antd";
import PropTypes from "prop-types";


const ImportErrorDisplay = (props) => {
    const {customError, result} = props;

    if (customError !== undefined) {
        return React.cloneElement(customError(result))
    }

    return (
        <List size="small">
            {result.errors.map((error, index) => <List.Item key={index}>{error}</List.Item>)}
        </List>
    )
}

ImportErrorDisplay.propTypes = {
    customError: PropTypes.func,
    result: PropTypes.object
}

export default ImportErrorDisplay;