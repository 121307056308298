import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const MethodOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Description" value={entity.description}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Reference" value={entity.reference} entityDef={entityDefs.reference}/>
            <OverviewLine label="Abbreviation" value={entity.abbreviation}/>
        </div>
    )
}

export default MethodOverview;