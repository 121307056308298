import {Input, Radio} from "antd";
import FilterDialogButtons from "./FilterDialogButtons";


const EQ = '==';
const LE = '=le=';
const GE = '=ge=';
const INTERVAL = '[]';

const toFilter = selectedKeys => {
    let filter = {
        mode: EQ,
        value1: '',
        value2: ''
    }
    if (Array.isArray(selectedKeys)) {
        if (selectedKeys.length === 2) {
            filter = {
                mode: INTERVAL,
                value1: selectedKeys[0].value,
                value2: selectedKeys[1].value
            }
        } else if (selectedKeys.length === 1) {
            filter = {
                mode: selectedKeys[0].mode,
                value1: selectedKeys[0].value,
                value2: ''
            }
        }
    }
    return filter;
}

const fromFilter = filter => {
    if (filter.mode === INTERVAL) {
        return [
            {
                mode: GE,
                value: filter.value1
            },
            {
                mode: LE,
                value: filter.value2
            }
        ]
    }

    return [{
        mode: filter.mode,
        value: filter.value1
    }]
}

const NumericSearchProps = (props) => {
    const {dataIndex, setSelectedKeys, selectedKeys, confirm, clearFilters, autofocusRef} = props;

    const filter = toFilter(selectedKeys);

    const getPlaceholder1 = () => {
        switch (filter.mode) {
            case GE:
                return `Find ${dataIndex} >=`;
            case LE:
                return `Find ${dataIndex} <=`;
            case INTERVAL:
                return `Find ${dataIndex} >=`;
            default:
            case EQ:
                return `Find ${dataIndex} ==`;
        }
    }

    const getPlaceholder2 = () => {
        return `Find ${dataIndex} <=`;
    }

    return (
        <div style={{paddingBottom: 8}}>
            <Radio.Group
                value={filter.mode}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, mode: e.target.value}))}
            >
                <Radio value={EQ}>=</Radio>
                <Radio value={GE}>&gt;=</Radio>
                <Radio value={LE}>&lt;=</Radio>
                <Radio value={INTERVAL}>Interval</Radio>
            </Radio.Group>
            <Input
                ref={autofocusRef}
                placeholder={getPlaceholder1()}
                value={filter.value1}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, value1: e.target.value}))}
                onPressEnter={(e) => {
                    e.stopPropagation();
                    confirm();
                }}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            {filter.mode === INTERVAL &&
            <Input
                placeholder={getPlaceholder2()}
                value={filter.value2}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, value2: e.target.value}))}
                onPressEnter={(e) => {
                    e.stopPropagation();
                    confirm();
                }}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            }
            <FilterDialogButtons confirm={confirm} clearFilters={clearFilters}/>
        </div>
    );
}

export default NumericSearchProps;
