import {AutoComplete} from "antd";
import {useState} from "react";

const MyAutocomplete = (props) => {
    const {placeholder, onChange, options} = props;
    const [value, setValue] = useState("");
    return <AutoComplete
        style={{width: "100%"}}
        placeholder={placeholder}
        onChange={value => {
            setValue(value);
            onChange(value);
        }}
        options={options}
        value={value}
    />
}

export default MyAutocomplete;