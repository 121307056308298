import {Button} from "antd";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined} from "@ant-design/icons";
import React from "react";

const rowButtonStyle = {paddingLeft: '5px', paddingRight: '5px', height: '30px'};

export const smallTagStyle = {width: '130px'}

export const sorterColumn = (swap, fields, pagingFn) => ({
    title: '',
    width: '40px',
    key: 'sorter',
    render: (_, record, index) => {
        let globalIndex = index;
        if(pagingFn) {
            // When paging, the index is local to the current page,
            // so we have to add an offset for the current page.
            const paging = pagingFn();
            globalIndex = (paging.currentPage - 1) * paging.pageSize + index;
        }
        return <div style={{width: '35px'}}>
            <Button type='link' disabled={globalIndex === 0}
                    style={{...rowButtonStyle, padding: '0'}}
                    onClick={() => swap(globalIndex, globalIndex - 1)}><CaretUpOutlined/></Button>
            <Button type='link' disabled={globalIndex === fields.length - 1}
                    style={{...rowButtonStyle, padding: '0'}}
                    onClick={() => swap(globalIndex, globalIndex + 1)}><CaretDownOutlined/></Button>
        </div>;
    }
})

export const removeColumn = (remove) => ({
    title: '',
    width: '10px',
    key: 'remove',
    render: (_, record, index) =>
        <Button type='link' danger style={rowButtonStyle}
                onClick={() => remove(index)}><DeleteOutlined/></Button>
})