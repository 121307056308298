import PropTypes from "prop-types";
import {LogoutOutlined} from "@ant-design/icons";
import React from "react";

const ResolveUri = (props) => {
    const {uri, disabled, children} = props;
    if ((!uri || uri.length === 0) && (!children || children.length === 0)) return <span/>

    if (disabled) {
        return (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>
                {children || uri} <LogoutOutlined/>
            </span>
        )
    }
    return (
        <a href={"https://ws.pangaea.de/ws/resolve-uri?uri=" + encodeURIComponent(uri)} target="_blank"
           rel="noreferrer">
            {children || uri} <LogoutOutlined/>
        </a>
    );
}

ResolveUri.propTypes = {
    uri: PropTypes.string
}

export default ResolveUri;