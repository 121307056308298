import {Button, Dropdown, InputNumber, Space} from "antd";
import {useState} from "react";
import './DropdownStyle.css'

const DetailBarDropdown = (props) => {
    const {children, lifeTimeDays, setLifeTimeDays, tempAccessRequest, ...restProps} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const items = [
        {
            label: (
                <Space>
                    Key valid for
                    <InputNumber min={1} max={180} defaultValue={lifeTimeDays}
                                 onChange={(value) => setLifeTimeDays(value)}/>
                    days
                    <Button onClick={() => {
                        setDropdownOpen(false);
                        tempAccessRequest('createToken');
                    }
                    }
                            shape="round">
                        Ok
                    </Button>
                </Space>),
            key: '1',
        },
        {
            label: (
                <Space style={{width: '100%', justifyContent: 'space-between'}}>
                    Delete all keys for all users
                    <Button shape="round" onClick={() => {
                        setDropdownOpen(false);
                        tempAccessRequest('removeAllTokens');
                    }}>
                        Ok
                    </Button>
                </Space>
            ),
            key: '2'
        }
    ];
    return (
        <Dropdown.Button
            open={dropdownOpen}
            onOpenChange={(open) => {
                setDropdownOpen(open);
            }}
            menu={{items}}
            {...restProps}
        >
            {children}
        </Dropdown.Button>
    )
}

export default DetailBarDropdown;
