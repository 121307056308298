import {useMemo} from "react";
import ContainerContext from "./ContainerContext";
import {deleteTab} from "../lib/flexlayoutTabTools";

const ContainerContextWrapper = (props) => {
    const {model, tabId, onDirtyChange, children} = props;

    const containerContext = useMemo(() => ({
            closeContainer: () => deleteTab(model, tabId),
            onDirtyChange: isDirty => onDirtyChange(tabId, isDirty)
        }),
        [model, onDirtyChange, tabId]
    );

    return (
        <ContainerContext.Provider value={containerContext}>
            {children}
        </ContainerContext.Provider>
    )
}

export default ContainerContextWrapper;
