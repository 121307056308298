import {Tag} from "antd";
import React from "react";
import Color from "color";
import "./EntityTag.css";
import PropTypes from "prop-types";


export const getText = (entityRef, entityDef, display) => {
    if(entityRef.dummy) {
        return "-------------";
    }
    if (display === 'letter') {
        return entityDef.abbr;
    }
    if (entityRef.name === undefined || entityRef.name === null || (entityRef.name + "").trim() === '' || display === 'id') {
        return entityDef.abbr + "-" + entityRef.id;
    }
    return entityRef.name;
}

export const commonStyle = entityDef => ({
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "relative",
    top: "5px",
    color: Color(entityDef.color).darken(0).saturate(1),
    borderColor: Color(entityDef.color).lighten(0.4).saturate(1),
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "100",
    background: '#fafafa'
})

const EntityTagNonInteractive = (props) => {
    const {entityRef, entityDef, display, style} = props;

    if (entityRef === undefined || entityRef.id === null) {
        return <span/>
    }


    return (
        <Tag
            checked={false}
            style={{...commonStyle(entityDef), ...style}}
        >
            {getText(entityRef, entityDef, display)}
        </Tag>
    );
}

EntityTagNonInteractive.propTypes = {
    entityRef: PropTypes.object,
    entityDef: PropTypes.object,
    display: PropTypes.string,
    style: PropTypes.object,
    noLoader: PropTypes.bool
}

export default EntityTagNonInteractive;
