import {Col, Row, Space} from "antd";

const ButtonsInHeader = ({style, children}) => {
    return (
        <Row gutter={[0, 16]} justify="space-between" align="middle" style={style}>
            <Col>
                <Space wrap>
                    {children}
                </Space>
            </Col>
        </Row>
    )
}

export default ButtonsInHeader;