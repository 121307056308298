import React, {useContext} from "react";
import EntityHeader from "../entities/EntityHeader";
import {FormExtContext} from "../../../tk/forms/FormExt";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import DataSeriesDataDisplay from "./DataSeriesDataDisplay";
import UsagesDisplay from "./UsagesDisplay";
import {entityFields, renderField} from "../entities/entityFields";


const DataSeriesDetail = (props) => {
    const {entity, entityDef} = props;
    const {watch, formState} = useContext(FormExtContext);
    const watchDataType = watch("dataType");

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["parameter", "dataType", "format", "nDataPoints"].map(
                        name => renderField(entityFields.series.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["staffs", "method", "dataset", "comment"].map(
                        name => renderField(entityFields.series.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2}>
                    <DataSeriesDataDisplay
                        idSeries={entity.me?.id}
                        dataType={watchDataType}
                        format={entity?.format}
                    />
                    <UsagesDisplay entityId={entity.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default DataSeriesDetail;
