import {Button, Popover} from "antd";
import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import NotificationsContext from "../../../contexts/NotificationsContext";


const CitationButton = ({buttonWrapper, onLoad, children}) => {

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [citation, setCitation] = useState("");
    const {notifyError} = useContext(NotificationsContext);

    const button = children || (
        <Button
            type="primary"
            ghost
            loading={loading}
        >
            Citation
        </Button>
    )

    return (
        <Popover
            content={<span>{citation} <small>(Copied to clipboard)</small></span>}
            trigger="click"
            open={visible}
            zIndex={10000}
            onOpenChange={open => {
                if (!open) {
                    setVisible(false);
                    return;
                }
                setLoading(true);
                onLoad()
                    .then(response => {
                        setCitation(response.html);
                        setVisible(true);
                        setLoading(false);
                        navigator.clipboard
                            .writeText(response.text)
                            .then(
                                function () {
                                },
                                function (err) {
                                    notifyError('Could not copy text', err);
                                });
                    })
                    .catch(error => {
                        setLoading(false);
                        notifyError("Error loading citation", error);
                    })
            }}
        >
            {buttonWrapper &&
                React.cloneElement(buttonWrapper, buttonWrapper.props, button
                )}

            {!buttonWrapper &&
                button
            }
        </Popover>
    )
}

CitationButton.propTypes = {
    buttonWrapper: PropTypes.element,
    onLoad: PropTypes.func
}

export default CitationButton;
