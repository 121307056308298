import {useState} from "react";


const useDelayedState = (defaultValue, delayMs) => {
    const [value, setValue] = useState(defaultValue);
    const [, setTimerId] = useState();

    const setValueDelayed = (newValue) => {
        setTimerId(prevTimerId => {
            if (prevTimerId) {
                clearTimeout(prevTimerId);
            }
            return setTimeout(() => {
                    setValue(newValue);
                    setTimerId(undefined);
                },
                delayMs
            );
        });
    }

    return [
        value,
        setValueDelayed
    ];
}

export default useDelayedState;
