import {Input} from "antd";
import PropTypes from "prop-types";
import {CloseCircleFilled} from "@ant-design/icons";
import {useState} from "react";

const QueryInput = (props) => {
    const {backgroundColor, value, onChange, error, onDirtyChange, inputRef} = props;

    const [queryInput, setQueryInput] = useState(value);
    const [timeoutID, setTimeoutID] = useState();

    /*
    useEffect(() => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            const id = setTimeout(() => {
                onChange(queryInput);
                setTimeoutID(undefined);
            }, 3000);
            setTimeoutID(id);
        },
        // We exclude timeoutID
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryInput]
    );
     */

    return (
        <div>
            <Input
                ref={inputRef}
                style={{
                    borderRadius: '0',
                    width: "100%",
                    backgroundColor: backgroundColor,
                    padding: "2px",
                    borderColor: error ? "#ff0000" : undefined,
                    fontFamily: "monospace",
                }}
                placeholder="Filter table with rsql"
                value={queryInput}
                onChange={e => {
                    setQueryInput(e.target.value);
                    onDirtyChange(true);
                }}
                onPressEnter={() => {
                    if (timeoutID) {
                        clearTimeout(timeoutID)
                        setTimeoutID(undefined);
                    }
                    onDirtyChange(false);
                    onChange(queryInput);
                }}
            />
            {value.length > 0 &&
                <span
                    style={{
                        position: "absolute",
                        marginTop: "5px",
                        marginLeft: "-16px",
                        cursor: "pointer",
                        color: "gray"
                    }}
                    onClick={() => {
                        setQueryInput("");
                        onChange("");
                    }}
                >
                <CloseCircleFilled/>
            </span>
            }
        </div>
    )
}

QueryInput.propTypes = {
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    error: PropTypes.bool
}

export default QueryInput;