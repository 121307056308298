import {Controller} from "react-hook-form";
import {Input} from "antd";
import React, {useContext} from "react";
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import useAutofocus from "../../hooks/useAutofocus";

const {TextArea} = Input;


const TextAreaLimitedField = (props) => {
    const {paramName, maxLength, rules, autoFocus} = props;

    const {setValue, control, instanceId} = useContext(FormExtContext);
    const inputRef = useAutofocus(autoFocus, instanceId);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));

    return (
        <Controller
            name={paramName}
            control={control}
            rules={rules}
            render={({field}) =>
                <div ref={drop}>
                    <DndHighlight isOver={isOver} canDrop={canDrop}/>
                    <TextArea
                        {...field}
                        style={formStyle}
                        showCount
                        maxLength={maxLength}
                        autoSize={{minRows: 1, maxRows: 12}}
                        ref={inputRef || field.ref}
                    />
                    <FormFieldError paramName={paramName}/>
                </div>
            }
        />
    )
}

TextAreaLimitedField.propTypes = {
    paramName: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    rules: PropTypes.object,
    autoFocus: PropTypes.bool
}

export default TextAreaLimitedField;
