import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const BasisOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Callsign" value={entity.callsign}/>
            <OverviewLine label="IMO number" value={entity.imoNumber}/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
        </div>
    )
}

export default BasisOverview;