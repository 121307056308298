import EntityHeader from "../entities/EntityHeader";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import TextAreaField from "../../../tk/input/TextAreaField";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import entityDefs from "../entities/entityDefs";
import TextAreaLimitedField from "../../../tk/input/TextAreaLimitedField";
import {FormExtContext} from "../../../tk/forms/FormExt";


const PanGroupDetail = (props) => {
    const {entity} = props;
    const {formState} = useContext(FormExtContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDefs.panGroup}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    <EntityFormItem label='Name' paramName='name' required mode="textArea">
                        <TextAreaLimitedField maxLength={60} rules={{required: true}} autoFocus={true}/>
                    </EntityFormItem>
                    <EntityFormItem label='Institution' paramName='institution' entityDef={entityDefs.institution}>
                        <SelectEntityField size="tiny"/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    <EntityFormItem label='Comment' paramName='comment'>
                        <TextAreaField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default PanGroupDetail;