import {Controller} from "react-hook-form";
import {Input} from "antd";
import React, {useContext} from "react";
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import useAutofocus from "../../hooks/useAutofocus";


const InputField = (props) => {
    const {paramName, placeholder, options, autoFocus} = props;

    const {setValue, control, instanceId} = useContext(FormExtContext);
    const inputRef = useAutofocus(autoFocus, instanceId);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <div ref={drop}>
                    <DndHighlight canDrop={canDrop} isOver={isOver}/>
                    <Input
                        {...field}
                        style={formStyle}
                        placeholder={placeholder}
                        options={options}
                        ref={inputRef || field.ref}
                    />
                    <FormFieldError paramName={paramName}/>
                </div>
            }
        />
    )
}

InputField.propTypes = {
    paramName: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    autoFocus: PropTypes.bool
}

export default InputField;
