import React from "react";
import PropTypes from "prop-types";


const LinkDatasetFile = (props) => {
    const {idDataset, filename, style, children} = props;
    return (
        <div style={style}>
            <a href={"https://download.pangaea.de/dataset/" + idDataset + "/files/" + encodeURI(filename)}
               target="_blank"
               rel="noreferrer"
               style={style}
            >
                {children}
            </a>
        </div>
    )
}

LinkDatasetFile.propTypes = {
    idDataset: PropTypes.number,
    filename: PropTypes.string,
    style: PropTypes.object
}

export default LinkDatasetFile;
