import {Row} from "antd";

const FormBackground = (props) => {
    const {children} = props;
    return (
        <Row
            gutter={[20, 0]}
            className="space-align-block"
            style={{
                backgroundColor: "white",
                width: "100%",
                paddingLeft: "4px",
                paddingRight: "4px",
                borderRadius: "4px",
                marginLeft: "0px"
            }}
        >
            {children}
        </Row>
    );
}

export default FormBackground;