import {useEffect, useRef} from "react";

const useAutofocus = (autoFocus, instanceId) => {
    const inputRef = useRef(null);
    useEffect(() => {
            const id = setTimeout(() => {
                if (!autoFocus) return;
                inputRef.current?.focus();
            }, 100);
            return () => {
                clearTimeout(id);
            }
        },
        [autoFocus, instanceId]
    );
    return inputRef;
}

export default useAutofocus;