import React from 'react';
import {Tabs} from "antd";
import TextSearchProps from "./TextSearchProps";
import EntitySearchProps from "./EntitySearchProps";
import "./EntityOrTextSearchProps.css";


const EntityOrTextSearchProps = (props) => {
    const {dataIndex, setSelectedKeys, selectedKeys, confirm, clearFilters, entityDef, autofocusRef} = props;
    const autofocusTextRef = React.createRef();
    const tabItems = [
        {
            label: 'Entity Search',
            key: 'entity-search',
            children: <EntitySearchProps
                autofocusRef={autofocusRef}
                dataIndex={dataIndex}
                entityDef={entityDef}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
            />
        },
        {
            label: 'Text Search',
            key: 'text-search',
            children: <TextSearchProps
                autofocusRef={autofocusTextRef}
                dataIndex={dataIndex}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
            />
        }
    ];

    return (
        <Tabs
            items={tabItems}
            size="small"
            onChange={activeKey => {
                if (activeKey === 'entity-search') {
                    setTimeout(() => autofocusRef.current?.focus(), 100);
                } else {
                    setTimeout(() => autofocusTextRef.current?.select(), 100);
                }
            }}
        />
    );
}

export default EntityOrTextSearchProps;
