import {Button, Col, Popconfirm, Row, Space} from "antd";
import React from "react";
import PropTypes from "prop-types";


const DetailButtons = (props) => {
    const {isDirty, onDelete, onRevert, renderItem} = props;
    return (
        <>
            <Row style={{width: '100%'}}>
                <Col span={24} style={{marginTop: "8px"}}>
                    <Space>
                        {renderItem ? renderItem() : ""}
                        {onDelete !== undefined &&
                            <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={onDelete}
                            >
                                <Button danger type='default'>Delete</Button>
                            </Popconfirm>
                        }
                        {onRevert !== undefined &&
                            <Popconfirm
                                title="Are you sure？"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={onRevert}
                            >
                                <Button
                                    type='link'
                                    disabled={!isDirty}
                                >
                                    Revert
                                </Button>
                            </Popconfirm>
                        }
                    </Space>

                </Col>
            </Row>
            <div style={{height: "8px"}}/>
        </>
    )
}

DetailButtons.propTypes = {
    isDirty: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    onRevert: PropTypes.func
}

export default DetailButtons;
