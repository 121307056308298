import {Space} from "antd";
import {useState} from "react";
import ModByType from "./ModByType";


const Mixer = () => {
    const [model, setModel] = useState({
        spec: {
            modName: 'ModOr'
        }
    });
    //console.log("MIXER MODEL", model);
    return (
        <div>
            <Space align="baseline">
                <ModByType
                    isRoot={true}
                    model={model}
                    setModel={setModel}
                />
                {/*
                <pre>
                    {JSON.stringify(model, null, 2)}
                </pre>
                */}
            </Space>
        </div>
    );
}

export default Mixer;
