import EntityHeader from "../entities/EntityHeader";
import React, {useContext, useState} from "react";
import entityDefs from "../entities/entityDefs";
import "./DatasetDetail.css";
import {FormExtContext} from "../../../tk/forms/FormExt";
import useEnumType from "../../../hooks/useEnumType";
import Color from "color";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {Collapse, Modal, Row, Space} from "antd";
import DatasetSteps from "../dataset/DatasetSteps";
import DisabledTooltip from "../../../tk/bits/DisabledTooltip";
import JiraMetadataLookupButton from "../getjirametadata/JiraMetadataLookupButton";
import JiraLink from "../../../tk/bits/JiraLink";
import PangaeaDatasetLink from "../../../tk/bits/PangaeaDatasetLink";
import FormRowContainer from "../../../tk/forms/FormRowContainer";
import TicketInputField from "../../../tk/input/TicketInputField";
import SelectField from "../../../tk/input/SelectField";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import SelectMultiEntityField from "../../../tk/input/SelectMultiEntityField";
import TextAreaField from "../../../tk/input/TextAreaField";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import DatetimeInputField from "../../../tk/input/DatetimeInputField";
import HistoryTableField from "../../../tk/inputComplex/HistoryTableField";
import FormGroupTitle from "../../../tk/forms/FormGroupTitle";
import FileImportButton from "../listimport/FileImportButton";
import {importMatrix, uploadFile} from "../../../lib/importFile";
import MyOk from "../../../tk/bits/MyOk";
import DatasetImportStatus from "../dataset/DatasetImportStatus";
import DatasetClearDataButton from "../dataset/DatasetClearDataButton";
import UsagesDisplay from "./UsagesDisplay";
import TextAreaLimitedField from "../../../tk/input/TextAreaLimitedField";
import ReadonlyField from "./ReadonlyField";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {apiBaseUrl} from "../../../lib/config";
import useDatasetConfig from "../../../hooks/useDatasetConfig";
import DataMatrixForm from "../dataset/DataMatrixForm";
import EnforceDOIRegisterButton from "../dataset/EnforceDOIRegisterButton";
import dayjs from "dayjs";
import CitationButton from "../dataset/CitationButton";
import AttributeTableField from "../../../tk/inputComplex/AttributeTableField";
import InputLimitedField from "../../../tk/input/InputLimitedField";
import useResource from "../../../hooks/useResource";
import EntityTagList from "../../../tk/bits/EntityTagList";
import TemporaryAccessButton from "../dataset/TemporaryAccessButton";
import RelatedReferenceTableField from "../../../tk/inputComplex/RelatedReferenceTableField";
import RelatedDatasetTableField from "../../../tk/inputComplex/RelatedDatasetTableField";
import DatasetStaffsTableField from "../../../tk/inputComplex/DatasetStaffsTableField";
import ButtonsInHeader from "../entities/ButtonsInHeader";
import {requestCitation} from "../../../lib/networkRequests";

const setValueOptions = {
    shouldDirty: true,
    shouldValidate: true,
    shouldTouch: true
};

const DatasetDetail = props => {
    const {entity, entityDef, loadEntity} = props;
    const {onClone} = useContext(MySettingsContext);
    const {setValue, watch, formState, reset, getValues} = useContext(FormExtContext);
    const collectionTypes = useEnumType('collection');
    const datasetStatuses = useEnumType('datasetStatus');
    const loginOptions = useEnumType('loginOption');
    const topotypes = useEnumType('topotype');
    const curationLevels = useEnumType('curationLevel');
    const processingLevels = useEnumType('processingLevel');
    const [importContext, setImportContext] = useState(undefined);
    const groupTitleColor = Color(entityDef.bgColor).darken(0.5);
    const watchSeries = watch("series");
    const watchTicket = watch("ticket");
    const watchDoiRegistered = watch("doiRegistered");
    const watchCollectionType = watch("collectionType");
    const watchStatus = watch("datasetStatus");
    const {ws2Axios} = useWS2Axios();
    const [datasetConfig] = useDatasetConfig(watchSeries);
    const {result: eventsInSeries} = useResource("dataset/" + entity?.me.id + "/events");

    const ticketRequiredCondition = {
        isTrue: watchTicket === undefined || watchTicket === null || watchTicket === "",
        message: "No ticket entered"
    };

    const handleImport = data => {
        for (const [key, value] of Object.entries(data)) {
            const prevVal = getValues(key);
            if (prevVal !== value) {
                setValue(key, value, setValueOptions);
            }
        }
    }

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
                noCreatedUpdated={true}
            />
            {entity?.me &&
                <Row style={{marginBottom: '8px', marginTop: '4px'}}>
                    <DatasetSteps dataset={entity} series={watchSeries} doiRegistered={watchDoiRegistered}/>
                </Row>
            }
            {entity &&
                <ButtonsInHeader>
                    <DisabledTooltip conditions={[ticketRequiredCondition]}>
                        <JiraLink ticket={watchTicket}>JIRA</JiraLink>
                    </DisabledTooltip>
                    <DisabledTooltip
                        conditions={[
                            {isTrue: entity.idDataset === undefined, message: "Dataset is unsubmitted"}
                        ]}
                    >
                        <PangaeaDatasetLink idDataset={entity.idDataset}/>
                    </DisabledTooltip>
                    <DisabledTooltip conditions={[
                        ticketRequiredCondition,
                        {
                            isTrue: formState.isDirty,
                            message: "Dataset has unsaved changes"
                        }
                    ]}>
                        <JiraMetadataLookupButton
                            ticket={watchTicket}
                            onImport={handleImport}
                            datasetId={entity.me?.id}
                        />
                    </DisabledTooltip>
                    <DisabledTooltip
                        conditions={[
                            {isTrue: entity.me?.id === undefined, message: "Dataset is unsubmitted"},
                            {isTrue: formState.isDirty, message: "Dataset has unsaved changes"},
                            {isTrue: watchCollectionType.id !== null, message: 'No data import for collections'}
                        ]}
                    >
                        <FileImportButton
                            modalTitle="Import Data File"
                            uploadHint="Click or drag data matrix to this area"
                            onTest={options =>
                                uploadFile(
                                    ws2Axios,
                                    response => {
                                        if (response.data.matrixMetadata) {
                                            setImportContext({
                                                detectedFormats: response.data.importerStatus?.formats,
                                                matrixMetadata: response.data.matrixMetadata,
                                                counts: response.data.importerStatus?.counts['de.pangaea.importer.processor.Validator'],
                                                hasData: response.data.hasData,
                                                filename: options.file.name
                                            })
                                        }
                                        options.onSuccess(response);
                                    },
                                    response => {
                                        options.onError(response);
                                    },
                                    options.file,
                                    result => {
                                        options.setResult({
                                            ...result,
                                            hint: result.hasData ?
                                                <p>File contains a data matrix. On import, the data of the
                                                    dataset will be discarded and the new data will be imported.
                                                    Metadata will be updated as well.</p>
                                                :
                                                <p>File does not contain a data matrix. On import, the data of
                                                    the dataset will be kept. Metadata will be updated.</p>
                                        })
                                    },
                                    entityDef.apiGet + "/" + entity.me?.id + "/import1"
                                )}
                            onImport={options => {
                                importMatrix(
                                    ws2Axios,
                                    response => {
                                        options.onSuccess(response);
                                        reset(response.data.dataset);
                                    },
                                    response => {
                                        options.onError(response);
                                    },
                                    options.setResult,
                                    entityDef.apiGet + "/" + entity.me?.id + "/import2?hasData=" + importContext.hasData,
                                    importContext
                                );
                            }}
                            customResult={result => (
                                <div>
                                    <h3>Import Initiated <MyOk/></h3>
                                    <DatasetImportStatus idDataset={entity.idDataset} withHint/>
                                </div>
                            )}
                            onClose={success => {
                                if (!success) return;
                                loadEntity()
                                    .then(reset);
                            }}
                        >
                            Import Data File
                        </FileImportButton>
                    </DisabledTooltip>
                    <DisabledTooltip
                        conditions={[
                            {
                                isTrue: watchSeries === undefined || watchSeries.length === 0,
                                message: "No data to clear"
                            }
                        ]}
                    >
                        <DatasetClearDataButton
                            onConfirm={() => {
                                setValue("series", [], setValueOptions);
                                setValue("matrixConfigs", [], setValueOptions);
                                if (watchStatus.id !== -1) {
                                    setValue("datasetStatus", {id: -2, name: "draft"}, setValueOptions);
                                }
                            }}
                        />
                    </DisabledTooltip>
                    <DisabledTooltip
                        conditions={[
                            {isTrue: entity.me?.id === undefined, message: "Dataset is unsubmitted"},
                            {isTrue: formState.isDirty, message: "Dataset has unsaved changes"}
                        ]}
                    >
                        <a href={apiBaseUrl + entityDef.apiGet + "/" + entity.me?.id + "/metaheader/metaheader-" + entity.me?.id + ".txt"}
                           target="_blank"
                           rel="noreferrer"
                           download={"metaheader-" + entity.me?.id + ".txt"}
                        >
                            Save Settings
                        </a>
                    </DisabledTooltip>
                    <TemporaryAccessButton
                        entityRef={entity.me}
                    />
                    <DisabledTooltip
                        conditions={[
                            {isTrue: watchDoiRegistered, message: "DOI is already registered"},
                            {
                                isTrue: !entity.datasetStatus || entity.datasetStatus.id < 4,
                                message: "Dataset must be published"
                            }
                        ]}
                    >
                        <EnforceDOIRegisterButton
                            entityRef={entity.me}
                            onSuccess={() => setValue("doiRegistered", dayjs.utc())}
                        />
                    </DisabledTooltip>
                    <CitationButton
                        onLoad={() =>
                            requestCitation(ws2Axios, entity.me?.id)
                                .then(result => ({
                                    html: <div>{result.data}</div>,
                                    text: result.data
                                }))}
                        buttonWrapper={
                            <DisabledTooltip
                                conditions={[
                                    {isTrue: entity.me?.id === undefined, message: "Dataset is unsubmitted"},
                                    {isTrue: formState.isDirty, message: "Dataset has unsaved changes"}
                                ]}
                            />
                        }
                    />
                </ButtonsInHeader>}

            <Row>
                <Space wrap size={[8, 0]}>
                    <FormRowContainer label="Ticket" paramName='ticket'>
                        <TicketInputField width="170px" autoFocus={true}/>
                    </FormRowContainer>
                    <FormRowContainer label="Status" paramName='datasetStatus' entityDef={entityDefs.datasetStatus}
                                      required helpHref='https://wiki.pangaea.de/wiki/Status'>
                        <SelectField
                            values={datasetStatuses}
                            allowClear={false}
                            onChange={value => {
                                if (value.id === -1 && watchDoiRegistered === true) {
                                    Modal.warning({
                                        title: "Delete a dataset with DOI",
                                        content: 'The dataset will be marked as "deleted". Remove the related data serieses with "Clear Data" button. Please add a replacement dataset in the references section or write a comment.'
                                    });
                                }
                            }}
                        />
                    </FormRowContainer>

                    <FormRowContainer
                        label="Collection Type"
                        paramName='collectionType'
                        entityDef={entityDefs.collectionType}
                        helpHref='https://wiki.pangaea.de/wiki/PANGAEA_publication_types'
                    >
                        <DisabledTooltip
                            conditions={[
                                {
                                    isTrue: watchSeries !== undefined && watchSeries.length > 0,
                                    message: "Data/series not empty"
                                }
                            ]}
                        >
                            <SelectField values={collectionTypes}/>
                        </DisabledTooltip>
                    </FormRowContainer>

                    <FormRowContainer label="Protection" paramName='loginOption' entityDef={entityDefs.loginOption}
                                      required>
                        <SelectField values={loginOptions} allowClear={false}/>
                    </FormRowContainer>
                    <FormRowContainer label="Curation level" paramName='curationLevel'
                                      entityDef={entityDefs.curationLevel}
                                      helpHref='https://wiki.pangaea.de/wiki/Curation_levels'>
                        <SelectField values={curationLevels}/>
                    </FormRowContainer>
                    <FormRowContainer label="Processing level" paramName='processingLevel'
                                      entityDef={entityDefs.processingLevel}
                                      helpHref='https://wiki.pangaea.de/wiki/Processing_levels'>
                        <SelectField values={processingLevels}/>
                    </FormRowContainer>
                    <FormRowContainer label="Moratorium until" paramName='moratoriumUntil'>
                        <DatetimeInputField optionalPresets={[
                            {label: 'In 3 months', value: dayjs().startOf('d').add(3, 'M')},
                            {label: 'In 6 months', value: dayjs().startOf('d').add(6, 'M')},
                            {label: 'In 1 year', value: dayjs().startOf('d').add(1, 'y')},
                            {label: 'In 2 years', value: dayjs().startOf('d').add(2, 'y')},
                        ]}/>
                    </FormRowContainer>

                    <FormRowContainer label="Publication date" paramName='datetimePublication'>
                        <ReadonlyField type="date"/>
                    </FormRowContainer>

                </Space>
            </Row>

            <FormBackground>
                <Collapse bordered={false} defaultActiveKey={['meta']} className="custom-collapse" items={[
                    {
                        key: "meta",
                        label: "Metadata",
                        children: <Row gutter={[20, 0]}>
                            <ResponsiveCardCol no={0} wide>
                                <EntityFormItem label='Title' paramName='title' required mode="textArea">
                                    <TextAreaLimitedField maxLength={255} rules={{required: true}}/>
                                </EntityFormItem>

                                <EntityFormItem label="Author(s)" border={true}
                                                noChildrenProps={true}
                                >
                                    <DatasetStaffsTableField paramName='staffs'/>
                                </EntityFormItem>

                                <EntityFormItem label="Institution" paramName='institution'
                                                entityDef={entityDefs.institution}>
                                    <SelectEntityField size="tiny"/>
                                </EntityFormItem>

                                <EntityFormItem label="Keywords" paramName='keywords'
                                                entityDef={entityDefs.keyword}>
                                    <SelectMultiEntityField/>
                                </EntityFormItem>

                                {watchCollectionType.id !== null &&
                                    <>
                                        <FormGroupTitle color={groupTitleColor}>Collection</FormGroupTitle>
                                        <EntityFormItem label="Journal" paramName='journal'
                                                        entityDef={entityDefs.journal}>
                                            <SelectEntityField size="tiny"/>
                                        </EntityFormItem>

                                        <EntityFormItem label="Volume" paramName='volume'>
                                            <InputLimitedField maxLength={20}/>
                                        </EntityFormItem>
                                    </>
                                }

                                <FormGroupTitle color={groupTitleColor}>Abstract & Comment</FormGroupTitle>

                                <EntityFormItem label='Abstract' paramName='abstractText'
                                                helpHref='https://wiki.pangaea.de/wiki/Abstract'>
                                    <TextAreaField/>
                                </EntityFormItem>

                                <EntityFormItem label="Dataset comment" paramName='comment'>
                                    <TextAreaField/>
                                </EntityFormItem>

                                <EntityFormItem label="Internal comment" paramName='commentInternal'>
                                    <TextAreaField/>
                                </EntityFormItem>


                                <FormGroupTitle color={groupTitleColor}>Projects & Awards</FormGroupTitle>

                                <EntityFormItem label="Projects" paramName='projects'
                                                entityDef={entityDefs.project}>
                                    <SelectMultiEntityField/>
                                </EntityFormItem>

                                <EntityFormItem label="Awards" paramName='awards' entityDef={entityDefs.award}>
                                    <SelectMultiEntityField/>
                                </EntityFormItem>

                                <FormGroupTitle color={groupTitleColor}>Export Filename</FormGroupTitle>

                                <EntityFormItem label="Export Filename" paramName='filename' mode="textArea">
                                    <TextAreaLimitedField maxLength={255}/>
                                </EntityFormItem>

                            </ResponsiveCardCol>

                            <ResponsiveCardCol no={1} wide>
                                <FormGroupTitle color={groupTitleColor}>References</FormGroupTitle>

                                <EntityFormItem label="Related references&nbsp;(literature&nbsp;&&nbsp;data)"
                                                border={true}
                                                noChildrenProps={true}
                                >
                                    <RelatedReferenceTableField paramName='references'/>
                                </EntityFormItem>

                                <EntityFormItem label="Related datasets&nbsp;(PANGAEA&nbsp;data&nbsp;sets)"
                                                border={true}
                                                noChildrenProps={true}
                                >
                                    <RelatedDatasetTableField paramName='datasets' selfId={entity?.me.id}/>
                                </EntityFormItem>

                                <EntityFormItem label="Parent Dataset"
                                                paramName="parentDataset"
                                                entityDef={entityDefs.dataset}>
                                    <SelectEntityField
                                        searchFilter={records => {
                                            if (!entity) return records;
                                            return records.filter(r => r.id !== entity.me.id);
                                        }}
                                    />
                                </EntityFormItem>

                                <FormGroupTitle color={groupTitleColor}>Other</FormGroupTitle>

                                <EntityFormItem label="Alternative title" paramName='alternativeTitle'>
                                    <TextAreaField/>
                                </EntityFormItem>

                                <EntityFormItem label="Attributes" paramName='attributes' border={true}>
                                    <AttributeTableField entityType='dataset/attribute'/>
                                </EntityFormItem>

                                <EntityFormItem label="Topotype" paramName='topotype'
                                                entityDef={entityDefs.topotype}
                                                helpHref='https://wiki.pangaea.de/wiki/Intern:Topologic_type'>
                                    <SelectField values={topotypes}/>
                                </EntityFormItem>

                                <EntityFormItem label='License' paramName='license'
                                                entityDef={entityDefs.license}
                                                helpHref='https://wiki.pangaea.de/wiki/License'>
                                    <SelectEntityField size="tiny" noNewButton={true}/>
                                </EntityFormItem>

                                <EntityFormItem label="Dataset history" paramName='history' border={true}>
                                    <HistoryTableField/>
                                </EntityFormItem>

                                <EntityFormItem label="Group Access" paramName='groupAccess'
                                                entityDef={entityDefs.panGroup}>
                                    <SelectMultiEntityField noNewButton={true}/>
                                </EntityFormItem>

                                <EntityFormItem label="User Access" paramName='userAccess'
                                                entityDef={entityDefs.panUser}>
                                    <SelectMultiEntityField noNewButton={true}/>
                                </EntityFormItem>


                                <FormGroupTitle color={groupTitleColor}>Events</FormGroupTitle>

                                <EntityFormItem label="Extra events" paramName="events" entityDef={entityDefs.event}>
                                    <SelectMultiEntityField/>
                                </EntityFormItem>

                                <EntityFormItem label="Events in series" paramName="" entityDef={entityDefs.event}>
                                    <EntityTagList list={eventsInSeries}/>
                                </EntityFormItem>

                            </ResponsiveCardCol>
                        </Row>
                    },
                    {
                        key: "data",
                        label: "Data",
                        children: (
                            <ResponsiveCardCol no={2} wide>
                                <DataMatrixForm dataset={entity} datasetConfig={datasetConfig}/>
                            </ResponsiveCardCol>
                        )
                    }
                ]}/>
                <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
            </FormBackground>
        </>
    )
}

export default DatasetDetail;
