import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";
import useReload from "./useReload";


const loadDataSeries = (idSeries, ws2Axios, setLoading, setData) => {
    if (idSeries === undefined) {
        return;
    }

    setLoading(true);
    ws2Axios
        .get(apiBaseUrl + "data/" + idSeries)
        .then(response => {
                setData(response.data);
                setLoading(false);
        });
};


export const useSeriesDataSource = (idSeries) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
            loadDataSeries(idSeries, ws2Axios, setLoading, setData);
        },
        [idSeries, ws2Axios]
    );

    return [data, loading];
}


export const useSeriesDataSourceWithReloadTrigger = (idSeries) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const {ws2Axios} = useWS2Axios();

    const {reload, reloadDependency} = useReload();
    const trigger = reload;

    useEffect(() => {
            loadDataSeries(idSeries, ws2Axios, setLoading, setData);
        },
        [idSeries, ws2Axios, reloadDependency]
    );

    return [data, loading, trigger];
}


// export default useSeriesDataSource;