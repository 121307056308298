import {Input, Table} from "antd";
import EntityTag from "../../../tk/bits/EntityTag";
import entityDefs from "../entities/entityDefs";
import React, {useCallback, useContext, useState} from "react";
import {useFieldArray} from "react-hook-form";
import {FormExtContext} from "../../../tk/forms/FormExt";
import {useDrop} from "react-dnd";
import {collectFn, DndHighlight} from "../../../lib/dropIndicatorStyle";
import PropTypes from "prop-types";
import {findIndexWithInstance, formatOptions, newEventRow, newSeriesRow} from "./DataMatrixHorizontal";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import useEntities from "../../../hooks/useEntities";
import {getDataMatrixContext2} from "../detailforms/DatasetDecorator";
import AutocompleteField from "../../../tk/input/AutocompleteField";
import TextAreaField from "../../../tk/input/TextAreaField";
import {randomId} from "../../../lib/random";
import MyAutocomplete from "../../../tk/input/MyAutocomplete";
import EntitySelectPIBulk from "./EntitySelectPIBulk";
import EntitySelectMethodBulk from "./EntitySelectMethodBulk";


const myTagStyle = {maxWidth: "140px"}

const makeId = entityRef => entityRef.id + "-" + entityRef.instanceId;

const DataMatrixVertical = (props) => {
    const {paramName, datasetConfig} = props;
    const {control, watch, setValue, getValues} = useContext(FormExtContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [commentBulkInput, setCommentBulkInput] = useState("");
    const {fields, append, move, remove, swap, insert} = useFieldArray({
        control,
        name: paramName,
    });
    const watchFields = watch(paramName);

    const parameterIds = watchFields ?
        watchFields
            .filter(config => config.parameter.id)
            .map(config => config.parameter.id)
        : [];
    const {entities: parameters} = useEntities(entityDefs.parameter, parameterIds);
    const parametersTrans = {};
    for (const parameter of parameters) {
        parametersTrans[parameter.idParameter] = parameter;
    }

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [
            'ENTITY_' + entityDefs.series.entityType,
            'ENTITY_' + entityDefs.event.entityType
        ],
        collect: collectFn,
        drop: (item, monitor) => {
            if (monitor.didDrop()) return;
            const index = findIndexWithInstance(item.data, watchFields);
            if (index !== -1) {
                move(index, fields.length - 1);
            } else if (item.entityDef === entityDefs.series) {
                append(newSeriesRow(datasetConfig, item.data));
            } else if (item.entityDef === entityDefs.event) {
                append(newEventRow(datasetConfig));
            }
        }
    }), [watchFields, datasetConfig, append, move, fields]);

    const handleTagInsert = useCallback((entityRefNew, insertIndex, watchFields) => {
        const removeIndex = findIndexWithInstance(entityRefNew, watchFields);
        if (removeIndex >= 0) {
            if (removeIndex < insertIndex) {
                insertIndex--;
            }
            move(removeIndex, insertIndex);
            return;
        }

        insert(insertIndex, newSeriesRow(datasetConfig, entityRefNew));
    }, [datasetConfig, insert, move]);

    const handleTagInsertRelative = useCallback((entityRefNew, entityRefRelativeTo, entityDefNew) => {
        const insertIndex = findIndexWithInstance(entityRefRelativeTo, watchFields);
        if (entityDefNew === entityDefs.series) {
            handleTagInsert(entityRefNew, insertIndex, watchFields);
        } else if (entityDefNew === entityDefs.event) {
            insert(insertIndex, newEventRow(datasetConfig));
        }
    }, [datasetConfig, handleTagInsert, insert, watchFields]);

    const handleRemoveById = useCallback(entityRef => {
        remove(findIndexWithInstance(entityRef, watchFields));
    }, [remove, watchFields]);

    const handleTagDuplicate = useCallback((entityRef, index) => {
        insert(
            index + 1,
            {
                ...watchFields[index],
                dataSeries: {
                    ...watchFields[index].dataSeries,
                    instanceId: randomId()
                }
            }
        );
    }, [insert, watchFields]);

    const batchSetValue = (fieldName, value) => {
        for (let index = 0; index < watchFields.length; index++) {
            const field = watchFields[index];
            if (selectedRowKeys.includes(makeId(field.dataSeries))) {
                setValue(`${paramName}.${index}.${fieldName}`, value, {shouldDirty: true});
            }
        }
    };

    const batchSetFirstAuthor = (fieldName) => {
        const firstStaff = getValues('staffs.0.staffs');
        batchSetValue(fieldName, firstStaff);
    }

    const bulkStaffsTitle = (title, fieldName) => {
        if (selectedRowKeys.length === 0) {
            return title;

        } else {
            return <div>
                <div>{title}</div>
                <div>
                    <EntitySelectPIBulk
                        onSelect={value => batchSetValue(fieldName, value)}
                        onClear={() => batchSetValue(fieldName, undefined)}
                        onFirstAuthor={() => batchSetFirstAuthor(fieldName)}
                    />
                </div>
            </div>
        }
    }

    const bulkMethodTitle = (title, fieldName) => {
        if (selectedRowKeys.length === 0) {
            return title;

        } else {
            return <div>
                <div>{title}</div>
                <div>
                    <EntitySelectMethodBulk
                        onSelect={value => batchSetValue(fieldName, value)}
                        onClear={() => batchSetValue(fieldName, undefined)}
                    />
                </div>
            </div>
        }
    }

    const bulkAutocompleteTitle = (title, fieldName) => {
        if (selectedRowKeys.length === 0) {
            return title;
        } else {
            return <div>
                <div>{title}</div>
                <div>
                    <MyAutocomplete
                        placeholder="Update selected"
                        onChange={value => batchSetValue(fieldName, value)}
                        options={formatOptions.flat().map(v => ({
                            label: v, value: v
                        }))}
                    />
                </div>
            </div>
        }
    }

    const bulkTextTitle = (title, fieldName) => {
        if (selectedRowKeys.length === 0) {
            return title;
        } else {
            return <div>
                <div>{title}</div>
                <div>
                    <Input
                        placeholder="Update selected"
                        value={commentBulkInput}
                        onChange={e => {
                            setCommentBulkInput(e.target.value);
                            batchSetValue(fieldName, e.target.value);
                        }}
                    />
                </div>
            </div>
        }
    }

    const columns = [
        {
            title: "Data Series",
            dataIndex: "dataSeries",
            width: 130,
            render: (entityRef, _, index) =>
                <EntityTag
                    entityRef={entityRef}
                    entityDef={entityDefs.series}
                    onRemove={handleRemoveById}
                    onInsert={handleTagInsertRelative}
                    onDuplicate={entityRef => handleTagDuplicate(entityRef, index)}
                    dndDirection="vertical"
                    dropAcceptOverride={[
                        entityDefs.series,
                        entityDefs.event
                    ]}
                />
        },
        {
            title: "Title",
            dataIndex: "title",
            width: 220,
            render: (title, row) => {
                const {
                    title: titleCalc,
                    unit: unitCalc
                } = getDataMatrixContext2(datasetConfig, row, parametersTrans);
                return <>
                    <b style={{textAlign: "center"}}>{titleCalc}</b>{unitCalc &&
                <span>&nbsp;[{unitCalc}]</span>}
                </>;
            }
        },
        {
            title: "Parameter",
            dataIndex: "parameter",
            width: 220,
            render: (entityRef, row, index) => {
                if (row.dataSeries.dummy) return <span/>
                return <SelectEntityField
                    paramName={paramName + '.' + index + '.parameter'}
                    entityDef={entityDefs.parameter}
                    tagStyle={myTagStyle} size="tiny"
                />
            }
        },
        {
            title: () => bulkMethodTitle("Method", "method"),
            dataIndex: "method",
            width: 220,
            render: (entityRef, row, index) => {
                if (row.dataSeries.dummy) return <span/>
                return <SelectEntityField
                    paramName={paramName + '.' + index + '.method'}
                    entityDef={entityDefs.method}
                    tagStyle={myTagStyle} size="tiny"
                />
            }
        },
        {
            title: () => bulkStaffsTitle("PI", "staffs"),
            dataIndex: "staffs",
            width: 220,
            render: (entityRef, row, index) => {
                if (row.dataSeries.dummy) return <span/>
                return <SelectEntityField
                    paramName={paramName + '.' + index + '.staffs'}
                    entityDef={entityDefs.staffs}
                    tagStyle={myTagStyle} size="tiny"
                />
            }
        },
        {
            title: () => bulkTextTitle("Comment", "comment"),
            dataIndex: "comment",
            width: 220,
            render: (comment, row, index) => {
                if (row.dataSeries.dummy) return <span/>
                return <TextAreaField
                    paramName={paramName + '.' + index + '.comment'}
                />
            }
        },
        {
            title: "Config",
            dataIndex: "datasetConfigField",
            width: 220,
            render: (entityRef, row, index) => {
                const {
                    dataTypeIdForConfigOptions,
                    availableConfigs
                } = getDataMatrixContext2(datasetConfig, row, parametersTrans);
                return <SelectEntityField
                    paramName={paramName + '.' + index + '.datasetConfigField'}
                    entityDef={entityDefs.datasetConfigField}
                    size="full"
                    tagStyle={{maxWidth: "140px"}}
                    searchFilter={
                        records => records.filter(
                            record => datasetConfig
                                .fields[record.id]
                                .inputDataType
                                .includes(dataTypeIdForConfigOptions)
                        )
                    }
                    searchLimit={100}
                    onChange={() => swap(0, 0)} // force redraw
                    disabled={!availableConfigs || availableConfigs.length <= 1}
                    noNewButton={true}
                />;
            }
        },
        {
            title: () => bulkAutocompleteTitle("Format", "format"),
            dataIndex: "format",
            width: 220,
            render: (format, row, index) => {
                const {
                    options: formatOptions
                } = getDataMatrixContext2(datasetConfig, row, parametersTrans);
                return <AutocompleteField
                    style={{paddingTop: "4px"}}
                    paramName={paramName + '.' + index + '.format'}
                    placeholder={row.parameter?.format || "Format"}
                    options={formatOptions || []}
                    onChange={() => swap(0, 0)} // Force redraw
                />
            },
        }
    ];

    return (
        <div ref={drop}>
            <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDefs.series.color}
                          color2={entityDefs.series.bgColor}/>
            <Table
                size='small'
                columns={columns}
                dataSource={watchFields}
                rowKey={record => makeId(record.dataSeries)}
                pagination={false}
                sticky
                scroll={{
                    x: 1000
                }}
                rowSelection={{
                    selectedRowKeys,
                    selections: true,
                    fixed: true,
                    columnWidth: 60,
                    onChange: setSelectedRowKeys
                }}
            />
        </div>
    )
}

DataMatrixVertical.propTypes = {
    idDataset: PropTypes.number,
    datasetConfig: PropTypes.object,
    data: PropTypes.array,
    columnWidth: PropTypes.number
}

export default DataMatrixVertical;
