import {Popconfirm} from "antd";
import DetailBarButton from "../../../tk/forms/DetailBarButton";
import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import useWS2Axios from "../../../hooks/useWS2Axios";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {enforceDOIRegistration} from "../../../lib/networkRequests";


const EnforceDOIRegisterButton = (props) => {
    const {entityRef, onSuccess, disabled} = props;
    const {ws2Axios} = useWS2Axios();
    const [loading, setLoading] = useState(false);
    const {notifySuccess, notifyError} = useContext(NotificationsContext);

    return (
        <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
                setLoading(true);
                enforceDOIRegistration(ws2Axios, entityRef.id)
                    .then(() => {
                        setLoading(false);
                        notifySuccess("DOI registration succeeded.");
                        onSuccess();
                    })
                    .catch(error => {
                        setLoading(false);
                        //notifyError("DOI registration error: " + (error.response.data?.error || error.message));
                        notifyError("DOI registration error", error);
                    })
            }}
        >
            <DetailBarButton disabled={disabled} loading={loading}>
                Enforce DOI Registration
            </DetailBarButton>
        </Popconfirm>
    )
}

EnforceDOIRegisterButton.propTypes = {
    entityRef: PropTypes.object,
    disabled: PropTypes.bool,
    onSuccess: PropTypes.func
}

export default EnforceDOIRegisterButton;