import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useModResource = (path, rsql) => {
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
        if (!rsql) {
            setResult([]);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        ws2Axios
            .post(apiBaseUrl + 'query/relations/' + path, {q: rsql})
            .then(response => {
                setResult(response.data);
                setIsLoading(false);
            })
            .catch(e => {
                setResult([]);
                setIsLoading(false);
            })
    }, [ws2Axios, path, rsql]);

    return {result, isLoading};
};

export default useModResource;
