import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import entityDefs from "../pages/editorial/entities/entityDefs";
import useWS2Axios from "./useWS2Axios";


const useDatasetConfigFields = () => {
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
        setLoading(true);
        ws2Axios
            .get(apiBaseUrl + entityDefs.datasetConfigField.apiGet + "/list")
            .then(response => {
                setFields(response.data.records);
                setLoading(false);
            });
    }, [ws2Axios, setFields, setLoading]);

    return {configFields: fields, configFieldsLoading: loading};
};

export default useDatasetConfigFields;
