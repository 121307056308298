import {Button, Col, Row, Table} from "antd";
import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {FormExtContext} from "../../../tk/forms/FormExt";
import ErrorDisplay, {ValidationErrorsDisplay} from "../../../tk/error/ErrorDisplay";
import EntityTagList from "../../../tk/bits/EntityTagList";
import SelectInput from "../../../tk/input/SelectInput";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {entityFields, renderFieldPlain, renderFieldPlainRemove} from "../entities/entityFields";
import SelectField from "../../../tk/input/SelectField";
import {useWatch} from "react-hook-form";
import InputField from "../../../tk/input/InputField";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import {batchRequest} from "../../../lib/networkRequests";
import useWS2Axios from "../../../hooks/useWS2Axios";
import HelpLink from "../../../tk/bits/HelpLink";

export const BatchOps = Object.freeze({
    PATCH: {
        SET: {id: 1, name: 'Set'},
        FIND_REPLACE: {id: 2, name: 'Find & replace'},
        APPEND: {id: 3, name: 'Append'},
        REGEXP_REPLACE: {
            id: 4,
            name: 'Regexp replace',
            helpHref: 'https://en.wikibooks.org/wiki/Regular_Expressions/POSIX_Basic_Regular_Expressions'
        },
        REGEXP_REPLACE_CASEINSENSITIVE: {
            id: 5,
            name: 'ReGeXp RePlAcE',
            helpHref: 'https://en.wikibooks.org/wiki/Regular_Expressions/POSIX_Basic_Regular_Expressions'
        }
    },
    SPECIAL: {
        CLEAR: {id: 6, name: 'Clear'},
        ADD: {id: 7, name: 'Add'},
        REMOVE: {id: 8, name: 'Remove'},
        PARAMETER_ANNOTATOR: {id: 9, name: 'Annotate'},
        DATASET_CONFIG_FORMAT: {id: 10, name: 'Set'},
        DELETE: {id: 11, name: 'Delete'}
    }
});

const BatchColumnSelector = ({entityFields, selectedColumns, onSelect}) => {
    const options = Object.values(entityFields)
        .filter(field => selectedColumns.find(col => col.paramName === field.paramName) === undefined)
        .map(field => ({
            name: field.label,
            id: field.paramName,
            key: field.paramName
        }))
    const sortedNames = options.map(option => option.name).sort();
    const sortedOptions = sortedNames.map(name => options.find(option => option.name === name))

    return (
        <div>
            <SelectInput
                options={sortedOptions}
                overrideValue={{value: null, label: <span><PlusOutlined/> Add batch operation</span>}}
                onChange={e => onSelect(e.id)}
            />
        </div>
    )
}

const StringOperationSelect = ({paramName}) => {
    const {watch} = useContext(FormExtContext);
    const watcher = watch(paramName);
    return (
        <>
            <SelectField
                paramName={paramName}
                values={Object.values(BatchOps.PATCH)}
                style={{
                    width: 150
                }}
                allowClear={false}
            />
            {watcher.helpHref &&
                <HelpLink href={watcher.helpHref}/>
            }
        </>
    );
}

const SpecialOperationSelect = ({paramName, values}) => {
    return (
        <SelectField
            paramName={paramName}
            values={values}
            style={{
                width: 150
            }}
            allowClear={false}
        />
    )
}

const SetOperationSelect = ({paramName}) => (
    <SelectField
        paramName={paramName}
        values={[
            {id: 1, name: "Set"},
        ]}
        style={{
            width: 150
        }}
        allowClear={false}
        disabled
    />
)

const makeOpsParamName = paramName => paramName + ".ops";

const renderInputFields = function (selectedID, BatchOps, column) {
    let operationParams = <></>;
    if (selectedID === BatchOps.PATCH.SET.id) { // SET
        operationParams = renderFieldPlain(column, column.paramName + ".value");
    } else if ([BatchOps.PATCH.FIND_REPLACE.id, BatchOps.PATCH.REGEXP_REPLACE.id, BatchOps.PATCH.REGEXP_REPLACE_CASEINSENSITIVE.id].includes(selectedID)) {
        operationParams = <>
            <InputField placeholder="Find" paramName={column.paramName + ".find"}/>
            <InputField placeholder="Replace" paramName={column.paramName + ".replace"}/>
        </>
    } else if (selectedID === BatchOps.PATCH.APPEND.id) { // Append
        operationParams = <><InputField placeholder="Appendix" paramName={column.paramName + ".append"}/></>
    } else if (selectedID === BatchOps.SPECIAL.ADD.id) {
        const addKey = column.addKey || "value";
        operationParams = renderFieldPlain(column, column.paramName + "." + addKey);
    } else if (selectedID === BatchOps.SPECIAL.CLEAR.id) {
        operationParams = <p>Clear all {column.label}</p>
    } else if (selectedID === BatchOps.SPECIAL.REMOVE.id) {
        const removeKey = column.removeKey || "value";
        operationParams = renderFieldPlainRemove(column, column.paramName + "." + removeKey);
    }
    else if (selectedID === BatchOps.SPECIAL.DATASET_CONFIG_FORMAT.id) {
        operationParams = renderFieldPlain(column, column.paramName + ".value");
    }
    return operationParams;
}


const specialOpsForParam = (paramName) => {
    if (paramName === 'terms.ops') {
        return [BatchOps.SPECIAL.PARAMETER_ANNOTATOR];
    }
    else if (paramName === 'configFormat.ops') {
        return [BatchOps.SPECIAL.DATASET_CONFIG_FORMAT];
    }
    else {
        return [BatchOps.SPECIAL.ADD, BatchOps.SPECIAL.REMOVE, BatchOps.SPECIAL.CLEAR];
    }
};


const specialOpDefaultIdForParam = (paramName) => {
    if (paramName === 'terms.ops') {
        return BatchOps.SPECIAL.PARAMETER_ANNOTATOR.id;
    }
    else if (paramName === 'configFormat.ops') {
        return BatchOps.SPECIAL.DATASET_CONFIG_FORMAT.id;
    }
    else if (['datasetSelf.ops', 'eventSelf.ops'].includes(paramName)) {
        return BatchOps.SPECIAL.DELETE.id;
    }
    else {
        return BatchOps.SPECIAL.ADD.id;
    }
};


const renderOperationSelectorForString = (opsParamName) => {
    if (opsParamName === 'configFormat.ops') {
        return <SpecialOperationSelect
                    paramName={opsParamName}
                    values={specialOpsForParam(opsParamName)}
                />
    }
    else {
        return <StringOperationSelect paramName={opsParamName}/>
    }
};


const renderOperationSelector = function (dataType, BatchOps, opsParamName) {
    switch (dataType) {
        case 'string':
            return renderOperationSelectorForString(opsParamName);
        case 'entityRefList':
            return <SpecialOperationSelect
                paramName={opsParamName}
                values={specialOpsForParam(opsParamName)}
            />

        case 'entityRef':
            if (['datasetSelf.ops', 'eventSelf.ops'].includes(opsParamName)) {
                return <SpecialOperationSelect
                        paramName={opsParamName}
                        values={[BatchOps.SPECIAL.DELETE]}
                    />;
            }
            else {
                return <SetOperationSelect paramName={opsParamName}/>;
            }

        default:
            return <SetOperationSelect paramName={opsParamName}/>;
    }
}

const BatchRow = ({column, onRemove}) => {
    const opsParamName = makeOpsParamName(column.paramName);
    const {control} = useContext(FormExtContext);
    const watchOps = useWatch({name: opsParamName, control});

    return (
        <Row key={column.paramName} align="middle" gutter={[16, 0]} wrap={false}>
            <Col flex="auto">
                <EntityFormItem {...column} noChildrenProps={true} noRevert={true}>
                    {renderInputFields(watchOps.id, BatchOps, column)}
                </EntityFormItem>
            </Col>
            <Col flex="none">
                {renderOperationSelector(column.type.dataType, BatchOps, opsParamName)}
            </Col>
            <Col flex="50px">
                <Button
                    type='text'
                    onClick={onRemove}
                >
                    <DeleteOutlined/>
                </Button>
            </Col>
        </Row>
    );
}

const EditableRows = ({batchColumns, onRemove}) => (

    batchColumns.map((column, index) => (
        <BatchRow
            key={column.paramName}
            column={column}
            onRemove={() => {
                onRemove(index, column.paramName);
            }}
        />
    ))
)


const isArrayWithContent = a => {
    return a !== undefined && Array.isArray(a) && a.length > 0;
};


const handleAllBatchErrors = function (errorResponse, setErrorMessage, entityDef) {
    const validationErrors = errorResponse.response?.data?.validationErrors;
    const generalErrors = errorResponse.response?.data?.generalErrors;
    if (isArrayWithContent(validationErrors)) {
        setErrorMessage(
            <ValidationErrorsDisplay
                errors={validationErrors}
                entityDef={entityDef}
            />
        );
    }
    else if (isArrayWithContent(generalErrors)) {
        setErrorMessage(
            <ErrorDisplay
                message={generalErrors[0]}
            />
        );
    }
    else if ([500].includes(errorResponse.request.status)) {
        setErrorMessage(
            <span style={{color: 'red'}}>{errorResponse.response.data.message}</span>
        )
    }
}
const BatchEditContent = ({onCancel, onOk, entityRefs, entityDef, previewColumns}) => {
    const {
        setValue,
        reset,
        resetField,
        getValues,
        register,
        unregister,
        watch
    } = useContext(FormExtContext);
    const {ws2Axios} = useWS2Axios();
    const [activeColumns, setActiveColumns] = useState([]);
    const [errorMessage, setErrorMessage] = useState(<span/>);
    const [testResult, setTestResult] = useState();
    const [testing, setTesting] = useState(false);
    const [executing, setExecuting] = useState(false);

    const batchEditableFields = useMemo(() => {
            return entityFields[entityDef.entityType]
                .filter(field => field.batchEditable === true);
        },
        [entityDef.entityType]
    );

    useEffect(() => {
            setErrorMessage(<span/>)
        },
        [entityRefs]
    );
    const wa = watch();
    console.log("***********FORM", wa)

    return (
        <>
            <EntityTagList list={entityRefs} entityDef={entityDef}/>
            <div style={{height: '16px'}}/>
            <BatchColumnSelector
                entityFields={batchEditableFields}
                selectedColumns={activeColumns}
                onSelect={paramName => {
                    const field = batchEditableFields.find(field => field.paramName === paramName);
                    setActiveColumns([
                        ...activeColumns,
                        field
                    ]);
                    const fieldType = field.batchType ? field.batchType : field.type
                    register(paramName);
                    // .value is appended internally by react hook form
                    // to the paramName attribute of an input element to indicate the actual value of the input
                    resetField(paramName, {
                        keepDirty: true,
                        defaultValue: {
                            ops: {id: null, name: null},
                            value: fieldType.empty,
                            add: fieldType.empty,  //for separate ADD/REMOVE rendering, e.g. dataset->staffs
                            remove: fieldType.empty,  //for separate ADD/REMOVE rendering, e.g. dataset->staffs
                            find: "",
                            replace: "",
                            append: ""
                        }
                    });
                    const opKey = paramName + ".ops";
                    if (fieldType.special) {
                        // Default (before selection) operation for batch.
                        setValue(opKey, {id: specialOpDefaultIdForParam(opKey)}, {shouldDirty: true});
                    }
                    else if (['datasetSelf.ops', 'eventSelf.ops'].includes(opKey)) {
                        setValue(opKey, {id: specialOpDefaultIdForParam(opKey)}, {shouldDirty: true});
                    }
                    else {
                        setValue(opKey, {id: BatchOps.PATCH.SET.id}, {shouldDirty: true})
                    }
                }}
            />
            <div style={{height: '16px'}}/>
            <EditableRows
                batchColumns={activeColumns}
                onRemove={(index, paramName) => {
                    setActiveColumns(activeColumns.toSpliced(index, 1));
                    resetField(paramName);
                    unregister(paramName);
                    setValue('dirtyCounter', 0, {shouldDirty: true}); // since we unregister the field, the form will not be dirtied
                }}
            />
            {errorMessage}
            <div className="ant-modal-footer">
                <Button type='link' onClick={(e) => {
                    reset();
                    setActiveColumns([]);
                    setTestResult(undefined);
                    setErrorMessage(<span/>);
                }}>
                    Reset
                </Button>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type='primary'
                    disabled={activeColumns.length === 0}
                    loading={testing}
                    onClick={() => {
                        setTesting(true);
                        setTestResult(undefined);
                        const values = getValues();
                        values.dirtyCounter = undefined;
                        batchRequest(ws2Axios, values, entityDef, entityRefs, true)
                            .then((result) => {
                                setErrorMessage(<span/>);
                                setTestResult(result.data);
                                setTesting(false);
                                reset(values);
                            })
                            .catch(response => {
                                handleAllBatchErrors(response, setErrorMessage, entityDef)
                                setTesting(false);
                            })
                    }}
                >
                    Test
                </Button>
                <Button
                    type='primary'
                    disabled={activeColumns.length === 0}
                    loading={executing}
                    onClick={() => {
                        setExecuting(true);
                        const values = getValues();
                        values.dirtyCounter = undefined;
                        batchRequest(ws2Axios, values, entityDef, entityRefs, false)
                            .then((result) => {
                                setErrorMessage(<span/>);
                                setTestResult(undefined);
                                setExecuting(false);
                                onOk();
                            })
                            .catch(response => {
                                handleAllBatchErrors(response, setErrorMessage, entityDef)
                                setExecuting(false);
                            })
                    }}
                >
                    Execute
                </Button>
            </div>
            {testResult && <>
                <div>
                    <h3>Test Result</h3>
                    {testResult.map(res => (
                        <Table
                            key={res.entityType}
                            columns={previewColumns}
                            rowKey={(record) => record[entityDef.idProp]}
                            dataSource={res.entities}
                            size="small"
                            scroll={{
                                x: 300
                            }}
                        />
                    ))}
                </div>
                <div className="ant-modal-footer">
                </div>
            </>}
        </>
    )
}

BatchEditContent.propTypes = {
    onCancel: PropTypes.func
}

export default BatchEditContent;
