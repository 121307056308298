import {collectFn, DndHighlight} from "../../lib/dropIndicatorStyle";
import {Button, Col, Divider, Row, Select, Space} from "antd";
import {formStyle} from "../forms/formStyle";
import React, {useState} from "react";
import {useDrop} from "react-dnd";
import EntityTag from "../bits/EntityTag";
import useEntitySearch from "../../hooks/useEntitySearch";
import EntityCreatorModal from "../../pages/editorial/entities/EntityCreatorModal";
import {nextInstanceId} from "../../pages/editorial/EditorialApp";
import useWS2Axios from "../../hooks/useWS2Axios";
import FilterTag from "../bits/FilterTag";

const {Option} = Select;


const SelectMultiEntityInput = React.forwardRef((props, ref) => {
    const {
        entityDef,
        value: valueControlled,
        onChange: onChangeControlled,
        onPressEnter,
        placeholder,
        style,
        newDefaults,
        noNewButton,
        dropdownOpen: controlledDropdownOpen,
        setDropdownOpen: setControlledDropdownOpen,
        dropdownButtons,
        autofocusRef,
        sortable,
        prefabAxios,
        disableEntitySearch
    } = props;
    const [valueInt, setValueInt] = useState([]);
    const value = (valueControlled ? valueControlled : valueInt)
        .filter(val => val.id !== null);
    const onChange = onChangeControlled ? onChangeControlled : setValueInt;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const entitySearch = useEntitySearch(entityDef.entityType);
    const [newOpen, setNewOpen] = useState(false);
    const [instanceId, setInstanceId] = useState(nextInstanceId());
    const {ws2Axios} = useWS2Axios(prefabAxios);
    const {doRsqlSearch} = useEntitySearch(entityDef.entityType, undefined, 1000, ws2Axios);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [
            'ENTITY_' + entityDef.entityType,
            'LIST_' + entityDef.entityType,
            'FILTER_' + entityDef.entityType
        ],
        collect: collectFn,
        drop: (item, monitor) => {
            if (monitor.didDrop()) return;
            if (Array.isArray(item.data)) {
                handleListAppend(item.data);
            } else if (item.data.rsql) {
                doRsqlSearch(item.data.rsql)
                    .then(items => handleListAppend(items));
            } else {
                let existIndex = findIndex(item.data.id);
                if (existIndex >= 0 && !sortable) return;
                handleTagAppend({
                    ...item.data,
                    key: item.data.id
                });
            }
        }
    }), [value]);

    const wrapSetDropdownOpen = value => setControlledDropdownOpen ?
        setControlledDropdownOpen(value) :
        setDropdownOpen(value);

    const wrapDropdownOpen = controlledDropdownOpen !== undefined ?
        controlledDropdownOpen :
        dropdownOpen;

    let compDropdownButtons = [];
    if (dropdownButtons) {
        compDropdownButtons.push(...dropdownButtons);
    }

    if (!noNewButton) {
        compDropdownButtons.push(
            <Button
                onClick={() => {
                    setNewOpen(true);
                    setInstanceId(nextInstanceId());
                }}
                key="new-button"
            >
                + new
            </Button>
        )
    }
    compDropdownButtons = compDropdownButtons.map((value, index) => ({
        ...value,
        key: index
    }));

    const findIndex = id => {
        for (let i = 0; i < value.length; i++) {
            if (value[i].id === id) {
                return i;
            }
        }
        return -1;
    }
    const handleTagRemove = entityRef => {
        let newValue = [...value];
        let removeIndex = findIndex(entityRef.id);
        newValue.splice(removeIndex, 1);
        onChange(newValue);
    }
    const handleTagInsert = (entityRefNew, insertIndex) => {
        let removeIndex = findIndex(entityRefNew.id);
        let newValue = [...value];
        if (removeIndex >= 0) {
            newValue.splice(removeIndex, 1);
            if (removeIndex < insertIndex) {
                insertIndex--;
            }
        }
        newValue.splice(insertIndex, 0, entityRefNew);
        onChange(newValue);
    }

    const handleTagInsertRelative = (entityRefNew, entityRefRelativeTo) => {
        handleTagInsert(entityRefNew, findIndex(entityRefRelativeTo.id));
    }

    const handleTagAppend = (entityRefNew) => {
        const existIndex = findIndex(entityRefNew.id);
        if (existIndex !== -1) return;
        onChange([...value, entityRefNew]);
    }

    const handleListAppend = tagList => {
        const mapped = tagList
            .filter(entityRef => {
                const exists = value.find(row => row.id === entityRef.id);
                return !exists;
            })
            .map(entityRef => ({
                ...entityRef,
                key: entityRef.id
            }))
        const newValue = [...value, ...mapped];
        onChange(newValue);
    }

    const tagRender = (props) => {
        const {label, value} = props;
        return (
            <EntityTag
                entityRef={{id: value, name: label}}
                entityDef={entityDef}
                onRemove={handleTagRemove}
                onInsert={sortable ? handleTagInsertRelative : handleTagAppend}
                key={value}
            />
        );
    };

    return (
        <div
            data-testid='SelectMultiEntityInput'
            ref={drop}
        >
            <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDef.color} color2={entityDef.bgColor}/>
            <EntityCreatorModal
                visible={newOpen}
                setVisible={setNewOpen}
                entityDef={entityDef}
                newDefaults={newDefaults || {}}
                onCreated={newEntity => {
                    setNewOpen(false);
                    onChange([...value, {...newEntity.me, label: newEntity.me.name, value: newEntity.me.id}]);
                }}
                instanceId={instanceId}
            />
            <Row align="middle">
                <Col>
                    <FilterTag entityDef={entityDef} list={value} size="micro"/>
                </Col>
                <Col flex="auto">
                    <Select
                        ref={ref || autofocusRef}
                        mode="multiple"
                        showSearch
                        placeholder={placeholder}
                        style={{width: '100%', ...formStyle, ...style}}
                        value={value === undefined ?
                            [] :
                            value.map(v => ({
                                value: v.id, label: v.name
                            }))
                        }
                        onChange={newValue => {
                            const mapped = newValue.map(v => ({id: v.value, name: v.label}))
                            onChange(mapped);
                        }}
                        onSearch={searchString => {
                            if (disableEntitySearch) return;
                            entitySearch.doSearch(searchString);
                        }}
                        filterOption={false}
                        notFoundContent={null}
                        loading={entitySearch.isLoading}
                        allowClear={!entitySearch.isLoading}
                        labelInValue={true}
                        tagRender={tagRender}
                        open={wrapDropdownOpen}
                        onDropdownVisibleChange={open => {
                            if (disableEntitySearch) return;
                            if (!open) {
                                wrapSetDropdownOpen(false);
                                return;
                            }
                            if (!value || value.length === 0) {
                                entitySearch.doSearch("").then(() => wrapSetDropdownOpen(true));
                            }
                        }}
                        onDoubleClick={() => wrapSetDropdownOpen(true)}
                        onInputKeyDown={() => {
                            wrapSetDropdownOpen(true);
                        }}
                        onSelect={() => {
                            wrapSetDropdownOpen(false);
                        }}
                        onKeyUp={e => {
                            if (e.key === "Enter" && onPressEnter) {
                                onPressEnter(e);
                            }
                        }}
                        /* Does not work with extra buttons in dropdown
                        onBlur={() => {
                        wrapSetDropdownOpen(false);
                    }}
                        */
                        dropdownRender={
                            compDropdownButtons && compDropdownButtons.length > 0 ?
                                menu => <>
                                    {menu}
                                    <Divider style={{margin: '8px 0',}}/>
                                    <Space style={{padding: '0 8px 4px'}}>
                                        {compDropdownButtons}
                                    </Space>
                                </> :
                                undefined
                        }
                        popupMatchSelectWidth={300}
                    >
                        {/* this goes up one line. but disabled because it pops up, when you click on an entitytag
                onFocus={() => {
                    entitySearch.doSearch("").then(() => setDropdownOpen(true));
                }}
                */}
                        {entitySearch.result.map(value => (
                            <Option key={value.id} value={value.id}>{value.name}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>
        </div>
    )
})

export default SelectMultiEntityInput;