import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const DataSeriesOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Parameter" value={entity.parameter} entityDef={entityDefs.parameter}/>
            <OverviewLine label="Data type" value={entity.dataType} entityDef={entityDefs.dataType}/>
            <OverviewLine label="Format" value={entity.format}/>
            <OverviewLine label="Data points" value={entity.nDataPoints}/>
            <OverviewLine label="PI" value={entity.staffs} entityDef={entityDefs.staffs}/>
            <OverviewLine label="Method" value={entity.method} entityDef={entityDefs.method}/>
            <OverviewLine label="Comment" value={entity.comment}/>
        </div>
    )
}

export default DataSeriesOverview;
