import React from "react";
import FormGroupTitle from "../forms/FormGroupTitle";
import EntityTag from "../bits/EntityTag";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import EntityFormItem from "../forms/EntityFormItem";
import TextAreaField from "../input/TextAreaField";
import {SelectTextInputField} from "../input/SelectTextInputField";


const AttributeFields = ({structure, paramName, fields, remove}) => {
    return structure.map(schema => {
        return (
            <div key={schema.term.id}>
                <FormGroupTitle color={'#303030'}>
                    <div>
                        {schema.term.name} <EntityTag entityRef={schema.term}
                                                      entityDef={entityDefs.term}
                                                      display={'letter'}/>
                    </div>
                </FormGroupTitle>
                {schema.subs.map(sub => (
                    <div key={sub.term.id}>
                        {sub.term.id !== -1 &&
                            <FormGroupTitle color={'#505050'} sub={true}>
                                <div key={sub.term.id}>
                                    {sub.term.name} <EntityTag
                                    entityRef={sub.term}
                                    entityDef={entityDefs.term}
                                    display={'letter'}
                                />
                                </div>
                            </FormGroupTitle>
                        }
                        {sub.attributes.map(attr => {
                            const item = fields[attr.i]
                            return {item, attr};
                        })
                            .filter(({item, attr}) => item !== undefined)
                            .map(({item, attr}) => {
                                const p = paramName + '.' + attr.i + '.value'
                                return (
                                    <div key={item.id}>
                                        {attr.cvTerms &&
                                            <EntityFormItem
                                                label={item.entityRef.name}
                                                paramName={p}
                                                termEntityRef={item.entityRef}
                                                onDelete={() => remove(attr.i)}
                                            >
                                                <SelectTextInputField
                                                    options={attr.cvTerms.map(t => t.name)}
                                                />
                                            </EntityFormItem>
                                        }
                                        {!attr.cvTerms &&
                                            <EntityFormItem
                                                label={item.entityRef.name}
                                                paramName={p}
                                                termEntityRef={item.entityRef}
                                                onDelete={() => remove(attr.i)}
                                            >
                                                <TextAreaField/>
                                            </EntityFormItem>
                                        }
                                    </div>
                                )
                            })}
                    </div>
                ))}
            </div>
        )
    })
}

export default AttributeFields;
