import SelectMultiEntityInput from "../../../../tk/input/SelectMultiEntityInput";
import FilterDialogButtons from "./FilterDialogButtons";


const EntitySearchProps = (props) => {
    const {dataIndex, entityDef, setSelectedKeys, selectedKeys, confirm, clearFilters, autofocusRef} = props;

    return (
        <div style={{paddingBottom: 8}}>
            <SelectMultiEntityInput
                autofocusRef={autofocusRef}
                entityDef={entityDef}
                value={selectedKeys}
                placeholder={`Find ${dataIndex}`}
                style={{
                    marginBottom: "16px"
                }}
                onChange={setSelectedKeys}
                onPressEnter={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    confirm();
                }}
                noNewButton
            />
            <FilterDialogButtons confirm={confirm} clearFilters={clearFilters}/>
        </div>
    );
}

export default EntitySearchProps;
