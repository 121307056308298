import {useRef, useState} from "react";

const SHOW_RENDER_COUNTER = false;
export const useRenderCounter = message => {
    const renderCounter  = useRef(0);
    renderCounter.current = renderCounter.current + 1;
    const getTime = ()=>{
        const currTimeUnformatted = new Date();
        const hours = String(currTimeUnformatted.getHours()).padStart(2,'0');
        const minutes = String(currTimeUnformatted.getMinutes()).padStart(2,'0')
        const seconds = String(currTimeUnformatted.getSeconds()).padStart(2,'0');
        return `${hours}:${minutes}:${seconds}`
    }
    const [counterInitTime] = useState(getTime())
    // if (__DEV__ && SHOW_RENDER_COUNTER){ DEV is not defined !
    if (SHOW_RENDER_COUNTER){
        console.log("COUNTER T ",counterInitTime,renderCounter.current)
            return (
                <span
                    style={{
                        backgroundColor: 'darkgoldenrod',
                        borderRadius: 6,
                        color: 'hsl(0, 0%, 100%)',
                        fontSize: 13,
                        fontWeight: 'bold',
                        height: 40,
                        margin: 2,
                        textAlign: 'center',
                        width: 75}}
                >
            {counterInitTime+message+" "+renderCounter.current}
                </span>

            );
        }
};