import {Radio} from "antd";
import DatetimeLineInput from "./DatetimeLineInput";
import FilterDialogButtons from "./FilterDialogButtons";


const EQ = '==';
const LE = '=le=';
const GE = '=ge=';
const INTERVAL = '[]';

const toFilter = selectedKeys => {
    let filter = {
        mode: EQ,
        value1: '',
        value2: ''
    }
    if (Array.isArray(selectedKeys)) {
        if (selectedKeys.length === 2) {
            filter = {
                mode: INTERVAL,
                value1: selectedKeys[0].value,
                value2: selectedKeys[1].value
            }
        } else if (selectedKeys.length === 1) {
            filter = {
                mode: selectedKeys[0].mode,
                value1: selectedKeys[0].value,
                value2: ''
            }
        }
    }
    return filter;
}

const fromFilter = filter => {
    if (filter.mode === INTERVAL) {
        return [
            {
                mode: GE,
                value: filter.value1
            },
            {
                mode: LE,
                value: filter.value2
            }
        ]
    }

    return [{
        mode: filter.mode,
        value: filter.value1
    }]
}

const DatetimeSearchProps = (props) => {
    const {setSelectedKeys, selectedKeys, confirm, clearFilters, withTime, autofocusRef} = props;
    const filter = toFilter(selectedKeys);

    return (
        <div style={{paddingBottom: 8}}>
            <Radio.Group
                value={filter.mode}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, mode: e.target.value}))}
            >
                <Radio value={EQ}>=</Radio>
                <Radio value={GE}>&gt;=</Radio>
                <Radio value={LE}>&lt;=</Radio>
                <Radio value={INTERVAL}>Interval</Radio>
            </Radio.Group><br/>

            {filter.mode === INTERVAL && <div style={{marginTop: '4px'}}>from</div>}
            <DatetimeLineInput
                autofocusRef={autofocusRef}
                value={filter.value1}
                withTime={withTime}
                onChange={(value) => setSelectedKeys(fromFilter({...filter, value1: value}))}
                style={{
                    marginBottom: "16px",
                }}
                onPressEnter={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    confirm();
                }}
            /><br/>

            {filter.mode === INTERVAL &&
                <div>
                    <div>until</div>
                    <DatetimeLineInput
                        value={filter.value2}
                        withTime={withTime}
                        onChange={(value) => setSelectedKeys(fromFilter({...filter, value2: value}))}
                        style={{
                            marginBottom: "16px",
                        }}
                        onPressEnter={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            confirm();
                        }}
                    /><br/>
                </div>
            }
            <FilterDialogButtons confirm={confirm} clearFilters={clearFilters}/>
        </div>
    );
}

export default DatetimeSearchProps;
