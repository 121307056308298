import {Popconfirm} from "antd";
import DetailBarButton from "../../../tk/forms/DetailBarButton";
import React from "react";
import PropTypes from "prop-types";


const DatasetClearDataButton = (props) => {
    const {onConfirm, disabled} = props;
    return (
        <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onConfirm}
        >
            <DetailBarButton disabled={disabled}>
                Clear Data
            </DetailBarButton>
        </Popconfirm>
    )
}

DatasetClearDataButton.propTypes = {
    onConfirm: PropTypes.func,
    disabled: PropTypes.bool
}

export default DatasetClearDataButton;