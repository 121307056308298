import {Popover, Space} from "antd";
import React, {useContext, useState} from "react";
import useWS2Axios from "../../../hooks/useWS2Axios";
import NotificationsContext from "../../../contexts/NotificationsContext";
import DetailBarDropdown from "../../../tk/forms/DetailBarDropdown";
import {requestTempAccess} from "../../../lib/networkRequests";


const TemporaryAccessButton = (props) => {
    const {entityRef} = props;
    const [popoverOpen, setIsPopoverOpen] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [areKeysDeleted, setAreKeysDeleted] = useState(false);
    const [countDeletedKeys, setCountDeletedKeys] = useState(undefined);
    const {ws2Axios} = useWS2Axios();
    const {notifyError} = useContext(NotificationsContext);
    const [lifeTimeDays, setLifeTimeDays] = useState(180);

    const tempAccessRequest = (action) => {
        setAreKeysDeleted(false); //set default value before each request, otherwise value from previous render persists
        requestTempAccess(ws2Axios, entityRef.id, lifeTimeDays, action
        ).then(response => {
            if (response.status === 200 && action === 'createToken') {
                setIsPopoverOpen(true);
                setAccessToken(response.data.url)
                navigator.clipboard
                    .writeText(response.data.url)
                    .then(
                        function () {
                        },
                        function (err) {
                            notifyError('Could not copy text', err);
                        });
            } else if (response.status === 200 && action === "removeAllTokens") {
                setIsPopoverOpen(true);
                setCountDeletedKeys(response?.data['countDeleted']);
                setAreKeysDeleted(true);
            }
        }).catch(error => {
            if (action === "createToken") {
                notifyError("Couldn't get temporary access token", error?.response?.data?.error);
            } else if (action === "removeAllTokens") {
                notifyError("Couldn't remove all temporary access tokens", error?.response?.data?.error);
            } else {
                notifyError(error?.response?.data?.error);
            }
        })
    }

    return (
        <>
            <Popover
                className='detail-dropdown' //to force transparent background css
                content={
                    areKeysDeleted ? (
                            <Space>
                                deleted<strong>{countDeletedKeys}</strong> access key(s) for all users
                            </Space>) :
                        (
                            <Space>
                                <a href={accessToken} target="_blank" rel="noreferrer">{accessToken}</a>
                                <strong>{`${lifeTimeDays} days`}</strong>
                                <small>(Copied to clipboard)</small>
                            </Space>)
                }
                trigger="click"
                open={popoverOpen}
                onOpenChange={open => {
                    if (!open) {
                        setIsPopoverOpen(false);
                        return;
                    }
                    tempAccessRequest("createToken");
                }}
            >
                <DetailBarDropdown
                    //trigger={['click','hover']} // to close dropdown on the ... icon click
                    lifeTimeDays={lifeTimeDays}
                    setLifeTimeDays={setLifeTimeDays}
                    tempAccessRequest={tempAccessRequest}
                >
                    <Space>
                        Temporary Access Key
                    </Space>
                </DetailBarDropdown>
            </Popover>
        </>
    )
}


export default TemporaryAccessButton;
