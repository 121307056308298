import React from "react";
import {pangaeaColor} from "../../lib/globalColors";
import {Button} from "antd";
import PropTypes from "prop-types";


const logoTextStyle = {
    float: "left",
    width: "250px",
    height: "60px",
    margin: "0px 0px 0px 0",
    color: "white",
    fontSize: "20px"
}

const pangaeaDotStyle = {
    color: pangaeaColor,
    fontSize: "4rem"
}

const PangaeaLogo = ({subtext, onClick}) => {
    const title = process.env.REACT_APP_MODE_TITLE;
    const logoUrl = process.env.REACT_APP_MODE_LOGO_URL;

    const logoImgStyle = {
        float: "left",
        width: "98px",
        height: "60px",
        margin: "2px 4px 0px 0",
        background: "url('" + logoUrl + "')"
    }

    return (
        <>
            <Button onClick={onClick} type="link" style={logoImgStyle}>&nbsp;</Button>
            <span style={logoTextStyle}>{title}<span style={pangaeaDotStyle}>.</span>{subtext}</span>
        </>
    )
}

PangaeaLogo.propTypes = {
    subtext: PropTypes.string,
    onClick: PropTypes.func
}

export default PangaeaLogo;