import React from "react";
import {Typography} from "antd";
import dayjs from "dayjs";
import {columnEnteredSorter} from "./CardColumn";
import ControlBoard from "./ControlBoard";

const {Text} = Typography;

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);


const serviceClassOrder = {
    expedite: 1,
    bug: 3,
    standard: 4
}

const order = card => {
    if (card.tags.includes('Top')) {
        return 2;
    }
    return serviceClassOrder[card.serviceClass];
}

export const prioritySorter = (a, b) => {
    const aOrder = order(a);
    const bOrder = order(b);

    if (aOrder === bOrder) {
        return columnEnteredSorter(a, b);
    } else if (aOrder < bOrder) {
        return -1;
    } else {
        return 1;
    }
}

const serviceClassDefs = {
    'expedite': {
        name: 'expedite',
        label: 'Expedite',
        color: '#ff9fba',
        jiraIssueType: 1,
        jiraLabel: 'Expedite',
        policies: (
            <Text>
                <p><Text strong>Expedite</Text> is for emergency cases, that must be handled immediately.</p>
                <Text strong>Policies</Text>
                <ul>
                    <li>Process immediately</li>
                    <li>Pause other work</li>
                    <li>Only 1 expedite is allowed anytime</li>
                </ul>
            </Text>
        )
    },
    'bug': {
        name: 'bug',
        label: 'Bug',
        color: '#ffd39f',
        jiraIssueType: 1,
        policies: (
            <Text>
                <p><Text strong>Bugs</Text> are malfunctions in the system. Things that do not work as intended.
                </p>
                <Text strong>Policies</Text>
                <ul>
                    <li>Process with priority</li>
                    <li>Process first in/first out</li>
                </ul>
            </Text>
        )
    },
    'standard': {
        name: 'standard',
        label: 'Standard',
        color: '#fff',
        jiraIssueType: 2,
        policies: (
            <Text>
                <p><Text strong>Standard</Text> items are requests for new features, changes to existing
                    functionality, or others.</p>
                <Text strong>Policies</Text>
                <ul>
                    <li>Process first in/first out</li>
                </ul>
            </Text>
        )
    }
}

const columnDefs = {
    'backlog': {
        name: 'Backlog',
        columnName: 'backlog',
        sorter: prioritySorter,
        policies: (
            <Text>
                <p>All new tickets go to the <Text strong>Backlog</Text>. Tickets are pushed here.</p>
                <Text strong>Policies</Text>
                <ul>
                    <li>If <Text strong>Expedite</Text> spawns, gather to discuss, promote to <Text strong>In
                        Development</Text> regardless of limits.
                    </li>
                </ul>
                <Text strong>Bonus</Text>
                <ul>
                    <li>Attach label <Text strong>Top</Text> to have a ticket at the top of the column.</li>
                </ul>
            </Text>
        )
    },
    'todo': {
        name: 'Committed',
        columnName: 'todo',
        sorter: prioritySorter,
        limits: {
            min: 2,
            max: 6
        },
        policies: (
            <Text>
                <p>The dev team is <Text strong>committed</Text> to process these tickets as soon as
                    development capacities become free.</p>
                <Text strong>Policies</Text>
                <ul>
                    <li>The free places in this column are filled in regular <Text strong>replenishment
                        meetings</Text> with tickets from the backlog. E.g., stakeholders could vote for
                        tickets.
                    </li>
                </ul>
                <Text strong>Notice</Text>
                <ul>
                    <li>Attach label <Text strong>Committed</Text> to promote a ticket into this column.
                    </li>
                </ul>
            </Text>
        )
    },
    'inProgress': {
        name: 'In development',
        columnName: 'inProgress',
        sorter: prioritySorter,
        limits: {
            max: 8
        },
        policies: (
            <Text>
                <Text strong>Policies</Text>
                <ul>
                    <li>Stuff is being built ...</li>
                </ul>
            </Text>
        )
    },
    'done': {
        name: 'Done this week',
        columnName: 'done'
    }
};

const BoardOpts = Object.freeze({
    QS: {
        id: -31924, //qualiservice curators
        values: {
            board: 'PMW',
            titleOnCard: true,
            jiraProjectId: 10030,
            components: [10600], //qualiservice component
        }
    },
    DEFAULT: {
        values: {
            board: 'PMW',
            titleOnCard: true,
            jiraProjectId: 10030,
            components: [],
        }
    }
})

const PMWBoardPage = () => {
    return (
        <div style={{padding: "4px"}}>
            <ControlBoard
                title={<h2>Development Process Control</h2>}
                serviceClassDefs={serviceClassDefs}
                columnDefs={columnDefs}
                options={BoardOpts}
            />
        </div>
    )
}

export default PMWBoardPage;