import React from "react";

const FlexLayoutContext = React.createContext({
    refLayout: undefined,
    addEntityDetailTab: undefined, // (entityRef, entityDef) => ...
    addEntityCreateTab: undefined, // (defaultEntity, initEntity, entityDef) => ...
    addDatafileDirectImportTab: undefined, // (fileList) => ...
    addJiraDirectImportTab: undefined,
    addListTab: undefined,
    addListImportTab: undefined
});

export default FlexLayoutContext;

