import {Divider, Select, Space} from "antd";
import React, {useMemo, useState} from "react";
import useEntitySearch from "../../hooks/useEntitySearch";
import PropTypes from "prop-types";
import {useDrop} from "react-dnd";
import {collectFn, DndHighlight} from "../../lib/dropIndicatorStyle";
import {transformSize} from "./SelectEntityField";

const {Option} = Select;


const EntitySelect = (props) => {
    const {entityDef, compDropdownButtons, onSelect, size, placeholder, dropdownVisible, setDropdownVisible} = props;
    const [selectValue, setSelectValue] = useState(undefined);
    const [dropdownVisibleInt, setDropdownVisibleInt] = useState(false);
    const dropdownVisibleWrap = dropdownVisible !== undefined ? dropdownVisible : dropdownVisibleInt;
    const setDropdownVisibleWrap = setDropdownVisible !== undefined ? setDropdownVisible : setDropdownVisibleInt;
    const entitySearch = useEntitySearch(entityDef.entityType);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: 'ENTITY_' + entityDef.entityType,
        collect: collectFn,
        drop: item => {
            setSelectValue({
                value: item.data.id,
                label: item.data.name
            });
            onSelect(item.data);
        }
    }), [setSelectValue, onSelect]);
    const width = useMemo(() => transformSize(size), [size]);

    return (
        <div
            style={width}
            ref={drop}
        >
            <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDef.color} color2={entityDef.bgColor}/>
            <Select
                showSearch
                style={{width: '100%'}}
                value={selectValue}
                onChange={v => {
                    setSelectValue(v);
                    if (v === undefined) {
                        onSelect(undefined);
                    } else {
                        onSelect({id: v.value, name: v.label});
                    }
                }}
                onSearch={s => entitySearch.doSearch(s)}
                filterOption={false}
                notFoundContent={null}
                allowClear={true}
                labelInValue={true}
                placeholder={placeholder || "Add " + entityDef.label}
                onBlur={() => {
                    setSelectValue(undefined);
                }}
                open={dropdownVisibleWrap}
                onDropdownVisibleChange={open => {
                    if (!open) {
                        setDropdownVisibleWrap(false);
                    }
                }}
                onInputKeyDown={() => {
                    setDropdownVisibleWrap(true);
                }}
                onSelect={() => {
                    setDropdownVisibleWrap(false);
                }}
                onFocus={() => {
                    entitySearch.doSearch("").then(() => setDropdownVisibleWrap(true));
                }}
                dropdownRender={menu =>
                    <>
                        {menu}
                        <Divider style={{margin: '8px 0',}}/>
                        <Space style={{padding: '0 8px 4px'}}>
                            {compDropdownButtons}
                        </Space>
                    </>
                }
                popupMatchSelectWidth={300}
            >
                {entitySearch.result.map(value =>
                    <Option key={value.id} value={value.id}>{value.name}</Option>
                )}
            </Select>
        </div>
    )
}

EntitySelect.propTypes = {
    entityDef: PropTypes.object,
    compDropdownButtons: PropTypes.array,
    onSelect: PropTypes.func,
    size: PropTypes.string,
    placeholder: PropTypes.string
}

export default EntitySelect;
