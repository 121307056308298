import React from "react";
import {Button} from "antd";
import {StarFilled, StarTwoTone} from "@ant-design/icons";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {isFilterStarred, setFilterStar} from "../../features/stars/filterStarsSlice";
import {starStyle} from "./Starred";

const StarredFilter = (props) => {
    const {rsql, entityDef} = props;
    const isStarredHere = useSelector(state => isFilterStarred(state.filterStars.starStore, rsql, entityDef.entityType));
    const dispatch = useDispatch();

    if (isStarredHere) {
        return (
            <Button
                type='link'
                style={starStyle}
                onClick={e => {
                    e.stopPropagation();
                    dispatch(setFilterStar({
                        rsql,
                        entityType: entityDef.entityType,
                        isStarred: false
                    }))
                }}
            >
                <StarFilled style={{color: "#AA0"}}/>
            </Button>
        )
    }

    // If not starred
    return (
        <Button
            type='link'
            style={starStyle}
            onClick={e => {
                e.stopPropagation();
                dispatch(setFilterStar({
                    rsql,
                    entityType: entityDef.entityType,
                    isStarred: true
                }))
            }}
        >
            <StarTwoTone twoToneColor='#AA0'/>
        </Button>
    )
}

StarredFilter.propTypes = {
    entityDef: PropTypes.object,
    rsql: PropTypes.string
}

export default StarredFilter;