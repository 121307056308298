import EntityHeader from "../entities/EntityHeader";
import React, {useContext} from "react";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import FormBackground from "../../../tk/forms/FormBackground";
import SelectMultiEntityField from "../../../tk/input/SelectMultiEntityField";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import entityDefs from "../entities/entityDefs";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {entityFields, renderField} from "../entities/entityFields";


const StaffsDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["nameLast", "nameFirst", "email", "orcid"].map(
                        name => renderField(entityFields.staffs.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["phone", "uri", "institution"].map(
                        name => renderField(entityFields.staffs.find(field => field.paramName === name))
                    )}
                    <EntityFormItem label='Keywords' paramName='keywords' entityDef={entityDefs.keyword}>
                        <SelectMultiEntityField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2}>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default StaffsDetail;
