import {Button, Empty} from "antd";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import FilterTag from "./FilterTag";
import {useDispatch, useSelector} from "react-redux";
import {clearFilterStars} from "../../features/stars/filterStarsSlice";
import {DeleteOutlined} from "@ant-design/icons";


const StarredFilters = () => {
    const starStoreFilters = useSelector(state => state.filterStars.starStore);
    const dispatch = useDispatch();

    if (!starStoreFilters || starStoreFilters.length === 0) {
        return <Empty description="There are no starred filters"/>
    }

    //console.log("StarredFilters", starStoreFilters)
    return (
        <div>
            {Object.keys(starStoreFilters)
                .map(key => {
                    const filterEntityDef = entityDefs[key];
                    if (starStoreFilters[key].length === 0) {
                        return <span key={key}/>
                    }
                    return (
                        <div key={key}>
                            <h5 style={{
                                marginTop: "8px",
                                marginBottom: "2px",
                                color: 'rgb(153, 153, 153)',
                                fontSize: '12px'
                            }}>
                                {filterEntityDef.labelPl}
                                <Button
                                    type='link'
                                    onClick={() => {
                                        dispatch(clearFilterStars({
                                            entityType: filterEntityDef.entityType
                                        }))
                                    }}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </h5>
                            {starStoreFilters[key]
                                .filter(rsql => rsql !== null)
                                .map(rsql =>
                                    <FilterTag key={key + rsql} rsql={rsql} entityDef={filterEntityDef}/>
                                )}
                        </div>
                    );
                })}
        </div>
    )
}

export default StarredFilters;
