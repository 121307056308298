import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const CampaignOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Name (optional)" value={entity.nameOptional}/>
            <OverviewLine label="Comment" value={entity.comment}/>
            <OverviewLine label="Date begin" value={entity.datetimeBegin} type="date"/>
            <OverviewLine label="Date end" value={entity.datetimeEnd} type="date"/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Basis" value={entity.basis} entityDef={entityDefs.basis}/>
            <OverviewLine label="Project" value={entity.project} entityDef={entityDefs.project}/>
            <OverviewLine label="Reference" value={entity.reference} entityDef={entityDefs.reference}/>
            <OverviewLine label="Staffs" value={entity.staffs} entityDef={entityDefs.staffs}/>
        </div>
    )
}

export default CampaignOverview;