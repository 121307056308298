import {Select} from "antd";
import {formStyle} from "../forms/formStyle";
import React, {forwardRef} from "react";

const {Option} = Select;


const SelectTextInput = forwardRef(
    ({onChange, value, options, disabled, allowClear, style}, ref) => {

        const filterOption = (input, option) => (option?.children ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());

        return (
            <Select
                style={{width: "200px", ...formStyle, ...style}}
                value={value}
                onChange={v => {
                    onChange(v);
                }}
                allowClear={(allowClear === undefined) ? true : allowClear}
                disabled={disabled}
                ref={ref}
                showSearch
                filterOption={filterOption}
            >
                {options.map(value => <Option key={value} value={value}>{value}</Option>)}
            </Select>
        );
    });

export default SelectTextInput;