import React, {useContext} from "react";
import EntityHeader from "../entities/EntityHeader";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {entityFields, renderField} from "../entities/entityFields";


const AwardDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
                helpHref='https://wiki.pangaea.de/wiki/Intern:Award'
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["title", "awardNumber", "institution"].map(
                        name => renderField(entityFields.award.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["project", "uri", "comment"].map(
                        name => renderField(entityFields.award.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2}>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default AwardDetail;
