import {Controller} from "react-hook-form";
import dayjs from "dayjs";
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import {formStyle} from "../forms/formStyle";
import FormFieldError from "../forms/FormFieldError";
import React, {useContext} from "react";
import {FormExtContext} from "../forms/FormExt";


const DatetimeInputField = (props) => {
    const {paramName, disabled, optionalPresets} = props;
    const {control} = useContext(FormExtContext);
    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) => {
                const {onBlur, name, value, ref} = field;
                const DatePicker = generatePicker(dayjsGenerateConfig);
                const defaultPresets = [{label: 'Now (UTC)', value: dayjs().utc()}]
                //dataFormatList - Date matching when typing, for pasted format recognition
                const dateFormatList = ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD', 'YYYY-MM', 'YYYY', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm']
                return (<>
                    <DatePicker
                        style={{minWidth: '200px', ...formStyle}}
                        showNow={false}
                        presets={Array.isArray(optionalPresets) && optionalPresets.length ? defaultPresets.concat(optionalPresets) : defaultPresets}
                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            //this format for TimePicker overrides format in DatePicker, use with dateFormatList
                            format: "HH:mm:ss",
                        }}
                        onChange={v => {
                            if (v === null) {
                                field.onChange(null);
                            } else {
                                field.onChange(v.format('YYYY-MM-DDTHH:mm:ss'))
                            }
                        }}
                        onBlur={onBlur}
                        name={name}
                        value={value === null || value === undefined ? null : dayjs(value)}
                        ref={ref}
                        disabled={disabled}
                        format={dateFormatList}
                    />
                    <FormFieldError paramName={paramName}/>
                </>)
            }}
        />
    )
}

export default DatetimeInputField;
