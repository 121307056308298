const FormGroupTitle = (props) => {
    const {children, color, sub} = props;
    if (sub) {
        return (
            <h4
                style={{
                    //paddingTop: "12px",
                    color: color,
                    fontWeight: "1000",
                    marginBottom: "12px"
                }}
            >
                {children}
            </h4>
        )
    }
    return (
        <h3
            style={{
                paddingTop: "12px",
                color: color,
                fontWeight: "1000"
            }}
        >
            {children}
        </h3>
    )
}

export default FormGroupTitle;