import {Button, Modal, Popover, Space, Tag} from "antd";
import React, {useContext, useMemo, useState} from "react";
import FlexLayoutContext from "../../contexts/FlexLayoutContext";
import {commonStyle} from "./EntityTagNonInteractive";
import {DownloadOutlined, SelectOutlined, UnorderedListOutlined} from "@ant-design/icons";
import OverviewLine from "../../pages/editorial/overviews/OverviewLine";
import Color from "color";
import StarredFilter from "./StarredFilter";
import PropTypes from "prop-types";
import {useDrag} from "react-dnd";
import useWS2Axios from "../../hooks/useWS2Axios";
import overviewRsqlButtons from "../../pages/editorial/entities/overviewRsqlButtons";
import ListExport from "../../pages/editorial/listexport/ListExport";
import BetaTag from "./BetaTag";


export const listToRsql = (list) => {
    if (!list || list.length === 0) return undefined;
    const ids = list
        .map(entityRef => entityRef.id)
        .filter(v => v !== undefined)
        .reduce((acc, curr) => {
            if (!acc) {
                return curr.toString();
            }
            return acc + ',' + curr;
        }, undefined);
    return "id=in=(" + ids + ")";
}

// If list is given, then it will override rsql
const FilterTag = ({rsql, list, entityDef, children, extraStyle, size}) => {
    const rsqlWrapped = list ? listToRsql(list) : rsql;
    const {addListTab} = useContext(FlexLayoutContext);
    const [visible, setVisible] = useState(false);
    const {ws2Axios} = useWS2Axios();
    const [exportOpen, setExportOpen] = useState(false);

    const [, drag] = useDrag(() => {
            if (list) {
                return ({
                    type: 'LIST_' + entityDef?.entityType,
                    item: {
                        entityDef,
                        data: list
                    }
                })
            }
            return ({
                type: 'FILTER_' + entityDef?.entityType,
                item: {
                    entityDef,
                    data: {rsql: rsqlWrapped}
                }
            });
        },
        [list, entityDef, rsqlWrapped]
    );

    const moreButtons = overviewRsqlButtons[entityDef.entityType];

    const title = useMemo(() => (
            <div
                style={{
                    color: Color(entityDef.color).darken(0).saturate(1)
                }}
            >
                {entityDef.labelPl} Filtered List
                <Space style={{paddingLeft: '20px'}}>
                    <Button type="link" style={{padding: '0'}} onClick={() => {
                        setVisible(false);
                        addListTab(entityDef, rsqlWrapped);
                    }}>
                        <SelectOutlined/>
                    </Button>
                    <StarredFilter rsql={rsqlWrapped} entityDef={entityDef}/>
                    <Button type="link" style={{padding: '0'}} onClick={() => {
                        setExportOpen(true);
                        setVisible(false);
                    }}>
                        <DownloadOutlined/>
                    </Button>
                    {moreButtons && React.cloneElement(moreButtons(rsqlWrapped, entityDef, ws2Axios))}
                </Space>
            </div>
        ),
        [addListTab, entityDef, moreButtons, rsqlWrapped, ws2Axios]
    );

    const borderStyle = ['mini', 'micro'].includes(size) ? 'none' : 'solid';

    const content = useMemo(() => (
            <div>
                <OverviewLine label="rsql" value={rsqlWrapped} max={30}/>
            </div>
        ),
        [rsqlWrapped]
    );

    if ((!list || list.length === 0) && size === 'micro') {
        return <span/>
    }

    if (list && list.length === 0) {
        return (
            <Tag
                data-testid='FilterTag'
                style={{
                    ...commonStyle(entityDef),
                    borderStyle: borderStyle,
                    ...extraStyle
                }}
            >
                <div>
                    {children}
                    {!children &&
                        <>
                            <UnorderedListOutlined data-testid='FilterTag-ListIcon'/>
                            <span> 0 entities</span>
                        </>
                    }
                </div>
            </Tag>
        )
    }
    return (
        <>
            <Modal
                title={<span>List Export <BetaTag/></span>}
                open={exportOpen}
                footer={null}
                width="80%"
                maskClosable={false}
                onCancel={() => setExportOpen(false)}
                destroyOnClose={true}
                bodyStyle={{paddingLeft: "0", paddingRight: "0", paddingBottom: "0"}}
            >
                <ListExport entityDef={entityDef} rsql={rsqlWrapped} onClose={() => setExportOpen(false)}/>
            </Modal>
            <Popover
                content={content}
                title={title}
                trigger="click"
                placement="bottomLeft"
                open={visible}
                onOpenChange={setVisible}
                destroyTooltipOnHide={true}
                zIndex={10000}
                overlayInnerStyle={{paddingTop: "8px", maxWidth: "700px"}}
                autoAdjustOverflow={true}
            >
                <Tag
                    data-testid='FilterTag'
                    ref={drag}
                    style={{
                        ...commonStyle(entityDef),
                        cursor: "pointer",
                        borderStyle: borderStyle,
                        ...extraStyle
                    }}
                    onDoubleClick={(e) => {
                        e.stopPropagation();
                        addListTab(entityDef, rsqlWrapped);
                    }}
                >
                    <div>
                        {children}
                        {!children &&
                            <>
                                <UnorderedListOutlined data-testid='FilterTag-ListIcon'/>
                                {size !== 'mini' && size !== 'micro' &&
                                    rsqlWrapped
                                }
                                {size === 'mini' && list &&
                                    <span> {list.length} entities</span>
                                }
                            </>
                        }
                    </div>
                </Tag>
            </Popover>
        </>
    )
}

FilterTag.propTypes = {
    rsql: PropTypes.string,
    list: PropTypes.array,
    entityDef: PropTypes.object,
    extraStyle: PropTypes.object
}

export default FilterTag