import {Button, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const FilterDialogButtons = (props) => {
    const {confirm, clearFilters} = props;
    return (
        <Space>
            <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined/>}
                size="small"
                style={{
                    width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => {
                    clearFilters && clearFilters();
                    confirm();
                }}
                size="small"
                style={{
                    width: 90,
                }}
            >
                Clear
            </Button>
        </Space>
    )
}

FilterDialogButtons.propTypes = {
    confirm: PropTypes.func,
    clearFilters: PropTypes.func
}

export default FilterDialogButtons;