import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const ReferenceOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Authors" value={entity.staffs} entityDef={entityDefs.staffs}/>
            <OverviewLine label="Title" value={entity.title}/>
            <OverviewLine label="Source" value={entity.source}/>
            <OverviewLine label="Journal" value={entity.journal} entityDef={entityDefs.journal}/>
            <OverviewLine label="Year" value={entity.year}/>
            <OverviewLine label="Volume" value={entity.volume}/>
            <OverviewLine label="Pages" value={entity.pages}/>
            <OverviewLine label="Serial number" value={entity.serialNumber}/>
            <OverviewLine label="Reference status" value={entity.referenceStatus} entityDef={entityDefs.referenceStatus}/>
            <OverviewLine label="Reference type" value={entity.referenceType} entityDef={entityDefs.referenceType}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term}/>
            <OverviewLine label="Comment" value={entity.comment}/>
        </div>
    )
}

export default ReferenceOverview;