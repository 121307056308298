import {Button, Popover} from "antd";
import React from "react";
import {pangaeaColor} from "../../../lib/globalColors";


const PoliciesButton = ({content, backgroundColor}) => {
    if (!content) return <></>
    const wrappedContent = (
        <div
            style={{
                maxWidth: '250px'
            }}
        >
            {content}
        </div>
    )
    return (
        <Popover
            trigger='click'
            content={wrappedContent}
        >
            <Button
                size='small'
                style={{
                    backgroundColor,
                    fontWeight: 'bolder',
                    fontSize: 'smaller',
                    color: pangaeaColor
                }}
            >
                P
            </Button>
        </Popover>
    );
}

export default PoliciesButton;
