import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useEntityCreate = (entityDef) => {
    const {ws2Axios} = useWS2Axios();

    const createEntity = (data, onSuccess, onFinish, onError) => {
        ws2Axios
            .post(apiBaseUrl + entityDef.apiGet, data)
            .then(response => {
                const newEntity = response.data;
                if (onSuccess) {
                    onSuccess(newEntity);
                }
                if (onFinish) {
                    onFinish();
                }
            })
            .catch(({response}) => {
                if (onError) {
                    onError(response?.data);
                }
                if (onFinish) {
                    onFinish();
                }
            })
    }

    return {createEntity}
}

export default useEntityCreate;