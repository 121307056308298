const mixerMods = {
    global: {
        entityTypes: [
            'dataset',
            'event',
            'parameter',
            'term',
            'institution',
            'terminology',
            'journal',
            'staffs',
            'reference',
            'location',
            'campaign',
            'keyword',
            'method',
            'project',
            'basis',
            'award',
            'panUser',
            'panGroup',
            'funder',
            'series'
        ],
        options: [
            {
                label: 'Or',
                modName: 'ModOr'
            },
            {
                label: 'And',
                modName: 'ModAnd'
            },
            {
                label: 'Not',
                modName: 'ModNot'
            },
        ]
    },
    award: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'award/institution'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'award/project'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'award/datasetsReverse'
            },
        ]
    },
    basis: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'basis/institution'
            },
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'basis/campaignsReverse'
            },
        ]
    },
    campaign: {
        options: [
            {
                label: "Bases",
                modName: 'ModServerTransform',
                entityTypeOutput: 'basis',
                apiPath: 'campaign/basis'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'campaign/project'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'campaign/reference'
            },
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'campaign/staffs'
            },
            {
                label: "Attributes",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'campaign/attributes'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'campaign/eventsReverse'
            },
        ]
    },
    dataset: {
        options: [
            {
                label: "Awards",
                modName: 'ModServerTransform',
                entityTypeOutput: 'award',
                apiPath: 'dataset/awards'
            },
            {
                label: "Authors",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'dataset/staffs'
            },
            {
                label: "Extra events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'dataset/events'
            },
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'dataset/institution'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'dataset/keywords'
            },
            {
                label: "Journals",
                modName: 'ModServerTransform',
                entityTypeOutput: 'journal',
                apiPath: 'dataset/journal'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'dataset/projects'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'dataset/references'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'dataset/datasets'
            },
            {
                label: "Datasets reverse",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'dataset/datasetsReverse'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'dataset/seriesReverse'
            },
        ]
    },
    event: {
        options: [
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'event/campaign'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'event/project'
            },
            {
                label: "Locations",
                modName: 'ModServerTransform',
                entityTypeOutput: 'location',
                apiPath: 'event/termLocation'
            },
            {
                label: "Methods",
                modName: 'ModServerTransform',
                entityTypeOutput: 'method',
                apiPath: 'event/method'
            },
            {
                label: "Attributes",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'event/attributes'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'event/keywords'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'event/datasetsReverse'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'event/seriesReverse'
            },
        ]
    },
    institution: {
        options: [
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'institution/keywords'
            },
            {
                label: "Journals",
                modName: 'ModServerTransform',
                entityTypeOutput: 'journal',
                apiPath: 'institution/journalsReverse'
            },
            {
                label: "Groups",
                modName: 'ModServerTransform',
                entityTypeOutput: 'panGroup',
                apiPath: 'institution/panGroupsReverse'
            },
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'institution/staffsReverse'
            },
            {
                label: "Bases",
                modName: 'ModServerTransform',
                entityTypeOutput: 'basis',
                apiPath: 'institution/basesReverse'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'institution/projectsReverse'
            },
            {
                label: "Awards",
                modName: 'ModServerTransform',
                entityTypeOutput: 'award',
                apiPath: 'institution/awardsReverse'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'institution/datasetsReverse'
            },
        ]
    },
    journal: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'journal/institution'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'journal/keywords'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'journal/datasetsReverse'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'journal/referencesReverse'
            },
        ]
    },
    keyword: {
        options: [
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'term/keywordDatasetsReverse'
            },
            {
                label: "Parameters",
                modName: 'ModServerTransform',
                entityTypeOutput: 'parameter',
                apiPath: 'term/keywordParametersReverse'
            },
            {
                label: "Journals",
                modName: 'ModServerTransform',
                entityTypeOutput: 'journal',
                apiPath: 'term/keywordJournalsReverse'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'term/keywordReferencesReverse'
            },
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'term/keywordInstitutionsReverse'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'term/keywordEventsReverse'
            },
        ]
    },
    location: {
        options: [
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'term/locationEventsReverse'
            },
        ]
    },
    method: {
        options: [
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'method/reference'
            },
            {
                label: "Terms",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'method/terms'
            },
            {
                label: "Parameters",
                modName: 'ModServerTransform',
                entityTypeOutput: 'parameter',
                apiPath: 'method/parametersReverse'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'method/seriesReverse'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'method/eventsReverse'
            },
        ]
    },
    panGroup: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'panGroup/institution'
            },
            {
                label: "Users",
                modName: 'ModServerTransform',
                entityTypeOutput: 'panUser',
                apiPath: 'panGroup/panUsersReverse'
            },
        ]
    },
    panUser: {
        options: [
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'panUser/staffs'
            },
            {
                label: "Groups",
                modName: 'ModServerTransform',
                entityTypeOutput: 'panGroup',
                apiPath: 'panUser/groups'
            },
        ]
    },
    parameter: {
        options: [
            {
                label: "Methods",
                modName: 'ModServerTransform',
                entityTypeOutput: 'method',
                apiPath: 'parameter/method'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'parameter/reference'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'parameter/keywords'
            },
            {
                label: "Terms",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'parameter/terms'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'parameter/dataSeriesReverse'
            },
        ]
    },
    project: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'project/institution'
            },
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'project/staffs'
            },
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'project/campaignsReverse'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'project/eventsReverse'
            },
            {
                label: "Awards",
                modName: 'ModServerTransform',
                entityTypeOutput: 'award',
                apiPath: 'project/awardsReverse'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'project/datasetsReverse'
            },
        ]
    },
    reference: {
        options: [
            {
                label: "Journals",
                modName: 'ModServerTransform',
                entityTypeOutput: 'journal',
                apiPath: 'reference/journal'
            },
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'reference/staffs'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'reference/keywords'
            },
            {
                label: "Parameters",
                modName: 'ModServerTransform',
                entityTypeOutput: 'parameter',
                apiPath: 'reference/parametersReverse'
            },
            {
                label: "Methods",
                modName: 'ModServerTransform',
                entityTypeOutput: 'method',
                apiPath: 'reference/methodsReverse'
            },
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'reference/campaignsReverse'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'reference/datasetsReverse'
            },
        ]
    },
    series: {
        options: [
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'series/dataset'
            },
            {
                label: "Methods",
                modName: 'ModServerTransform',
                entityTypeOutput: 'method',
                apiPath: 'series/method'
            },
            {
                label: "Parameters",
                modName: 'ModServerTransform',
                entityTypeOutput: 'parameter',
                apiPath: 'series/parameter'
            },
            {
                label: "Staffs",
                modName: 'ModServerTransform',
                entityTypeOutput: 'staffs',
                apiPath: 'series/staffs'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'series/events'
            },
            {
                label: "Terms",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'series/terms'
            },
        ]
    },
    staffs: {
        options: [
            {
                label: "Institutions",
                modName: 'ModServerTransform',
                entityTypeOutput: 'institution',
                apiPath: 'staffs/institution'
            },
            {
                label: "Keywords",
                modName: 'ModServerTransform',
                entityTypeOutput: 'keyword',
                apiPath: 'staffs/keywords'
            },
            {
                label: "Users",
                modName: 'ModServerTransform',
                entityTypeOutput: 'panUser',
                apiPath: 'staffs/panUsersReverse'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'staffs/dataSeriesReverse'
            },
            {
                label: "Projects",
                modName: 'ModServerTransform',
                entityTypeOutput: 'project',
                apiPath: 'staffs/projectsReverse'
            },
            {
                label: "References",
                modName: 'ModServerTransform',
                entityTypeOutput: 'reference',
                apiPath: 'staffs/referencesReverse'
            },
            {
                label: "Datasets",
                modName: 'ModServerTransform',
                entityTypeOutput: 'dataset',
                apiPath: 'staffs/datasetsReverse'
            },
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'staffs/campaignsReverse'
            },
        ]
    },
    term: {
        options: [
            {
                label: "Terminologies",
                modName: 'ModServerTransform',
                entityTypeOutput: 'terminology',
                apiPath: 'term/terminology'
            },
            {
                label: "Campaigns",
                modName: 'ModServerTransform',
                entityTypeOutput: 'campaign',
                apiPath: 'term/campaignsReverse'
            },
            {
                label: "Events",
                modName: 'ModServerTransform',
                entityTypeOutput: 'event',
                apiPath: 'term/eventsReverse'
            },
            {
                label: "Methods",
                modName: 'ModServerTransform',
                entityTypeOutput: 'method',
                apiPath: 'term/methodsReverse'
            },
            {
                label: "Parameters",
                modName: 'ModServerTransform',
                entityTypeOutput: 'parameter',
                apiPath: 'term/parametersReverse'
            },
            {
                label: "Series",
                modName: 'ModServerTransform',
                entityTypeOutput: 'series',
                apiPath: 'term/seriesReverse'
            },
        ]
    },
    terminology: {
        options: [
            {
                label: "Terms",
                modName: 'ModServerTransform',
                entityTypeOutput: 'term',
                apiPath: 'term/termsReverse'
            }
        ]
    }
}

mixerMods.funder = mixerMods.institution;

export default mixerMods;