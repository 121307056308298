import {apiBaseUrl} from "./config";


const sendEditorialAPIRequest = (reqFunction, apiPath, onSuccess, onError) => {
    const url = apiBaseUrl + apiPath;

    return reqFunction(url)
        .then(response => {
            if (200 === response.status) {
                if (onSuccess) {
                    onSuccess(response);
                }
            } else {
                if (onError) {
                    onError(response);
                }
            }
        })
        .catch(ex => {
            console.log('Exception:', ex);

            if (onError) {
                onError(ex.response);
            }
        });
};


// Example call:
// https://editorial.pangaea.de/rest2/parameter/527308/suggest-annotation
export const suggestParameterAnnotation = (myAxios, parameter_id, onSuccess, onError) => {
    const apiPath = 'parameter/' + encodeURIComponent(parameter_id) + '/suggest-annotation';
    const reqFunction = url => {
        return myAxios.get(url, { withCredentials: true });
    };

    return sendEditorialAPIRequest(reqFunction, apiPath, onSuccess, onError);
};


// Example call:
// POTS https://editorial.pangaea.de/rest2/parameter/normalise-parameter-terms
export const normaliseParameterTerms = (myAxios, jsonPayload, onSuccess, onError) => {
    const apiPath = 'parameter/normalise-parameter-terms';
    const reqFunction = url => {
        return myAxios.post(url, jsonPayload);
    };

    return sendEditorialAPIRequest(reqFunction, apiPath, onSuccess, onError);
};