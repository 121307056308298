import PropTypes from "prop-types";
import DecimalFormat from "../../lib/DecimalFormat";

const FormattedNumber = (props) => {
    const {value, format, style} = props;
    if (format === undefined) {
        return value.toString();
    }
    return (
        <div style={style}>
            {new DecimalFormat(format === null ? "" : format).format(value)}
        </div>
    );
}

FormattedNumber.propTypes = {
    value: PropTypes.number,
    format: PropTypes.string,
    style: PropTypes.object
}

export default FormattedNumber;