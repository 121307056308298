import FormExt, {FormExtContext} from "../../tk/forms/FormExt";
import EntityTag from "../../tk/bits/EntityTag";
import React, {useCallback, useContext, useMemo} from "react";
import {Button} from "antd";
import SelectEntityField from "../../tk/input/SelectEntityField";
import TableField2, {findUnsortedIndex} from "../../tk/inputComplex/TableField2";
import entityDefs from "../editorial/entities/entityDefs";
import TextAreaField from "../../tk/input/TextAreaField";
import EntityFormItem from "../../tk/forms/EntityFormItem";
import SelectMultiEntityField from "../../tk/input/SelectMultiEntityField";
import FilterTag from "../../tk/bits/FilterTag";
import SelectInstitutionWithAffiliationDefault from "../../tk/input/SelectStaffsWithAffiliationDefault";
import {smallTagStyle} from "../../tk/inputComplex/inputComplexLib";
import NumberInputField from "../../tk/input/NumberInputField";
import InputCheckField from "../../tk/input/InputCheckField";

export const renderEntityRef = entityDef => {
    return entityRef => {
        if (!entityRef || entityRef.id === null) {
            return <span/>
        }
        return <EntityTag
            entityRef={entityRef}
            entityDef={entityDef}
            style={smallTagStyle}
        />
    };
}

export const renderEditableEntityRef = (entityDef, paramName, name) => {
    return (entityRef, _, index) =>
        <SelectEntityField
            paramName={paramName + '.' + index + '.' + name}
            entityDef={entityDef}
            size='tiny'
            tagStyle={smallTagStyle}
        />
}

export const renderEditableInstitutionWithAffiliationDefault = (paramName, name, staffsName, getValues, updateDefaultStaffsInst) => {
    return (entityRef, _, index) => {
        return <SelectInstitutionWithAffiliationDefault
            paramName={paramName + '.' + index + '.' + name}
            staffsRef={getValues(paramName + '.' + index + '.' + staffsName)}
            size='tiny'
            tagStyle={smallTagStyle}
            updateDefaultStaffsInst={updateDefaultStaffsInst}
        />
    }
}

export const renderEditableEntityRefList = (entityDef, paramName, name) => {
    return (entityRef, _, index) =>
        <SelectMultiEntityField
            paramName={paramName + '.' + index + '.' + name}
            entityDef={entityDef}
        />
}


const renderTextArea = (paramName, name, disabled, getValues, subParamName) => {
    return (_, row, index) => {
        let origIndex = index;
        if (getValues) {
            origIndex = findUnsortedIndex(row.key, getValues, paramName, subParamName);
        }
        return <TextAreaField
            paramName={paramName + '.' + origIndex + '.' + name}
            disabled={disabled}
        />;
    }
}


export const renderTextAreaCustom = (paramName, name, disableFunc, getValues, subParamName) => {
    return (_, row, index) => {
        let origIndex = index;
        let disabled = true;
        if (getValues) {
            origIndex = findUnsortedIndex(row.key, getValues, paramName, subParamName);
            disabled = disableFunc !== undefined ? disableFunc(origIndex) : true;
        }

        return <TextAreaField
            paramName={paramName + '.' + origIndex + '.' + name}
            disabled={disabled}
        />;
    }
}


export const renderEditableTextArea = (paramName, name, getValues, subParamName) => {
    return renderTextArea(paramName, name, false, getValues, subParamName);
}


export const renderNumberInputCustom = (paramName, name, min, styleOverride, disableFunc, getValues, subParamName) => {
    return (text, row, index) => {
        let origIndex = index;
        let disabled = true;
        if (getValues) {
            origIndex = findUnsortedIndex(row.key, getValues, paramName, subParamName);
            disabled = disableFunc !== undefined ? disableFunc(origIndex) : true;
        }

        return <NumberInputField
            min={min ? min : undefined}
            paramName={paramName + '.' + origIndex + '.' + name}
            styleOverride={styleOverride}
            disabled={disabled}
        />;
    }
};


export const renderNumberInput = (paramName, name, min, styleOverride, disabled) => {
    return (text, _, index) => {
        return <NumberInputField
            min={min ? min : undefined}
            paramName={paramName + '.' + index + '.' + name}
            styleOverride={styleOverride}
            disabled={disabled}
        />;
    }
};


export const renderEditableNumberInput = (paramName, name, min, styleOverride) => {
    return renderNumberInput(paramName, name, min, styleOverride, /* disabled */ false);
};


export const renderCheckbox = (paramName, name, disabled) => {
    return (text, _, index) => {
        return <InputCheckField
            paramName={paramName + '.' + index + '.' + name}
            disabled={disabled}
        />;
    }
}


export const renderEditableCheckbox = (paramName, name) => {
    return renderCheckbox(paramName, name, /* disabled */ false);
}

const TestForm = (props) => {
    const {getValues} = useContext(FormExtContext);
    const renderComment = useMemo(() => {
            return renderTextArea('table', 'comment', false, getValues, 'staffs')
        },
        [getValues]
    )
    const columns = useMemo(() => (
            [
                {
                    title: 'ID',
                    dataIndex: 'staffs',
                    align: 'right',
                    render: entityRef => entityRef?.id
                },
                {
                    title: 'Staffs',
                    dataIndex: 'staffs',
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.staffs)
                },
                {
                    title: 'Affiliation',
                    dataIndex: 'institution',
                    entityDef: entityDefs.institution,
                    render: renderEditableEntityRef(entityDefs.institution, 'institution'),
                    multiEdit: 'entityRef'
                },
                {
                    title: 'Projects',
                    dataIndex: 'projects',
                    entityDef: entityDefs.project,
                    render: renderEditableEntityRefList(entityDefs.project, 'projects'),
                    multiEdit: 'entityRefList'
                },
                {
                    title: 'Comment',
                    dataIndex: 'comment',
                    render: renderComment,
                    multiEdit: 'text'
                }
            ]),
        [renderComment]
    );

    const filterList = useMemo(() => ([
            {id: 13000, name: 'Paula'},
            {id: 14000, name: 'Britta'},
            {id: 15000, name: 'Johanna'}
        ]),
        []
    );

    const newRowFn = useCallback(entityRef => {
            return {
                //key: entityRef.id,
                staffs: entityRef,
                projects: [],
                institution: {id: null, name: null},
                comment: 'default'
            }
        },
        []
    )
    return (
        <>
            <EntityFormItem label="Table" paramName='table' entityDef={entityDefs.staffs} border={true}>
                <TableField2
                    columns={columns}
                    newRowFn={newRowFn}
                />
            </EntityFormItem>

            <EntityFormItem label="Staffs" paramName='staffs' entityDef={entityDefs.staffs}>
                <SelectMultiEntityField sortable={true}/>
            </EntityFormItem>

            <EntityFormItem label='FilterTag list' entityDef={entityDefs.staffs}>
                <FilterTag list={filterList}/>
            </EntityFormItem>

            <EntityFormItem label='FilterTag rsql' entityDef={entityDefs.staffs}>
                <FilterTag rsql='id>3'/>
            </EntityFormItem>

            <div>
                <Button type='primary' htmlType='submit'>
                    Submit
                </Button>
            </div>
        </>
    )
}

const TestPage2 = (props) => {

    const defaultEntity = useMemo(() => (
            {
                table: [
                    {
                        key: 9000,
                        staffs: {id: 9000, name: 'Tom'},
                        institution: {id: 5, name: 'Monster AG'},
                        projects: [{id: 19, name: null}, {id: 20, name: null}],
                        comment: 'Hello'
                    },
                    {
                        key: 10000,
                        staffs: {id: 10000, name: 'Jimmy'},
                        comment: 'World'
                    },
                    {
                        key: 11000,
                        staffs: {id: 11000, name: 'Tim'},
                        projects: [{id: 19, name: null}, {id: 21, name: null}],
                        comment: '!'
                    },
                    {
                        key: 12000,
                        staffs: {id: 12000, name: 'Benny'},
                        comment: 'Lalala'
                    }
                ],
                staffs: []
            }
        ),
        []
    );

    const handleUpdate = useCallback(data => {
            console.log("UPDATE", data)
        },
        []
    );

    return <>
        <h1>TableField2 Demo</h1>
        <FormExt
            defaultEntity={defaultEntity}
            updateEntity={handleUpdate}
        >
            <TestForm/>
        </FormExt>
    </>
}

export default TestPage2;