import JiraLink from "../../../tk/bits/JiraLink";
import {Col, Row, Space, Tag, Typography} from "antd";
import dayjs from "dayjs";
import hashCode from "../../../lib/hashCode";
import Color from "color";

const {Text} = Typography;
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);


const UserOnCard = ({user}) => {
    let url = user.url;
    if (url === null) {
        url = 'https://issues.pangaea.de/secure/useravatar?size=medium&avatarId=10072'
    }
    return <img
        style={{
            width: '24px',
            borderRadius: '50%'
        }}
        src={url}
        alt={user.name}
    />
}

const AgeDots = ({age}) => {
    const arr = [];
    for (let i = 0; i < age; i++) {
        arr.push(
            <div
                key={'dot-' + i}
                style={{
                    lineHeight: '6px',
                    width: '1px',
                    fontSize: '20px',
                    color: "darkred"
                }}
            >·</div>
        );
    }
    return (
        <Space
            wrap={true}
            align='center'
            size={[6, 0]}
        >
            {arr} {age > 20 &&
            <span style={{
                fontWeight: 'normal',
                color: 'darkred',
                fontSize: '10px'
            }}>{age}</span>
        }
        </Space>
    )
}

const componentColor = component => {
    const hash = hashCode(component);
    const color = Color.rgb(16, 142, 233).darken(0.3);
    return color.rotate(hash);
}

const tagColor = tag => {
    switch (tag) {
        case 'AWI':
            return '#00ace5';
        case 'MARUM':
            return '#004f8b';
        default:
            return '#108ee9';
    }
}

export const calcAge = (beginDate, endDate) => {
    return dayjs.duration(
        dayjs(endDate).diff(beginDate)
    )
        .asDays().toFixed(0);
}

const CardOnBoard = ({card, serviceClassDefs, options}) => {
    if (!serviceClassDefs) return <div>UNDEF</div>
    const age = calcAge(card.datetimeColumnEntered, dayjs())

    return (
        <div
            style={{
                borderStyle: 'solid',
                borderWidth: '0px',
                boxShadow: '4px 4px 15px #ccc',
                padding: '4px',
                paddingBottom: '0',
                paddingRight: '8px',
                backgroundColor: '#fff',
                borderLeftWidth: '16px',
                borderColor: serviceClassDefs[card.serviceClass].color
            }}
        >
            <Row
                justify='space-between'
                align='middle'
                gutter={[8, 0]}
            >
                <Col>
                    <JiraLink ticket={card.ticket}/>
                </Col>
                <Col>
                    <div
                        style={{
                            fontSize: '12px'
                        }}
                    >
                        {card.userCreated.name}
                    </div>
                </Col>
            </Row>
            {options?.titleOnCard &&
                <div
                    style={{
                        paddingTop: '6px',
                        paddingBottom: '6px'
                    }}
                >
                    <div style={{
                        fontWeight: 'normal',
                        fontSize: '1.0rem',
                        color: '#444',
                    }}>
                        {card.summary}
                    </div>
                </div>
            }
            {!options?.titleOnCard &&
                <div>
                </div>
            }
            <Space wrap={true} size={[0, 4]} style={{marginTop: '2px', marginBottom: '2px'}}>
                {card.components.map(component =>
                    <Tag key={'component-' + component + card.ticket}
                         color={componentColor(component)}>{component}</Tag>
                )}
            </Space>
            <Space wrap={true} size={[0, 4]} style={{marginTop: '2px', marginBottom: '2px'}}>
                {card.tags.map(tag =>
                    <Tag
                        key={'tag-' + tag + card.ticket}
                        color={tagColor(tag)}
                    >
                        <Text
                            style={{
                                color: '#fff',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                maxWidth: '100px'
                            }}
                            ellipsis={true}
                        >
                            {tag}
                        </Text>
                    </Tag>
                )}
            </Space>
            <Row
                justify='space-between'
                align='middle'
                gutter={[8, 8]}
                wrap={false}
            >
                <Col>
                    {age < 100 &&
                        <AgeDots age={age}/>
                    }
                    {age >= 100 &&
                        <Space
                            align='center'
                            size={[6, 0]}
                            style={{
                                marginTop: '4px',
                                paddingBottom: '6px'
                            }}
                        >
                            <span style={{
                                fontWeight: 'normal',
                                color: 'darkred',
                                fontSize: '16px'
                            }}>{age} days</span>
                        </Space>
                    }
                </Col>
                <Col>
                    {card.assignee.id !== null &&
                        <UserOnCard user={card.assignee}/>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default CardOnBoard;