import {Col, Collapse, Row, Spin, Table} from "antd";
import React, {useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import EntityTag from "../../../tk/bits/EntityTag";
import entityDefs from "../entities/entityDefs";
import FormattedNumber from "../../../tk/bits/FormattedNumber";
import {useSeriesDataSourceWithReloadTrigger} from "../../../hooks/useSeriesDataSource";
import FormattedDatetime from "../../../tk/bits/FormattedDatetime";
import LinkDatasetFile from "../../../tk/bits/LinkDatasetFile";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MyError from "../../../tk/bits/MyError";
import {
    recalculateNumericDataSeries,
    searchReplaceStringDataSeries,
    regexReplaceStringDataSeries,
    replaceIdDataSeries,
    editDataSeries,
    checkDataSeriesJob
}
    from "../../../lib/editDataSeries";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {canEditDataSeries} from "../../../lib/config";

import {
    basicNormalStyle,
    basicErrorStyle,
    dataTypeUtil,
    validator,
    InfoAreaController
} from "./DataSeriesDataDisplayCommon";
import {NumericDataSeriesEditor} from "./DataSeriesNumericEditor";
import {StringDataSeriesEditor} from "./DataSeriesStringEditor";
import {IdDataSeriesEditor} from "./DataSeriesIdEditor";


const sequenceColumn = {
    title: 'Sequence',
    dataIndex: 'sequence',
    align: 'right',
};

const numberColumn = (title, dataIndex, format) => ({
    title,
    dataIndex,
    align: 'right',
    render: value =>
        <nobr><FormattedNumber value={value} format={format}/></nobr>
});


const entityTagColumn = (entityDef, title, dataIndex) => ({
        title,
        dataIndex,
        align: 'right',
        render: value =>
            <EntityTag
                entityRef={value}
                entityDef={entityDef}
            />
    });

const stringColumn = (title, dataIndex) => ({
    title,
    dataIndex,
    render: value =>
        <nobr>{value}</nobr>
});

const fileColumn = (title, dataIndex, idDataset) => ({
    title,
    dataIndex,
    render: value =>
        <nobr><LinkDatasetFile idDataset={idDataset} filename={value}>{value}</LinkDatasetFile></nobr>
});

const bytesColumn = (title, dataIndex) => ({
    title,
    dataIndex,
    render: value =>
        <nobr>{value.substring(2)}</nobr>
});

const datetimeColumn = (format) => ({
    title: 'Value',
    dataIndex: 'value',
    align: 'right',
    render: value =>
        <FormattedDatetime value={value} format={format}/>
});

const statusColumn = {
    title: 'Status',
    dataIndex: 'dataStatus',
    align: 'center',
    render: entityRef =>
        <EntityTag entityRef={entityRef} entityDef={entityDefs.dataStatus}/>
};


const DataSeriesDataDisplay = (props) => {
    const {idSeries, dataType, format} = props;
    const [data, loading, trigger] = useSeriesDataSourceWithReloadTrigger(idSeries)
    const {watch} = useContext(FormExtContext);
    const watchDataset = watch("dataset");

    const [testLoaded, setTestLoaded] = useState(false);

    const [fieldErrorText, setFieldErrorText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [infoText, setInfoText] = useState('');

    const [collapseActiveKeys, setCollapseActiveKeys] = useState([]);

    const infoAreaFields = {
        fieldErrorText: fieldErrorText,
        setFieldErrorText: setFieldErrorText,
        errorText: errorText,
        setErrorText: setErrorText,
        infoText: infoText,
        setInfoText: setInfoText
    };

    const infoAreaController = new InfoAreaController(infoAreaFields);

    const [searchItem, setSearchItem] = useState(undefined);
    const [replaceItem, setReplaceItem] = useState(undefined);
    const {ws2Axios} = useWS2Axios();


    // TODO: Should this depend on the status of a data series resp. of its enclosing data set?
    const dataSeriesEditingEnabled = canEditDataSeries === 'true';

    // This functionality will be supported as soon as the
    // corresponding REST-endpoints are available.
    const editPreview = false;

    const initialTableSelect = {
        selectedRowKeys: [],
        loading: false
    };

    const [tableSelect, setTableSelect] = useState(initialTableSelect);

    const { selectedTableRowKeys, tableLoading } = tableSelect;

    const [selectedRowData, setSelectedRowData] = useState(undefined);

    const enabledRowSelection = {
        type: 'radio',
        selectedTableRowKeys,
        onChange: (selectedRowKeys) => {
            const selectedData = makeSelectedRowData(data, selectedRowKeys);
            console.log('Selected rows', selectedRowKeys);
            console.log('Selected data', selectedData);

            setTableSelect({
                ...tableSelect,
                selectedRowKeys: selectedRowKeys
            });

            setSelectedRowData(selectedData);
        }
    };


    const getSelectedRowIndex = (data, selectedRowKeys) => {
        if (selectedRowKeys === undefined) {
            return undefined;
        }
        else {
            const len = selectedRowKeys.length;

            if (len !== 1) {
                return undefined;
            }
            else {
                const rowKey = selectedRowKeys[0];
                for (let idx = 0; idx < data.length; idx++) {
                    if (data[idx].sequence === rowKey) {
                        return idx;
                    }
                }

                return undefined;
            }
        }
    };

    const makeSelectedRowData = (data, selectedRowKeys) => {
        const len = data.length;
        const rowIndex = getSelectedRowIndex(data, selectedRowKeys);
        if (rowIndex === undefined) {
            return undefined;
        }
        // This should not happen.
        else if (rowIndex < 0 || rowIndex >= len) {
            return undefined;
        }
        else {
            return data[rowIndex];
        }
    };

    const disabledRowSelection = undefined;

    const [tableRowSelection, setTableRowSelection] = useState(disabledRowSelection);

    const switchRowSelectionMode = (enabled) => {
        if (enabled) {
            setTableRowSelection(enabledRowSelection);
        }
        else {
            setTableRowSelection(disabledRowSelection);
            setSelectedRowData(undefined);
        }
    };

    // TODO: BEGIN - Move this code to separate modules.

    const reportFieldError = (error) => {
        infoAreaFields.setFieldErrorText(error);
        infoAreaFields.setErrorText('');
        infoAreaFields.setInfoText('');
    };


    const reportAPIError = (error) => {
        infoAreaFields.setFieldErrorText('');
        infoAreaFields.setErrorText(error);
        infoAreaFields.setInfoText('');
    };


    const reportAPISuccess = (text) => {
        infoAreaFields.setFieldErrorText('');
        infoAreaFields.setErrorText('');
        infoAreaFields.setInfoText(text);
    };


    const clearAllInfo = () => {
        infoAreaFields.setFieldErrorText('');
        infoAreaFields.setErrorText('');
        infoAreaFields.setInfoText('');
    };


    const setAllInfo = (info) => {
        infoAreaFields.setFieldErrorText(info.fieldErrorText);
        infoAreaFields.setErrorText(info.errorText);
        infoAreaFields.setInfoText(info.infoText);
    };


    const validateNonZeroNumber = (value, setStyle) => {
        const validation = validator.nonZeroNumberValidation(value);
        setStyle(validation.style);
        setAllInfo(validation.info);

        return validation.valid;
    };

    const validateNumber = (value, setStyle) => {
        const validation = validator.numberValidation(value);

        setStyle(validation.style);
        setAllInfo(validation.info);

        return validation.valid;
    };

    const nonEmptyStringStyle = (value) => {
        const ok = value !== undefined && value !== '';
        if (ok) {
            return basicNormalStyle;
        }
        else {
            return basicErrorStyle;
        }
    }

    const isItemDefined = (item) => {
        return item && item.id && item.name;
    };

    const fieldErrorComputedText = (fieldInfo) => {
        if (infoAreaFields.fieldErrorText !== '') {
            return infoAreaFields.fieldErrorText;
        }

        const dataType = fieldInfo.dataType;
        if (dataTypeUtil.isIdDataType(dataType)) {
            const description = dataTypeUtil.isTermDataType(dataType) ? 'term' : 'event';
            const checkSearchItem = fieldInfo.checkSearchItem;
            const checkReplaceItem = fieldInfo.checkReplaceItem;
            const searchDefined = isItemDefined(checkSearchItem);
            const replaceDefined = isItemDefined(checkReplaceItem);

            if (!searchDefined) {
                return 'Please select a search ' + description + '.';
            }
            else if (!replaceDefined) {
                return 'Please select a replace ' + description + '.';
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    const validateNonEmptySearchPattern = (value) => {
        const ok = validator.nonEmptyText(value);
        if (ok) {
            clearAllInfo();

            return true;
        }
        else {
            reportFieldError('Please enter a nonempty search pattern.');

            return false;
        }
    };

    const validateNonEmptyItems = (searchItem, replaceItem) => {
        const description = dataTypeUtil.isTermDataType(dataType) ? 'term' : 'event';
        const searchDefined = isItemDefined(searchItem);
        const replaceDefined = isItemDefined(replaceItem);
        if (searchDefined && replaceDefined) {
            clearAllInfo();

            return true;
        }
        else if (searchDefined) {
            reportFieldError('Please select a replace ' + description + '.');

            return false;
        }
        else {
            reportFieldError('Please select a search ' + description + '.');

            return false;
        }
    };


    const validateEditItems = (oldItem, newItem) => {
        const article = dataTypeUtil.isTermDataType(dataType) ? 'a' : 'an';
        const description = dataTypeUtil.isTermDataType(dataType) ? 'term' : 'event';
        const aDescription = article + ' ' + description;

        const searchDefined = isItemDefined(oldItem);
        const replaceDefined = isItemDefined(newItem);
        if (searchDefined && replaceDefined) {
            clearAllInfo();

            return true;
        }
        else if (searchDefined) {
            reportFieldError('Please select a replace ' + description + '.');

            return false;
        }
        else {
            reportFieldError('Please select ' + aDescription + ' in the table.');

            return false;
        }
    };

    // TODO: END - Move this code to separate modules.

    const performTest = (infoTextPrefix) => {
        if (infoAreaFields.fieldErrorText !== '') {
            console.log(infoTextPrefix + ': Invalid state => skipping.');

            reportAPIError('Invalid input => test run skipped.');
        }
        else {
            console.log(infoTextPrefix);

            setTestLoaded(true);
        }
    }

    const onScaleTest = (factor, offset) => {
        // TODO: These must be implemented when the preview endpoints are available.
        performTest('Scale test [' + factor + ', ' + offset + ']');
    };

    const onReplTest = (searchPattern, replacePattern) => {
        // TODO: These must be implemented when the preview endpoints are available.
        validateNonEmptySearchPattern(searchPattern);

        performTest('Replace test [' + searchPattern + ', ' + replacePattern + ']');
    };

    const onRegTest = (searchPattern, replacePattern) => {
        // TODO: These must be implemented when the preview endpoints are available.
        performTest('Reg. ex. test [' + searchPattern + ', ' + replacePattern + ']');
    };

    const onTest = () => {
        switch (dataType && dataType.id) {

            default:
                console.log("ERROR, no test for datatype", dataType);
        }
    };


    const onCheckSuccess = (response) => {
        const data = response?.data;
        const hasRunningUpdates = data !== undefined && data.hasRunningUpdates;

        if (hasRunningUpdates) {
            const timeout = 5000;
            const promise = new Promise(resolve => setTimeout(resolve, timeout));

            promise.then(() => checkDataSeriesJob(ws2Axios, idSeries, onCheckSuccess, onCheckError));
        }
        else {
            const reloadTrigger = trigger;
            if (reloadTrigger !== undefined) {
                console.log('Reloading table.');

                reloadTrigger();
            }
        }
    };


    const onCheckError = (response) => {
        console.log('Error:', response);
    };


    const performRun = (infoTextPrefix, runFunction) => {
        if (infoAreaFields.fieldErrorText !== '') {
            console.log(infoTextPrefix + ': Invalid state => skipping.');

            reportAPIError('Invalid state => processing skipped.');
        }
        else {
            console.log(infoTextPrefix);

            setTestLoaded(false);

            runFunction();
        }
    };


    const makeOnSuccessFunction = (functionName, reloadTrigger) => {
        return (response) => {
            console.log(functionName + ' - Success', response);

            reportAPISuccess('Data series edit started successfully.');

            // Reload trigger is specified: reload immediately.
            if (reloadTrigger !== undefined) {
                reloadTrigger();
            }
            // No reload trigger: check and reload later.
            else {
                // Check if there are jobs for the current data series and
                // update table content as soon as no running jobs are found.
                checkDataSeriesJob(ws2Axios, idSeries, onCheckSuccess, onCheckError);
            }
        };
    }


    const makeOnErrorFunction = (functionName) => {
        return (response) => {
            console.log(functionName + ' - Error', response);

            reportAPIError(response.data.error);
        };
    }


    const onScaleRun = (factor, offset) => {
        const functionName = 'Scale';
        const noTrigger = undefined;
        const onSuccess = makeOnSuccessFunction(functionName, noTrigger);
        const onError = makeOnErrorFunction(functionName);

        performRun(
            'Scale run [' + factor + ', ' + offset + ']',
            () => recalculateNumericDataSeries(ws2Axios, idSeries, offset, factor, onSuccess, onError));
    };


    const onSetCommonRun = (sequence, status, value, functionName) => {
        const onSuccess = makeOnSuccessFunction(functionName, trigger);
        const onError = makeOnErrorFunction(functionName);

        const key = sequence.toString();
        const jsonPayload = {
            [key]: {
                status: status,
                value: value
            }
        };

        performRun(
            functionName + ' run [' + sequence + ', ' + status + ', ' + value + ']',
            () => editDataSeries(ws2Axios, idSeries, jsonPayload, onSuccess, onError));
    };


    const onSetNumericRun = (sequence, status, oldValue, newValue) => {
        onSetCommonRun(sequence, status, newValue, 'Set numeric');
    };


    const onReplRun = (searchPattern, replacePattern) => {
        const functionName = 'Replace';
        const noTrigger = undefined;
        const onSuccess = makeOnSuccessFunction(functionName, noTrigger);
        const onError = makeOnErrorFunction(functionName);
        if (!validateNonEmptySearchPattern(searchPattern)) {
            return;
        }

        performRun(
            'Replace run [' + searchPattern + ', ' + replacePattern + ']',
            () => searchReplaceStringDataSeries(ws2Axios, idSeries, searchPattern, replacePattern, onSuccess, onError));
    };

    const onRegExRun = (searchPattern, replacePattern) => {
        const functionName = 'RegExp';
        const noTrigger = undefined;
        const onSuccess = makeOnSuccessFunction(functionName, noTrigger);
        const onError = makeOnErrorFunction(functionName);

        if (!validateNonEmptySearchPattern(searchPattern)) {
            return;
        }

        performRun(
            'Reg. ex. run [' + searchPattern + ', ' + replacePattern + ']',
            () => regexReplaceStringDataSeries(ws2Axios, idSeries, searchPattern, replacePattern, onSuccess, onError))
    };

    const onSetStringRun = (sequence, status, oldValue, newValue) => {
        onSetCommonRun(sequence, status, newValue, 'Set string');
    };

    const onItemReplRun = (searchItem, replaceItem) => {
        const functionName = dataTypeUtil.isTermDataType(dataType) ? 'TermRepl' : 'EventRepl';
        const itemName = dataTypeUtil.isTermDataType(dataType) ? 'Term' : 'Event';
        const noTrigger = undefined;
        const onSuccess = makeOnSuccessFunction(functionName, noTrigger);
        const onError = makeOnErrorFunction(functionName);

        if (!validateEditItems(searchItem, replaceItem)) {
            return;
        }

        const search_id = searchItem.id;
        const replace_id = replaceItem.id;

        performRun(
            itemName + ' replace run [' + searchItem.name + ', ' + replaceItem.name + ']',
            () => replaceIdDataSeries(ws2Axios, idSeries, search_id, replace_id, onSuccess, onError));
    }


    const onSetItemRun = (sequence, status, oldValue, newValue) => {
        if (!validateEditItems(oldValue, newValue)) {
            return;
        }

        const functionName = dataTypeUtil.isTermDataType(dataType) ? 'Set term' : 'Set event';
        const new_id = newValue.id;
        onSetCommonRun(sequence, status, new_id, functionName);
    }


    // TODO: Remove this obsolete function.
    const onRun = () => {
        switch (dataType && dataType.id) {
            case 5:
            case 7:
                break;

            default:
                console.log("ERROR, no test for datatype", dataType);
        }
    };

    const onReset = () => {
        const newSearchItem = {id: null, name: null};
        const newReplaceItem = {id: null, name: null};
        setSearchItem(newSearchItem);
        setReplaceItem(newReplaceItem);

        setTestLoaded(false);

        validateNonEmptyItems(newSearchItem, newReplaceItem);
    };

    const editorMethods = {
        onScaleRun: onScaleRun,
        onScaleTest: onScaleTest,
        onSetNumericRun: onSetNumericRun,
        onReplRun: onReplRun,
        onRegExRun: onRegExRun,
        onSetStringRun: onSetStringRun,
        onItemReplRun: onItemReplRun,
        onSetItemRun: onSetItemRun,
        onRegTest: onRegTest,
        onReplTest: onReplTest,
        onTest: onTest,
        onRun: onRun,
        onReset: onReset,
        setTestLoaded: setTestLoaded
    };

    const infoAreaTextMethods = {
        setFieldErrorText: infoAreaFields.setFieldErrorText,
        setErrorText: infoAreaFields.setErrorText,
        setInfoText: infoAreaFields.setInfoText,
        setInfo: setAllInfo
    };


    const columns = useMemo(() => {
            switch (dataType && dataType.id) {
                case 1:
                    return [
                        sequenceColumn,
                        numberColumn('Value', 'value', format),
                        statusColumn
                    ];
                case 2:
                case 6:
                    return [
                        sequenceColumn,
                        stringColumn('Value', 'value'),
                        statusColumn
                    ];
                case 3:
                    return [
                        sequenceColumn,
                        datetimeColumn(format),
                        statusColumn
                    ];
                case 4:
                    return [
                        sequenceColumn,
                        fileColumn('Filename', 'filename', watchDataset?.id),
                        stringColumn('Charset', 'charset'),
                        stringColumn('Mime Type', 'mimetype'),
                        bytesColumn('Hash', 'hash'),
                        numberColumn('Filesize', 'filesize', '<humanReadableSize>'),
                        numberColumn('Version', 'version', '#0'),
                        statusColumn
                    ];
                case 5:
                    return [
                        sequenceColumn,
                        entityTagColumn(entityDefs.term, 'Value', 'value'),
                        statusColumn
                    ];

                case 7:
                    return [
                        sequenceColumn,
                        entityTagColumn(entityDefs.event, 'Event', 'value'),
                        statusColumn
                    ];

                default:
                    console.log("ERROR, no columns for datatype", dataType);
                    return [];
            }
        },
        [format, dataType, watchDataset]
    );

    if (!dataType) {
        return <div><MyError/> Data type is missing</div>
    }

    return (
        <Spin size="large" spinning={loading}>
        <Row style={{width: '100%'}}>
            <Col span={24}>
                <Collapse bordered={false} defaultActiveKey={collapseActiveKeys} items={[{
                    key: "usekey",
                    label: "Data",
                    children: <>
                        <Table
                            className='mini-table'
                            columns={columns}
                            rowSelection={tableRowSelection}
                            loading={tableLoading}
                            dataSource={data}
                            rowKey='sequence'
                            pagination={{
                                showSizeChanger: true,
                            }}
                            scroll={{
                                x: 20
                            }}
                        />
                        {dataSeriesEditingEnabled && dataTypeUtil.isNumericDataType(dataType) &&
                            <NumericDataSeriesEditor
                                dataType={dataType}
                                setTestLoaded={setTestLoaded}
                                validateNonZeroNumber={validateNonZeroNumber}
                                validateNumber={validateNumber}

                                fieldErrorText={fieldErrorText}
                                errorText={errorText}
                                infoText={infoText}

                                editPreview={editPreview}
                                switchRowSelectionMode={switchRowSelectionMode}

                                selectedRowData={selectedRowData}
                                reportFieldError={reportFieldError}
                                editorMethods={editorMethods}

                                infoAreaFields={infoAreaFields}
                                infoAreaTextMethods={infoAreaTextMethods}
                                infoAreaController={infoAreaController}
                            />
                        }
                        {dataSeriesEditingEnabled && dataTypeUtil.isStringDataType(dataType) &&
                            <StringDataSeriesEditor
                                dataType={dataType}
                                nonEmptyStringStyle={nonEmptyStringStyle}
                                validateNonEmptySearchPattern={validateNonEmptySearchPattern}
                                setTestLoaded={setTestLoaded}
                                editPreview={editPreview}
                                onTest={onTest}
                                onRun={onRun}
                                onReset={onReset}

                                fieldErrorText={fieldErrorText}
                                errorText={errorText}
                                infoText={infoText}

                                reportFieldError={reportFieldError}
                                switchRowSelectionMode={switchRowSelectionMode}
                                selectedRowData={selectedRowData}

                                editorMethods={editorMethods}

                                infoAreaTextMethods={infoAreaTextMethods}
                                infoAreaController={infoAreaController}
                            />
                        }
                        {dataSeriesEditingEnabled && dataTypeUtil.isIdDataType(dataType) &&
                            <IdDataSeriesEditor
                                dataType={dataType}
                                searchItem={searchItem}
                                setSearchItem={setSearchItem}
                                replaceItem={replaceItem}
                                setReplaceItem={setReplaceItem}
                                validateNonEmptyItems={validateNonEmptyItems}
                                validateEditItems={validateEditItems}
                                editPreview={editPreview}
                                onTest={onTest}
                                onRun={onRun}
                                onReset={onReset}
                                fieldErrorComputedText={fieldErrorComputedText}
                                fieldErrorText={fieldErrorText}
                                errorText={errorText}
                                infoText={infoText}
                                switchRowSelectionMode={switchRowSelectionMode}
                                reportFieldError={reportFieldError}
                                clearAllInfo={clearAllInfo}
                                selectedRowData={selectedRowData}

                                editorMethods={editorMethods}

                                infoAreaFields={infoAreaFields}
                                infoAreaTextMethods={infoAreaTextMethods}
                                infoAreaController={infoAreaController}
                            />
                        }
                        {editPreview && errorText === '' && testLoaded &&
                            <Table
                                className='mini-table'
                                columns={columns}
                                dataSource={data}
                                rowKey='sequence'
                                pagination={{
                                    showSizeChanger: true,
                                }}
                                scroll={{
                                    x: 20
                                }}
                            />
                        }
                        </>
                    }]}
                    onChange={(activeKeys) => {
                        setCollapseActiveKeys(activeKeys);
                    }}
                />
            </Col>
        </Row>
        </Spin>
    )
}

DataSeriesDataDisplay.propTypes = {
    idSeries: PropTypes.number,
    dataType: PropTypes.object,
    format: PropTypes.string
}

export default DataSeriesDataDisplay;
