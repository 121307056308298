import ResponsiveArea from "../../../tk/cards/ResponsiveArea";
import CardColorBackground from "../../../tk/cards/CardColorBackground";
import BigEntityTable from "./BigEntityTable";
import entityColumns from "../entities/entityColumns";
import React from "react";
import Color from "color";

const EntityTable = (props) => {
    const {entityDef, refLayout, defaultRsql} = props;
    const cardColor = entityDef ? Color(entityDef.bgColor).lighten(0.2) : "#eee";
    //console.log("RENDERWRAPPER ENTITY TABLE " + entityDef.entityType, refLayout, defaultRsql)

    return (
        <ResponsiveArea>
            <CardColorBackground color={cardColor}>
                <BigEntityTable
                    refLayout={refLayout}
                    entityDef={entityDef}
                    entityColumns={entityColumns[entityDef.entityType]}
                    defaultRsql={defaultRsql}
                />
            </CardColorBackground>
        </ResponsiveArea>
    )
}

export default EntityTable;