import {Table, Tooltip} from "antd";
import React, {useContext, useEffect, useMemo} from "react";
import EntityTag from "../../../tk/bits/EntityTag";
import entityDefs from "../entities/entityDefs";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import PropTypes from "prop-types";
import {getBestMatch} from "../getjirametadata/EntityRefMatchField";
import {FormExtContext} from "../../../tk/forms/FormExt";


const JournalMatchField = (props) => {
    const {paramName, journal} = props;
    const {setValue} = useContext(FormExtContext);
    useEffect(() => {
            const bestMatch = getBestMatch(journal.matches);
            if (bestMatch) {
                setValue(paramName, bestMatch);
            }
        },
        [journal, paramName, setValue]
    );
    const columns = useMemo(() => [
            {
                title: 'External',
                dataIndex: 'original',
                width: '35%',
                render: () => {
                    if (!journal) return "???";
                    let name = journal.name;
                    if (journal.issn && journal.issn.length > 0) {
                        name += ", ISSN " + journal.issn;
                    }
                    if (journal.abbrev && journal.abbrev.length > 0) {
                        name += " (" + journal.abbrev + ")";
                    }
                    return <span>{name}</span>;
                }
            },
            {
                title: 'PANGAEA',
                dataIndex: 'journal',
                width: '30%',
                render: (entityRef, _, index) =>
                    <SelectEntityField
                        paramName={paramName}
                        entityDef={entityDefs.journal}
                        size="tiny"
                        newDefaults={{
                            name: journal.name,
                            issn: journal.issn
                        }}
                    />
            },
            {
                title: 'Potential Matches',
                dataIndex: 'matches',
                width: '35%',
                render: (matches, _, i) => (
                    journal?.matches.map(match => (
                        <Tooltip key={i} title={match.match_info + ", score: " + match.score}>
                            <span><EntityTag entityRef={match} entityDef={entityDefs.journal}/></span>
                        </Tooltip>
                    ))
                )
            }
        ],
        [journal, paramName]
    );

    return (
        <Table
            className='mini-table'
            size='small'
            columns={columns}
            dataSource={[journal]}
            rowKey='id'
            pagination={false}
        />
    )
}

JournalMatchField.propTypes = {
    paramName: PropTypes.string,
    entityType: PropTypes.string
}

export default JournalMatchField;
