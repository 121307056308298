import TableField2, {findUnsortedIndex} from "./TableField2";
import {renderEditableTextArea, renderEntityRef} from "../../pages/test/TestPage2";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import HelpLink from "../bits/HelpLink";
import SelectField from "../input/SelectField";
import {smallTagStyle} from "./inputComplexLib";
import React, {useContext} from "react";
import useEnumType from "../../hooks/useEnumType";
import {FormExtContext} from "../forms/FormExt";

interface TableField2Props {
    paramName: string,
    selfId: number
}

const RelatedDatasetTableField: React.FC<TableField2Props> = (props: TableField2Props) => {
    const {paramName, selfId} = props;
    const relationTypesForDatasets = useEnumType('relationTypesForDatasets');
    const {watch, getValues} = useContext(FormExtContext);
    const addedIds = watch(paramName).map(entry => entry.datasetRelated.id)
    return (
        <TableField2
            filterFn={(entityRef) => entityRef.id !== selfId && !(addedIds.includes(entityRef.id))}
            entityDef={entityDefs.dataset}
            paramName={paramName}
            columns={[
                {
                    title: 'ID',
                    dataIndex: 'datasetRelated.id',
                    width: 60,
                    align: 'right',
                    render: (_, row) => row.datasetRelated.id,
                    sorter: (a, b) => a.datasetRelated.id - b.datasetRelated.id,
                    defaultSortOrder: 'ascend'
                },
                {
                    title: 'Dataset',
                    dataIndex: 'datasetRelated',
                    width: 270,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.dataset),
                    sorter: (a, b) => a.datasetRelated.name > b.datasetRelated.name ? 1 : -1
                },
                {
                    title: <span>Relation type&nbsp;<HelpLink
                        href='https://wiki.pangaea.de/wiki/Intern:Reference_relation'/></span>,
                    dataIndex: 'relationType',
                    width: 210,
                    render: (_, row: any) => {  //TODO:what is _  whats the point?
                        const origIndex = findUnsortedIndex(row.key, getValues, paramName, "datasetRelated");
                        return <SelectField
                            //paramName=references in DatasetDetails, or references.value in BatchEditContent
                            paramName={paramName + '.' + origIndex + '.relationType'}
                            values={relationTypesForDatasets}
                            tagStyle={smallTagStyle}
                        />;
                    },
                    multiEdit: 'type',
                    multiEditOptions: relationTypesForDatasets,
                    sorter: (a, b) => a.relationType.name > b.relationType.name ? 1 : -1
                },
                {
                    title: 'Comment',
                    width: 300,
                    dataIndex: 'comment',
                    render: renderEditableTextArea(paramName, 'comment', getValues, 'datasetRelated'),
                    multiEdit: 'text',
                    sorter: (a, b) => a.comment > b.comment ? 1 : -1
                }
            ]}
            newRowFn={(entityRef: object) => ({
                datasetRelated: entityRef,
                relationType: {id: 12, name: "Related to"},
                comment: ''
            })}
        />
    )
}

export default RelatedDatasetTableField;