import {Button} from "antd";
import PropTypes from "prop-types";


const QuickFilter = (props) => {
    const {isEnabled, setIsEnabled, text} = props;
    return (
        <Button
            type="primary"
            ghost={!isEnabled}
            size="small"
            onClick={() => setIsEnabled(!isEnabled)}
        >
            {text}
        </Button>
    )
}

QuickFilter.propTypes = {
    isEnabled: PropTypes.bool,
    setIsEnabled: PropTypes.func,
    text: PropTypes.string
}

export default QuickFilter;