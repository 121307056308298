import {Controller} from "react-hook-form";
import dayjs from "dayjs";
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import {formStyle} from "../forms/formStyle";
import FormFieldError from "../forms/FormFieldError";
import React, {useContext} from "react";
import {FormExtContext} from "../forms/FormExt";


const DateInputField = (props) => {
    const {paramName, disabled} = props;
    const {control} = useContext(FormExtContext);
    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) => {
                const {onBlur, name, value, ref} = field;
                const DatePicker = generatePicker(dayjsGenerateConfig);
                return (<>
                        <DatePicker
                            style={{minWidth: '150px', ...formStyle}}
                            onChange={v => {
                                if (v === null) {
                                    field.onChange(null);
                                } else {
                                    field.onChange(v.format('YYYY-MM-DD'))
                                }
                            }}
                            onBlur={onBlur}
                            name={name}
                            value={value === null || value === undefined ? null : dayjs(value)}
                            ref={ref}
                            disabled={disabled}
                        />
                        <FormFieldError paramName={paramName}/>
                    </>
                );
            }}
        />
    )
}

export default DateInputField;
