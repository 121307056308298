import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const InstitutionOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Abbreviation" value={entity.abbreviation}/>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Type" value={entity.institutionType} entityDef={entityDefs.institutionType}/>
            <OverviewLine label="ROR" value={entity.ror} type="uri"/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Contact" value={entity.contact}/>
        </div>
    )
}

export default InstitutionOverview;