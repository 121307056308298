import React, {useContext} from "react";
import {ResponsiveContext} from "./ResponsiveArea";
import {Col} from "antd";
import PropTypes from "prop-types";


const ResponsiveCardCol = (props) => {
    const {no, wide, children} = props;
    const cardContext = useContext(ResponsiveContext);
    if (wide) {
        return (
            <Col span={cardContext.wideSpan[no]}>
                {children}
            </Col>
        );
    }
    return (
        <Col span={cardContext.colSpan[no]}>
            {children}
        </Col>
    );
}

ResponsiveCardCol.propTypes = {
    no: PropTypes.number.isRequired,
    wide: PropTypes.bool
}

export default ResponsiveCardCol;