import React, {useContext, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {Button, Input, Row, Tooltip} from "antd";
import {CheckCircleTwoTone, GlobalOutlined, LoadingOutlined, UpSquareOutlined} from '@ant-design/icons';
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import {isBlank} from "../../lib/stringUtils";
import LinkResolve from "../bits/LinkResolve";
import useWS2Axios from "../../hooks/useWS2Axios";
import MyError from "../bits/MyError";
import PropTypes from "prop-types";
import {uriCheck} from "../../lib/networkRequests";


const checkStateToCode = (checkState, handleRedirectUpdate, errorMessage) => {
    switch (checkState) {
        case 0: // unchecked
            return <LoadingOutlined/>
        case 1: // ok
            return <CheckCircleTwoTone twoToneColor="#52c41a"/>
        case 2: // err
            // Keep the space before <MyError/>, otherwise the tooltip will not show
            return <Tooltip title={errorMessage || "Unknown error"}> <MyError/></Tooltip>
        case 3: // redirect
            return <UpSquareOutlined onClick={handleRedirectUpdate}/>
        case 4: // no data
        default:
            return <span/>
    }
}

const UriInputField = (props) => {
    const {paramName} = props;
    const {setValue, control, getValues, watch} = useContext(FormExtContext);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));
    const {ws2Axios} = useWS2Axios();
    const [checkState, setCheckState] = useState(4); // 0: unchecked, 1: ok, 2: err, 3: redirect, 4: no data
    const [timeoutID, setTimeoutID] = useState();
    const [redirectTarget, setRedirectTarget] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const watchInput = watch(paramName);

    useEffect(() => {
            setCheckState(0);
            setRedirectTarget(undefined);
            setErrorMessage(undefined);

            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            const id = setTimeout(() => {
                const trimmedInput = watchInput?.trim();
                if (!trimmedInput || trimmedInput.length === 0) {
                    setCheckState(4);
                    return;
                }
                uriCheck(ws2Axios, trimmedInput)
                    .then(response => {
                        if (response.status === "redirected") {
                            setCheckState(3);
                            setRedirectTarget(response.target);
                        } else {
                            setCheckState(1);
                        }
                    })
                    .catch(err => {
                        if (err.response) {
                            setErrorMessage(err.response.data.error);
                        } else {
                            setErrorMessage(err.message);
                        }
                        setCheckState(2);
                    })
            }, 1000);
            setTimeoutID(id);
            return () => clearTimeout(id);
        },
        // We exclude timeoutID
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watchInput, ws2Axios]
    );

    const checkSuffix = checkStateToCode(
        checkState,
        () => setValue(paramName, redirectTarget),
        errorMessage
    );

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <Row>
                    <div
                        ref={drop}
                        style={{
                            width: 'calc(100% - 32px)'
                        }}
                    >
                        <DndHighlight isOver={isOver} canDrop={canDrop}/>
                        <Input
                            {...field}
                            style={formStyle}
                            suffix={checkSuffix}
                        />
                    </div>
                    <Tooltip title="Open URL">
                        <LinkResolve uri={field.value}>
                            <Button
                                type="link"
                                icon={<GlobalOutlined/>}
                                disabled={isBlank(getValues(paramName))}
                                style={{zIndex: "10"}}
                            />
                        </LinkResolve>
                    </Tooltip>
                    <FormFieldError paramName={paramName}/>
                </Row>
            }
        />
    )
}

UriInputField.propTypes = {
    paramName: PropTypes.string
}

export default UriInputField;
