import EntitySelect from "../../../tk/input/EntitySelect";
import React, {useState} from "react";
import entityDefs from "../entities/entityDefs";
import {Button} from "antd";

const EntitySelectPIBulk = (props) => {
    const {onFirstAuthor, onClear, onSelect} = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const compDropdownButtons = [
        <Button key="firstauthor" onClick={() => {
            onFirstAuthor();
            setDropdownVisible(false);
        }}>
            First Author
        </Button>,
        <Button key="clear" onClick={() => {
            onClear();
            setDropdownVisible(false)
        }}>
            Clear
        </Button>
    ];

    return (
        <EntitySelect
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
            entityDef={entityDefs.staffs}
            placeholder="Update selected"
            size="full"
            onSelect={onSelect}
            compDropdownButtons={compDropdownButtons}
        />
    )
}

export default EntitySelectPIBulk