import {useMemo} from "react";
import axios from "axios";
import useMyUser from "./useMyUser";


export const loginUrl = () => process.env.REACT_APP_LOGIN_REDIRECT + "?referer=" + encodeURIComponent(window.location.href);

export const logoutUrl = () => process.env.REACT_APP_LOGOUT_REDIRECT;
export const token = () => process.env.REACT_APP_SEND_TOKEN_IN_HEADER === "true";
const useWS2Axios = (prefabAxios) => {

    const {myUser} = useMyUser();

    const ws2Axios = useMemo(() => {

            const headers = {};
            // if (process.env.REACT_APP_SEND_TOKEN_IN_HEADER === true) {
            if (token()) {  //can't access process.env vars directly for some reason, therefore wrap in a function
                if (myUser.token) {
                    headers['Authorization'] = "Bearer " + myUser.token
                }
            }

            const axiosInstance = axios.create({
                timeout: 120000,
                timeoutErrorMessage: "TIMEOUT",
                validateStatus: status => (status >= 200 && status < 300) || status === 401,
                withCredentials: true,
                headers
            });

            axiosInstance.interceptors.response.use(response => {
                if (response.status === 401) {
                    window.location.href = loginUrl();
                }
                return response;
            });

            return axiosInstance;
        },
        [myUser.token]
    );

    if (prefabAxios) {
        return {
            ws2Axios: prefabAxios
        }
    }

    return {
        ws2Axios
    }
}

export default useWS2Axios;