import React, {useContext, useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import PropTypes from "prop-types";
import ErrorDisplay from "../error/ErrorDisplay";
import ContainerContext from "../../contexts/ContainerContext";
import {handleApplicationError} from "../../lib/errorHandler";


export const FormExtContext = React.createContext({});

const stopPropagate = (callback) => {
    return (event) => {
        if (typeof event.stopPropagation === 'function') {
            // prevent any outer forms from receiving the event too
            event.stopPropagation();
            callback(event);
        }
    }
}

const SyncUp = ({onChange}) => {
    const {control} = useContext(FormExtContext);
    const allWatch = useWatch({control});
    useEffect(() => {
            if (onChange) {
                onChange(allWatch);
            }
        },
        [onChange, allWatch]
    );
    return <></>
}

const FormExt = (props) => {
    const {
        defaultEntity,
        updateEntity,
        instanceId,
        globalErrors,
        setGlobalErrors,
        entityInit,
        children,
        onChange,
        noDirtyReport
    } = props;
    const {
        handleSubmit,
        control,
        setValue,
        formState: {errors},
        setError,
        clearErrors,
        getValues,
        reset,
        formState,
        resetField,
        watch,
        setFocus,
        register,
        unregister,
        trigger
    } = useForm({
            defaultValues: defaultEntity
        }
    );
    const [stateGlobalErrors, setStateGlobalErrors] = useState([]);
    const thisGlobalErrors = globalErrors || stateGlobalErrors;
    const thisSetGlobalErrors = setGlobalErrors || setStateGlobalErrors;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const {onDirtyChange} = useContext(ContainerContext);

    useEffect(() => {
            if (!entityInit) return;
            Object.keys(entityInit).forEach(key => {
                setValue(key, entityInit[key], {shouldDirty: true});
            })
        },
        [entityInit, setValue]
    );

    useEffect(() => {
            const isMyDirty = formState.isDirty;
            if (isDirty !== isMyDirty) {
                setIsDirty(isMyDirty);
                if (onDirtyChange !== undefined && !noDirtyReport) {
                    onDirtyChange(isMyDirty);
                }
            }
        },
        [formState, isDirty, noDirtyReport, onDirtyChange]
    )

    return (
        <form
            onSubmit={stopPropagate(
                handleSubmit(data => {
                    // TODO: this might be not in use for MyEntityForm, but the "direct jira import" needs it
                    thisSetGlobalErrors([]);
                    setIsSubmitting(true);
                    updateEntity(
                        {...defaultEntity, ...data, datetimeUpdated: defaultEntity.datetimeUpdated},
                        undefined,
                        () => setIsSubmitting(false),
                        (error) => {
                            setIsSubmitting(false);
                            handleApplicationError(error, setError, thisSetGlobalErrors);
                        }
                    )
                }))}
            onKeyPress={(e) => {
                // Block enter key, so that the form is not submitted when pressing enter somewhere.
                // Have to use the submit button.
                if (e.key === "Enter" && e.target.tagName !== "BUTTON" && e.target.tagName !== "TEXTAREA") {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
        >
            <FormExtContext.Provider value={{
                errors,
                setValue,
                control,
                isSubmitting,
                setIsSubmitting,
                getValues,
                reset,
                thisGlobalErrors,
                formState,
                resetField,
                defaultEntity,
                watch,
                setFocus,
                instanceId,
                handleSubmit,
                setError,
                register,
                unregister,
                trigger,
                clearErrors: () => {
                    clearErrors();
                    setGlobalErrors([]);
                    setStateGlobalErrors([]);
                }
            }}
            >
                <SyncUp onChange={onChange}/>
                {thisGlobalErrors.map((value, index) =>
                    <ErrorDisplay key={index} message={value}/>
                )}
                {children}
            </FormExtContext.Provider>
        </form>
    )
}

FormExt.propTypes = {
    defaultEntity: PropTypes.object.isRequired,
    updateEntity: PropTypes.func.isRequired,
    onDirtyChange: PropTypes.func,
    instanceId: PropTypes.number,
    entityInit: PropTypes.object
}

export default FormExt;