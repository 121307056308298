import {useEffect, useState} from "react";

const TypingIcon = (props) => {
    const [count, setCount] = useState(3);
    useEffect(() => {
            const id = setTimeout(() => {
                    setCount((count + 1) % 4);
                },
                500
            );
            return () => clearTimeout(id)
        },
        [count]
    );
    return <span>{".".repeat(count)}</span>;
}

export default TypingIcon;