import {collectFn} from "./dropIndicatorStyle";

export const emptyEntityRef = {id: null, name: null}

export const entityRef = (id, name) => ({id, name});

export const fixDefaults = (emptyEntity, wsEntity) => {
    const result = {...emptyEntity};
    for (const [key, val] of Object.entries(wsEntity)) {
        if (val === undefined || val === null || val.id === null) continue;
        result[key] = val;
    }
    return result;
}

export const dropEntities = (entityDef, startDropFn, addEntityFn, setIsLoading, doRsqlSearch) => ({
    accept: [
        'ENTITY_' + entityDef.entityType,
        'LIST_' + entityDef.entityType,
        'FILTER_' + entityDef.entityType
    ],
    collect: collectFn,
    drop: (item, monitor) => {
        console.log("DROPPED", item)
        if (monitor.didDrop()) return;
        if (Array.isArray(item.data)) {
            const entityRefs = [];
            item.data.forEach(entityRef => entityRefs.push(entityRef));
            addEntityFn(entityRefs);
        } else if (item.data.rsql) {
            setIsLoading(true);
            doRsqlSearch(item.data.rsql)
                .then(items => addEntityFn(items))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        } else {
            addEntityFn([item.data]);
        }
    }
})
