import React from "react";
import PropTypes from "prop-types";


const LinkResolve = (props) => {
    const {uri, children, style} = props;
    return (
        <div style={style}>
            <a href={"https://ws.pangaea.de/ws/resolve-uri?uri=" + encodeURIComponent(uri)}
               target="_blank"
               rel="noreferrer"
            >
                {children}
            </a>
        </div>
    )
}

LinkResolve.propTypes = {
    uri: PropTypes.string,
    style: PropTypes.object
}

export default LinkResolve;
