import {Controller} from "react-hook-form";
import {Switch} from "antd";
import React, {useContext} from "react";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";


const InputCheckField = (props) => {
    const {paramName, disabled} = props;
    const {control} = useContext(FormExtContext);

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <>
                    <Switch
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        checked={field.value}
                        disabled={disabled || false}
                    />
                    <FormFieldError paramName={paramName}/>
                </>
            }
        />
    )
}

InputCheckField.propTypes = {
    paramName: PropTypes.string,
    disabled: PropTypes.bool
}

export default InputCheckField;
