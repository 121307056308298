import {Input, Radio} from "antd";
import FilterDialogButtons from "./FilterDialogButtons";


const CONTAINS = '==';
const ENDS = '=le=';
const BEGINS = '=ge=';
const EQUALS = '[]';


const TextSearchProps = (props) => {
    const {dataIndex, setSelectedKeys, selectedKeys, confirm, clearFilters, autofocusRef} = props;

    const toFilter = selectedKeys => {
        if (Array.isArray(selectedKeys) && selectedKeys.length === 1) {
            const value = selectedKeys[0].value;

            if (typeof value == "string") {
                // TODO: resets filter correctly, but can only select mode when first entered some value
                if (value === "**" || value === "*" || value === "") {
                    setTimeout(() => clearFilters(), 50);
                    return {
                        mode: EQUALS,
                        value1: null,
                        value2: null
                    }

                }
                if (value.startsWith('*') && value.endsWith('*') && value.length > 1) {
                    return {
                        mode: CONTAINS,
                        value1: value.substring(1, value.length - 1)
                    }
                } else if (value.endsWith('*')) {
                    return {
                        mode: BEGINS,
                        value1: value.substring(0, value.length - 1)
                    }
                } else if (value.startsWith('*')) {
                    return {
                        mode: ENDS,
                        value1: value.substring(1)
                    }
                } else {
                    return {
                        mode: EQUALS,
                        value1: value
                    }
                }

            } else if (typeof value == "object") {
                console.log("TEXT SEARCH PROPS BUT VALUE IS OBJECT", value)
            }
        }
        return {
            mode: CONTAINS,
            value1: '',
        };
    }

    const fromFilter = filter => {
        switch (filter.mode) {
            case CONTAINS:
                return [{
                    mode: '==',
                    value: '*' + filter.value1 + '*'
                }];
            case BEGINS:
                return [{
                    mode: '==',
                    value: filter.value1 + '*'
                }];
            case ENDS:
                return [{
                    mode: '==',
                    value: '*' + filter.value1
                }];
            case EQUALS:
            default:
                return [{
                    mode: '==',
                    value: filter.value1
                }];
        }
    }

    const getPlaceholder1 = () => {
        switch (filter.mode) {
            case CONTAINS:
                return `Find ${dataIndex} contains`;
            case BEGINS:
                return `Find ${dataIndex} begins with`;
            case ENDS:
                return `Find ${dataIndex} ends with`;
            case EQUALS:
                return `Find ${dataIndex} equals`;
            default:
                return "???";
        }
    }

    const filter = toFilter(selectedKeys);

    return (
        <div style={{paddingBottom: 8}}>
            <Radio.Group
                value={filter.mode}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, mode: e.target.value}))}
            >
                <Radio value={CONTAINS}>contains</Radio>
                <Radio value={BEGINS}>begins</Radio>
                <Radio value={ENDS}>ends</Radio>
                <Radio value={EQUALS}>equals</Radio>
            </Radio.Group>
            <Input
                ref={autofocusRef}
                placeholder={getPlaceholder1()}
                value={filter.value1}
                onChange={(e) => setSelectedKeys(fromFilter({...filter, value1: e.target.value}))}
                onPressEnter={(e) => {
                    e.stopPropagation();
                    confirm();
                }}
                style={{
                    marginTop: "8px",
                    marginBottom: "16px",
                    display: 'block',
                }}
            />
            <FilterDialogButtons confirm={confirm} clearFilters={clearFilters}/>
        </div>
    );
}

export default TextSearchProps;
