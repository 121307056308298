import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import {Button, Input, Space, Tooltip} from "antd";
import {GlobalOutlined} from '@ant-design/icons';
import {formStyle} from "../forms/formStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import {isBlank} from "../../lib/stringUtils";
import PropTypes from "prop-types";
import useAutofocus from "../../hooks/useAutofocus";


const TicketInputField = (props) => {
    const {paramName, width, autoFocus} = props;

    const {control, getValues, instanceId} = useContext(FormExtContext);
    const inputRef = useAutofocus(autoFocus, instanceId);

    const myWidth = width || 'calc(100% - 32px)';

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <Space.Compact>
                    <Input
                        style={{width: myWidth, ...formStyle}}
                        {...field}
                        ref={inputRef || field.ref}
                    />
                    <Tooltip title="Open JIRA ticket">
                        <a href={"https://issues.pangaea.de/browse/" + field.value} target="_blank" rel="noreferrer">
                            <Button icon={<GlobalOutlined/>} style={formStyle}
                                    disabled={isBlank(getValues(paramName))}/>
                        </a>
                    </Tooltip>
                    <FormFieldError paramName={paramName}/>
                </Space.Compact>
            }
        />
    )
}

TicketInputField.propTypes = {
    paramName: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    autoFocus: PropTypes.bool
}

export default TicketInputField;
