import {useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useEntitySearch = (entityType, filter, limit, prefabAxios) => {
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [controller, setController] = useState();
    const {ws2Axios} = useWS2Axios(prefabAxios);
    let myLimit = limit;
    if (myLimit === undefined) myLimit = 100;

    const doRsqlSearchInt = (rsql, path) => {
        if (controller) {
            controller.abort();
        }
        const newController = new AbortController();
        setController(newController);
        setIsLoading(true);
        return ws2Axios
            .get(apiBaseUrl + path, {
                params: {
                    offset: 0,
                    limit: myLimit,
                    sort: "-id",
                    q: rsql
                },
                signal: newController.signal
            })
            .then(response => {
                setController(undefined);
                setIsLoading(false);
                let records;
                if (response.data.records) { // Not so nice, but should only be for transition
                    records = response.data.records;
                } else {
                    records = response.data;
                }
                if (filter) {
                    records = filter(records);
                }
                setResult(records);
                return records;
            })
            .catch(() => {
                setIsLoading(false);
            })
    }

    const doSearch = searchString => {
        if (entityType === 'datasetConfigField') {
            return doRsqlSearchInt('name==' + searchString + '*', entityType + "/namesList")
        } else {
            return doRsqlSearchInt(searchString, 'query/autocomplete/' + entityType);
        }
    }

    const doRsqlSearch = rsql => {
        return doRsqlSearchInt(rsql, entityType + "/namesList");
    }

    return {
        result, doSearch, doRsqlSearch, isLoading
    }
}

export default useEntitySearch;
