import {Input} from "antd";
import {addEntityDetailTab, openList} from "../../lib/flexlayoutTabTools";
import entityDefs, {findEntityDefByAbbr} from "./entities/entityDefs";

const {Search} = Input;

const deconstructScopedId = value => {
    const indexOfDash = value.indexOf('-');
    if (indexOfDash > 0 && indexOfDash < 4) {
        return {
            entityTypeAbbr: value.substring(0, indexOfDash),
            id: value.substring(indexOfDash + 1)
        }
    }
    const indexOfDigit = value.search(/\d/);
    if (indexOfDigit > 0 && indexOfDigit < 4) {
        return {
            entityTypeAbbr: value.substring(0, indexOfDigit),
            id: value.substring(indexOfDigit)
        }
    }
    return undefined;
}

const GlobalSearch = ({refLayout}) => {

    const handleSearch = value => {
        console.log("VALUE", value, value[0])

        if (value && value.length > 0) {
            const parts = deconstructScopedId(value);
            if (parts) {
                if (parts.entityTypeAbbr.toLowerCase() === 'pdi') {
                    openList(entityDefs.dataset, refLayout, 'ticket==PDI-' + parts.id)
                } else {
                    const entityDef = findEntityDefByAbbr(parts.entityTypeAbbr);
                    addEntityDetailTab(refLayout.current, {id: parts.id}, entityDef);
                }
            } else {
                addEntityDetailTab(refLayout.current, {id: value}, entityDefs.dataset);
            }
        }
    }

    return (
        <Search
            className='globalsearch'
            prefix='ID'
            onSearch={handleSearch}
            allowClear={true}
            style={{
                width: 150,
                paddingTop: 14,
            }}
        />
    )
}

export default GlobalSearch;