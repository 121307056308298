import {Input, Space} from "antd";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";


function setComponent(value, setValue, min, max) {
    if (value.length === 0 || value === "*") {
        setValue("");
    } else {
        let number = parseInt(value);
        if (isNaN(number)) {
            number = 0;
        }
        if (number < min) number = min;
        else if (number > max) number = max;
        setValue(number.toString());
    }
}

const ComponentInput = (props) => {
    const {
        placeholder,
        length,
        width,
        value,
        onChange,
        onBlur,
        inputRef,
        prevInputRef,
        nextInputRef,
        onPressEnter
    } = props;
    return (
        <Input
            ref={inputRef}
            placeholder={placeholder}
            maxLength={length}
            style={{width: width + 'px'}}
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            onKeyDown={e => {
                if (e.key === "ArrowLeft" && e.target.selectionEnd === 0 && prevInputRef !== undefined) {
                    prevInputRef.current.focus({cursor: 'end'}, 20)
                    e.preventDefault();
                } else if (e.key === "ArrowRight" && e.target.selectionStart === value.length && nextInputRef !== undefined) {
                    nextInputRef.current.focus({cursor: 'start'}, 20)
                    e.preventDefault();
                } else if (!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey && !(e.key === "Backspace") && !(e.key === "Delete") && !(e.key === "ArrowLeft") && !(e.key === "ArrowRight") && e.target.selectionStart === length - 1 && nextInputRef !== undefined) {
                    setTimeout(() =>
                        nextInputRef.current.focus({cursor: 'start'}, 20)
                    );
                } else if (e.key === "Backspace" && e.target.selectionEnd === 0 && prevInputRef !== undefined) {
                    prevInputRef.current.focus({cursor: 'end'});
                    e.preventDefault();
                }
            }}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    onPressEnter(e);
                }
            }}
        />
    )
}
ComponentInput.propTypes = {
    placeholder: PropTypes.string,
    length: PropTypes.number,
    width: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    prevInputRef: PropTypes.object,
    nextInputRef: PropTypes.object,
    onPressEnter: PropTypes.func
}

const ComponentDivider = (props) => {
    const {value} = props;
    return (
        <div style={{paddingTop: "2px"}}>{value}</div>
    )
}

const DatetimeLineInput = (props) => {
    const {value, onChange, withTime, style, onPressEnter, autofocusRef} = props;
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [hour, setHour] = useState("");
    const [minute, setMinute] = useState("");
    const yearInputRef = useRef(null);
    const monthInputRef = useRef(null);
    const dayInputRef = useRef(null);
    const hourInputRef = useRef(null);
    const minuteInputRef = useRef(null);

    useEffect(() => {
        const dateTimeTuple = value.split(' '); // split date - time
        const dateComponents = dateTimeTuple[0].split('-'); // split date components
        if (dateComponents.length > 0) {
            setComponent(dateComponents[0], setYear, 1700, 9999);
        }
        if (dateComponents.length > 1) {
            setComponent(dateComponents[1], setMonth, 1, 12);
        }
        if (dateComponents.length > 2) {
            setComponent(dateComponents[2], setDay, 1, 31);
        }
        if (dateComponents.length < 3) {
            setDay("");
        }
        if (dateComponents.length < 2) {
            setMonth("");
        }
        if (dateComponents.length < 1) {
            setYear("");
        }
        if (dateTimeTuple.length > 1) {
            const timeComponents = dateTimeTuple[1].split(':');
            setComponent(timeComponents[0], setHour, 0, 23);
            if (timeComponents.length > 1) {
                setComponent(timeComponents[1], setMinute, 0, 59);
            } else {
                setMinute("");
            }
        } else {
            setHour("");
            setMinute("");
        }
    }, [value]);

    const onChangeWrap = value => {
        setTimeout(
            () => onChange(value + "*"),
            60
        );
    }

    const handleChange = () => {
        if (minute.length > 0) {
            onChangeWrap(year.padStart(4, '0') + "-" + month.padStart(2, '0') + "-" + day.padStart(2, '0') + " " + hour.padStart(2, '0') + ":" + minute.padStart(2, '0'));
        } else if (hour.length > 0) {
            onChangeWrap(year.padStart(4, '0') + "-" + month.padStart(2, '0') + "-" + day.padStart(2, '0') + " " + hour.padStart(2, '0'));
        } else if (day.length > 0) {
            onChangeWrap(year.padStart(4, '0') + "-" + month.padStart(2, '0') + "-" + day.padStart(2, '0'));
        } else if (month.length > 0) {
            onChangeWrap(year.padStart(4, '0') + "-" + month.padStart(2, '0'));
        } else if (year.length > 0) {
            onChangeWrap(year.padStart(4, '0'));
        } else {
            onChangeWrap("");
        }
    }

    const wrapOnPressEnter = (e) => {
        handleChange();
        // TODO: not very nice timing
        setTimeout(
            () => onPressEnter(e),
            100
        );
    }

    return (
        <Space style={style}>
            <ComponentInput
                value={year}
                length={4}
                width={60}
                placeholder="YYYY"
                onChange={newValue => setYear(newValue)}
                onBlur={handleChange}
                inputRef={autofocusRef || yearInputRef}
                nextInputRef={monthInputRef}
                onPressEnter={e => wrapOnPressEnter(e)}
            />
            <ComponentDivider value="-"/>
            <ComponentInput
                value={month}
                length={2}
                width={50}
                placeholder="MM"
                onChange={newValue => setMonth(newValue)}
                onBlur={handleChange}
                inputRef={monthInputRef}
                prevInputRef={autofocusRef || yearInputRef}
                nextInputRef={dayInputRef}
                onPressEnter={e => wrapOnPressEnter(e)}
            />
            <ComponentDivider value="-"/>
            <ComponentInput
                value={day}
                length={2}
                width={50}
                placeholder="DD"
                onChange={newValue => setDay(newValue)}
                onBlur={handleChange}
                inputRef={dayInputRef}
                prevInputRef={monthInputRef}
                nextInputRef={withTime ? hourInputRef : undefined}
                onPressEnter={e => wrapOnPressEnter(e)}
            />
            {withTime &&
                <>
                    <ComponentDivider value="T"/>
                    <ComponentInput
                        value={hour}
                        length={2}
                        width={50}
                        placeholder="HH"
                        onChange={newValue => setHour(newValue)}
                        onBlur={handleChange}
                        inputRef={hourInputRef}
                        prevInputRef={dayInputRef}
                        nextInputRef={minuteInputRef}
                        onPressEnter={e => wrapOnPressEnter(e)}
                    />
                    <ComponentDivider value=":"/>
                    <ComponentInput
                        value={minute}
                        length={2}
                        width={50}
                        placeholder="MM"
                        onChange={newValue => setMinute(newValue)}
                        onBlur={handleChange}
                        inputRef={minuteInputRef}
                        prevInputRef={hourInputRef}
                        onPressEnter={e => wrapOnPressEnter(e)}
                    />
                </>
            }
        </Space>
    )
}

DatetimeLineInput.propTypes = {
    values: PropTypes.string,
    onChange: PropTypes.func
}

export default DatetimeLineInput;