import React, {useCallback, useContext, useEffect, useState} from "react";
import FileImportDialog from "./FileImportDialog";
import {Button, Row, Space} from "antd";
import ContainerContext from "../../../contexts/ContainerContext";
import ImportErrorDisplay from "./ImportErrorDisplay";


const FileImportForm = (props) => {
    const {
        initState,
        title,
        customError,
        uploadHint,
        customResult,
        extraOptions,
        //extraDeps,
        onReset,
        onTest,
        onImport,
        onChange,
        entityDef,
        reuploadToken,
        setReuploadToken
    } = props;
    const [isImporting, setIsImporting] = useState(false);
    const [readyForImport, setReadyForImport] = useState(initState ? initState.readyForImport : false);
    const [file, setFile] = useState(initState ? initState.file : null);
    const [result, setResult] = useState(initState ? initState.result : undefined);
    const [fileList, setFileList] = useState(initState ? initState.fileList : []);
    const [precheckResult, setPrecheckResult] = useState(initState?.precheckResult);
    const {closeContainer} = useContext(ContainerContext);

    useEffect(() => {
            if (!onChange) return;
            onChange({
                uploadHint,
                readyForImport,
                file,
                result,
                fileList,
                precheckResult,
                entityDef
            })
        },
        [entityDef, file, fileList, onChange, precheckResult, readyForImport, result, title, uploadHint]
    );

    const handleReadyForImportChanged = useCallback(response => {
            setReadyForImport(response.success);
        },
        []
    );

    const handleImport = useCallback(() => {
            setIsImporting(true);
            onImport({
                onSuccess: response => {
                    setIsImporting(false);
                },
                onError: response => {
                    setIsImporting(false);
                },
                file,
                setResult
            });
        },
        [file, onImport]
    );

    const handleResetAll = useCallback(() => {
            setReadyForImport(false);
            setFile(null);
            setResult(undefined);
            setPrecheckResult(undefined)
            setFileList([]);
            if (onReset) {
                onReset();
            }
        },
        [onReset]
    );

    const handleCancel = useCallback(() => {
            handleResetAll();
            closeContainer();
        },
        [closeContainer, handleResetAll]
    );

    const okButtonProps = {
        disabled: !readyForImport || result,
        loading: isImporting
    };

    return (
        <div>
            <h3>{title}</h3>
            <FileImportDialog
                uploadHint={uploadHint}
                onReadyForImportChanged={handleReadyForImportChanged}
                onImport={handleImport}
                onTest={onTest}
                file={file}
                setFile={setFile}
                customError={customError}
                onReset={handleResetAll}
                extraOptions={extraOptions}
                //extraDeps={extraDeps}
                fileList={fileList}
                setFileList={setFileList}
                precheckResult={precheckResult}
                setPrecheckResult={setPrecheckResult}
                disabled={result}
                reuploadToken={reuploadToken}
                setReuploadToken={setReuploadToken}
                preview={result === undefined}
            />
            {result &&
            <ImportErrorDisplay customError={customResult} result={result}/>
            }
            <Row justify="end" style={{paddingTop: "24px"}}>
                <Space>
                    <Button
                        key="reset"
                        type="link"
                        onClick={handleResetAll}
                    >
                        Reset
                    </Button>
                    <Button
                        key="cancel"
                        type={result ? "primary" : ""}
                        onClick={handleCancel}
                    >
                        {result ? "Close" : "Cancel"}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleImport}
                        loading={isImporting}
                        {...okButtonProps}
                    >
                        Import
                    </Button>
                </Space>
            </Row>
        </div>
    )
}

export default FileImportForm;