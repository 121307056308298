import {Button, Input, Table, Space} from "antd";
import React, {useContext, useMemo, useState} from "react";
import {useFieldArray} from "react-hook-form";
import EntityTag from "../bits/EntityTag";
import dayjs from "dayjs";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import TextAreaField from "../input/TextAreaField";
import {removeColumn} from "./inputComplexLib";
import {formStyle} from "../forms/formStyle";
import {FormExtContext} from "../forms/FormExt";
import MyUserContext from "../../contexts/MyUserContext";


const HistoryTableField = (props) => {
    const {paramName} = props;
    const {control} = useContext(FormExtContext);
    const {myUser} = useContext(MyUserContext);
    const {fields, append, remove} = useFieldArray({
        control,
        name: paramName,
    });
    const [comment, setComment] = useState('');

    const columns = useMemo(() => [
            {
                title: 'Comment',
                dataIndex: 'comment',
                render: (comment, _, index) =>
                    <TextAreaField paramName={paramName + '.' + index + '.comment'} control={control} rows={1}/>
            },
            {
                title: 'Time',
                dataIndex: 'datetimeUpdate',
                width: 150,
                render: date =>
                    date === null ? "new" : dayjs(date).format('YYYY-MM-DD HH:mm')
            },
            {
                title: 'User',
                dataIndex: 'user',
                width: 180,
                render: entityRef =>
                    <EntityTag entityRef={entityRef} entityDef={entityDefs.panUser}/>
            },
            removeColumn(remove)
        ],
        [paramName, remove, control]
    );

    return (
        <>
            {fields.length > 0 &&
            <div>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={fields}
                    rowKey='id'
                    pagination={false}
                    className='mini-table'
                />
            </div>
            }
            <Space.Compact>
                <Input
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{...formStyle, width: 'calc(100% - 120px)'}}
                    placeholder='Add comment'
                />
                <Button
                    disabled={comment === ''}
                    type='primary'
                    onClick={() => {
                        append({
                            comment,
                            user: myUser,
                            datetimeUpdate: null
                        });
                        setComment('');
                    }}
                >
                    Add comment
                </Button>
            </Space.Compact>
        </>
    )
}

export default HistoryTableField;
