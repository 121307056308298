import PropTypes from "prop-types";
import {LogoutOutlined} from "@ant-design/icons";
import React from "react";


const OrcidLink = (props) => {
    const {orcid} = props;
    if(!orcid) return <span/>
    return (
        <a href={"https://orcid.org/" + encodeURI(orcid)} target="_blank" rel="noreferrer">
            {orcid} <LogoutOutlined/>
        </a>
    );
}

OrcidLink.propTypes = {
    orcid: PropTypes.string
}

export default OrcidLink;