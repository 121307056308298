import {Controller} from "react-hook-form";
import {AutoComplete} from "antd";
import React, {useContext} from "react";
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";


const AutocompleteField = (props) => {
    const {paramName, placeholder, options, onChange, style} = props;
    const {setValue, control, watch} = useContext(FormExtContext);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));
    const watchField = watch(paramName);

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <div ref={drop}>
                    <DndHighlight canDrop={canDrop} isOver={isOver}/>
                    <AutoComplete
                        {...field}
                        value={watchField}
                        style={{
                            ...formStyle,
                            minWidth: "150px",
                            width: "100%",
                            ...style
                        }}
                        placeholder={placeholder}
                        options={options}
                        onChange={(e) => {
                            field.onChange(e);
                            if (onChange) onChange(e);
                        }}
                        disabled={options.length === 0}
                    />
                    <FormFieldError paramName={paramName}/>
                </div>
            }
        />
    )
}

AutocompleteField.propTypes = {
    paramName: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    style: PropTypes.object
}

export default AutocompleteField;
