import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import LoginForm from "./pages/login/LoginForm";
import EditorialApp from "./pages/editorial/EditorialApp";
import useMyUser from "./hooks/useMyUser";
import MyUserContext from "./contexts/MyUserContext";
import useWS2Axios from "./hooks/useWS2Axios";
import {apiBaseUrl} from "./lib/config";
import TestPage2 from "./pages/test/TestPage2";
import TestPage3 from "./pages/test/TestPage3";
import TestPage4 from "./pages/test/TestPage4";


const AppRoutes = (props) => {
    const {ws2Axios} = useWS2Axios();
    const {myUser, login, logout} = useMyUser();

    useEffect(() => {
            ws2Axios.post(
                apiBaseUrl + "login"
            )
                .then(response => {
                    login(response.data);
                })
        },
        [login, ws2Axios]
    );

    if (myUser.id === null) {
        return <div>Not logged in ...</div>
    }

    return (
        <MyUserContext.Provider value={{myUser, login, logout}}>
            <Routes>
                <Route exact path="/" element={<EditorialApp/>}/>
                <Route path="/login" element={<LoginForm/>}/>
                <Route path="/test2" element={<TestPage2/>}/>
                <Route path="/test3" element={<TestPage3/>}/>
                <Route path="/test4" element={<TestPage4/>}/>
            </Routes>
        </MyUserContext.Provider>
    )
}

export default AppRoutes;
