import React from "react";
import {Button} from "antd";
import {StarFilled, StarOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {isStarred, setStar} from "../../features/stars/entityStarsSlice";

export const starStyle = {
    paddingLeft: '4px',
    paddingRight: '4px',
    marginTop: '-4px',
    marginBottom: '-4px'
};

const Starred = (props) => {
    const {entityRef, entityDef} = props;
    const isStarredHere = useSelector(state => isStarred(state.entityStars.starStore, entityRef, entityDef.entityType));
    const dispatch = useDispatch();

    if (isStarredHere) {
        return (
            <Button
                type='link'
                style={starStyle}
                onClick={e => {
                    e.stopPropagation();
                    dispatch(setStar({
                        entityRef,
                        entityType: entityDef.entityType,
                        isStarred: false
                    }));
                }}
            >
                <StarFilled/>
            </Button>
        )
    }

    // If not starred
    return (
        <Button
            type='link'
            style={starStyle}
            onClick={e => {
                e.stopPropagation();
                dispatch(setStar({
                    entityRef,
                    entityType: entityDef.entityType,
                    isStarred: true
                }));
            }}
        >
            <StarOutlined/>
        </Button>
    )
}

Starred.propTypes = {
    entityRef: PropTypes.object,
    entityDef: PropTypes.object
}

export default Starred;