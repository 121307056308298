import EntityFormItem from "../../../tk/forms/EntityFormItem";
import entityDefs from "../entities/entityDefs";
import EntityTagList from "../../../tk/bits/EntityTagList";
import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import DataMatrixHorizontal from "./DataMatrixHorizontal";
import {FormExtContext} from "../../../tk/forms/FormExt";
import {Empty, Switch} from "antd";
import DataMatrixVertical from "./DataMatrixVertical";


const DataMatrixForm = (props) => {
    const {dataset, datasetConfig} = props;
    const {watch} = useContext(FormExtContext);
    const watchSeries = watch("series");
    const watchMatrixConfigs = watch("matrixConfigs");
    const [displayMode, setDisplayMode] = useState(false);

    if (watchSeries === undefined || watchSeries.length === 0 || watchMatrixConfigs === undefined || dataset === undefined) {
        return (
            <Empty/>
        )
    }

    const watchSeries2 = watchMatrixConfigs.map(matrixConfig => matrixConfig.dataSeries)

    const unconfiguredSeries = watchSeries.filter(series =>
        watchSeries2.filter(config => config.id === series.id).length === 0
    );

    return (
        <>
            {dataset.events && dataset.events.length === 1 &&
                <EntityFormItem label="Single Event" entityDef={entityDefs.event}>
                    <EntityTagList list={dataset.events} empty={<div>none</div>}/>
                </EntityFormItem>
            }
            <EntityFormItem label="Unconfigured Series" entityDef={entityDefs.series}>
                <EntityTagList list={unconfiguredSeries} empty={<div>none</div>}/>
            </EntityFormItem>
            <EntityFormItem label="View" noChildrenProps={true}>
                <Switch
                    checked={displayMode}
                    onChange={setDisplayMode}
                    unCheckedChildren="Vertical"
                    checkedChildren="Horizontal"
                />
            </EntityFormItem>
            {!displayMode &&
                <EntityFormItem
                    label="Data&nbsp;Matrix&nbsp;Configuration"
                    border={true}
                    paramName="matrixConfigs"
                >
                    <DataMatrixVertical
                        firstEvent={dataset.events.length > 0 ? dataset.events[0] : {id: null, name: null}}
                        datasetConfig={datasetConfig}
                    />
                </EntityFormItem>
            }
            {displayMode &&
                <EntityFormItem
                    label="Data&nbsp;Matrix&nbsp;Configuration"
                    paramName='matrixConfigs'
                    border={true}
                >
                    <DataMatrixHorizontal
                        idDataset={dataset.idDataset}
                        columnWidth={200}
                        firstEvent={dataset.events.length > 0 ? dataset.events[0] : {id: null, name: null}}
                        datasetConfig={datasetConfig}
                    />
                </EntityFormItem>
            }
        </>
    )
}

DataMatrixForm.propTypes = {
    dataset: PropTypes.object,
    datasetConfig: PropTypes.object
}

export default DataMatrixForm;