import useLocalStorage from "./useLocalStorage";

const useLocalStorageNested = (containerName, key, initialValue) => {
    //containerName = "columnFilters"
    const initialValueNested = {}
    initialValueNested[key] = initialValue;
    // if key=series: columnFilters:{series:[{"id":"id","name":"ID","checked":true},..],}

    if (!containerName || !key || !initialValue) {
        throw Error("Missing or falsy args for useLocalStorageNested");
    }

    const [storedValueNested, setValue] = useLocalStorage(containerName, initialValueNested);
    const setValueNested = (value) => {
        try {
            const newValue = {}
            newValue[key] = value;
            const storedValueUpdated = {...storedValueNested, ...newValue};
            setValue(storedValueUpdated);
        } catch (error) {
            console.log(error);
        }
    }

    const storedValue = storedValueNested[key] ? storedValueNested[key] : initialValue
    // case of yet unset key

    return [storedValue, setValueNested];
};

export default useLocalStorageNested;