import React from "react";

const MySettingsContext = React.createContext({
    wideTags: false,
    messageApi: null,
    onClone: null
});

export default MySettingsContext;

