import CitationButton from "../dataset/CitationButton";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {requestCitation} from "../../../lib/networkRequests";


const overviewButtons = {
    dataset: (entityRef, ws2Axios) => (
        <CitationButton
            onLoad={() =>
                requestCitation(ws2Axios, entityRef.id)
                    .then(result => ({
                        html: <div>{result.data}</div>,
                        text: result.data
                    }))}
        >
            <Button type="link">
                <FontAwesomeIcon icon={faQuoteLeft}/>
            </Button>
        </CitationButton>
    )
}

export default overviewButtons;