import React, {useEffect, useState} from "react";
import {apiBaseUrl} from "../../../lib/config";
import entityDefs from "../entities/entityDefs";
import PropTypes from "prop-types";
import {Space, Spin} from "antd";
import MyOk from "../../../tk/bits/MyOk";
import MyError from "../../../tk/bits/MyError";
import useWS2Axios from "../../../hooks/useWS2Axios";


export const isTerminalStatus = status => {
    switch (status) {
        case "FINISHED":
        case "CANCELED":
            return true;
        default:
            return false;
    }
}

const getStatusIcon = status => {
    switch (status) {
        case "FINISHED":
            return <MyOk/>
        case "CANCELED":
            return <MyError/>
        default:
            return <Spin/>
    }
}

const DatasetImportStatus = (props) => {
    const {idDataset, withHint} = props;
    const [requestNo, setRequestNo] = useState(0);
    const [importStatus, setImportStatus] = useState([]);
    const {ws2Axios} = useWS2Axios();
    useEffect(() => {
            const importerPollSecs = Number(process.env.REACT_APP_IMPORTER_POLL_SECS);
            if (importerPollSecs === 0) return;

            const doStatusRequest = () => {
                if (idDataset !== undefined) {
                    ws2Axios
                        .get(apiBaseUrl + entityDefs.dataset.apiGet + "/" + idDataset + "/importstatus")
                        .then(response => setImportStatus(response.data))
                } else {
                    setImportStatus([]);
                }
            }

            doStatusRequest();
            const timeout = setTimeout(() => {
                setRequestNo(requestNo + 1);
                doStatusRequest();
            }, importerPollSecs * 1000);
            return () => clearTimeout(timeout);
        },
        [idDataset, ws2Axios, requestNo]
    );

    if (importStatus.length === 0) {
        return <Space><div>Import status: n/a</div></Space>
    }
    const lastStatus = importStatus[0];
    const icon = getStatusIcon(lastStatus.status);

    if (lastStatus.exceptions !== undefined && lastStatus.exceptions.length > 0) {
        return <Space><div>{icon}</div><div>Import {lastStatus.status}, {lastStatus.exceptions.map(ex => <span>{ex}</span>)}</div></Space>
    }
    if (withHint) {
        return (
            <div>
                <Space><div>{icon}</div><div>Import {lastStatus.status}</div></Space>
                {!isTerminalStatus(lastStatus.status) && <><br/>
                    Your import is being processed in the background ... you can close this dialog, now.
                </>}
            </div>
        )
    }
    return (
        <Space><div>{icon}</div><div>Import {lastStatus.status}</div></Space>
    )
}

DatasetImportStatus.propTypes = {
    idDataset: PropTypes.number,
    withHint: PropTypes.bool
}

export default DatasetImportStatus;