import {configureStore} from "@reduxjs/toolkit";
import entityStarReducer from "./features/stars/entityStarsSlice";
import filterStarReducer from "./features/stars/filterStarsSlice"


export const store = configureStore({
    preloadedState: {
        entityStars: {
            starStore: JSON.parse(localStorage.getItem("starred")) || {}
        },
        filterStars: {
            starStore: JSON.parse(localStorage.getItem("starredFilters")) || {}
        }
    },
    reducer: {
        entityStars: entityStarReducer,
        filterStars: filterStarReducer
    }
});

store.subscribe(() => {
    localStorage.setItem("starred", JSON.stringify(store.getState().entityStars.starStore));
    localStorage.setItem("starredFilters", JSON.stringify(store.getState().filterStars.starStore));
});