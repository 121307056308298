import PropTypes from "prop-types";
import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
var weekYear = require('dayjs/plugin/weekYear') // dependent on weekOfYear plugin
dayjs.extend(weekYear)
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(timezone)


const FormattedDatetime = (props) => {
    const {value, format, style} = props;
    if (value === null) {
        return "";
    }
    if (format === undefined || format === null) {
        return value.toString();
    }

    let format2 = format;
    format2 = format2.replaceAll("y", "Y")
    format2 = format2.replaceAll("d", "D")
    format2 = format2.replaceAll("'T'", "T")
    format2 = format2.replaceAll("DDD", "ddd")

    return (
        <div style={style}>
            {dayjs.utc(value).format(format2)}
        </div>
    );
}

FormattedDatetime.propTypes = {
    value: PropTypes.string,
    format: PropTypes.string,
    style: PropTypes.object
}

export default FormattedDatetime;