import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";
import PangaeaDatasetLink from "../../../tk/bits/PangaeaDatasetLink";

const DatasetOverview = (props) => {
    const {entity} = props;
    let staffs;
    if (entity.staffs !== undefined) {
        staffs = entity.staffs.map((staffsComplex) => staffsComplex.staffs);
    }
    let references;
    if(entity.references !== undefined) {
        references = entity.references.map(refComplex => refComplex.reference)
    }
    let datasets;
    if(entity.datasets !== undefined) {
        datasets = entity.datasets.map(refComplex => refComplex.datasetRelated)
    }
    return (
        <>
            <OverviewLine label="Author(s)" value={staffs} entityDef={entityDefs.staffs} max={3}/>
            <OverviewLine label="Title" value={entity.title}/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="Status" value={entity.datasetStatus} entityDef={entityDefs.datasetStatus}/>
            <OverviewLine label="Protection" value={entity.loginOption} entityDef={entityDefs.loginOption}/>
            <OverviewLine label="License" value={entity.license} entityDef={entityDefs.license}/>
            <OverviewLine label="Moratorium until" value={entity.moratoriumUntil}/>
            <OverviewLine label="Curation level" value={entity.curationLevel} entityDef={entityDefs.curationLevel}/>
            <OverviewLine label="Processing level" value={entity.processingLevel} entityDef={entityDefs.processingLevel}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term} max={3}/>
            <OverviewLine label="Ticket" value={entity.ticket} type="ticket"/>
            <OverviewLine label="Projects" value={entity.projects} entityDef={entityDefs.project} max={3}/>
            <OverviewLine label="Awards" value={entity.awards} entityDef={entityDefs.award} max={3}/>
            <OverviewLine label="Events" value={entity.events} entityDef={entityDefs.event} max={3}/>
            <OverviewLine label="References (literature & data)" value={references} entityDef={entityDefs.reference} max={3}/>
            <OverviewLine label="References (PANGAEA data sets)" value={datasets} entityDef={entityDefs.dataset} max={3}/>
            <OverviewLine label="Data series" value={entity.series} entityDef={entityDefs.series} max={3}/>
            <PangaeaDatasetLink idDataset={entity.idDataset}/>
        </>
    )
}

export default DatasetOverview;