import React, {useMemo} from "react";
import {StarTwoTone} from "@ant-design/icons";
import {useSelector} from "react-redux";
import StarredFilters from "../../tk/bits/StarredFilters";
import Usages from "../../tk/bits/Usages";


const StarPage = () => {
    const starStore = useSelector(state => state.entityStars.starStore);

    const usagesData = useMemo(() =>
            Object
                .entries(starStore)
                .map(usage => {
                    return {
                        entityRefs: usage[1],
                        type: usage[0],
                    }
                }),
        [starStore]
    );

    return (
        <div style={{padding: "4px"}}>
            <h2>
                <StarTwoTone twoToneColor='#AA0'/>
                <StarTwoTone twoToneColor='#AA0'/>
                <StarTwoTone twoToneColor='#AA0'/>
            </h2>
            <h5>Entities</h5>
            <Usages usages={usagesData} withClearStarsButtons={true}/>
            <h5>Filtered Lists</h5>
            <StarredFilters/>
        </div>
    )
}

export default StarPage;