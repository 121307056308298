import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const ParameterOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Abbreviation" value={entity.abbreviation}/>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Unit" value={entity.unit}/>
            <OverviewLine label="Data type" value={entity.dataType} entityDef={entityDefs.dataType}/>
            <OverviewLine label="Description" value={entity.description}/>
            <OverviewLine label="Format" value={entity.format}/>
            <OverviewLine label="Range min" value={entity.rangeMin}/>
            <OverviewLine label="Range max" value={entity.rangeMax}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="Method" value={entity.method} entityDef={entityDefs.method}/>
            <OverviewLine label="Reference" value={entity.reference} entityDef={entityDefs.reference}/>
            <OverviewLine label="UCUM" value={entity.ucum}/>
            <OverviewLine label="Keywords" value={entity.keywords} entityDef={entityDefs.term}/>
        </div>
    )
}

export default ParameterOverview;