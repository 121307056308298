import {useCallback, useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import entityDefs from "../pages/editorial/entities/entityDefs";
import useDatasetConfigFields from "./useDatasetConfigFields";
import useWS2Axios from "./useWS2Axios";


const useDatasetConfig = (series) => {
    const [error, setError] = useState(undefined);
    const {configFields, configFieldsLoading} = useDatasetConfigFields();
    const {ws2Axios} = useWS2Axios();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(true);

    const handleError = useCallback((errorResponse) => {
        if (error !== undefined) return;
        let response = errorResponse.response;
        if (!response) {
            setError({
                code: "0",
                message: "Oh no, an error!"
            })
        } else {
            setError(response.data) // with fields: data.code and data.message
        }
    }, [error]);

    const compileResult = (seriesList, parameterList, configFields) => ({
        series: seriesList.reduce((prev, current) => {
            prev[current.me.id] = current;
            return prev;
        }, {}),
        parameter: parameterList.reduce((prev, current) => {
            prev[current.me.id] = current;
            return prev;
        }, {}),
        fields: configFields !== undefined ?
            configFields.reduce((prev, current) => {
                prev[current.me.id] = current;
                return prev;
            }, {}) : {}
    });

    useEffect(() => {
            let seriesListResp;
            setLoading(true);

            if (series === undefined || series.length === 0) {
                setResult(compileResult([], [], []));
                setLoading(false);
                return;
            }

            if(configFieldsLoading) {
                return;
            }

            const query = series
                .map(series => series.id)
                .reduce((prevId, currentId) => prevId + "," + currentId);

            ws2Axios
                .post(
                    apiBaseUrl + entityDefs.series.apiGet + "/list",
                    {
                        offset: 0,
                        limit: 10000,
                        sort: "id",
                        q: "id=in=(" + query + ")"
                    }
                )
                .then(response => {
                    seriesListResp = response.data.records;

                    const query = seriesListResp
                        .map(series => series.parameter.id)
                        .reduce((prevId, currentId) => prevId + "," + currentId);

                    return ws2Axios
                        .post(
                            apiBaseUrl + entityDefs.parameter.apiGet + "/list",
                            {
                                offset: 0,
                                limit: 10000,
                                sort: "id",
                                q: "id=in=(" + query + ")"
                            }
                        )
                })
                .then(response => {
                    let parameterListResp = response.data.records;
                    setResult(compileResult(seriesListResp, parameterListResp, configFields));
                    setLoading(false);
                })
                .catch(errorResponse => {
                    handleError(errorResponse);
                    setLoading(false);
                });
        },
        [configFields, configFieldsLoading, handleError, series, ws2Axios/*, instanceId*/]
    );

    return [
        result,
        loading,
        error
    ];
};

export default useDatasetConfig;
