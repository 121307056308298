import {SearchOutlined} from '@ant-design/icons';
import NumericSearchProps from "./NumericSearchProps";
import React from "react";

const getColumnSearchPropsNumeric = (dataIndex) => {
    const autofocusRef = React.createRef();
    return ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) =>
            <div style={{
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '4px'
            }}>
                <NumericSearchProps
                    autofocusRef={autofocusRef}
                    dataIndex={dataIndex}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            </div>,

        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => autofocusRef.current?.select(), 100);
            }
        }

    });
};

export default getColumnSearchPropsNumeric;
