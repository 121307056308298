import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const ProjectOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Name" value={entity.name}/>
            <OverviewLine label="Label" value={entity.label}/>
            <OverviewLine label="URI" value={entity.uri} type="uri"/>
            <OverviewLine label="URI data" value={entity.uriData} type="uri"/>
            <OverviewLine label="Project type" value={entity.projectType} entityDef={entityDefs.projectType}/>
            <OverviewLine label="Coordinator" value={entity.staffs} entityDef={entityDefs.staffs}/>
            <OverviewLine label="Institution" value={entity.institution} entityDef={entityDefs.institution}/>
            <OverviewLine label="Comment" value={entity.comment}/>
        </div>
    )
}

export default ProjectOverview;