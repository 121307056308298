import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, Col, Form, Input, Layout, Row, Space} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {apiBaseUrl} from "../../lib/config";
import MyUserContext from "../../contexts/MyUserContext";
import useWS2Axios from "../../hooks/useWS2Axios";
import "./LoginForm.css"

const {Content, Footer} = Layout;

const certifiedImageStyle = {width: "136px"}
const socialImageStyle = {width: "75px"}

const LoginForm = (props) => {
    const {register} = useForm();
    const {login} = useContext(MyUserContext);
    const navigate = useNavigate();
    const {ws2Axios} = useWS2Axios();

    const onSubmit = data => {
        ws2Axios.post(
            apiBaseUrl + "login",
            {},
            {
                auth: data
            }
        )
            .then(response => {
                login(response.data);
                navigate("/");
            })
    }

    return (
        <Layout>
            <Content style={{backgroundColor: "white"}}>
                <Row style={{paddingTop: "100px"}}>
                    <Col flex="auto"/>
                    <Col flex="300px">
                        <div id='components-form-demo-normal-login'>
                            <h1>Login to PANGAEA</h1>
                            <Form onFinish={onSubmit} className="login-form">
                                <Form.Item
                                    name="username"
                                    rules={[{required: true, message: 'Please input your Username!'}]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                           placeholder="Username"/>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{required: true, message: 'Please input your Password!'}]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon"/>}
                                        type="password"
                                        placeholder="Password"
                                        {...register("password")}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox
                                            {...register("remember")}
                                        >
                                            Remember me
                                        </Checkbox>
                                    </Form.Item>

                                    <a className="login-form-forgot"
                                       href="https://www.pangaea.de/user/lostpass.php?referer=">
                                        Forgot password
                                    </a>
                                </Form.Item>

                                <Form.Item>
                                    <Button className="login-form-button" type='primary' htmlType='submit'>
                                        Log in
                                    </Button>
                                    Or <a href="https://www.pangaea.de/user/signup.php?referer=">register now!</a>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col flex="auto"/>
                </Row>
            </Content>
            <Footer className="myfooter">
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col>
                        <h4>PANGAEA is hosted by</h4>
                        <p>
                            Alfred Wegener Institute, Helmholtz Center for Polar and Marine Research (AWI)<br/>
                            Center for Marine Environmental Sciences, University of Bremen (MARUM)
                        </p>

                        <h4>The System is supported by</h4>
                        <p>
                            The European Commission, Research<br/>
                            Federal Ministry of Education and Research (BMBF)<br/>
                            Deutsche Forschungsgemeinschaft (DFG)<br/>
                            International Ocean Discovery Program (IODP)
                        </p>

                        <Space size="large">
                            <a href="https://pangaea.de/about/legal.php">Legal notice</a>
                            <a href="https://pangaea.de/about/terms.php">Terms of use</a>
                            <a href="https://pangaea.de/about/privacypolicy.php">Privacy policy</a>
                            <a href="https://pangaea.de/about/cookies.php">Cookies</a>
                            <a href="https://pangaea.de/about/jobs.php">Jobs</a>
                            <a href="https://pangaea.de/contact/">Contact</a>
                        </Space>

                    </Col>
                    <Col>
                        <h4>PANGAEA is certified by</h4>
                        <Space>
                            <a href="//www.worlddatasystem.org/" target="_blank" title="World Data System" rel="noreferrer">
                                <img className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                     style={certifiedImageStyle}
                                     src="https://pangaea.de/assets/v.c52a7fe50cc6e2caad4c5ad8d5ea1a42/logos/logo-wds-block.png"
                                     alt="World Data System"/>
                            </a>
                            <a href="//www.wmo.int/" target="_blank" title="World Meteorological Organization" rel="noreferrer">
                                <img className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                     style={certifiedImageStyle}
                                     src="https://pangaea.de/assets/v.c52a7fe50cc6e2caad4c5ad8d5ea1a42/logos/logo-wmo-block.png"
                                     alt="World Meteorological Organization"/>
                            </a>
                            <a href="//www.coretrustseal.org/" target="_blank" title="CoreTrustSeal" rel="noreferrer">
                                <img className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                     style={certifiedImageStyle}
                                     src="https://pangaea.de/assets/v.c52a7fe50cc6e2caad4c5ad8d5ea1a42/logos/logo-coretrustseal-block.png"
                                     alt="CoreTrustSeal"/>
                            </a>
                        </Space>
                    </Col>
                    <Col>
                        <h4>Share on...</h4>
                        <Space>
                            <a rel="nofollow noreferrer" className="self-referer-link share-link"
                               href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fpangaea.de%2F&t=Data+Publisher+for+Earth+%26+Environmental+Science"
                               title="Share on Facebook" target="_blank">
                                <img id="facebook-icon" className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                     style={socialImageStyle}
                                     src="https://pangaea.de/assets/v.c52a7fe50cc6e2caad4c5ad8d5ea1a42/social-icons/facebook-icon.png"
                                     alt="Facebook Icon"/>
                            </a>
                            <a rel="nofollow noreferrer" className="self-referer-link share-link"
                               href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fpangaea.de%2F&text=Data+Publisher+for+Earth+%26+Environmental+Science&via=PANGAEAdataPubl"
                               title="Share on Twitter" target="_blank">
                                <img id="twitter-icon" className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                     style={socialImageStyle}
                                     src="https://pangaea.de/assets/v.c52a7fe50cc6e2caad4c5ad8d5ea1a42/social-icons/twitter-icon.png"
                                     alt="Twitter Icon"/>
                            </a>
                        </Space>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    )
}

export default LoginForm;