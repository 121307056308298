import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useWS2Axios from "./useWS2Axios";
import useReload from "./useReload";
import {handleNetworkError} from "../lib/errorHandler";
import {listRequest} from "../lib/networkRequests";
import {emptyEntityRef} from "../lib/entityUtils";


const useEntityList = (entityDef, pagination, sort, query, prefabAxios) => {
    const [entityList, setEntityList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(100);
    const [error, setError] = useState(undefined);
    const navigate = useNavigate();
    const {ws2Axios} = useWS2Axios(prefabAxios);
    const {reload, reloadDependency} = useReload();
    const [controller, setController] = useState();

    const offset = pagination ? ((pagination.current - 1) * pagination.pageSize) : 0;
    const limit = pagination ? pagination.pageSize : 100;

    useEffect(() => {
            if (query === undefined) return;
            //console.log("LOAD LIST", pagination, sort, query)
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);
            setLoading(true);

            listRequest(ws2Axios, entityDef, query, offset, limit, sort, {signal: newController.signal})
                .then(response => {
                    setController(undefined);
                    setLoading(false);
                    setError(undefined);
                    if (response.data.records.length === 0) {
                        setEntityList([]);
                        setTotal(0);
                    } else {
                        const rows = response.data.records.map((row, index) => ({...row, key: index}));
                        setEntityList(rows);
                        setTotal(response.data.total);
                    }
                })
                .catch(errorResponse => {
                    if (errorResponse.message !== "canceled") {
                        setLoading(false);
                        setError(handleNetworkError(errorResponse));
                        setEntityList([]);
                        setTotal(0);
                    }
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityDef.entityType, pagination.current, pagination.pageSize, sort, navigate, query, ws2Axios, offset, limit, reloadDependency]
    );

    const findById = id => {
        if (!entityList || entityList.length === 0) return emptyEntityRef;
        const foundEntity = entityList.find(entity => entity.me.id === id);
        if (!foundEntity) {
            return emptyEntityRef;
        }
        return foundEntity.me;
    }

    return {entityList, loading, total, error, reload, findById};
};

export default useEntityList;
