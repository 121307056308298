import {useState} from "react";

const useReload = () => {
    const [reloadDependency, setReloadDependency] = useState(Math.random());
    const reload = () => {
        setReloadDependency(Math.random());
    }
    return {reload, reloadDependency}
}

export default useReload;
