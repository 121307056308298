import {Button} from "antd";
import React, {useContext} from "react";
import FlexLayoutContext from "../../contexts/FlexLayoutContext";
import entityDefs from "./entities/entityDefs";


const EntityButton = (props) => {
    const {entityDef} = props;
    const {addListTab} = useContext(FlexLayoutContext);
    return (
        <Button
            style={{width: "100%", backgroundColor: entityDef.bgColor}}
            onClick={() => addListTab(entityDef)}
        >
            {entityDef.labelPl}
        </Button>
    )
}

const FourthDMenu = (props) =>
    <div>
        <EntityButton entityDef={entityDefs.project}/>
        <EntityButton entityDef={entityDefs.campaign}/>
        <EntityButton entityDef={entityDefs.event}/>
        <EntityButton entityDef={entityDefs.dataset}/>
        <EntityButton entityDef={entityDefs.series}/>
        <EntityButton entityDef={entityDefs.institution}/>
        <EntityButton entityDef={entityDefs.basis}/>
        <EntityButton entityDef={entityDefs.staffs}/>
        <EntityButton entityDef={entityDefs.reference}/>
        <EntityButton entityDef={entityDefs.method}/>
        <EntityButton entityDef={entityDefs.parameter}/>
        <EntityButton entityDef={entityDefs.term}/>
        <EntityButton entityDef={entityDefs.keyword}/>
        <EntityButton entityDef={entityDefs.location}/>
        <EntityButton entityDef={entityDefs.terminology}/>
    </div>

export default FourthDMenu;