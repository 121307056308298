import useUsages from "../../hooks/useUsages";
import {Spin} from "antd";
import Usages from "./Usages";
import PropTypes from "prop-types";


const UsagesLoader = (props) => {

    const {entityDef, entityId} = props;
    const {usages} = useUsages(entityDef, entityId);

    if (usages === undefined) {
        return <Spin/>
    }

    return <Usages usages={usages}/>
}

UsagesLoader.propTypes = {
    entityDef: PropTypes.object.isRequired,
    entityId: PropTypes.number.isRequired
}

export default UsagesLoader;
