import React from "react";
import PropTypes from "prop-types";
import {LogoutOutlined} from "@ant-design/icons";


const smallAndBoldStyle = {
    fontWeight: 'bolder',
    fontSize: 'smaller'
};

const JiraLink = ({ticket, disabled, size, children}) => {

    if ((!ticket || ticket.length === 0) && !children) return <span/>

    let content;

    content = <span>{children || ticket} <LogoutOutlined/></span>;
    if (size === 'small') {
        content = <span>{ticket.substring(4)}</span>
    }

    if (disabled) {
        return (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>
                {content}
            </span>
        )
    }

    return (
        <a
            className="button"
            target="_blank"
            href={"https://issues.pangaea.de/browse/" + encodeURI(ticket)}
            rel="noreferrer"
            style={!children ? smallAndBoldStyle : {}}
        >
            {content}
        </a>
    )
}

JiraLink.propTypes = {
    ticket: PropTypes.string
}

export default JiraLink;