
// Some useful colours and basic styles.
import {Button, Input, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {normaliseNumericVal} from "../../../lib/editDataSeries";
import SelectEntityInput from "../../../tk/input/SelectEntityInput";
import entityDefs from "../entities/entityDefs";

const antGray13 = '#000000';
const antRed7 = '#cf1322';

export const basicNormalStyle = { color: antGray13 };
export const basicErrorStyle = { color: antRed7 };
export const formRowHeight = '3.5ex';


export const dataTypeUtil = {
    numericTypeId: 1,
    stringTypeId: 2,
    termTypeId: 5,
    uriTypeId: 6,
    eventTypeId: 7,

    isNumericDataType: (dataType) => {
        return dataType && dataType.id === dataTypeUtil.numericTypeId;
    },

    isStringDataType: (dataType) => {
        return dataType?.id === dataTypeUtil.stringTypeId ||
               dataType?.id === dataTypeUtil.uriTypeId;
    },

    isTermDataType: (dataType) => {
        return dataType && (dataType.id ===  dataTypeUtil.termTypeId);
    },

    isEventDataType: (dataType) => {
        return dataType && (dataType.id === dataTypeUtil.eventTypeId);
    },

    isIdDataType: (dataType) => {
        return dataTypeUtil.isTermDataType(dataType) ||
            dataTypeUtil.isEventDataType(dataType);
    },

    entityDefForType: (dataType) => {
        if (dataTypeUtil.isIdDataType(dataType)) {
            return dataTypeUtil.isTermDataType(dataType) ? entityDefs.term : entityDefs.event;
        }
        else {
            return undefined;
        }
    }
};


export const infoUtil = {
    // TODO: Remove keys errorText and infoText.
    allInfoCleared: {
        fieldErrorText: '',
        apiErrorText: '',
        errorText: '',
        apiInfoText: '',
        infoText: ''
    },

    fieldError: (error) => {
        return {
            ...infoUtil.allInfoCleared,
            fieldErrorText: error
        };
    },

    apiError: (error) => {
        return {
            ...infoUtil.allInfoCleared,
            apiErrorText: error,
            errorText: error
        };
    },

    apiSuccess: (text) => {
        return {
            ...infoUtil.allInfoCleared,
            infoText: text,
            apiInfoText: text
        };
    },

    mergeTexts: (text1, text2) => {
        if (text1 === undefined) {
            return text2;
        }
        else if (text2 === undefined) {
            return text1;
        }
        else if (text1 === '') {
            return text2;
        }
        else {
            return text1;
        }
    },

    mergeInfos: (info1, info2) => {
        return {
            fieldErrorText: infoUtil.mergeTexts(info1.fieldErrorText, info2.fieldErrorText),
            errorText: infoUtil.mergeTexts(info1.errorText, info2.errorText),
            apiErrorText: infoUtil.mergeTexts(info1.errorText, info2.errorText),
            infoText: infoUtil.mergeTexts(info1.infoText, info2.infoText),
            apiInfoText: infoUtil.mergeTexts(info1.infoText, info2.infoText)
        };
    }
};


export const validator = {
    numberValidation: (value) => {
        const ok = value !== '' && !isNaN(Number(value));
        if (ok) {
            return {
                valid: true,
                style: basicNormalStyle,
                info: infoUtil.allInfoCleared
            };
        }
        else {
            const txt = value === '' ? 'The empty string' : value;
            return {
                valid: false,
                style: basicErrorStyle,
                info: infoUtil.fieldError(txt + ' is not a number.')
            };
        }
    },

    nonZeroNumberValidation: (value) => {
        const validation = validator.numberValidation(value);

        if (validation.valid) {
            if (Number(value) === 0.0) {
                return {
                    valid: false,
                    style: basicErrorStyle,
                    info: infoUtil.fieldError('Invalid input: 0.')
                }
            }
        }

        return validation;
    },

    emptyText: (text) => {
        return text === undefined || text === '';
    },

    nonEmptyText: text => (!validator.emptyText(text))
};


export const EditButtons = (props) => {
    const {editPreview, onTest, onRun, onReset} = props;

    return (
        <Space size={12} direction={"horizontal"}>
            {editPreview &&
                <Button
                    type="primary"
                    onClick={onTest}>
                    Test
                </Button>
            }
            <Button
                type="primary"
                onClick={onRun}>
                Apply
            </Button>
            <Button
                type="primary"
                onClick={onReset}>
                Reset
            </Button>
        </Space>);
};


export const InfoArea = (props) => {
    const {fieldErrorText, errorText, infoText} = props;

    const textHeight = '2ex';

    const emptyText = validator.emptyText;

    const nonEmptyText = validator.nonEmptyText;

    const allEmpty = emptyText(fieldErrorText) && emptyText(errorText) && emptyText(infoText);

    return (
        <Space size={12} direction={"vertical"} align={"start"} style={{height: textHeight}}>
            {nonEmptyText(fieldErrorText) &&
                <Row style={{height: textHeight}}>
                    <span style={basicErrorStyle}>
                        {fieldErrorText}
                    </span>
                </Row>
            }
            {nonEmptyText(errorText) &&
                <Row style={{height: textHeight}}>
                    <span style={basicErrorStyle}>
                        {errorText}
                    </span>
                </Row>
            }
            {nonEmptyText(infoText) &&
                <Row style={{height: textHeight}}>
                    <span style={basicNormalStyle}>
                        {infoText}
                    </span>
                </Row>
            }
            {allEmpty &&
                <Row style={{height: textHeight}}>
                    <span style={basicNormalStyle}>
                    </span>
                </Row>
            }
        </Space>
        );
};


export class InfoAreaController {
    constructor(props) {
        const {
            fieldErrorText,
            setFieldErrorText,
            errorText,
            setErrorText,
            infoText,
            setInfoText
        } = props;

        this.fieldErrorText = fieldErrorText;
        this.setFieldErrorText = setFieldErrorText;
        // TODO: Rename and use api everywhere.
        this.apiErrorText = errorText;
        this.setApiErrorText = setErrorText;
        this.apiInfoText = infoText;
        this.setApiInfoText = setInfoText;
    }


    reportFieldError (error) {
        this.setFieldErrorText(error);
        this.setApiErrorText('');
        this.setApiInfoText('');
    }


    reportAPIError(error) {
        this.setFieldErrorText('');
        this.setApiErrorText(error);
        this.setApiInfoText('');
    }


    reportAPISuccess(text) {
        this.setFieldErrorText('');
        this.setApiErrorText('');
        this.setApiInfoText(text);
    }


    clearAll() {
        this.setFieldErrorText('');
        this.setApiErrorText('');
        this.setApiInfoText('');
    }


    setInfo(info) {
        this.setFieldErrorText(info.fieldErrorText);
        this.setApiErrorText(info.apiErrorText);
        this.setApiInfoText(info.apiInfoText);
    };
}


export class FieldController {
    constructor(props) {
        const {
            name,
            value,
            setValue,
            style,
            setStyle
        } = props;

        this.name = name;
        this.value = value;
        this.setValue = setValue;
        this.style = style;
        this.setStyle = setStyle;
    }
}


export class FieldGroupController {
    constructor(fieldControllers) {
        this.fieldControllers = fieldControllers;
    }

    setValue(fieldName, value) {
        const fieldController = this.fieldControllers[fieldName];
        if (fieldController !== undefined) {
            fieldController.setValue(value);
        }
    }

    setValues(values) {
        for (let fieldName in values) {
            this.setValue(fieldName, values[fieldName]);
        }
    }

    setStyle(fieldName, style) {
        const fieldController = this.fieldControllers[fieldName];
        if (fieldController !== undefined) {
            fieldController.setStyle(style);
        }
    }

    setStyles(styles) {
        for (let fieldName in styles) {
            this.setStyle(fieldName, styles[fieldName]);
        }
    }
}


class EditorValidator {
    validateDefinedNewValue(newValue) {
        const msg = 'Internal error: EditorValidator.validateDefinedNewValue() must be overridden.';

        throw new Error(msg);
    }


    styleForUndefinedNewValue() {
        const msg = 'Internal error: EditorValidator.styleForUndefinedNewValue() must be overridden.';

        throw new Error(msg);
    }


    validStatus(status) {
        return status !== undefined && status?.id !== undefined && status?.id !== null;
    }


    validValue(value) {
        if (value === undefined || value === null) {
            return false;
        }
        else {
            const id = value.id;
            const name = value.name;

            return (id === undefined || id !== null) && (name === undefined || name !== null);
        }
    }


    validateFields(sequence, oldStatus, newStatus, oldValue, newValue) {
        if (sequence === undefined || oldStatus === undefined || oldValue === undefined) {
            return {
                styles: {
                    sequence: basicErrorStyle,
                    oldStatus: basicErrorStyle,
                    newStatus: basicNormalStyle,
                    oldValue: basicErrorStyle,
                    newValue: basicNormalStyle
                },
                info: infoUtil.fieldError('Please select one row in the table.')
            };
        }
        else if (!this.validStatus(newStatus)) {
            return {
                styles: {
                    sequence: basicNormalStyle,
                    oldStatus: basicNormalStyle,
                    newStatus: basicErrorStyle,
                    oldValue: basicNormalStyle,
                    newValue: this.styleForUndefinedNewValue()
                },
                info: infoUtil.fieldError('Please enter the new status to be set.')
            };
        }
        else if (!this.validValue(newValue)) {
            return {
                styles: {
                    sequence: basicNormalStyle,
                    oldStatus: basicNormalStyle,
                    newStatus: basicNormalStyle,
                    oldValue: basicNormalStyle,
                    newValue: this.styleForUndefinedNewValue()
                },
                info: infoUtil.fieldError('Please enter the new value to be set.')
            };
        }
        else {
            return this.validateDefinedNewValue(newValue);
        }
    }
}


export class NumericEditorValidator extends EditorValidator {
    validateDefinedNewValue(newValue) {
        const validation = validator.numberValidation(newValue);

        return {
            styles: {
                sequence: basicNormalStyle,
                oldStatus: basicNormalStyle,
                newStatus: basicNormalStyle,
                oldValue: basicNormalStyle,
                newValue: validation.style
            },
            info: validation.info
        };
    }


    styleForUndefinedNewValue() {
        return basicErrorStyle;
    }
}


export class StringEditorValidator extends EditorValidator {
    validateDefinedNewValue(newValue) {
        return {
            styles: {
                sequence: basicNormalStyle,
                oldStatus: basicNormalStyle,
                newStatus: basicNormalStyle,
                oldValue: basicNormalStyle,
                newValue: basicNormalStyle
            },
            info: infoUtil.allInfoCleared
        };
    }


    styleForUndefinedNewValue() {
        return basicNormalStyle;
    }
}


export class IdEditorValidator extends EditorValidator {
    validateDefinedNewValue(newValue) {
        return {
            styles: {
                sequence: basicNormalStyle,
                oldStatus: basicNormalStyle,
                newStatus: basicNormalStyle,
                oldValue: basicNormalStyle,
                newValue: basicNormalStyle
            },
            info: infoUtil.allInfoCleared
        };
    }


    styleForUndefinedNewValue() {
        return basicErrorStyle;
    }
}


export const makeEditorValidator = (dataTypeId) => {
        switch (dataTypeId) {
            case 1:
                return new NumericEditorValidator();

            case 2:
            case 6:
                return new StringEditorValidator();

            case 5:
            case 7:
                return new IdEditorValidator();

            default:
                console.log('Cannot make validator for data type', dataTypeId);
                return undefined;
        }
}


const widthString = (w) => {
    return '' + w + 'em';
};


export const DataSeriesField = (props) => {
    const {
        title,
        id,
        label,
        disabled,
        value,
        basicStyle,
        labelWidth,
        fieldWidth,
        height,
        onChange
    } = props;

    const actualHeight = height === undefined ? formRowHeight : height;
    const disabledValue = disabled !== undefined ? disabled : false;
    const width = labelWidth + fieldWidth;

    return (
        <Row style={{width: widthString(width), height: actualHeight}}>
            <Space size={12}
                   direction={"horizontal"}
                   style={{height: actualHeight}}>
                <Space
                    style={{width: widthString(labelWidth), height: actualHeight}}>
                    <span>{title}</span>
                </Space>
                <Input
                    id={id}
                    label={label}
                    disabled={disabledValue}
                    value={value}
                    style={{...basicStyle, width: widthString(fieldWidth), height: actualHeight}}
                    onChange={onChange}
                />
            </Space>
        </Row>
    );
};


export const DataSeriesIdField = (props) => {
    const {
        dataType,
        entityDef,
        title,
        disabled,
        value,
        otherValue,
        basicStyle,
        labelWidth,
        fieldWidth,
        height,
        onChange
    } = props;

    const actualWidth = widthString(labelWidth + fieldWidth);
    const actualHeight = height === undefined ? formRowHeight : height;
    const disabledValue = disabled !== undefined ? disabled : false;

    const actualEntityDef = entityDef !== undefined ? entityDef : dataTypeUtil.entityDefForType(dataType);


    return (
        <Space
            size={12}
            direction={"horizontal"}
            style={{width: actualWidth, height: actualHeight}}>
            <Space
                style={{width: widthString(labelWidth), height: actualHeight}}>
                <span>{title}</span>
            </Space>
            <SelectEntityInput
                disabled={disabledValue}
                value={value}
                filterFn={(r) => r?.id !== otherValue?.id}
                entityDef={actualEntityDef}
                noNewButton={true}
                height={actualHeight}
                fixedWidth={{
                    minWidth: widthString(fieldWidth),
                    width: widthString(fieldWidth),
                    maxWidth: widthString(fieldWidth)
                }}
                style={basicStyle}
                onChange={onChange}
            />
        </Space>
    );
};


export const DataSeriesRecordEditor = (props) => {
    const {
        // TODO: Use either id or data type as parameter.
        dataType,

        // TODO: Consider removing this, since no preview will be implemented.
        setTestLoaded,
        // TODO: Consider removing this, since no preview will be implemented.
        editPreview,

        fieldErrorText,
        errorText,
        infoText,

        selectedRowData,

        editorMethods,
        infoAreaController,
        singleEdit,
        switchRowSelectionMode
    } = props;

    const dataTypeId = dataType.id;

    const [sequence, setSequence] = useState(undefined);
    const [sequenceStyle, setSequenceStyle] = useState(basicNormalStyle);
    const sequenceController = new FieldController({
        name: 'sequence',
        value: sequence,
        setValue: setSequence,
        style: sequenceStyle,
        setStyle: setSequenceStyle
    });

    const [oldStatus, setOldStatus] = useState(undefined);
    const [oldStatusStyle, setOldStatusStyle] = useState(basicNormalStyle);
    const oldStatusController = new FieldController({
        name: 'status',
        value: oldStatus,
        setValue: setOldStatus,
        style: oldStatusStyle,
        setStyle: setOldStatusStyle
    });

    const [newStatus, setNewStatus] = useState(undefined);
    const [newStatusStyle, setNewStatusStyle] = useState(basicNormalStyle);
    const newStatusController = new FieldController({
        name: 'status',
        value: newStatus,
        setValue: setNewStatus,
        style: newStatusStyle,
        setStyle: setNewStatusStyle
    });

    const [oldValue, setOldValue] = useState(undefined);
    const [oldValueStyle, setOldValueStyle] = useState(basicNormalStyle);
    const oldValueController = new FieldController({
        name: 'oldValue',
        value: oldValue,
        setValue: setOldValue,
        style: oldValueStyle,
        setStyle: setOldValueStyle
    });

    const [newValue, setNewValue] = useState(undefined);
    const [newValueStyle, setNewValueStyle] = useState(basicNormalStyle);
    const newValueController = new FieldController({
        name: 'newValue',
        value: newValue,
        setValue: setNewValue,
        style: newValueStyle,
        setStyle: setNewValueStyle
    });

    const fieldsController = new FieldGroupController({
        sequence: sequenceController,
        oldStatus: oldStatusController,
        newStatus: newStatusController,
        oldValue: oldValueController,
        newValue: newValueController
    });

    const editorValidator = makeEditorValidator(dataTypeId);

    const doNothing = (e) => {};


    const onNewStatusChanged = obj => {
        setNewStatus(obj);
        setTestLoaded(false);

        validateFields(sequence, oldStatus, obj, oldValue, newValue);
    };


    const getChangedValue = (obj) => {
        if (dataTypeUtil.isIdDataType(dataType)) {
            return obj;
        }
        else {
            return obj?.target?.value;
        }
    }


    const onNewValueChanged = obj => {
        const value = getChangedValue(obj);

        setNewValue(value);
        setTestLoaded(false);

        validateFields(sequence, oldStatus, newStatus, oldValue, value);
    };


    const validateFields = (sequence, oldStatus, newStatus, oldValue, newValue) => {
        const validation = editorValidator.validateFields(sequence, oldStatus, newStatus, oldValue, newValue);

        fieldsController.setStyles(validation?.styles);
        infoAreaController.setInfo(validation?.info);
    }


    useEffect(() => {
        const updateFields = (selectedRow) => {
            if (!singleEdit) {
                return;
            }

            if (selectedRow === undefined) {
                setSequence(undefined);
                setOldStatus(undefined);
                setNewStatus(undefined);
                setOldValue(undefined);
                setNewValue(undefined);

                validateFields(undefined, undefined, undefined, undefined, undefined);
            }
            else {
                const currentStatus = selectedRow.dataStatus;
                const currentValue = selectedRow.value;

                setSequence(selectedRow.sequence);
                setOldStatus(currentStatus);
                setNewStatus(currentStatus);
                setOldValue(currentValue);
                setNewValue(currentValue);

                validateFields(selectedRow.sequence, currentStatus, currentStatus, currentValue, currentValue);
            }
        };

        updateFields(selectedRowData);

        return () => {
            updateFields(selectedRowData);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowData, singleEdit]);


    const onReset = () => {
        setNewStatus(undefined);
        setNewValue(undefined);

        // This resets / clears the selected row.
        switchRowSelectionMode(singleEdit);
    };


    const prepareValue = (newValue) => {
        switch (dataTypeId) {
            case 1:
                return normaliseNumericVal(newValue);

            case 2:
            case 6:
            case 5:
            case 7:
                return newValue;

            default:
                console.log('Cannot prepare new value for processing:', dataType, newValue);
                return undefined;
        }
    };


    const selectRunFunction = () => {
        switch (dataTypeId) {
            case 1:
                return editorMethods.onSetNumericRun;

            case 2:
            case 6:
                return editorMethods.onSetStringRun;

            case 5:
            case 7:
                return editorMethods.onSetItemRun;

            default:
                return undefined;
        }
    }


    const onRun = () => {
        let runFunction = selectRunFunction();
        if (runFunction === undefined) {
            console.log('Cannot determine run function for data type', dataTypeId);
        }
        else if (!editorValidator.validStatus(newStatus)) {
            console.log('Cannot process because the new status is invalid.');
        }
        else {
            const preparedNewValue = prepareValue(newValue);
            if (preparedNewValue !== undefined) {
                runFunction(sequence, newStatus.id, oldValue, preparedNewValue);
            }
            else {
                console.log('Cannot process because the new value is not defined.');
            }
        }
    };


    const onTest = () => {
        // TODO: Implement me.
    };


    useEffect(() => {
        setTestLoaded(false);
        validateFields(sequence, oldStatus, newStatus, oldValue, newValue);

        return () => {
            setTestLoaded(false);
            validateFields(sequence, oldStatus, newStatus, oldValue, newValue);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequence, oldStatus, newStatus, oldValue, newValue]);


    const fieldFunc =
        dataTypeUtil.isIdDataType(dataType) ? DataSeriesIdField : DataSeriesField;


    // Widths in ems.
    const labelWidth = 6;
    const fieldWidth = 25;
    const showOld = false;


    return (
        <Row size={12} align={"top"}>
            <Space size={12} direction={"vertical"}>
                <DataSeriesField
                    title={"Sequence"}
                    id={"sequence"}
                    label={"Sequence"}
                    disabled={true}
                    value={sequence}
                    basicStyle={sequenceStyle}
                    labelWidth={labelWidth}
                    fieldWidth={fieldWidth}
                    height={formRowHeight}
                    onChange={doNothing}
                />
                <Space size={'large'} direction={"horizontal"}>
                    {showOld &&
                    <DataSeriesIdField
                        dataType={dataType}
                        entityDef={entityDefs.dataStatus}
                        title={"Old status"}
                        id={"old-status"}
                        label={"Old status"}
                        disabled={true}
                        value={oldStatus}
                        otherValue={undefined}
                        basicStyle={oldStatusStyle}
                        labelWidth={labelWidth}
                        fieldWidth={fieldWidth}
                        height={formRowHeight}
                        onChange={doNothing}
                    />
                    }
                    <DataSeriesIdField
                        dataType={dataType}
                        entityDef={entityDefs.dataStatus}
                        title={"New status"}
                        id={"new-status"}
                        label={"New status"}
                        disabled={false}
                        value={newStatus}
                        otherValue={undefined}
                        basicStyle={newStatusStyle}
                        labelWidth={labelWidth}
                        fieldWidth={fieldWidth}
                        height={formRowHeight}
                        onChange={onNewStatusChanged}
                    />
                </Space>
                <Space size={'large'} direction={"horizontal"}>
                    {
                        showOld &&
                        fieldFunc({
                            dataType: dataType,
                            title: "Old value",
                            id: "old-value",
                            label: "Old value",
                            disabled: true,
                            value: oldValue,
                            otherValue: newValue,
                            basicStyle: oldValueStyle,
                            labelWidth: labelWidth,
                            fieldWidth: fieldWidth,
                            height: formRowHeight,
                            onChange: doNothing
                        })
                    }
                    {
                        fieldFunc({
                            dataType: dataType,
                            title: "New value",
                            id: "new-value",
                            label: "New value",
                            disabled: false,
                            value: newValue,
                            otherValue: oldValue,
                            basicStyle: newValueStyle,
                            labelWidth: labelWidth,
                            fieldWidth: fieldWidth,
                            height: formRowHeight,
                            onChange: onNewValueChanged
                        })
                    }
                </Space>
                <Row>
                    <EditButtons
                        editPreview={editPreview}
                        onTest={onTest}
                        onRun={onRun}
                        onReset={onReset}>
                    </EditButtons>
                </Row>
                <Row>
                    <InfoArea
                        fieldErrorText={fieldErrorText}
                        errorText={errorText}
                        infoText={infoText}
                    />
                </Row>
            </Space>
        </Row>
    );
};
