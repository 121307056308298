import useModResource from "../../../hooks/useModResource";
import {Button, Col, Row, Spin} from "antd";
import entityDefs from "../entities/entityDefs";
import FilterTag from "../../../tk/bits/FilterTag";
import AddModButton from "./AddModButton";
import ModByType from "./ModByType";
import {FollowsArrow} from "./MixerSymbols";
import {DeleteOutlined} from "@ant-design/icons";
import {useCallback, useEffect} from "react";


const ModServerTransform = ({onDelete, collapsed, model, setModel}) => {
    //console.log("############ ModServerTransform", model)

    const setChild = useCallback(newChild => {
            setModel({
                ...model,
                child: newChild
            })
        },
        [model, setModel]
    );

    const handleDelete = useCallback(() => {
            setChild(undefined)
        },
        [setChild]
    );

    const handleSetModel = useCallback(childModel => {
            setModel({
                ...model,
                child: childModel
            })
        },
        [model, setModel]
    );

    let rsqlInput;
    if (Array.isArray(model.input)) {
        rsqlInput = "id=in=(" + model.input.reduce((acc, entityRef) => acc + (acc === '' ? '' : ',') + entityRef.id, '') + ")";
    } else {
        rsqlInput = model.input;
    }

    const {result, isLoading} = useModResource(model.spec.apiPath, rsqlInput);

    const entityDefOut = entityDefs[model.spec.entityTypeOutput];

    useEffect(() => {
            if (!model.child) {
                return;
            }
            if (result === model.child?.input) {
                return;
            }
            console.log("UPDATE MODEL", model, result)
            setModel({
                ...model,
                child: {
                    ...model.child,
                    input: result
                }
            })
        },
        [model, result, setModel]
    );

    if (collapsed) {
        return (
            <>
                <FollowsArrow/>&nbsp;{model.spec.label} {model.child &&
                <ModByType
                    onDelete={handleDelete}
                    collapsed={true}
                    model={{
                        ...model.child,
                        entityType: entityDefOut.entityType
                    }}
                    setModel={handleSetModel}
                />
            }
            </>
        )
    }

    return (
        <div style={{width: "100%"}}>
            <Row justify='space-between' align="middle">
                <Col>
                    <div>
                        <Spin spinning={isLoading}>
                            <FollowsArrow/> <span>{model.spec.label}</span> <FilterTag extraStyle={{marginLeft: "8px"}}
                                                                                       list={result}
                                                                                       entityDef={entityDefOut}/>
                        </Spin>
                    </div>
                    {!model.child &&
                        <AddModButton
                            entityDef={entityDefOut}
                            setOutMod={spec =>
                                setChild({
                                    ...model.child,
                                    spec: spec
                                })
                            }
                        />
                    }
                </Col>
                <Col>
                    {onDelete &&
                        <Button
                            onClick={() => onDelete()}
                            size="small"
                            type="text"
                        >
                            <DeleteOutlined/>
                        </Button>
                    }
                </Col>
            </Row>
            {model.child &&
                <ModByType
                    onDelete={handleDelete}
                    collapsed={false}
                    model={{
                        ...model.child,
                        entityType: entityDefOut.entityType
                    }}
                    setModel={handleSetModel}
                />
            }
        </div>
    )
}

export default ModServerTransform;