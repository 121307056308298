import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";
import entityDefs from "../pages/editorial/entities/entityDefs";
import {entityRef} from "../lib/entityUtils";


const useJiraImport = (ticket, initEntity) => {
    const [entity, setEntity] = useState(initEntity);
    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
        if (initEntity) return;
        setIsLoading(true);
        ws2Axios
            .get(apiBaseUrl + entityDefs.dataset.apiGet + "/issue/" + ticket)
            .then(response => {
                // TODO: use entityDefs.setDefaultFn (or similar)
                setEntity({
                    ...response.data,
                    datasetStatus: entityRef(-2, 'draft'),
                    loginOption: entityRef(3, 'access rights needed')
                });
                setIsLoading(false);
                setError(undefined);
            })
            .catch(errorResponse => {
                let response = errorResponse.response;
                if (response) {
                    setError(response.data) // with fields: data.code and data.message
                } else {
                    setError({
                        code: "0",
                        message: "Oh no, an error!"
                    })
                }
                setIsLoading(false);
            });
    }, [initEntity, ticket, ws2Axios]);

    return {
        entity,
        isLoading,
        error
    };
};

export default useJiraImport;
