import {entityDuplicates} from "./networkRequests";

const onDuplicationSelfFiltered = (matches, entityId, onDuplication, onFinish) => {
    const filteredMatches = matches.filter(match => {
        return entityId !== match.entityRef.id;
        //relies on the match object structure (entityRef)
    })
    if (filteredMatches.length > 0) {
        onDuplication(filteredMatches)
    } else {
        onFinish()
    }
}
export const submitWithDuplicationWarning = (data, onDuplication, onFinish, ws2Axios, entityDef, entityId) => {
    entityDuplicates(data, ws2Axios, entityDef.findDuplicates)
        .then((matches) => {
                if (matches.length > 0) {
                    if (entityId) {
                        //exclude self-found-error during entity edit
                        onDuplicationSelfFiltered(matches, entityId, onDuplication, onFinish)
                    } else {
                        onDuplication(matches)
                    }
                } else {
                    onFinish();
                }
            }
        ).catch(error => {
        console.log(error);
        if (onFinish) {
            onFinish();
        }
    });
}