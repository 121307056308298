import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";
import useReload from "./useReload";


const useResource = path => {
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {ws2Axios} = useWS2Axios();
    const {reload, reloadDependency} = useReload();

    useEffect(() => {
        setIsLoading(true);
        ws2Axios
            .get(apiBaseUrl + path)
            .then(response => {
                setIsLoading(false);
                setResult(response.data);
            })
            .catch(e => {
                setIsLoading(false);
                setResult([]);
            })
    }, [ws2Axios, path, reloadDependency]);

    return {result, isLoading, reload};
};

export default useResource;
