import {Controller} from "react-hook-form";
import {InputNumber} from "antd";
import React, {useContext} from "react";
import {formStyle} from "../forms/formStyle";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {collectFn, DndHighlight, dropTextFn} from "../../lib/dropIndicatorStyle";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";


const NumberInputField = (props) => {
    const {paramName, min, max, styleOverride = {}, disabled} = props;
    const {setValue, control} = useContext(FormExtContext);
    const [{canDrop, isOver}, drop] = useDrop(() => ({
        accept: [NativeTypes.HTML, NativeTypes.TEXT],
        collect: collectFn,
        drop: item => dropTextFn(item, setValue, paramName)
    }));

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <div ref={drop}>
                    <DndHighlight canDrop={canDrop} isOver={isOver}/>
                    <InputNumber
                        {...field}
                        style={{
                            ...formStyle,
                            minWidth: '120px',
                            ...styleOverride
                        }}
                        min={min}
                        max={max}
                        disabled={disabled}
                    />
                    <FormFieldError paramName={paramName}/>
                </div>
            }
        />
    )
}

export default NumberInputField;