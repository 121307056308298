import ResponsiveArea from "../../../tk/cards/ResponsiveArea";
import React, {useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import FormExt, {FormExtContext} from "../../../tk/forms/FormExt";
import useEntityCreate from "../../../hooks/useEntityCreate";
import FlexLayoutContext from "../../../contexts/FlexLayoutContext";
import ErrorDisplay from "../../../tk/error/ErrorDisplay";
import ModalPop from "../../../tk/bits/ModalPop";
import GenericCreateFooter from "../../../tk/bits/GenericCreateFooter";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {Button} from "antd";
import {handleApplicationError} from "../../../lib/errorHandler";
import {detailComponentFactory} from "./componentFactories";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {submitWithDuplicationWarning} from "../../../lib/duplicateCheck";
import {filterEmptyAttributes} from "../detailforms/AttributesDecorator";


const ModalInForm = (props) => {
    const {entityDef, visible, setVisible, globalErrors, setGlobalErrors, onCreated} = props;
    const [loading, setLoading] = useState(false);
    const {handleSubmit, setError, reset, clearErrors, getValues, defaultEntity} = useContext(FormExtContext);
    const {notifyEntityCreateSuccess, notifyError} = useContext(NotificationsContext);
    const {addEntityCreateTab, addEntityDetailTab} = useContext(FlexLayoutContext);
    const {createEntity} = useEntityCreate(entityDef);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [entityRefList, setEntityRefList] = useState([]);
    const [submitClose, setSubmitClose] = useState(false)
    const {ws2Axios} = useWS2Axios();

    const handleClose = () => {
        setVisible(false);
        reset();
    }

    const handlePop = () => {
        addEntityCreateTab(defaultEntity, getValues(), entityDef);
        handleClose();
    }
    const onDefaultSubmit = (data, openDetailTab) => {
        createEntity(
            data,
            newEntity => {
                handleClose();
                notifyEntityCreateSuccess(newEntity.me, entityDef);
                if (openDetailTab) {
                    addEntityDetailTab(newEntity.me, entityDef);
                }
                if (onCreated) {
                    onCreated(newEntity);
                }
            },
            () => setLoading(false),
            error => {
                notifyError(entityDef.label + ' validation errors. Creation cancelled.');
                handleApplicationError(error, setError, setGlobalErrors);
            }
        );
    }

    const handleOkDuplicate = (onSubmit, onSubmitAndClose) => {
        setIsModalOpen(false);
        const handleOkSubmit = submitClose ? onSubmit : onSubmitAndClose
        handleOkSubmit();
    };
    const handleCancelDuplicate = () => {
        setIsModalOpen(false);
        setLoading(false)
    };
    const duplicationWarning = (duplicateList, submitStatus) => {
        setEntityRefList(duplicateList);
        setIsModalOpen(true);
        setSubmitClose(submitStatus)
    }

    const onSubmit = ({openDetailTab, isDefaultSubmit}) => {
        clearErrors();
        setLoading(true);
        handleSubmit(
            data => {
                if (data.attributes) {
                    data.attributes = filterEmptyAttributes(data.attributes)
                }
                if (entityDef?.findDuplicates?.fromInputToAPIQuery && !isDefaultSubmit) {
                    submitWithDuplicationWarning(
                        data,
                        (matches) => duplicationWarning(matches, openDetailTab),
                        () => onDefaultSubmit(data, openDetailTab),
                        ws2Axios,
                        entityDef)
                } else {
                    onDefaultSubmit(data, openDetailTab)
                }
            },
            (errors, e) => {
                notifyError(entityDef.label + ' validation errors. Creation cancelled.')
                setLoading(false);
            }
        )();
    };

    return (
        <ModalPop
            maskClosable={false}
            width={entityDef.modalWidth || 900}
            open={visible}
            setOpen={setVisible}
            onCancel={handleClose}
            zIndex={1000}
            keyboard={false}
            onPop={handlePop}
            footer={
                <>
                    <Button key="reset" type="link" onClick={() => reset(defaultEntity)}>Reset</Button>
                    <Button key="cancel" onClick={handleClose}>Cancel</Button>
                    <GenericCreateFooter
                        entityDef={entityDef}
                        entityRefList={entityRefList}
                        isModalOpen={isModalOpen}
                        handleOkDuplicate={() => handleOkDuplicate(
                            () => onSubmit({openDetailTab: true, isDefaultSubmit: true}),
                            () => onSubmit({openDetailTab: false, isDefaultSubmit: true})
                        )}
                        handleCancelDuplicate={handleCancelDuplicate}
                        loading={loading}
                        renderItem={() =>
                            <>
                                <Button key="submit" type="primary" loading={loading}
                                        onClick={() => onSubmit({openDetailTab: true, isDefaultSubmit: false})}
                                >Create</Button>
                                <Button key="submitAndClose" type="primary" loading={loading}
                                        onClick={() => onSubmit({openDetailTab: false, isDefaultSubmit: false})}
                                >Create & Close</Button>
                            </>
                        }
                    />
                </>
            }

            destroyOnClose={true}
        >
            <ResponsiveArea>
                <div>
                    {globalErrors?.map((value, index) =>
                        <ErrorDisplay key={index} message={value}/>
                    )}
                    {React.cloneElement(
                        detailComponentFactory(entityDef.entityType),
                        {
                            entityDef
                        }
                    )}
                </div>
            </ResponsiveArea>
        </ModalPop>
    )
}

const EntityCreatorModal = (props) => {
    const {visible, setVisible, entityDef, newDefaults, onCreated, instanceId} = props;
    const [globalErrors, setGlobalErrors] = useState([]);

    const defaults = useMemo(() => {
            if (!entityDef) {
                return {};
            }
            if (newDefaults instanceof Function) {
                return newDefaults();
            }
            if (newDefaults && Object.getOwnPropertyNames(newDefaults).length > 0) {
                return newDefaults;
            }
            let emptyEntity = entityDef.emptyEntity;
            if (entityDef.setDefaultsFn) {
                const defaultEntity = entityDef.setDefaultsFn(emptyEntity);
                return defaultEntity;
            }
            return emptyEntity;
        },
        // Added instanceId
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entityDef, newDefaults, instanceId]
    );

    if (!entityDef || !visible) {
        return <span/>
    }

    return (
        <FormExt
            defaultEntity={defaults}
            updateEntity={(e) => {
                console.log("UPDATE ENTITY", e)
            }}
            instanceId={instanceId}
            setGlobalErrors={setGlobalErrors}
        >
            <ModalInForm
                entityDef={entityDef}
                visible={visible}
                setVisible={setVisible}
                onCreated={onCreated}
                globalErrors={globalErrors}
                setGlobalErrors={setGlobalErrors}
            />
        </FormExt>
    )
}

EntityCreatorModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    entityDef: PropTypes.object,
    newDefaults: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    onCreated: PropTypes.func,
    instanceId: PropTypes.number
}

export default EntityCreatorModal;