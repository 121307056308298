import {getEntity} from "../../../lib/entityRequests";
import entityDefs from "../entities/entityDefs";


const isDefined = object => object !== undefined && object !== null;

const termIdsFromParameterImportResponse = response => {
    if (response === undefined) {
        return [];
    }

    const rows = response?.data?.rows;
    if (rows === undefined) {
        return [];
    }

    const entities = rows.filter(isDefined).map(row => row.dbEntity);
    const terms = entities.filter(e => (isDefined(e)) &&
                                       Array.isArray(e.terms)).flatMap(e => e.terms);
    const ids = terms.filter(term => isDefined(term.term) && isDefined(term.term.id))
                     .map(term => term.term.id);

    // Remove duplicates.
    return [...new Set(ids)];
};


const makeTermMap = (terms) => {
    const map = new Map();
    for (const term of terms) {
        map.set(term.idTerm, term);
    }

    return map;
};


const expandTerms = (termRefs, termMap) => {
    return termRefs.map(ref => termMap.get(ref.term.id));
}


const patchRow = (row, termMap) =>
        isDefined(row.dbEntity) ?
            {
                ...row,
                dbEntity: {
                    ...row.dbEntity,
                    fullTerms: expandTerms(row.dbEntity.terms, termMap)
                }
            } :
            row;


const patchTerms = (response, termMap) => {
    if (response === undefined) {
        return undefined;
    }

    if (response.data === undefined || response.data.rows === undefined) {
        return response;
    }

    const rows = response.data.rows;
    const newRows = rows.filter(isDefined)
                        .map(row => patchRow(row, termMap));

    const newData = {
        ...response.data,
        rows: newRows
    };

    return {
        ...response,
        data: newData
    };
};


export const enrichParameterImportResponse = (ws2Axios, importResponse, onSuccess, onError, setResult, filename) => {
    const termIds = termIdsFromParameterImportResponse(importResponse);
    const entityDef = entityDefs.term;
    const promises = termIds.map(entityId => getEntity(ws2Axios, entityDef, entityId));
    const promise = Promise.all(promises);

    promise.then(terms => {
        const termMap = makeTermMap(terms);
        const response = patchTerms(importResponse, termMap);

        if (setResult !== undefined) {
            setResult({...response.data, filename: filename});
        }

        onSuccess(response);
    })
    .catch(error => onError(error));
};
