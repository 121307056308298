import {useState, useEffect} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useEnumType = (value) => {
    const [enumTypes, setEnumTypes] = useState([]);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
        ws2Axios
            .get(apiBaseUrl + "types/" + value)
            .then(response => {
                setEnumTypes(response.data);
            });
    }, [ws2Axios, value]);

    return enumTypes;
};

export default useEnumType;
