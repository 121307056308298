import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";

const PanUserOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Username" value={entity.username}/>
            <OverviewLine label="Full name" value={entity.fullName}/>
            <OverviewLine label="Email" value={entity.email}/>
            <OverviewLine label="ORCID" type='orcid' value={entity.orcid}/>
            <OverviewLine label="Phone" value={entity.phone}/>
            <OverviewLine label="Institution" value={entity.institution}/>
            <OverviewLine label="Description" value={entity.description}/>
            <OverviewLine label="Staffs" value={entity.staffs} entityDef={entityDefs.staffs}/>
            <OverviewLine label="Groups" value={entity.groups} entityDef={entityDefs.panGroup}/>
            <OverviewLine label="Created at" type='datetime' value={entity.created}/>
            <OverviewLine label="Last login" type='datetime' value={entity.lastLogin}/>
            <OverviewLine label="#Logins" value={entity.logins}/>
        </div>
    )
}

export default PanUserOverview;